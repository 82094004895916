import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 


import {
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({

}));

export default function AuthModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { authsApi, usersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const auth = params.auth

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [mods, setMods] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if(authsApi !== null){
      authsApi.get(auth).then(res => {
        console.log("auth", res);
        if(res.data.name == undefined){
          notify("error", "Denna auktoriteten finns inte.");
        }
        else{
          setName(res.data.name); 
          setDesc(res.data.description);
        }
      });

      usersApi.getWhere("authority", auth).then(res => {
        setUsers(res.data.recordset);
      });

      authsApi.getFunctionality(auth).then(res => {
        setMods(res.data);
      });
    }
  },[authsApi])

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Auktoritet {auth}
      </Header>
      <Body>
        <Grid container style={{overflowY:"auto", height:"95%", overflowX:"hidden"}}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>{name}</b>
            </Typography>
            <Typography variant="p">
              {desc}
            </Typography>
          </Grid>
          <Grid container style={{marginTop:"20px", marginBottom:"20px"}}>
            <Grid item xs={6}>
              Kan öppna <b>{mods.length}</b> moduler
            </Grid>
            <Grid item xs={6}>
              <b>{users.length}</b> användare har <b>{auth}</b> som auktoritet. 
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Funktionalitet
            </Typography>
            <Typography variant="p">
              Användare med <b>{auth}</b> som auktoritet kan öppna följande moduler.
            </Typography>
            <div style={{
              height:"350px", 
              overflowY:"auto", 
              marginTop:"20px", 
              borderLeft: "3px solid #757575"
              }}
            >
              <Table>
                <TableHead>
                  <TableCell><b>Modul</b></TableCell>
                  <TableCell><b>Beskrivning</b></TableCell>
                  <TableCell><b>Auktoriteter</b></TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {mods.map(e => {
                    return(
                      <TableRow key={e.name}>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.description}</TableCell>
                        <TableCell>{e.auths}</TableCell>
                        <TableCell align="right">
                          <IconButton  size="small" onClick={() => addModule(`::mod ${e.name}`)}>
                            <MoreHorizIcon size="small"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  )})
                  }
                </TableBody>
              </Table>
            </div>
          </Grid>
          <Grid item xs={12} style={{marginTop:"20px"}}>
            <Typography variant="h6">
              Användare 
            </Typography>
            <Typography variant="p">
              Följande användare har <b>{auth}</b> som auktoritet.
            </Typography>
            <div style={{
              height:"350px", 
              overflowY:"auto", 
              marginTop:"20px", 
              borderLeft: "3px solid #757575"
              }}
            >
              <Table>
                <TableHead>
                  <TableCell><b>Namn</b></TableCell>
                </TableHead>
                <TableBody>
                  {users.map(e => {
                    console.log(e);
                    return(
                      <TableRow key={e.name}>
                        <TableCell>{e.username}</TableCell>
                        <TableCell align="right">
                          <IconButton  size="small" onClick={() => addModule(`::anv ${e.name}`)}>
                            <MoreHorizIcon size="small"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  )})
                  }
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
