import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { useMainGridContext } from '../../../libs/mainGridContextLib.js';
import { useAppContext } from '../../../libs/contextLib';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import VasaEmojis from '../../general/Emojis';
import FileUpload from '../../general/file-upload';

import EntityEvents from '../../../shared/EntityEvents.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import { getMime } from '../../../shared/file-handler/utils.js';

import EventIcon from '@material-ui/icons/Event';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';

import OfficePrefs from '../../../shared/OfficePrefs.js'

import {
  Typography,
  Button,
  Grid,
  Tooltip,
  Collapse,
  TextField,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button:{
    background:"transparent", 
    border:"none", 
    color:theme.palette.text.primary, 
    cursor:"pointer",
    width:"100%", height:"100%"
  },
}));


export default function ImportantMessage(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser, session } = useAppContext();
  const { notify } = useMainGridContext();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [title, setTitle]                     = useState("");
  const [description, setDescription]         = useState("");
  const [current, setCurrent]                 = useState("title");

  const [file, setFile] = useState("");
  const [mime, setMime]   = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const eventsApi = new EntityEvents(currentUser.username);
  var offPrefs    = new OfficePrefs();

  const onEmojiClick = (emojiObject, event) => {
    if(current == "title"){
      setTitle(prev => prev + emojiObject.native);
    }
    else{
      setDescription(prev => prev + emojiObject.native);
    }
  }

  const resetForm = () => {
  }

  const publish= () => {
    var body = {
      creator: currentUser.username,
      title: title.replaceAll("\n", "::BR::"),
      description: description.replaceAll("\n", "::BR::")
    }

    if(fileUpload !== ""){
      body.fileUpload = fileUpload;
    }

    offPrefs.createMessage(body).then(res => {
      notify("success", "Länk delad!");
    })
    .catch(err => {notify("error", `Kunde inte publicera. ${err}`)});

    const sfs = new SecureFiles();

    if(file.name !== undefined){
      if(session !== undefined){
        // Upload file
        sfs.upload(mime, `uploads/${file.name}`, session.idToken.jwtToken).then(res => {
          var url = res.data.curl;
          axios.put(url, file);
        }) 
      }
    }
  }

  const getFname = (e) => {
    setFile(e.target.files[0]);
    setFileUpload({"filepath": `uploads/${e.target.files[0].name}`})
    var fileType = e.target.files[0].name.split(".")[1];
    setMime(getMime(fileType))
  }

  return(
    <>
      <Grid item xs={12}>
        <Collapse in={showEmojiPicker}>
          <VasaEmojis onEmojiClick={(o, e) => onEmojiClick(o,e)}/>
        </Collapse>
      </Grid>

      <Grid container item xs={12}>
        <Grid 
          item 
          container
          xs={1} 
          justify="center" 
          alignItems="center"
        >
          <IconButton
            onClick={() => setShowEmojiPicker(prev => !prev)}
          >
            <EmojiEmotionsIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <TextField 
            fullWidth 
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => setCurrent("title")}
            multiline
          />
          <TextField 
            fullWidth
            label="Beskrivning (använd detta för längre meddelanden)"
            onChange={(e) => setDescription(e.target.value)}
            onFocus={() => setCurrent("description")}
            value={description}
            multiline
          />
        </Grid>
      </Grid>
      <Grid container className={classes.publishToolbar}>
        <Grid item>
          <FileUpload getFname={(e) => getFname(e)}/>
        </Grid> 
        <Grid container item justify="flex-end" alignItems="flex-end">
          <Button onClick={publish}>
            <PublishIcon /> Publicera
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

