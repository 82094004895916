import React, { useEffect, useState} from 'react';
import { NewCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import axios from 'axios';

import _ from 'lodash';

import { useAppContext } from '../../libs/contextLib';

import {
  Grid,
  CircularProgress
} from '@material-ui/core';

var requestTemplate = {
  "body": null,
  "requestContext":{
    "identity": {
      "cognitoUserIdentity": null 
    }
  }
}

const url = "https://isgg-intelligens.biz";

export default function FinishOrder(params){

  const {services}            = useCustomerContext();
  const {currentService}      = useCustomerContext();
  const {customerInfo}        = useCustomerContext();
  const {setCustomerCreated}  = useCustomerContext();
  const {billingLen}          = useCustomerContext();
  const {billing}             = useCustomerContext();
  const {signed}              = useCustomerContext();
  const {setNextDisabled}     = useCustomerContext();
  const {handleNext}          = useCustomerContext();
  const {setCreatedCid}       = useCustomerContext();
  const {fsg}                 = useCustomerContext();
  const {price}               = useCustomerContext();
  const {comments}            = useCustomerContext();

  const { customersApi }  = useAppContext();
  const { ordersApi }     = useAppContext();
  const { servicesApi }   = useAppContext();
  const { currentUser }   = useAppContext();
  const { commentsApi }   = useAppContext();

  const { notify } = useMainGridContext()

  const [msg, setMsg] = useState("Skapar ny kund...");
  const [progress, setProgress] = useState(0);
  var cid = Object.keys(params).includes("newOrder") ? 
    customerInfo.customerid
    :
    0;
  const newOrder = Object.keys(params).includes("newOrder") ? true : false

  const handleSubmit = async () => {
      setNextDisabled(true);
      requestTemplate.requestContext.identity.cognitoUserIdentity = "admin1";
      
      if(!newOrder){
        const request = requestTemplate;      
        request.body = customerInfo;
        try{
          if(
            customerInfo.customerid !== undefined && 
            customerInfo.customerid !== null &&
            customerInfo.customerid !== "0"
          ){
            var createCustomer = await customersApi.createOld(customerInfo);
            setCreatedCid(customerInfo.customerid);
          }
          else{
            var createCustomer = await customersApi.create(customerInfo);
            cid = createCustomer.data.data.customerid;
            setCreatedCid(cid);
          }
        }
        catch(e){
          notify("error", "Nätverks fel när kunden skapades");
          return;
        }
      }

      else{
        console.log("cid", cid);
      }
      setProgress(25);
      setMsg("Skapar ny order...");
    
      if(billingLen.length > 0 && billing.length > 0 && services.length > 0){

        const totPrice = _.sumBy(services, s => {return parseFloat(s.price.replace(",", "."))});
        var orderBody = {
          "status": "ohanterad",
          "customerid": cid,
          "price": totPrice,
          "subscriptionLength": billingLen,
          "billingInterval": billing,
          "billingOptions": signed ? "NEJ" : "JA",
          "salesman": currentUser.username,
          "fsg": fsg
        }

        try{
          var createOrder = await ordersApi.create(orderBody);
          setProgress(50);
        }
        catch(e){
          notify("error", "Nätverksfel när ordern skapades");
        }
        try{
          await services.map(async (s, i) => {
            setMsg(`Skapar ${s.service}...`);

            var serviceBody = {
              "orderNumber": createOrder.data.output[0].orderId,
              "salesman": currentUser.username,
              "productCode": s.service,
              "amount": s.amount,
              "price": s.price,
              "identifier": "",
              "customerid": cid 
            }

            servicesApi.create(serviceBody);
            setProgress((progress) => progress + (50 / services.length));
            if(i == services.length - 1){
              setNextDisabled(false);
              handleNext();
            }
          }); 
        }catch(e){
          notify("error", "Närverksfel när tjänster skapades");
        }
      }

      try{
        setProgress(75);
        await comments.map(async (c, i) => {
          setMsg("Skriver kommentarer")
          var body = {
            "author": currentUser.username,
            "content": c,
            "regarding": cid 
          }
          commentsApi.create(body);
        })
        setProgress(100);
      }catch(e){
        notify("error", "Nätverksfel när kommentarer skapades");
      }
    }

    useEffect(() => {
      handleSubmit();
    }, []);

    return(
      <Grid container>
        <Grid 
          container 
          alignItems="center" 
          justify="center" 
          direction="column"
          style={{paddingTop:"20%", paddingBottom:"20%"}}
        >
        <CircularProgress variant="static" value={progress}/>
        <p>{msg}</p>
        </Grid>
      </Grid>
    )
}
