import React, {useState, useEffect} from 'react';
import MUIDataTable from 'mui-datatables';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => {

})

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nyanv");
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default function AllUsersModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();

  const { usersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [users, setUsers] = useState([]);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    var start = new Date();
    if(
      (users.length === 0 && usersApi !== null) ||
      (dataFromServer.entity === "users" && usersApi !== null)
    ){
      usersApi.getAll().then(res => {
        console.log("AllUsersModule res: ", res)
        setUsers(res.data.recordset);
        const end = new Date();
        setLoadTime(end - start);
      });
    }
  },[dataFromServer, usersApi, users.length])

  const columns = [
    {
      name: "id",
      label: "AID",
      options:{
        display: false
      }
    },
    {
      name: "profileMedia",
      label: "Profil",
      options:{
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var p = users[tableMeta.rowIndex].profileMedia;
          var u = users[tableMeta.rowIndex].username;
          return(
            <IconButton size="small" onClick={() => addModule(`::anv ${u}`)}>
              <Avatar 
                className={classes.avatar} 
                src={`https://vasa.s3.us-east-2.amazonaws.com/${p}`} 
                alt={u}
                style={{width:50, height:50}}
              />
            </IconButton>
          )
        }
      }
    },

    {
      name: "username",
      label: "Användarnamn"
    },
    {
      name: "authority",
      label: "Auktoritet"
    },
    {
      name: "loggedIn",
      label: "Inloggad",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value){
            return(
              <Typography variant="overline">Ja</Typography>
            )  
          }
          else{
            return(
              <Typography variant="overline">Nej</Typography>
            )  
          }

        }
      }
    },
    {
      name: "active",
      label: "Aktiv",
      options:{
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value){
            return(
              <Typography variant="overline">Ja</Typography>
            )  
          }
          else{
            return(
              <Typography variant="overline">Nej</Typography>
            )  
          }
        }
      }

    },
    {
      name: "created",
      label: "Skapad",
      options:{
        display: false
      }
    }
  ]
  
  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      //console.log("rowsDeleted", Object.keys(rowsDeleted.lookup));
      //deleteServices(Object.keys(rowsDeleted.lookup));
    },
    customToolbar: () => {
      return (<CustomTableToolbar />)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color="#00c853"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Alla användare 
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"95%", padding:"20px"}}> 
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Användardata</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{width: "100%"}}>
              <MUIDataTable
                size="small"
                title={"Användare"}
                data={users}
                columns={columns}
                options={options}
                style={{width:"100%"}}
              />
            </div>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
