import axios from 'axios';
import _ from 'lodash';
import config from './apiConfig.js';

export default class ModuleUtils{
  constructor(){
    //p5oz1qy8cg
    //m15BqUVggE4cNNDYlub5R4iZuKYP6Jo788j4TKpz
    //this.apiId = "ka7gblo67a";
    this.apiId = "p5oz1qy8cg";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.modules = [];
    this.apiKey = "m15BqUVggE4cNNDYlub5R4iZuKYP6Jo788j4TKpz";

  }  

  async getModules(){
    this.modules = await axios
      .get(`${this.endpoint}/read/all`, {headers:{'x-api-key': this.apiKey}})
      .then(res => {
        return res.data.modules;
      })
      .catch(err => {console.log("mod error", err); return []});
  }

  modulesRealCmds(){
    var tmp = this.modules.map(e => ({...e, ["name"]:"::"+e.name}));
    return tmp;
  }

  moduleExists(name){
    var modObj = _.filter(this.modules, o => o.name === name);
    return modObj.length > 0;
  }

  getModule(name){
    return _.filter(this.modules, o => o.name === name)[0];
  }

  hasParams(name){
    const mod = this.getModule(name);
    if(mod.parameters === undefined){
      return false;
    }
    else{
      return mod.parameters;
    }
  }

  modColor(domain){
    switch(domain){
      case "admin":
        return {light: "white", dark: "#eeeeee"};
      case "customers":
        return {light: "#64b5f6", dark:"#2196f3"};
      case "products":
        return {light: "#ffb74d", dark: "#ff9800"};
      case "users":
        return {light: "#81c784", dark:"#00c853"};
      default:
        return {light: "white", dark: "#eeeeee"};
    }
  }

  getAllDomain(domain){
    return _.filter(this.modules, function(o){return o.color === domain});
  }

  getDomain(name){
    var modObj = _.filter(this.modules, function(o){ return o.name === name}) ;
    if(modObj.length > 0){
      return modObj[0].color;
    }
    else{
      return "admin";
    }

  }

  getColor(name){
    var modObj = _.filter(this.modules, function(o){ return o.name === name}) ;
    if(modObj.length > 0){
      return(this.modColor(modObj[0].color));
    }
    else{
      return this.modColor("default");
    }
  }
}
