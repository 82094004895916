import axios from 'axios';

export function upload(file, session, mime, sfs, location, onError, onSuccess){
  if(file.name !== undefined && file.name.length > 0){
    if(session !== undefined){
      sfs.upload(mime, location, session.idToken.jwtToken).then(res => {
        var url = res.data.curl;
        axios.put(url, file).then(res => onSuccess());
      }).catch(err => {onError()});
    }
  }
}

export function openS3Folder(folder, session, sfs, onError, onSuccess){
  sfs.readFolder(folder, session.idToken.jwtToken)
    .then(res => {
      onSuccess(res);
    }).catch(err => onError());
}
