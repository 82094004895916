import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function notifySuccess(msg){
  toast.success(msg, {position:"top-right"})
}

export function notifyError(msg){
  toast.error(msg, {position:"top-right"})
}

export function notifyInfo(msg){
  toast.info(msg, {position:"top-right"})
}

export function notifyWarning(msg){
  toast.warning(msg, {position:"top-right"})
}

export function notifyDefault(msg){
  toast(msg, {position:"top-right"})
}

export function notifyDark(msg){
  toast.default(msg, {position:"top-right"})
}

export default function notify(type, msg){
  switch(type){
    case "success":
      notifySuccess(msg);
      break;
    case "error":
      notifyError(msg);
      break;
    case "info":
      notifyInfo(msg);
      break;
    case "warning":
      notifyWarning(msg);
      break;
    case "default":
      notifyDefault(msg);
      break;
    case "dark":
      notifyDark(msg);
      break;
  }
}
