import React, {useState, useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';

import { useMainGridContext } from '../../libs/mainGridContextLib.js';

export default function NewCustomerDocs(params){

  const { addModule } = useMainGridContext();

  const [module, setModule] = useState({});

  useEffect(() => {
    axios.get("https://isgg-intelligens.biz/modules/read/om").then((res) => {
      console.log(res);
      var d = new Date(res.data.created);
      res.data.created = 
        d.getFullYear() + '-' + (d.getMonth()+1)+ '-' + d.getDate();
      console.log(d);
      setModule(res.data);
    });
  },[]);

  return(
    <Grid continer>
      <Grid item style={{padding: "5px", borderRadius:"5px"}}>
        <Typography variant="h4">
          ::om
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="overline">
              Alias: kundnummer,kund
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="overline">
              Skapad: {module.created} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="primary" onClick={() => addModule("::om")}>
              Öppna
            </Button>
          </Grid>
        </Grid>
        <Typography variant="subtitle2">
          Visar en kunds personuppgifter och låter auktoriserade användare 
          uppdatera dessa. 
        </Typography>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5">
            Beskrivning
          </Typography>
          <Typography>
            I VASA representas en kund av tre delar, personuppgifter, order och
            tjänster. Denna modulen visar kundens personnuppgifter vilket 
            innefattar av namn, personnummer, adress, postnummer, ort, 
            telefonnummer, email. Den länkar även till modulerna för de
            andra komponeterna.
          </Typography>
          <br />
          <Typography variant="h5">
            Auktoriteter
          </Typography>
          <Typography>
            De nedan listade auktoriteterna visar vem som har tillgång till
            modulen och hur de kan ändra på den.
          </Typography>
          <div style={{padding:"10px"}}>
            {Object.keys(module).length > 0 ? module.auths.map(a => <Typography>{a}</Typography>): null}
          </div>
        </Grid>

      </Grid>
    </Grid>
  )

}
