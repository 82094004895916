import axios from 'axios';
import config from './apiConfig.js';

export default class Services{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "x1betjkjm0";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;

  }
  async get(){
    return axios.get(`${this.endpoint}/read/all/${this.user}`);
  }

  async get(oid){
    return axios.get(`${this.endpoint}/read/${oid}/${this.user}`);
  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/read/where/${col}/${val}/${this.user}`);
  }

  async getGroup(col, vals){
    this.requestTemplate.body = {"vals": vals};
    return axios.post(`${this.endpoint}/get/group/${col}`, this.requestTemplate);
  }

  async updateWhere(oid, col, val){
    return axios.post(`${this.endpoint}/update/${oid}/${col}/${val}/${this.user}`);
  }

  async updateOrderWhere(oid, col, val){
    alert(`oid: ${oid} col: ${col} val: ${val}`);
    return axios.post(`${this.endpoint}/update/order/${oid}/${col}/${val}/${this.user}`);
  }

  async delete(sid){
    return axios.delete(`${this.endpoint}/delete/${sid}/${this.user}`);
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, this.requestTemplate);
  }
}

