import axios from 'axios';
import config from './apiConfig.js';

export default class Docs{
  constructor(){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "4e4rop1pcc";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.docs_api_key;
  }

  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async getId(id){
    return axios.get(`${this.endpoint}/read/specific/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );

  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/read/where/${col}/${val}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.reqeustTemplate.body = body;
    return axios.post(`${this.endpoint}/create`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async update(col, val, id){
    return axios.post(`${this.endpoint}/update/${col}/${val}/${id}`,
      {},
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async del(id){
    return axios.delete(`${this.endpoint}/delete/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );

  }
}

