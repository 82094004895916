import React, { useState, useEffect, useRef } from 'react';
import { MainGridContext } from '../libs/mainGridContextLib.js';
import { useComsContext } from '../libs/comsContextLib';
import clsx from 'clsx';

import ModuleUtils from '../shared/ModuleUtils.js';
import { useAppContext } from '../libs/contextLib';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import notify from './general/notifications.js';
import { HotKeys } from 'react-hotkeys';

import Users from '../shared/Users.js';
import Modules from '../shared/Users.js';
import UserPrefs from '../shared/UserPrefs.js';

import axios from 'axios';
import '../css/Grid.css';
import '../css/ResizeBox.css';
import _ from 'lodash';
import {Responsive, WidthProvider} from 'react-grid-layout';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import {
  TextField, 
  Fade,
  Slide,
  Avatar
} from '@material-ui/core';

// Customer page
import {
  CustomerOrderModule, 
  CustomerPageModule, 
  NewPaymentModule, 
  PaymentsModule, 
  NewOrderModule, 
  CustomerEventModule,
  NewCustomerModule,
  OrderBook
} from'./ModuleDomains/Customers';

// Admin 
import {
  EntityEventsModule,
  CreateAuth,
  AllModules,
  AllAuths,
  AuthModule,
  NewModule,
  DocsModule,
  ModuleModule,
  NewFastAccess,
  AllDataStreams,
  InbetFile,
  FormatAg,
  PdfReader,
  PersonalStreams,
  EditDocument,
  Search,
  OpenAIModule,
  GroupModule
} from './ModuleDomains/Admin';

// Products
import {
  AllProductsModule,
  NewProductModule,
  ProductModule,
  CategoryModule
} from './ModuleDomains/Products.js';

// Users
import {
  CreateUserModule,
  AllUsersModule,
  UserModule,
  UserOrders,
  UserCustomers,
  UserSales,
  UserGoals
} from './ModuleDomains/Users';

// MUI
// Icons
import {
  Delete as DeleteIcon,
  Mail as MailIcon,
  Inbox as InboxIcon,
  Add as AddIcon,
  Tab as TabIcon,
  Home as HomeIcon,
  Clear as ClearIcon,
  Save as SaveIcon,
  Explore as ExploreIcon, 
  Pageview as PageViewIcon,
  Face as FaceIcon
} from '@material-ui/icons';

// UI
import {
  IconButton, 
  Typography,
  Button,
  Grid,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tab,
  Drawer,
  Divider
} from '@material-ui/core';

import VasaDialog from '../shared/VasaDialog.js';

import LoggedInSidebar from "./MainUI/LoggedInSidebar";
import BottomToolbar from "./MainUI/BottomToolbar";
import NewsFeed from "./MainUI/NewsFeed";
import WelcomeScreen from "./MainUI/WelcomeScreen";
import MainUserProfile from "./MainUI/MainUserProfile";
import AllModulesDialog from "./MainUI/AllModulesDialog";
import FastCommand from "./MainUI/FastCommand";
import FastAccess from "./MainUI/FastAccess";
import UserPreferences from "./MainUI/UserPreferences";
import OfficeStats from "./MainUI/OfficeStats";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import BugsModule from './VasaAdmin/BugsModule.js';
import StreamModule from './DataStreams/StreamModule'

const keyMap = {
  OPEN_ALL_MODS: "shift+a",
  OPEN_FAST_CMD: "shift+b",
  OPEN_1ST_RECENT: "shift+1",
  OPEN_2ST_RECENT: "shift+2",
  OPEN_3ST_RECENT: "shift+3",
  OPEN_4ST_RECENT: "shift+4",
  OPEN_5ST_RECENT: "shift+5",
  OPEN_6ST_RECENT: "shift+6",
  OPEN_7ST_RECENT: "shift+7",
  OPEN_8ST_RECENT: "shift+8",
  REMOVE_ALL_MODS: "shift+q",
  OPEN_NEW_CUSTOMER: "shift+s",
}

const drawerWidth = 340; 

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bg: {
    height:"100%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '99%',
    position: 'fixed',
    bottom: 0,
  },
  gridToolBar: {
    position: "fixed",
    bottom: 40,
    right: 60,
    background: theme.palette.background.paper
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: "white",
    border: "0px white #000",
    padding: theme.spacing(5, 10, 5),
  },
  menuButton:{
    position:"fixed",
    zIndex: 4,
    left: "0",
  },
  hide:{
    display: "none",
  },
  drawer:{
    width: drawerWidth
  },
  drawerPaper:{
    marginTop:"50px",
    width: drawerWidth,
    boxShadow: "1px 1px 5px 1px #fafafa",
    zIndex: 10,
  },
  drawerHeader:{
    display: 'flex',
    alignItems: theme.spacing(0,1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content:{
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin',  {
      easing: theme.transitions.easing.sharp,
      duration: 1,
    }),
    marginLeft: -drawerWidth
  },
  contentShift:{
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: 1,
    }),
    marginLeft: 0,
  },
  removeOverflow:{
    overflowY: "hidden"
  },
  pageSelected: {
    border:`1px solid ${theme.palette.domain.admin.main}`, 
    borderBottom:`5px solid ${theme.palette.domain.admin.main}`, 
    borderRadius:"5px"
  },
  pageDefault:{
    border:`1px solid ${theme.palette.domain.admin.main}`, 
    borderRadius:"5px"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(5) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
  drawer: {
    width: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
}));

function generateLayout(){
  return _.map(_.range(0,3), function(item, i){
    var y = Math.ceil(Math.random()*4) + 1;
    var x = Math.ceil(Math.random()*4) + 1;
    return {
      x: Math.round(Math.random()*5) * 2,
      y: Math.floor(i/6)*y,
      w: 6,
      h: 10,
      i: i.toString(),
      minW: 4
    }

  })
}

function ListItemModule(params){
  const cmd = params.cmd;
  const value = params.value;
  const openModuleFromDrawer = params.openModule;
  const { currentUser } = useAppContext();
  const index = params.index;

  const [showTextfield, setShowTextfield] = useState(false);
  const [selectedButton, setSelectedButton] = useState(false);

  const handleClick = (cmd) => {
    if(!value.var){
      openModuleFromDrawer(`${cmd}`);
      setSelectedButton(false);
    }
    else{
      setShowTextfield(true);
    }
      
  }

  const keyPress = (e) => {
    if(e.keyCode == 13){
      setShowTextfield(false);
      setSelectedButton(false);
      openModuleFromDrawer(`${cmd} ${e.target.value}`);
    }
  }
  
  return(
    <>
      <ListItem 
        button 
        key={cmd} 
        onClick={() => handleClick(`${cmd}`)} 
        selected={false}
      >
        <ListItemIcon selected={false}>
          {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
        </ListItemIcon>
        <ListItemText primary={value.d} selected={false}/>
      </ListItem>
      {showTextfield ?
        <TextField onKeyDown={keyPress} autoFocus style={{paddingLeft: "5%", width:"95%"}}/>
      :
        null
      }
    </>
  )
}

function ListItemDomain(params){
  const title = params.title;
  const mods = params.mods;
  const openModuleFromDrawer = params.openModule;
  const color = params.color;

  return(
    <>
      <ListItem style={{width:"100%", padding:0}}>
        <ListItemText 
          style={{background: color, paddingLeft:"20px"}}
          primary={<Typography variant="overline">{title}</Typography>} 
        />
      </ListItem>
      {Object.keys(mods).map((cmd, index) => (
        <ListItemModule
          key={index}
          cmd={cmd}
          value={mods[cmd]}
          openModule={params.openModule}
          index={index}
        />
      ))}
    </>
  );
}

function a11yProps(index){
  return{
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const scrollToRef = (res) => window.scrollTo(0,0);

function MainGrid(params){
  const classes = useStyles(); 
  const theme = useTheme();
  const { dataFromServer } = useComsContext();

  const [refs, setRefs] = useState([]);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [reference, setReference] = useState(null);

  const mainRef = useRef(null);
  const executeScroll = () => scrollToRef(mainRef);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { currentUser, moduleUtils, setModuleUtils} = useAppContext();
  const [currentBreakPoint, setBreakPoint] = useState("lg");
  const [mounted, setMounted] = useState(false);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [items, setItems] = useState([]);
  const [modules, setModules] = useState({});
  const [showBottomBar, setShowBottomBar] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fCmd, setFCmd] = useState(false);
  const [activeCmd, setActiveCmd] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [showUsersCmds, setShowUsersCmds]       = useState(false);
  const [showAdminCmds, setShowAdminCmds]       = useState(false);
  const [showProductCmds, setShowProductCmds]   = useState(false);
  const [showCustomerCmds, setShowCustomerCmds] = useState(false);

  const [updateItems, setUpdateItems] = useState(0);
  const [currItems, setCurrItems] = useState([]);

  const [showUserPrefs, setShowUserPrefs] = useState(false);

  const [ans, setAns] = useState({});
  const [commandLine, setCommandLine] = useState("Kommando");
  const [loggedInUsers, setLoggedInUsers] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [currCmd, setCurrCmd] = useState("");

  const handlers = {
    OPEN_ALL_MODS: () => {
      if(dialogOpen){
        setDialogOpen(false);
      }
      else{
        setDialogOpen(true);
      }
    },
    OPEN_FAST_CMD: () => {
      if(fCmd){
        setFCmd(false);
      }
      else{
        setFCmd(true);
      }
    },

    REMOVE_ALL_MODS: () => {
      removeAll();
    },
    OPEN_NEW_CUSTOMER: () => {
      addModule("::nykund");
    },
    OPEN_1ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[0].name);
      }
    },
    OPEN_2ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[1].name);
      }
    },
    OPEN_3ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[2].name);
      }
    },
    OPEN_4ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[3].name);
      }
    },
    OPEN_5ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[4].name);
      }
    },
    OPEN_6ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[5].name);
      }
    },
    OPEN_7ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[6].name);
      }
    },
    OPEN_8ST_RECENT: () => {
      if(localStorage.latest !== undefined){
        addModule(JSON.parse(localStorage.latest)[7].name);
      }
    },

  }

  const removeLatest = () => {
    onRemoveItem(items.length);
  }

  const closeAll= (domain) => {
    switch(domain){
      case "admin":
        setShowUsersCmds(false);
        setShowProductCmds(false);
        setShowCustomerCmds(false);
        break;
      case "users":
        setShowAdminCmds(false);
        setShowProductCmds(false);
        setShowCustomerCmds(false);
        break;
      case "prods":
        setShowAdminCmds(false);
        setShowUsersCmds(false);
        setShowCustomerCmds(false);
        break;
      case "custs":
        setShowAdminCmds(false);
        setShowUsersCmds(false);
        setShowProductCmds(false);
        break;
      default:
        return;
    }
  }

  const myModules = {
    "sales": {d: "min vecka", var: false},
    "kunder": {d: "mina kunder", var: false},
    "anv": {d: "min profil",  var: false}
  }

  const adminModules = {
    "eh": {d: "händelser", var: false},
    "docs": {d: "dokumentation", var: false},
    "mods": {d: "lista moduler", var: false},
    "mod": {d: "specifik modul", var: true},
    "nyaukt": {d: "ny auktoritet", var: false},
    "aukts": {d: "lista auktoriteter", var: false},
    "aukt": {d: "specifik auktoritet", var: true},
  }

  const productModules = {
    "kat": {d: "produktkategorier", var: false},
    "nyprod": {d: "ny produkt", var: false},
    "lager": {d: "lager", var: false},
    "prod": {d: "specifik produkt", var: true},
    "nyaukt": {d: "ny auktoritet", var: false},
  }

  const usersModules = {
    "nyanv": {d: "ny användare", var:false},
    "sales": {d: "användarstatistik", var:true},
    "kunder": {d: "användarkunder", var: true},
    "anv": {d: "profil", var: true}
  }

  const customersModules = {
    "ord": {d: "användares order", var: true},
    "kh": {d: "kundhistorik", var: true},
    "nyinbet": {d: "ny inbetalning", var: true},
    "inbet": {d: "lista kundinbetalningar", var: true},
    "nyorder": {d: "ny order", var: true},
    "om": {d: "kundprofil", var: true},
    "nykund": {d: "ny kund", var: false}
  }

  useEffect(() => {
    setMounted(true);
    if(moduleUtils == null){

      var mods = new ModuleUtils();
      // Async with IIFE
      (async () => {
        await mods.getModules();
      })();

      setModuleUtils(mods);
    }

    var usersApi = new Users(currentUser.username);
    if(dataFromServer.entity !== undefined){
      if(dataFromServer.type == "LOGIN"){
        notify("default", 
          <Grid container>
            <Grid item xs={2}>
              <Avatar size="small" /> 
            </Grid>
            <Grid item xs={10}>
              <Typography style={{color:"black"}}>{dataFromServer.creator} har loggat in! 👋</Typography>
            </Grid>
          </Grid>
        );
      }
      if(
        (dataFromServer.entity.toLowerCase() == "orders" || 
        dataFromServer.entity.toLowerCase() == "customers" ) &&
        dataFromServer.type == "CREATE"
      ){
        notify("default", 
          <Grid container>
            <Grid item xs={2}>
              <Avatar size="small" /> 
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" style={{color:"black"}}>🤑 {dataFromServer.creator} har tjänat pengar!</Typography>
            </Grid>
          </Grid>
        );
      }
      if(dataFromServer.entity.toLowerCase() == "importantmessages" && dataFromServer.type == "CREATE"){
        notify("default", 
          <Grid container>
            <Grid item xs={2}>
              <Avatar size="small" /> 
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" style={{color:"black"}}>{dataFromServer.creator} har delat ett viktigt meddelande!</Typography>
            </Grid>
          </Grid>
        );
      }
      if(dataFromServer.entity == "public"){
        notify("default", 
          <Grid container>
            <Grid item xs={2}>
              <Avatar size="small" /> 
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2">👉{dataFromServer.creator}</Typography>
              <Typography style={{color:"black"}} variant="body1">{dataFromServer.message} </Typography>
            </Grid>
          </Grid>
        );
      }
    }
    usersApi.getWhere("loggedIn", "1").then(res => {
      setLoggedInUsers(res.data.recordset);
    });

  },[dataFromServer]);

  const createBookmark = (i, d, c) => {
    var mod = _.filter(items, function(e){return e.i == i});
    mod = {"name": mod[0].type, color:c, description: d};
    var userPrefs = new UserPrefs(currentUser.username);
    userPrefs.read(currentUser.username).then(res => {
      var bookmarks = res.data.bookmarks;
      if(bookmarks === undefined){
        bookmarks = [mod];
      }
      else{
        bookmarks.push(mod);
      }
      userPrefs.update(currentUser.username, "bookmarks", bookmarks).then(res => {
        notify("success", "Nytt bokmärke skapat");
      }).catch(err => {
        notify("error", `Kunde inte skapa bokmärke. Felmeddelande: ${err}`)
      });
    });
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  var defaultProps = {
    rowHeight: 10,
    width: 500,
    height: 500,
    cols: {lg: 20, md: 20, sm: 12, xs: 8, xxs: 4}
  }

  const [layouts, setLayouts] = useState({lg: generateLayout()});
  const [currentBreakpoint, setBreakpoint] = useState("lg");

  const onBreakPointChange = (breakpoint: string) => {
    setBreakPoint(breakpoint);
  }

  const onLayoutChange = (layout: Layout, layouts: {[string]: layout}) => {

    saveToLS(activeTab, layouts, true)
  }

  const createElement = (el) => {
    const i = el.i;
    return (
      <div key={i} data-grid={el}>
        {handleCreation(el)}
      </div>
    )
  }

  /**
   * Create correct REACT component based on the given el
  **/
  const handleCreation = (el) => {
    var process = el.type.split(" ");
    var module = process[0];

    switch(module){
      case '::docs':
        return(
          <DocsModule 
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
          />
        );
      case '::nykund':
        return(
          <NewCustomerModule 
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
          />
        );
      case '::nyorder':
        return(
          <NewOrderModule 
            id={el.i} 
            auths={el.auths}
            cid={process[1]}
            updateAuths={el.updateAuths}
          />
        );
      case '::inbet':
        return(
          <PaymentsModule
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
            cid={process[1]}
          />
        );
      case '::kh':
        return(
          <CustomerEventModule
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
            cid={process[1]}
          />
        );
      case '::eh':
        return(
           <EntityEventsModule
             id={el.i} 
             auths={el.auths}
             updateAuths={el.updateAuths}
           />
        );
      case '::nyinbet':
        if(process.length == 3){
          return(
            <NewPaymentModule
              id={el.i} 
              auths={el.auths}
              cid={process[1]}
              oid={process[2]}
              updateAuths={el.updateAuths}
            />
          );
        }
        else{
          return(
            <NewPaymentModule
              id={el.i} 
              auths={el.auths}
              cid={process[1]}
              updateAuths={el.updateAuths}
            />
          );
        }
      case '::lager':
        return(
          <AllProductsModule
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
          />
        );
      case '::prod':
        return(
          <ProductModule
            id={el.i} 
            auths={el.auths}
            updateAuths={el.updateAuths}
            product={process[1]}
          />
        );
      case '::om':
        return(
          <CustomerPageModule 
            id={el.i} 
            remove={() => onRemoveItem(el.i)}
            auths={el.auths}
            cid={process[1]}
            updateAuths={el.updateAuths}
          />
        );
      case '::order':
        return(
          <CustomerOrderModule
            id={el.i} 
            remove={() => onRemoveItem(el.i)}
            auths={el.auths}
            updateAuths={el.updateAuths}
            cid={process[1]}
          />
        );
      case '::kat':
        return(
          <CategoryModule
            id={el.i} 
            remove={() => onRemoveItem(el.i)}
            updateAuths={el.updateAuths}
            auths={el.auths}
          />
        );
      case '::nyprod':
        return(
          <NewProductModule
            id={el.i} 
            remove={() => onRemoveItem(el.i)}
            updateAuths={el.updateAuths}
            auths={el.auths}
          />
        );
      case '::nyanv':
        return(
          <CreateUserModule
            id={el.i} 
            remove={() => onRemoveItem(el.i)}
            updateAuths={el.updateAuths}
            auths={el.auths}
          />
        );
      case '::anv':
        if(process.length == 2){
          return(
            <UserModule 
              id={el.i} 
              uname={process[1]} 
              auths={el.auths} 
              updateAuths={el.updateAuths}
            />
          );
        }
        else{
          return(
            <AllUsersModule
              id={el.i} 
              remove={() => onRemoveItem(el.i)}
              auths={el.auths}
              updateAuths={el.updateAuths}
            />
          );
        }
      case '::kunder':
        return(
          <UserCustomers 
            id={el.i} 
            uname={process[1]} 
            auths={el.auths} 
            updateAuths={el.updateAuths}
          />
        );
      case '::ord':
        return(
          <UserOrders 
            id={el.i} 
            uname={process[1]}
            auths={el.auths} 
            updateAuths={el.updateAuths}
          />
        );
      case '::sales':
        return(
          <UserSales 
            id={el.i} 
            uname={process[1]} 
            updateAuths={el.updateAuths}
            auths={el.auths} 
          />
        );
      case '::mods':
        return(
          <AllModules 
            id={el.i} 
            auths={el.auths} 
            updateAuths={el.updateAuths}
          />
        );
      case '::mod':
        return(
          <ModuleModule 
            id={el.i} 
            name={process[1]} 
            auths={el.auths} 
            updateAuths={el.updateAuths}
          />
        );
      case '::nymod':
        return(
          <NewModule 
            id={el.i} 
            auths={el.auths} 
            updateAuths={el.updateAuths}
          />
        );
      case '::nyaukt':
        return(
          <CreateAuth
            id={el.i} 
            auths={el.auths} 
          />
        );
      case '::aukts':
        return(
          <AllAuths
            id={el.i} 
            auths={el.auths} 
          />
        );
      case '::aukt':
        return(
          <AuthModule
            id={el.i} 
            auths={el.auths} 
            auth={process[1]}
          />
        );
      case '::nysnabb':
        return(
          <NewFastAccess
            id={el.i}
            auths={el.auths}
          />
        );
      case '::ds':
        return(
          <AllDataStreams
            id={el.i}
            auths={el.auths}
            updateAuths={el.updateAuths}
          />
        );
      case '::inbetfil':
        return(
          <InbetFile
            id={el.i}
            auths={el.auths}
            updateAuths={el.updateAuths}
          />
        );
      case '::formatAg':
        return(
          <FormatAg
            id={el.i}
            auths={el.auths}
            process={process}
          />
        );
      case '::orderbok':
        return(
          <OrderBook
            id={el.i}
            auths={el.auths}
            process={process}
          />
        );
      case '::pdf':
        return(
          <PdfReader
            id={el.i}
            auths={el.auths}
          />
        );
      case '::mål':
        return(
          <UserGoals
            id={el.i}
            auths={el.auths}
            updateAuth={el.updateAuths}
          />
        );
      case '::bugs':
        return(
          <BugsModule
            id={el.i}
            auths={el.auths}
            updateAuth={el.updateAuths}
          />
        );
      case '::data':
        return(
          <StreamModule
            id={el.i}
            auths={el.auths}
            stream={process.slice(1).join(" ")} 
          />
        );
      case '::pds':
        return(
          <PersonalStreams
            id={el.i}
            auths={el.auths}
            stream={process.slice(1).join(" ")} 
          />
        );
      case '::edok':
        return(
          <EditDocument
            id={el.i}
            auths={el.auths}
          />
        );
      case '::sök':
        return(
          <Search
            id={el.i}
            auths={el.auths}
            term={process.slice(1).join(" ")}
          />
        );
      case '::openai':
        return(
          <OpenAIModule
            id={el.i}
            auths={el.auths}
            term={process.slice(1).join(" ")}
          />
        );
      case '::grupper':
        return(
          <GroupModule
            id={el.i}
            auths={el.auths}
          />
        );
      case '::empty':
        return;
      default:
        alert("Inget gränsnitt har skapats för denna modul ännu...");
        return;
    }

  }

  const handleCommand = (cmd) => {
    var process = cmd.split(" ");
    var module = process[0];
    switch(module){
      case("::stäng"):
        if(process.length > 1){
          removeGroup(process.slice(1));
        }
        else{
          removeAll();
        }
        break;
    }
  }

  const getModule = async (name) => {
    const apiId = "ka7gblo67a";
    const endpoint = `https://${apiId}.execute-api.us-east-2.amazonaws.com/dev`;

    var res = await axios.get(`${endpoint}/read/${name}/${currentUser.username}`);
    return res; 
  }
  
  const removeGroup = (arr) => {
    var newItems = _.reject(items, function(item) {return arr.includes(item.i)});
    setItems(newItems);
  }

  const onRemoveItem = (i) => {
    var newItems = _.reject(items, {i: i});
    setItems(newItems);
    if(currentX == 1){
      setCurrentX(0);
    }
    else{
      setCurrentX(1);
    }
  }

  const removeAll = () => {
    setItems([]);
    setCurrentX(0);
  }

  const addHistoryToLocalStorage= (name) => {
    var latest = [];

    if(localStorage.latest == undefined){
      latest = [{name: name, time: new Date()}];
    }
    else{
      latest = JSON.parse(localStorage.latest);
      latest.push({name: name, time: new Date()});
      if(latest.length > 8){
        latest.shift(0);
      }
    }
    localStorage.setItem("latest", JSON.stringify(latest));
  }

  /**
   * Add element to react grid according to some rules
   *
   * @param name - name of module
   * @param meta - metadata about module
  **/

  const pushToGrid = (name, meta) => {
    var x = 0;
    var y = 0;
    if(currentX == 0){
      y = Math.floor((itemsAmount / 2))*250
      setCurrentX(1);
    }
    else{
      // New row
      x = 10;
      y = Math.floor((itemsAmount / 2))*250

      setCurrentX(0);
    }

    // Create new grid item
    setItems(
      items.concat({
        i: (itemsAmount+1).toString(),
        x: x,
        y: y,
        w: Number(10),
        h: Number(40),
        type: name,
        auths: meta.auths,
        updateAuths: meta.updateAuths,
        static:false,
        draggableCancel:".bodyClass"
      })
    )
    setItemsAmount(p => p = p + 1);
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
  }

  /**
   * Adds a module in the react grid 
   * All module commands are formatted in the following way:
   *  ::MODULE_NAME p1 p2 p3 ... pn
   * where pn is the nth paramete
   *
   * @param name - name of module to be added, will contain params for module
  **/
  const addModule = (name) => {
    // Extract name of module from name
    const moduleName = name.split(" ")[0].replace("::", "");
    executeScroll();

    if(moduleUtils.moduleExists(moduleName)){

      // Set history cookie
      addHistoryToLocalStorage(name);
      
      var meta = moduleUtils.getModule(moduleName);
      pushToGrid(name, meta);
    }
    else{
      alert("Oj! Det finns ingen modul med det namnet finns registrerad.");
      return;
    }

  }

  /**
   * Handle user command from commandline
   * @param event - a js event that contains the key pressed 
  **/
  const handleKeyDown = async (event) =>{
    var value = event.target.value;

    if(event.key == 'Enter' && value.length > 0){
      // Special command that will close all windows
      if(event.target.value.includes("::stäng")){
        handleCommand(event.target.value);
      }
      if(event.target.value.includes("::snb")){
        var fa = [];
        var modName = event.target.value.split(" ")[1];
        if(localStorage.fastAccess === undefined){
          fa = ["nykund", "anv", modName];
        }
        else{
          fa = JSON.parse(localStorage.fastAccess);
          fa.push(modName);
        }
        localStorage.setItem("fastAccess", JSON.stringify(fa));
      }
      if(!event.target.value.includes("::")){
        addModule(`::sök ${event.target.value}`);
      }
      else{
        var name = event.target.value;

        // Add new module on screen
        addModule(name);
      }
      event.target.value = "";
    }

  }

  /**
   * Open a module from the sidebar drawer
   * @param cmd - command to be executed, in this case name of the module and 
   *              potential params for the module
  **/ 
  const openModuleFromDrawer = (cmd) => {
    addModule(`::${cmd}`);
    setDrawerOpen(false);
  }

  const openTab = (index) => {
    // If active tab not homescreen save current items
    var its = JSON.parse(JSON.stringify(getFromLS(index))); 

    var amt = 0;
    if(its["lg"] !== undefined){
      amt = 0;
      its["lg"].map(e => {
        if(Number(e.i) > amt){
          amt = Number(e.i);
        }
      });
    }
    else{
      its["lg"] = [];
    }

    setItemsAmount(p => p = amt+1);

    setLayouts(p => p = its);
    setItems(its["lg"]);
    setCurrentX((its["lg"].length+1)%2)
  }

  useEffect(() => {
    setItems(p => currItems);
  },[updateItems])

  const updateDimensions = () => {
    var itms = updateDimensionsReturnItems();
    setItems(itms);
  }

  const newTab = (name) => {

    var value = {"lg": []};

    let ls = [];
    try{
      ls = JSON.parse(localStorage.getItem("tabs")) || [];
    }
    catch(e){
      /* ignore */
    }

    var keyExists = false;
    ls.map((e,i) => {
      if(e.name === name){
        keyExists = true;
        ls[i].items = value;
      }
    })

    if(!keyExists){
      ls.push({"name": name, "items": value});
    }

    localStorage.setItem("tabs", JSON.stringify(ls))


  }

  const saveToLS = (index, value, layoutBool) => {
    
    let ls = [{}];
    try{
      ls = JSON.parse(localStorage.getItem("tabs")) || [{"name": "home"}];
    }
    catch(e){
      /* ignore */
    }
    if(value.lg == undefined){
      value = {"lg": value};
    }
    if(value.lg.length > 0 && layoutBool){
      items.map((item,i) => {
        value.lg[i]["type"] = item.type;
        value.lg[i]["auths"] = item.auths;
        value.lg[i]["updateAuths"] = item.updateAuths;
        value.lg[i]["draggableCancel"] = item.draggableCancel;
      })
    }

    try{
      ls[index]["items"] = value;
      localStorage.setItem("tabs", JSON.stringify(ls))
    }
    catch(e){
      localStorage.setItem("tabs", JSON.stringify([{"name": "home", "items": []}]))
    }
  }

  const getFromLS = (index) => {
    let ls = [];
    try{
      ls = JSON.parse(localStorage.getItem("tabs")) || [{"name":"home", "items":[]}];
    }
    catch(e){
      /* ignore */
    }
    return ls[index].items;
  }

  /**
   * Updates the width, height and xy positions of all modules on screen and 
   * returns the resulting items array
  **/
  const updateDimensionsReturnItems = () => {
    var itms = items;
    refs.map((e, i) => {
      if(itms[i] !== undefined && e.clientWidth > 0 && e.clientHeight > 0){
        var rect = e.getBoundingClientRect();

        itms[i].w = Math.round(e.clientWidth*(10/886));
        itms[i].h = Math.round(e.clientHeight*(40/790));
        itms[i].x = Math.floor(rect.left*(10/886));
        itms[i].y = Math.floor(rect.top*(40/790));
      }
    });
    return itms;
  }

  return (
    <Fade in={params.visible}>
      <div 
        style={{
          paddingTop:"50px", 
          padding:"10px",
          position:"absolute", 
          height:"100vh",
          width:"100vw",
          overflowX:"hidden",
          overflowY:"auto",
        }}
        ref={mainRef}
        className={items.length > 0 ? classes.removeOverflow : null}
      >
        <HotKeys keyMap={keyMap} handlers={handlers} style={{overflowY:"hidden"}}>
        <Grid container>
          <Grid item xs={2}>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: false,
                [classes.drawerClose]: true,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: false,
                  [classes.drawerClose]: true,
                })
              }}
            >
              <List style={{paddingTop:"60px", width:"100%"}}>
                <ListItem button onClick={() => setShowUserPrefs(true)}>
                  <Tooltip title="Preferenser">
                    <ListItemIcon><FaceIcon/></ListItemIcon>
                  </Tooltip>
                </ListItem>
                <Divider />
                <ListItem button onClick={() => setDialogOpen(true)}>
                  <Tooltip title="Utforska">
                    <ListItemIcon><ExploreIcon /></ListItemIcon>
                  </Tooltip>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText style={{textAlign:"center"}}>
                    <Tooltip title="snabbåtkomst">
                      <Typography variant="overline" style={{textAlign:"center"}}>
                      FA
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                </ListItem>
                {localStorage.fastAccess !== undefined ?
                    JSON.parse(localStorage.fastAccess).map((e, i) =>{
                      var col = "white";
                      if(moduleUtils !== null){
                        col = moduleUtils.getDomain(e.module);
                      }
                      return(
                        <ListItem button onClick={() => addModule(`::${e.module}`)}>
                          <Tooltip key={i} title={`::${e.module}`}>
                            <ListItemText style={{textAlign:"center"}}>{e.customName}</ListItemText>
                           </Tooltip>
                        </ListItem>
                      )
                    })
                    :
                    null
                }
                <ListItem button onClick={() => setShowUserPrefs(true)}>
                  <ListItemIcon><AddIcon /></ListItemIcon>
                </ListItem>
              </List>
              <Divider />
            </Drawer>
          </Grid>
          <Grid item xs={12} style={{paddingLeft:theme.spacing(7) + 1}}>
            <MainGridContext.Provider 
              value={{
                onRemoveItem, 
                addModule, 
                notify,
                createBookmark,
                refs,
                setRefs,
                items,
                setItems,
                currentOwner,
                setCurrentOwner
                }}
            >
              <VasaDialog 
                open={showUserPrefs}
                handleClose={() => setShowUserPrefs(false)}
              >
                <UserPreferences />
              </VasaDialog>

              <FastCommand
                open={fCmd} 
                onClose={() => setFCmd(false)}
                modules={moduleUtils}
                addModule={(m) => addModule(m)}
                defaultCmd={activeCmd}
                setActiveCmd={setActiveCmd}
              />
              <ResponsiveGridLayout
                  {...defaultProps}
                  layouts={layouts}
                  onLayoutChange={onLayoutChange}
                  onBreakpointChange={onBreakPointChange}
                  className={classes.root}
                  draggableCancel='.not-draggable'
                  style={{
                    marginBottom:"20%",
                    paddingBottom:"100px",
                    marginTop:"50px",
                    background: "transparent", 
                }}>
                  {_.map(items, el => createElement(el))}
              </ResponsiveGridLayout>
              <ToastContainer style={{zIndex:10, paddingTop:"50px"}}/>
              <BottomToolbar
                style={{zIndex:100}}
                activeTab={activeTab}
                setActiveTab={(s) => setActiveTab(s)}
                remove={() => removeAll()} 
                createNewTab={(name) => newTab(name)}
                openTab={(i, a) => openTab(i, a)}
              />
            
            {items.length > 0 ?
              <>
                <Autocomplete
                  id="combo-box-demo"
                  options={moduleUtils !== null && moduleUtils.modules.length > 0 ? moduleUtils.modulesRealCmds(): [{name:""}]}
                  getOptionLabel={(option) => option.name}
                  autoComplete
                  style={{ width: "55%", marginLeft:"50px", color:"white", padding:"10px", bottom:"0", position:"fixed"}}
                  renderInput={(params) => <TextField {...params} onKeyDown={handleKeyDown} label="Kommando" />}
                />
              </>
              :
                <Slide direction="up" in={items.length == 0} style={{overflowY:"hidden"}}>
                  <Grid 
                    container 
                    alignItems="center" 
                    justify="center" 
                    style={{position:"absolute", top:"5%", overflowX:"hidden"}}
                    spacing={0}
                  >
                    <Grid item container xs={12} style={{height:"18vh"}}>
                      <MainUserProfile />
                    </Grid>

                    <Grid item container xs={12} style={{marginTop:"5%"}} alignItems="center" justify="center">
                      {localStorage.latest !== undefined ?
                        <Typography variant="h4">
                          Senast
                        </Typography>
                        :
                          null
                      }
                    </Grid>
                    <Grid 
                      item 
                      container xs={12} 
                      alignItems="center" 
                      justify="center" 
                      style={{padding: "50px", overflowX:"hidden", overflowY:"hidden"}}
                    >
                      <Grid item container xs={4}>
                        <WelcomeScreen addModule={(e) => addModule(e)}/> 
                      </Grid>
                    </Grid>
                    <Autocomplete
                      id="combo-box-demo"
                      options={moduleUtils !== null && moduleUtils.modules.length > 0 ? moduleUtils.modulesRealCmds(): [{name:""}]}
                      getOptionLabel={(option) => option.name}
                      autoComplete
                      style={{ width: "50%"}}
                      renderInput={(params) => <TextField {...params} variant="outlined" onKeyDown={handleKeyDown} label="Kommando" />}
                    />
                    <Grid item xs={12}>
                      <OfficeStats />
                    </Grid>
                  </Grid>
                </Slide>
            }
            </MainGridContext.Provider>
          </Grid>
        </Grid>
        </HotKeys>
      </div>
    </Fade>
  )
}

export default MainGrid;
