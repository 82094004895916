import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import _ from 'lodash';

import ImportantMessageEvent from './important-message';
import LinkShareEvent from './link-share';
import CreateEvent from './create';
import UserPostEvent from './user-post';
import LoginEvent from './login';
import Activity from './activity';


import {
  Typography,
  Grid,
  Avatar,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   marginTop:"20px", 
   marginBottom:"20px",
 },
 element:{
   marginBottom:"40px"
 }
 
}));

export default function EventProducer(params){
  const classes = useStyles();
  const theme = useTheme();

  const e = params.event;
  const i = params.index;
  const events = params.events;
  var profile = _.filter(params.users, (u) => {return u.username == e.creator})[0];

  const [component, setComponent] = useState(null);

  const isCreateEvent = () => {
    return(
      e.entity.toLowerCase() == "orders" || 
      e.entity.toLowerCase() == "customers"
    )
  }
  const isGoalEvent = () => {
    return(e.entity.toLowerCase() == "public" && e.type =="GOAL_ACCOMPLISHED")
  }
  const isImportantMessageEvent = () => {
    return(e.entity.toLowerCase() == "importantmessages" && e.type == "CREATE");
  }
  const isLoginEvent = () => {
    return(e.type == "LOGIN" && (i == 0 || i > 0 && events[i-1].type !== "LOGIN"));
  }
  const isLinkEvent = () => {
    return(e.entity == "link")
  }
  const isActivityEvent = () => {
    return(e.entity == "activities" && e.type == "CREATE");
  }


  const isPublicEvent = () => {
    return(e.entity == "public");
  }

  const renderComponent = () => {
    if(profile !== undefined){
      profile = profile.profileMedia;
    }
    if(isGoalEvent()){
      setComponent(
        <div className={classes.element}>
        <CreateEvent 
          profile={profile} 
          event={e} 
          index={i} 
          media="https://vasa.s3.us-east-2.amazonaws.com/public/images/animation_500_klfgfw5t.gif"/>
        </div>
      )
    }
    if(isImportantMessageEvent()){
      setComponent(
        <div className={classes.element}>
          <ImportantMessageEvent profile={profile} event={e}/>
        </div>
      )
    }
    if(isLoginEvent()){
      setComponent(
        <div className={classes.element}>
          <LoginEvent 
            profile={profile} 
            event={e} 
            index={i} 
            events={events} 
            users={params.users}
          />
        </div>
       );
    }
    if(isLinkEvent()){
      setComponent(
        <div className={classes.element}>
          <LinkShareEvent profile={profile} event={e} index={i}/>
        </div>
      )
    }
    if(isActivityEvent()){
      setComponent(
        <div className={classes.element}>
          <Activity 
            event={e} 
            profile={profile} 
            index={i}
            users={params.users}
          />
        </div>
      )
    }

    if(isPublicEvent()){
        setComponent(
          <div className={classes.element}>
            <UserPostEvent profile={profile} event={e} index={i}/>
          </div>
        )
    }
  }

  useState(() => {
    renderComponent();
  },[params.events, params.event])

  return(
    <Grid item xs={12}>
      {component}
    </Grid>
  )
}
