import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import {getTimeFromDate, formatDate} from '../../../shared/dateHelper.js';
import Reactions from '../reactions';
import EntityEvents from '../../../shared/EntityEvents.js';

import {
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link:{
    cursor:"pointer",
    background:theme.palette.background.paper,
    borderBottom:`1px solid ${theme.palette.border.primary}`,
    borderTop:`1px solid ${theme.palette.border.primary}`,
    "&:hover":{
      boxShadow: "1px 1px 5px 1px #e685ff",
    }
  },
  content:{
    padding:"20px"
  }
}));

export default function LinkShareEvent(params){
  const classes = useStyles();
  const theme = useTheme();

  const {session, currentUser} = useAppContext();

  const profile = params.profile;
  const event = params.event;
  const index = params.index;

  const [obj, setObj] = useState({
    title:"", 
    description:"",
    creator:"",
    created:""
  });
  const [media, setMedia] = useState(null);

  useEffect(() => {
    const offPrefs = new OfficePrefs();
    if(event.identifier.split(":").length == 1){
      setObj({...obj, 
          ["title"]:`Något gick fel när ${event.creator} delade en länk :(`
      });
    }
    else{
      const id = event.identifier.split(":")[0];
      const created = event.identifier.split(":")[1];

      offPrefs.getKey("link", id, created).then(res => {
        setObj({
          title: res.data.title,
          description: res.data.description,
          creator: res.data.creator,
          created: res.data.created,
          url: res.data.url,
          imgUrl: res.data.img
        })
        setMedia(res.data.img);
      });
    }
  },[session])

  const remove = () => {
    const entityEventsApi = new EntityEvents(currentUser.username);
    entityEventsApi.deleteEntityEvent(event.id).then(res => {
      setObj({...obj, ["title"]:"[BORTTAGEN]"});
    }).catch(err => console.log("does not delete"));

    const offPrefs    = new OfficePrefs();

    const table = "link";
    const id = event.identifier.split(":")[0];
    const created = event.identifier.split(":")[1];

    offPrefs.delete(table, id, created, currentUser.username).then(res => {
      console.log(res);
    })
    .catch(res => console.log("does not delete"));

  }

  return(
    <Grid container className={classes.link}>
      {obj.title !== undefined ?
        <>
          <EventProfile 
            profile={profile} 
            creator={obj.creator} 
            created={obj.created}
            remove={() => remove()}
          />
          <Grid item xs={6}>
            <img 
              width="100%" 
              src={media} 
              style={{cursor:"pointer"}} 
              onClick={() => window.open(obj.url, "_blank")}
            />
          </Grid>
          <Grid 
            item 
            xs={6} 
            className={classes.content} 
            onClick={() => window.open(obj.url, "_blank")}
          >
            <Typography variant="h5">
              {obj.title}
            </Typography>
            <Typography>
              <br />
              {obj.description}
            </Typography>
            <Typography variant="subtitle2">
              {obj.url}
            </Typography>
          </Grid>
        </>
        :
        <p>Hämtar...</p>
      } 
      <Reactions 
        eventId={event.id}
        like={event.thumbup} 
        confetti={event.confetti} 
        angry={event.angry}
        love={event.love}
      />
    </Grid>
  )
}

