import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';
import { EditableText } from '../general/UpdatableValue.js';
import Orders from '../../shared/Orders.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDate} from '../../shared/dateHelper.js'

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import FaceIcon from '@material-ui/icons/Face';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip
} from "@material-ui/core";

import ReactDataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  closeIcon:{
    margin:"auto",
    marginRight: -12,
    color: "white",
    padding: 0
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover':{
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0,2),
    color: "black",
    height: '100%',
    position: "absolute",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot:{
    color: 'inherit'
  },
  inputInput:{
    color: "black",
    padding: theme.spacing(1,1,1,0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]:{
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}))

const CustomButton = () => {
  return (
    <Button>
      Lägg till
    </Button>
  )
}

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nyprod");
    console.log("clicked on icon!");
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default function AllProductsModule(params){

  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { entityEventsApi, productsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("NONE");

  const [services, setServices] = useState([]);
  const [fetchTime, setFetchTime] = useState(0);
  const [initialServices, setInitialServices] = useState([]);
  const [rows, setRows] = useState([
    {id: 0, title: "Task 1", complete: 20},
    {id: 0, title: "Task 1", complete: 20},
    {id: 0, title: "Task 1", complete: 20}
  ]);
  
  useEffect(() => {
    const start = new Date();
    if(
      (services.length == 0 || dataFromServer.entity == "products") && 
      productsApi !== null)
    {
      productsApi.getAll().then(res => {
        console.log(res.data.recordset);
        setServices(res.data.recordset);
        const end = new Date();
        setFetchTime((end - start)/1000 + " s");
      }).catch(err => {
        notify("error", "Kan inte hämta lager");
      }); 
    }
    else{
      console.log("productsApi", productsApi);
    }
  }, [dataFromServer, productsApi])

  const columns = [
    {
      name: "pid",
      label: "PID",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, UpdateVaue) => {
          return(
            <Button onClick={() => addModule(`::prod ${value}`)}>
              {value}
            </Button>
          )
        },
        sort: true
      }
    },
    {
      name: "name",
      label: "Namn",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="name"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.updateWhere(tableMeta.rowData[0], "name", nv)}
          />)
        },
        sort: true
      }
    },
    {
      name: "category",
      label: "Kategori",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "storage",
      label: "Lager",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="storage"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.updateWhere(tableMeta.rowData[0], "storage", nv)}
          />)
        },
        sort: true
      }
    },
    {
      name: "orderCount",
      label: "Order",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "unitPrice",
      label: "Inköpspris",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="unitPrice"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.updateWhere(tableMeta.rowData[0], "unitPrice", nv)}
          />)}
        },
    },
    {
      name: "minimum",
      label: "Min.",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="minimum"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.updateWhere(tableMeta.rowData[0], "minimum", nv)}
          />)},
        sort: true
      }
    },
    {
      name: "maximum",
      label: "Max.",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="maximum"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.updateWhere(tableMeta.rowData[0], "maximum", nv)}
          />)},
        sort: true
      }
    },

  ];

  const deleteServices = (is) => {
    is.map(i => {
      var pid = services[i].pid;
      console.log("remove", pid);
      productsApi.delete(pid).then(res => notify("info", "Tjänst har blivit borttagen."));
    });
  }

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      console.log("rowsDeleted", Object.keys(rowsDeleted.lookup));
      deleteServices(Object.keys(rowsDeleted.lookup));
    },
    customToolbar: () => {
      return (<CustomTableToolbar />)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Body>
        <Header color="#ff9100" remove={() => onRemoveItem(params.id)}>
          {params.id} Lager
        </Header>
        <Grid container>
          <Grid item>
            <Typography variant="overline">
              Inladdningstid
            </Typography>
            <Typography variant="subtitle2">
              {fetchTime} 
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <div style={{width: "100%"}}>
            <MUIDataTable
              size="small"
              title={"Lager"}
              data={services}
              columns={columns}
              options={options}
              style={{width: "100%"}}
            />
          </div>
        </Grid>
      </Body>
    </Module>
  );
}
