import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import _ from 'lodash';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { FixedSizeList } from 'react-window';

import { 
  getMonthPeriod, 
  formatDateNormal, 
  currentMonthPeriod,
  formatDateTime
} from '../../shared/dateHelper.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  listSubheader:{
    margin:0, 
    padding:0, 
    borderRadius: "10px", 
    paddingLeft: "20px",
  }
}));

function groupModules(mods){
  var groupedList = {
    "admin": [],
    "products": [],
    "users": [],
    "customers": []
  }

  for(var i = 0; i < mods.length; i++){
    if(mods[i].color == "customers"){
      groupedList.customers.push(mods[i]);
    }
    if(mods[i].color == "admin"){
      groupedList.admin.push(mods[i]);
    }
    if(mods[i].color == "products"){
      groupedList.products.push(mods[i]);
    }
    if(mods[i].color == "users"){
      groupedList.users.push(mods[i]);
    }
  }
  return groupedList;
}

function CategoryItems(params){
  const classes = useStyles();
  const elems = params.elems;

  return(
    <>
    {elems.map(e => (
      <ListItem>
        <ListItemText>e</ListItemText>
      </ListItem>
    ))}
    </>
  );
}

function ModuleDescription(params){
  const classes = useStyles();
  const auths = params.auths;
  const description = params.description;
  const created = formatDateNormal(new Date(params.created));

  return(
    <Grid container>
      <Grid item xs={12}>
        {description}
      </Grid>
    </Grid>
  )
}

export default function AllModules(params){
  const classes = useStyles();
  const theme   = useTheme();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { modulesApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    if(modulesApi !== null){
      modulesApi.get().then(res => {
        setModules(res.data.modules);
        setFilteredModules(res.data.modules);
      });
    }
  },[dataFromServer, modulesApi]);

  const handleAutocompChange = (e) => {
    var fltrd= _.filter(modules, (o) => {
      var rgxp = new RegExp(e.target.value, "g");
      return o.name.match(rgxp);
    })
    setFilteredModules(fltrd);
  }

  const listItem = (el) => {
    var conds = [];
    if(el.conditions !== undefined){
      Object.keys(JSON.parse(el.conditions)).map(e => {
        conds.push(e);
      })
    }
    if(el.parameters !== undefined){
      return (
        <Grid container>
          <Grid item xs={10}>
            <Typography>
              {el.name} - {el.parameters}
            </Typography>
           </Grid>
           <Grid item xs={2} justify="flex-end" alignItems="flex-end">
             {conds.map(cond => {
               return(
                 <Typography variant="subtitle2" style={{background:"#8e24aa", borderRadius:"10px", color:"white", textAlign:"center"}}>
                   <span color>{cond}</span>
                 </Typography>
               )
             })}
           </Grid>
        </Grid>
    );
    }
    else{
      return el.name;
    }
  }
  
  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Alla moduler
      </Header>
      <Body>
        <Grid container style={{overflowY:"auto", height:"95%", overflowX:"hidden"}}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Alla moduler</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Visar {filteredModules.length} av {modules.length}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              size="small"
              options={modules}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Sök" variant="outlined" onChange={handleAutocompChange}/>}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <List subheader={<li />}>
                {Object.keys(groupModules(filteredModules)).map(e => {
                  var groups = groupModules(filteredModules);
                  return(
                    <li key={`section-${e}`}>
                      <ul style={{margin:0, padding:0}}>
                        <ListSubheader 
                          className={classes.listSubheader} 
                          style={{borderTop: `3px solid black`, borderBottom: "1px solid gray"}}
                        >
                          <Typography variant="overline">{e}</Typography>
                        </ListSubheader>
                        {groups[e].map(el => (
                          <ListItem key={`item-${e}-${el.name}`} button onClick={() => addModule(`::mod ${el.name}`)}>
                            <ListItemText 
                              primary={listItem(el)} 
                              secondary={
                              <ModuleDescription 
                                auths={el.auths} 
                                description={el.description}
                                created={el.created}
                              />}
                            />
                          </ListItem>
                        ))
                        }
                      </ul>
                    </li>
                  )
                })}
              </List>
            </div>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
