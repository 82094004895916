import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { 
  isAuthorisedWithAsyncCallback
} from "../../shared/moduleAuthority";

import _ from 'lodash';

import Services from '../../shared/Services';
import Customers from '../../shared/Customers.js';

import { ServicesContext } from '../../libs/servicesContextLib.js';
import { useServicesContext } from '../../libs/servicesContextLib.js';
import { OrdersContext } from '../../libs/ordersContextLib.js';
import { useOrdersContext } from '../../libs/ordersContextLib.js';
import { useAppContext } from '../../libs/contextLib';
import { formatDate, formatDateTime } from '../../shared/dateHelper.js';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import {Header, Body, Module} from '../Modules'; 
import { 
  EditableSelect, 
  EditableAutocomplete,
  EditableCounter,
  EditableText
} from '../general/UpdatableValue.js';
import {
  Fade,
  Grid,
  Typography,
  Stepper,
  Step,
  StepButton,
  Button,
  Divider,
  Chip,
  Paper,
  IconButton,
  Select, 
  MenuItem,
  TextField,
  CircularProgress,
  Tooltip,
  Collapse,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody
} from "@material-ui/core";
import ReactDataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

import Autocomplete from '@material-ui/lab/Autocomplete';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ReceiptIcon from '@material-ui/icons/Receipt';

var formatter = new Intl.NumberFormat("sv-SV", {
  style: "currency",
  currency: "SEK"
})

const useStyles = makeStyles((theme) => ({
  button:{
    width: "100%"
  },
  finishButton:{
    width: "100%",
  },
  orderComponent:{
    marginTop: "25px",
    marginBottom: "75",
  },
  darkBg:{
    background:theme.palette.background.default, 
    borderRadius:"5px", 
    padding:"5px"
  },
  orderComponent:{
    padding: "20px", 
    width:"100%", 
    backgroundColor: theme.palette.background.default
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root:{
    '&:nth-of-type(odd)':{
      backgroundColor: theme.palette.background.paper
    }
  }
}))(TableRow);

function getStatusString(step){
  switch(step){
    case 0:
      return "order";
    case 1:
      return "fakturerad";
    case 2:
      return "betalar";
    case 3:
      return "betalt";
    default:
      return "order";
  }
}

function StatusStepper(params){
  const classes = useStyles();

  const { updateOrder, updateModule } = useOrdersContext();
  const { notify }                    = useMainGridContext();
  const { ordersApi }                 = useAppContext();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const steps = getSteps();
  const status = params.status;
  const oid = params.oid;

  const totalSteps = () => {
    return steps.length;
  }

  const completedSteps = () => {
    return Object.keys(completed).length;
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  }

  const allStepsCompleted = () => {
    const reducer = (acc, val) => acc && val;
    if(completedSteps() === 0){
      return false;
    }
    else{
      return Object.values(completed).reduce(reducer);
    }
  }

  const handleNext = () => {
      const newActiveStep = 
        isLastStep() && !allStepsCompleted()
          ?
            steps.findIndex((step, i) => !(i in completed))
          : 
            activeStep + 1;
      setActiveStep(newActiveStep);
  }

  const handleStep = (step) => {
    setActiveStep(step);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    for(var i = activeStep; i >= 0; i--){
      newCompleted[i] = true;
    }
    setCompleted(newCompleted);
    handleNext();
  };

  const handleCompleteIndex = (index) => {
    const newCompleted = completed;
    for(var i = 0; i < 4; i++){
      if(i <= index){
        newCompleted[i] = true;
      }
      else{
        newCompleted[i] = false;
      }
    }
    setCompleted(newCompleted);
    handleNext();
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(prevActiveStep => prevActiveStep * 0);
    setCompleted({});
    updateOrder(oid, "status", "order");
  };

  useEffect(() => {
    switch(status){
      case "order":
        handleCompleteIndex(0);
        setActiveStep(1);
        break;
      case "fakturerad":
        handleCompleteIndex(1);
        setActiveStep(2);
        break;
      case "kund":
        handleCompleteIndex(2);
        setActiveStep(3);
        break;
      case "makulerad":
        break;
    }
  }, [status]);

  const updateStatus = () => {
    if(activeStep > 4){
      setActiveStep(0);
    }
    handleComplete();
    ordersApi.updateWhere(oid, "status", getStatusString(activeStep))
      .then(res => {updateModule(); notify("success", `Status uppdaterad status = ${getStatusString(activeStep)}`)});
  }

  const reverseStatus = () => {
    var dec = 1 + activeStep % 3
    setActiveStep(prevActiveStep => prevActiveStep - dec);
    handleCompleteIndex(activeStep-dec);
    updateOrder(oid, "status", getStatusString(activeStep-dec));
  }

  return(
    <Grid container style={{width: "100%"}}>
      <Typography variant="p" style={{marginTop: "50px"}}>
        Orderstatus
      </Typography>
      {status === "makulerad" ?
          null
        :
        <Stepper nonLinear activeStep={activeStep} style={{width:"100%"}}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton 
                  onClick={() => handleStep(index)} 
                  completed={completed[index]}
                  style={{color:"black"}}
                >
                  {label}
                </StepButton>
              </Step>
            )
          })
          }
        </Stepper>
      }
      {status !== "makulerad" ?
        <div style={{width: "100%"}}>
          {allStepsCompleted() ? (
            <div>
              <Typography>
                Kunden har betalt ordern.
              </Typography>
              <Grid container>
                <Grid xs={6}>
                  <Button 
                    color="secondary" 
                    onClick={reverseStatus} 
                    style={{width:"100%"}}
                  >
                    Ångra 
                  </Button>
                </Grid>
                <Grid xs={6}>
                  <Button 
                    color="primary" 
                    onClick={handleReset} 
                    style={{width:"100%"}}
                  >
                    Förnya 
                  </Button>
                </Grid>
              </Grid>

            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid 
                container 
                justify="center" 
                alignItems="center"
                style={{padding: "25px"}}
              >
                <Typography>{getStepContent(activeStep)}</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button 
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Tillbaka
                  </Button>
                </Grid>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Grid item xs={4}>
                      {params.updatable ?
                        <Button 
                          color="secondary" 
                          style={{width:"100%"}} 
                          onClick={reverseStatus}
                        >
                          Ångra
                        </Button>
                      :
                        null
                      }
                    </Grid>
                  ): (
                    <Grid item xs={4}>
                      {params.updatable ?
                        <Button 
                          variant="outlined" 
                          onClick={handleComplete}
                          color="primary"
                          className={classes.finishButton}
                          onClick={
                          completedSteps() === totalSteps() - 1 ? 
                              null 
                              : 
                              updateStatus
                          }
                        >
                          {completedSteps() === totalSteps() - 1 ? 
                            'Klar' 
                            : 
                            'Avklara'
                          }
                        </Button>
                        :
                        null
                      }
                    </Grid>
                ))}
                <Grid item xs={4}>
                  <Button
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  > 
                    Nästa
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )
          }
        </div>
        :
        <div style={{width:"100%"}}>
          <Grid 
            container 
            alignItems="center" 
            justify="center" 
            direction="column"
          >
            <Typography>
              Kunden är makulerad
            </Typography>
            <Button 
              style={{width: "100%"}} 
              color="secondary"
              onClick={(event) => updateOrder(event, "status", "order")}
            >
              Avmakulera kund
            </Button>
          </Grid>
        </div>
      }
    </Grid>
  )
}

function AddNewService(params){
  const classes = useStyles();
  const theme   = useTheme();

  const { productsApi, servicesApi, ordersApi } = useAppContext();
  const { updateOrder, updateModule }           = useOrdersContext();
  const { notify }                              = useMainGridContext();

  const order     = params.order;
  const updatable = params.updatable;

  const [visible, setVisible]         = useState(false);
  const [service, setService]         = useState("");
  const [amount, setAmount]           = useState("");
  const [price, setPrice]             = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [fsgToAdd, setFsgToAdd]       = useState(0);
  const [unitPrice, setUnitPrice]     = useState("");
  const [loading, setLoading]         = useState(false);

  useEffect(() => {
    if(productsApi !== null){
      productsApi.getAll().then((res => {
        setAllProducts(res.data.recordset);
      }));
    }
  },[productsApi])

  const updateValue = (callback, value) => {
    callback(value);
  }

  const showFsg = () => {
    if(service.length > 0 && amount.length > 0 && price.length > 0){
      var prodData = getProductData(service);
      setFsgToAdd(calculateFsg(price, amount, prodData.unitPrice));
    }
  }

  const getProductData = (name) => {
    return(_.filter(allProducts, function(o){return o.name == service})[0]);
  }

  const updUnitPrice = () => {
    var prod = getProductData(service);
    setUnitPrice(`Inköpspris: ${prod.unitPrice}`);
  }

  const calculateFsg = (price, amount, unitPrice) => {
    return parseFloat(price)*0.8 - unitPrice*amount;
  }

  const closeOperation = () => {
    setVisible(prev => !prev);
    setService("");
    setPrice("");
    setAmount("");
    setFsgToAdd(0);
  }

  const addService = () => {
    var serviceBody = {
      "orderNumber": order.orderId,
      "salesman": order.salesman,
      "productCode": service,
      "amount": amount,
      "price": price,
      "identifier": "",
      "customerid": order.customerid 
    }
    closeOperation();
    setLoading(true);
    servicesApi.create(serviceBody).then(res => {
      notify("success", `Ny tjänst har lagts till på order ${order.orderId}`);
      addToFsg(service, price, amount);
      updateModule();
      setLoading(false);
    })
    .catch(err => notify("error", `Kan inte läggs till tjänst. Flemeddelande: ${err}`));
  }

  /**
   * Adds correct amount of FSG to existing order in DB
   * @param prod - the product to add
   * @param price - the price of the product
  **/
  const addToFsg = (prod, price, amount) => {
    var prodData = _.filter(allProducts, function(o){return o.name == prod})[0];
    var orderFsg = order.fsg == null ? 0 : order.fsg;
    var fsg = calculateFsg(price, amount, prodData.unitPrice);
    var fsgNew = orderFsg + fsg;
    if(fsg < 0){
      notify("info", `Tillagd FSG är mindre än 0. FSG: ${fsg}`);
    }
    if(isNaN(fsgNew)){
      notify("error", 
        "Kan inte lägga till ny tjänt. Felmeddelandde FSG är inte en giltig siffra.");
    }
    else{
      ordersApi.updateWhere(order.orderId, "fsg", fsgNew).then(res => {
        setLoading(false);
        notify("success", `Orderns FSG är uppdaterad. ${formatter.format(fsgNew - orderFsg)} tillagd.`);
      }).catch(err => {
        notify("error", `Kunde inte uppdatera order. Felmeddelande: ${err}`);
      })
    }
  }

  return(
    <>
      <Grid container xs={12} style={{padding:"20px", borderTop:theme.palette.text.primary}}>
        <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
          {updatable ?
              <IconButton onClick={() => setVisible(prev => !prev)}>
                {loading ? 
                  <CircularProgress />
                :
                  <Tooltip title="Lägg till en tjänst">
                    <AddIcon />
                  </Tooltip>
                }
              </IconButton>
            :
              null
          }
        </Grid>
      </Grid>
      <Collapse in={visible}>
        <Grid 
          container 
          xs={12} 
          spacing={2} 
          style={{background:theme.palette.background.paper, borderRadius:"5px"}}
        >
          <Grid item xs={4}>
            <Autocomplete 
              options={allProducts}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => 
                <TextField 
                  label="Tjänst" 
                  helperText={unitPrice} 
                  autoFocus {...params}
                />
              }
              style={{width:"100%"}}
              onChange={(e,v) => setService(v.name)}
              onBlur={() => {showFsg();updUnitPrice()}}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              type="number" 
              size="small"
              fullWidth 
              label="Antal"
              onChange={(e) => updateValue((v) => setAmount(v), e.target.value)}
              onBlur={() => showFsg()}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              fullWidth 
              label="Pris"
              size="small"
              onChange={(e) => setPrice(e.target.value)}
              onBlur={() => showFsg()}
            />
          </Grid>
          <Grid container justify="flex-end" alignItems="flex-end" style={{padding:"10px"}}>
            <Grid item xs={8}>
              <Typography variant="subtitle2">
                FSG: {formatter.format(fsgToAdd)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button 
                fullWidth 
                color="secondary" 
                onClick={() => closeOperation()}
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth color="primary" onClick={() => addService()}>
                Lägg till
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}

function OrderComponent(params){

  const classes   = useStyles();
  const theme     = useTheme();

  const { updateModule }                        = useOrdersContext();
  const { notify }                              = useMainGridContext();
  const { servicesApi, ordersApi, productsApi } = useAppContext();

  const [rows, setRows] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const updatable = params.updatable;
  const status    = params.status;
  const order     = params.order;

  useEffect(() => {
    params.rows.map((row,i) => {row.index = i})
    setRows(params.rows);
    if(productsApi !== null && params.updatable){
      productsApi.getAll().then(res => {
        setAllProducts(res.data.recordset);
      })
    }
  },[params.rows, productsApi]);

  const updateElement = (sid, col, value) => {
    servicesApi.updateWhere(sid, col, value);
  }

  const onGridRowsUpdated = ({fromRow, toRow, updated}) => {
    if(params.updatable){
      const service = rows[fromRow];
      const col = Object.keys(updated)[0];
      const val = Object.values(updated)[0];

      const rs = rows.slice();
      for(let i = fromRow; i <= toRow; i++){
        rs[i] = { ...rs[i], ...updated};
      }
      setRows(rs);
      updateElement(service.serviceId, col, val);
    }
    else{
      notify("error", "Du har inte behörighet att uppdatera denna modulen");
    }
  }

  const calculateFsg = (price, amount, unitPrice) => {
    return parseFloat(price)*0.8 - unitPrice*amount;
  }

  const deleteService = (sid, product, price, amount) => {
    servicesApi.delete(sid).then(res => {
      notify("success", `Tjänst ${sid} har blivit borttagen`)
      removeFsg(product, price, amount)
      updateModule();
    });
  }

  /**
   * Remove correct amount of FSG from existing order in DB
   * @param prod - the product to add
   * @param price - the price of the product
  **/
  const removeFsg = (prod, price, amount) => {
    var prodData  = _.filter(allProducts, function(o){return o.name == prod})[0];
    var orderFsg  = order.fsg == null ? 0 : order.fsg;
    var fsg = calculateFsg(price, amount, prodData.unitPrice);
    var fsgNew    = orderFsg - fsg;
    if(fsg < 0){
      notify("info", `Borttagna FSG är mindre än 0. FSG: ${fsg}`);
    }
    ordersApi.updateWhere(order.orderId, "fsg", fsgNew).then(res => {
      notify("success", `Orderns FSG är uppdaterad. ${formatter.format(orderFsg - fsgNew)} togs bort.`);
    })
  }


  return(
    <ServicesContext.Provider value={{onGridRowsUpdated}}>
      <Grid container className={classes.orderComponent}>
        <Grid item xs={12}>
          <Typography 
            variant="p" 
            style={{marginBottom: "10px", marginTop: "25px"}}
          >
            Tjänster
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  <b>Skapad</b> 
                </TableCell>
                <TableCell colSpan={6}>
                  <b>Tjänst</b>
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <b>Antal</b> 
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <b>Pris</b> 
                </TableCell>
                {updatable ?
                  <TableCell align="right" colSpan={1}>
                    <b>Ta bort</b> 
                  </TableCell>
                :
                  null
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow>
                 <TableCell colSpan={6}>
                   {formatDateTime(new Date(row.dateOfSale))}
                 </TableCell>
                 <TableCell colSpan={6}>
                   <EditableAutocomplete
                     updateFunction={(e) => servicesApi.updateWhere(row.serviceId, "productCode", e)}
                     list={allProducts}
                     value={row.productCode}
                     updatable={params.updatable}
                     nameCol="name"
                   />
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <EditableCounter 
                      value={row.amount}
                      updatable={params.updatable}
                      updateCallback={(e) => 
                        servicesApi.updateWhere(row.serviceId, "amount", e)}
                    />
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <EditableText
                      onUpdate={(e) => 
                        servicesApi.updateWhere(row.serviceId, "price", e)}
                      value={row.price}
                      formatter={(e) => formatter.format(e)}
                      updatable={params.updatable}
                    />
                  </TableCell>
                  {updatable ? 
                    <TableCell align="right" colSpan={1}>
                      <Tooltip title="Ta bort tjänst">
                        <IconButton 
                          size="small" 
                          onClick={() => {
                            deleteService(row.serviceId, row.productCode, row.price, row.amount);
                          }}
                        >
                          <RemoveIcon fontSize="small"/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    :
                    null
                  }
                </StyledTableRow>
              ))
              }
            </TableBody>
          </Table>
          <AddNewService 
            visible={true} 
            order={order}
            updatable={updatable}
          />
          <StatusStepper 
            status={status} 
            oid={order.orderId} 
            updatable={updatable}
          />
          <br />
          <br />
          <Divider variant="middle"/>
        </Grid>
      </Grid>
    </ServicesContext.Provider>

  )

}

function OrderSummary(params){
  const classes = useStyles();
  const theme = useTheme();

  const { notify, addModule } = useMainGridContext();
  const {ordersApi, servicesApi, usersApi, productsApi} = useAppContext();

  const [showOrderComponent, setShowOrderComponent] = useState(false);
  const [updating, setUpdating] = useState(false)
  const [order, setOrder] = useState(params.order);
  const [allUsers, setAllUsers] = useState([]);

  const rows = params.service == undefined ? [] : params.service;
  const apiEndpoint = "https://isgg-intelligens.biz/orders/update"

  const updateModule = () => params.updateModule();

  useEffect(() => {
    setOrder(params.order)
    if(usersApi !== null && params.updatable){
      usersApi.getAll().then(res => {
        setAllUsers(res.data.recordset)
      });
    }
  },[params.order, usersApi]);

  const toggleOrderComponent = () => {
    setShowOrderComponent(showOrderComponent => !showOrderComponent);
  }

  const updateOrder = (event, col, val) => {
    if(params.updatable){
      setUpdating(true);
      ordersApi.updateWhere(params.order.orderId, col, val)
        .then(res => {
          updateModule();
          setUpdating(false);
        });
      }
    else{
      notify("error", "Du har inte behörighet att uppdatera denna modulen.")
    }
  }

  const getBattery = () => {
    switch(order.status){
      case "order":
        return <Battery20Icon />
      case "fakturerad":
        return <Battery50Icon />
      case "betalar":
        return <Battery60Icon />
      case "betalt":
        return <Battery90Icon />
      case "makulerad":
        return <BatteryAlertIcon />
    }
  }

  const updateSalesman = async (val) => {
    ordersApi.updateWhere(order.orderId, "salesman", val);
    return servicesApi.updateOrderWhere(order.orderId, "salesman", val);
  }

  return(
    <OrdersContext.Provider value={{updateOrder, updateModule, setUpdating}}>
      <Grid 
        container 
        style={{width: "100%", marginTop:"25px", marginBottom:"25px"}}
      >
        <Paper elevation={2} className={classes.orderComponent}>
            <Grid container>
              <Grid container>
                <Grid item xs={12} alignItems="flex-end" justify="flex-end">
                  <Typography 
                    variant="overline" 
                    style={{padding: "0", margin: 0}}
                  >
                    {"skapad " + formatDate(order.orderDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    Order {order.orderId}
                    <Tooltip title="Visa mer">
                      <IconButton onClick={() => toggleOrderComponent()}>
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Typography variant="overline">Signerad: {
                    order.billingOptions == "NEJ" ||
                    order.billingOptions === "NULL" ||
                    order.billingOptions === null ||
                    order.billingOptions === "inget" ? `❌ (${order.billingOptions})` :`✅ (${order.billingOptions})`
                  }</Typography>
                  {updating ? <CircularProgress /> : null}
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title="Status">
                    <Chip
                      label={order.status}
                      icon={getBattery()}
                      color={order.status === "makulerad" ? 
                        "secondary" : "primary"
                      }
                      style={order.status === "makulerad" ? 
                        {backgroundColor:"#f50057"} 
                        : 
                        {backgroundColor: '#26a69a'}}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={2}>
                  {order.status !== "makulerad" ?
                    <Tooltip title="Tryck för att makulera order">
                      <Chip
                        label={"Makulera"}
                        onClick={(e) => updateOrder(e, "status", "makulerad")}
                        icon={<DeleteIcon />}
                        color="default"
                      />
                    </Tooltip>
                    :
                    <Tooltip title="Tryck för att avmakulera kund">
                      <Chip
                        label={"Avmakulera"}
                        onClick={(e) => updateOrder(e, "status", "order")}
                        icon={<RestoreFromTrashIcon/>}
                        color="default"
                      />
                    </Tooltip>
                  }
                </Grid>
                <Grid item xs={2}>
                  {order.status !== "uppsagd" ?
                    <Tooltip title="Tryck för att säga upp">
                      <Chip
                        label={"Säg upp"}
                        onClick={(e) => updateOrder(e, "status", "uppsagd")}
                        icon={<CloseIcon />}
                        color="default"
                      />
                    </Tooltip>
                    :
                    <Tooltip title="Tryck för att göra till order">
                      <Chip
                        label={"Avbryt uppsägning"}
                        onClick={(e) => updateOrder(e, "status", "order")}
                        icon={<RestoreFromTrashIcon/>}
                        color="default"
                      />
                    </Tooltip>
                  }
                </Grid>

              </Grid>
            </Grid>
            <Divider />
            <Grid container style={{background:theme.palette.background.paper, padding:"10px"}}>
              <Grid item xs={3}>
                <Typography variant="overline">
                  Tjänster
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="overline">
                 Säljare 
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="overline">
                 Betalning 
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="overline">
                 Avtalslängd 
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="overline">
                  Autogiro 
                </Typography>
              </Grid>
              <Divider />
              <Grid item xs={3}>
                {rows.map(s => {
                  return(<>
                    <Typography variant="subtitle2">
                      {s.productCode}
                    </Typography>
                  </>)
                })
                }
              </Grid>
              <Grid container item xs={3}>
                <EditableAutocomplete
                  value={order.salesman}
                  list={
                    allUsers
                  }
                  dbLabel="salesman"
                  id={order.orderId}
                  url={apiEndpoint + `/${order.orderId}/salesman/`}
                  updateFunction={(val) => updateSalesman(val)}
                  updateParent={() => updateModule()}
                  updatable={params.updatable}
                  nameCol={"username"}
                />
              </Grid>
              <Grid container item xs={2}>
                <EditableSelect
                  value={order.billingInterval}
                  list={
                    [
                      "Engångsfaktura", 
                      "Månadsvis", 
                      "Kvartalsvis", 
                      "Halvårsvis", 
                      "Årsvis"
                    ]
                  }
                  dbLabel="billingInterval"
                  updateFunction={(val) => ordersApi.updateWhere(order.orderId,"billingInterval", val)}
                  id={order.orderId}
                  url={apiEndpoint + `/${order.orderId}/billingInterval/`}
                  updatable={params.updatable}
                />
              </Grid>
              <Grid container item xs={2}>
                <EditableSelect
                  value={order.subscriptionLength}
                  list={
                    [
                      "1 Månad", 
                      "3 Månader", 
                      "6 Månader", 
                      "1 År", 
                      "2 År",
                      "3 År",
                      "4 År",
                      "5 År",
                      "Obegränsat",
                    ]
                  }
                  dbLabel="subscriptionLength"
                  id={order.orderId}
                  updateFunction={(val) => ordersApi.updateWhere(order.orderId,"subscriptionLength", val)}
                  url={apiEndpoint + `/${order.orderId}/subscriptionLength/`}
                  updatable={params.updatable}
                />
              </Grid>
              <Grid container item xs={2}>
                <EditableSelect
                  value={order.billingOptions}
                  list={
                    [
                      "JA",
                      "NEJ"
                    ]
                  }
                  dbLabel="subscriptionLength"
                  id={order.orderId}
                  updateFunction={(val) => ordersApi.updateWhere(order.orderId,"billingOptions", val)}
                  url={apiEndpoint + `/${order.orderId}/subscriptionLength/`}
                  updatable={params.updatable}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container xs={12}>
              <Grid item container xs={9} alignItems="flex-end" justify="flex-end">
                <Typography variant="subtitle1">
                  FSG
                </Typography>
              </Grid>
              <Grid item xs={3} alignItems="flex-end" justify="flex-end">
                <EditableText
                  onUpdate={(e) => 
                    ordersApi.updateWhere(order.orderId, "fsg", e)}
                  value={order.fsg}
                  formatter={(e) => formatter.format(e)}
                  updatable={params.updatable}
                  variant="h5"
                />
              </Grid>
            </Grid>
            <Grid style={{overflow: "visible"}}>
              <Collapse in={showOrderComponent} >
                <OrderComponent 
                  rows={rows} 
                  status={order.status} 
                  order={order}
                  updatable={params.updatable}
                /> 
              </Collapse>
            </Grid>
        </Paper>
      </Grid>
    </OrdersContext.Provider>
  );
}

function getSteps() {
  return ["Order", "Fakturerad", "Kund"];
}

function getStepContent(step){
  switch(step){
    case 0:
      return "Kunden har godkänt en order som består av ovanstående tjänster.";
    case 1:
      return "Kunden har blivit fakturerad.";
    case 2:
      return "Kunden har betalat ordern";
    default:
      return "Detta är inte en status, något har gått fel...";
  }
}

export default function CustomerOrderModule(params){
  const classes = useStyles();
  const theme = useTheme();
  const [canUpdate, setCanUpdate] = useState(false);

  const [tmpOrderServices, setTmpOrderServices] = useState([]);

  const { onRemoveItem, addModule, notify } = useMainGridContext();
  const cid = params.cid;
  const [orders, setOrders] = useState([]);
  const [services, setServices] = useState({});
  const [showOrderComponent, setShowOrderComponent] = useState(false);
  const { servicesApi, ordersApi, customersApi, currentUser } = useAppContext();
  
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const steps = getSteps();

  const columns = [
    { key: 'serviceId', 
      name: 'ID',
    },
    { key: 'productCode', name: 'Tjänst', width: 200},
    { key: 'amount', name: 'Antal'},
    { key: 'price', name: 'Pris'},
    { key: 'salesman', name: 'Säljare'},
    { key: 'dateOfSale', name: 'Försäljningsdatum', width:200}
  ];

  const [rows,setRows] = useState([]);

  const getService = (oid) => {
    servicesApi.getWhere("orderNumber", oid)
      .then(res => {
        services[oid] = res.data.recordset;
        setRows(res.data.recordset);
        setServices(services);
      }).catch(err => {
        console.log("net error");
      });
  }

  useEffect(() => {
    if(ordersApi !== null){
      updateModule();
      isAuthorisedWithAsyncCallback(
        params.updateAuths, 
        currentUser, 
        (body)=>isOwner(body)
      ).then(updatable => {
        setCanUpdate(updatable)
      });
    }
  },[ordersApi]);

  const updateModule = () => {
    ordersApi.getWhere("customerid", cid)
      .then(res => {
        setOrders(res.data.recordset);
        res.data.recordset.map(order => {
          getService(order.orderId);
        })
      }).catch(err => {console.log("net error")});
  }

  const toggleOrderComponent = () => {
    setShowOrderComponent(showOrderComponent => !showOrderComponent);
  }

  /**
   * User is owner if the current salesman of the cid is the current user
   *
   * @param body - parameter from Modules.jsx
  **/
  const isOwner = async (body) => {
    var cApi =  new Customers(body.username);
    return cApi.get(cid).then(res => {
      var sman = res.data.recordset[0].salesman;
      var hasOwnership = sman == body.username;
      return(sman == body.username);
    })
  }

  const getCidServices = () => {
    var ss = new Services(currentUser.username);
    ss.getWhere("customerid", cid).then(res => {
      setTmpOrderServices(res.data.recordset);
    });
  }

  return(
    <Module 
      auths={params.auths} 
      remove={() => onRemoveItem(params.id)}
      ownership={(body) => isOwner(body)}
    >
      <Header 
        color={theme.palette.domain.customers.main} 
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Order {cid}
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"95%", padding:"20px"}}> 
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={4} style={{paddingTop:"4%"}}>
              <Typography variant="h4">
                <b>Order{canUpdate ? '*' : null}</b>
              </Typography>
            </Grid>
            <Grid container xs={8}>
              <Paper style={{width:"100%", padding:"10px", background:theme.palette.background.default}}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <Tooltip title="Visa inbetalningar på denna ordern.">
                      <Button 
                        onClick={(e) => addModule(`::inbet ${cid}`)}
                        color="primary"
                        fullWidth
                      >
                        Inbetalningar
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title="Öppna kundhistorik.">
                      <Button 
                        onClick={(e) => addModule(`::kh ${cid}`)}
                        color="primary"
                        fullWidth
                      >
                        Händelser 
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title="Öppna kundsida">
                      <Button 
                        onClick={(e) => addModule(`::om ${cid}`)}
                        color="primary"
                        fullWidth
                      >
                        Kundsida 
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid 
                    container 
                    xs={12} 
                    style={{
                      background:theme.palette.background.paper, 
                      borderRadius:"5px", 
                      marginRight:"10px", 
                      marginLeft:"10px", 
                      marginBottom:"10px", 
                      padding:"5px"
                    }}
                    spacing={2}
                  >
                    <Grid container item xs={3} justify="flex-end" alignItems="flex-end">
                      <Typography variant="subtitle2">
                        <b>Sammanfattning</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">
                        Antal: {orders.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{height:"100%", overflow:"visible"}}>
            {orders.map((elem) => {
              return (
                <OrderSummary 
                  order={elem} 
                  service={services[elem.orderId]}
                  updateModule={() => updateModule()}
                  updatable={canUpdate}
                />
              );
            })
            }
          </Grid>
          <Grid>
            <button onClick={() => getCidServices()}>lista alla tjänster</button>
            {tmpOrderServices.map(e => (
              <Typography>Produkt: {e.productCode} Pris: {e.price}</Typography>
            ))}
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
