import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useComsContext } from '../libs/comsContextLib';

import { useAppContext } from '../libs/contextLib';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FaceIcon from '@material-ui/icons/Face';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';

import '../App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 20,
    flexGrow: 1,
    color: 'black',
    overflowX:"hidden",
  },
   menuButton:{
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  navBar:{
    zIndex: 2000,
    height: 35,
    background: theme.palette.background.paper,
    position:"fixed"
  },
  toolbar:{
    zIndex: 20,
    minHeight: 35 
  },
  module: {
    height: "100%"
  },
  tabSelected:{
    border: `1px solid`,
    borderRadius:"5px",
    borderColor: theme.palette.type == 'light' ? 
      '#d9d9d9'
      :
      `#383838`,
  },
  tab:{
    borderLeft:"1px solid",
    borderRadius:"5px",
    borderColor: theme.palette.type == 'light' ? 
      '#d9d9d9'
      :
      `#383838`,
  }
}));

function TabGroup(params){
  const classes = useStyles();
  const theme   = useTheme();
  
  const addTab              = params.addTab;
  const removeTab           = params.removeTab;
  const openTab             = params.openTab;
  const setCurrentTabIndex  = params.setCurrentTabIndex;
  const currentTabIndex     = params.currentTabIndex;
  const tabs                = params.tabs;

  return(
    <Grid container>
      {tabs.map((e, i) => (
        <Grid 
          key={i}
          item 
          xs={2} 
          className={i == currentTabIndex ? classes.tabSelected : classes.tab}
        >
          <Grid container>
            <Grid item xs={11}>
              <Button 
                onClick={() => setCurrentTabIndex(i)} 
                fullWidth 
                size="small"
              >
                {e.name}
              </Button>
            </Grid>
            <Grid item xs={1} style={{paddingTop:"3px"}}>
              <IconButton 
                style={{height:5, width:5}} 
                onClick={() => removeTab(i)}
              >
                <CloseIcon style={{transform:"scale(0.7)"}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))
      }
      <Grid item xs={2} style={{paddingTop:"4px"}}>
        <IconButton size="small" onClick={() => addTab()}>
          <AddIcon fontSize="small"/>
        </IconButton>
      </Grid>
    </Grid>
  )
}

function MainNavBar(props){
  
  const classes = useStyles();
  const theme   = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, userProfile, prefersDarkMode, setPrefersDarkMode} = useAppContext();
  const { connected } = useComsContext();

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  }

  const updateTheme = () => {
    var theme = "light";
    if(!prefersDarkMode){
      theme = "dark";
    }
    localStorage.theme = theme; 
    setPrefersDarkMode(prev => !prev);

  }

  // Runs everytime Main is rendered
  return(
    <AppBar position="static" className={classes.navBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <img 
            src="https://vasa.s3.us-east-2.amazonaws.com/isgg-logo.png" 
            alt="logga"
            height="35"
            style={{paddingRight:"20px"}}
          />
        </div>
        <TabGroup 
          addTab={props.addTab}
          removeTab={props.removeTab}
          setCurrentTabIndex={props.setCurrentTabIndex}
          currentTabIndex={props.currentTabIndex}
          tabs={props.tabs}
        />
        <IconButton onClick={() => updateTheme()}>
          <EmojiObjectsIcon style={{color: prefersDarkMode ? "white" : "black"}}/>
        </IconButton>
        <Chip
          label={connected ? <CloudDoneIcon /> : <CloudOffIcon />}
        />
        {currentUser.username !== null ? 
          <Chip
            avatar={<Avatar src={`https://vasa.s3.us-east-2.amazonaws.com/${userProfile}`} alt={"ddd"}/>}
            clickable
            onClick={handleUserMenuClick}
            variant="outlined"
          /> 
          : 
          <Chip
          icon={<CircularProgress size="1rem" color="inherit"/>}
          />
        }
        <Menu 
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}
        >
          <MenuItem onClick={handleUserMenuClose}>
            <FaceIcon fontSize="small"/> Profil
          </MenuItem>
          <MenuItem onClick={handleUserMenuClose}>
            <SettingsIcon fontSize="small"/>
            Inställningar
          </MenuItem>
          <MenuItem onClick={props.handleLogout}>
            <ExitToAppIcon fontSize="small"/> 
            <Typography variant="inherit">Logga ut</Typography> 
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  ) 
}

export default MainNavBar;
