import axios from 'axios';
import config from './apiConfig.js';

export default class EntityEvents{
  constructor(){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "ept14i5t70";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.events_api_key
  }
  async deleteEntityEvent(id){
    return axios.delete(`${this.endpoint}/entityEvent/delete/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }
  async getAll(){
    return axios.get(`${this.endpoint}/entityEvent/read`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getPage(page, pageSize){
    return axios.get(`${this.endpoint}/entityEvent/readPage/${page}/${pageSize}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getPagePublic(page, pageSize){
    return axios.get(`${this.endpoint}/entityEvent/read/public/page/${page}/${pageSize}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async get(id){
    return axios.get(`${this.endpoint}/entityEvent/read/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/entityEvent/read/where/${col}/${val}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async updateWhere(id, col, val){
    return axios.post(`${this.endpoint}/entityEvent/update/where/${id}/${col}/${val}`,
      {},
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getLastOp(id, idCol, op){
    return axios.get(`${this.endpoint}/entityEvent/last/op/${id}/${idCol}/${op}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getLastNPublic(n){
    return axios.get(`${this.endpoint}/entityEvent/read/last/${n}`,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/entityEvent/create`, 
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }
    });
  }

  async getEntityEvents(identifier, idColumn){
    return axios
      .get(`${this.endpoint}/entityEvent/read/entity/${identifier}/${idColumn}`,
        {headers:{
          'x-api-key': this.apiKey
        }
      });
  }

  async getUserEventOverInterval(user, start, end, pageSize, page){
    return axios
      .get(`${this.endpoint}/entityEvent/read/entity/user/${user}/${start}/${end}/${pageSize}/${page}`,
        {headers:{
          'x-api-key': this.apiKey
        }
      });
  }

  async reactions(id){
    return axios
      .get(`${this.endpoint}/entityEvent/has/reacted/${id}`,
        {headers:{
          'x-api-key': this.apiKey
        }
      });
  }
}

