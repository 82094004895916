import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';
import { useInbetFileContext, InbetFileContext } from '../../libs/inbetFileContextLib';

import axios from 'axios';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Files from '../../shared/Files.js';
import {formatDateTime} from '../../shared/dateHelper.js';

import _ from 'lodash';

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  Fade,
  Paper,
  IconButton,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TabelContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {FcMoneyTransfer} from 'react-icons/fc';
import {SiWebmoney} from 'react-icons/si';
import {GiReceiveMoney} from 'react-icons/gi';
import {GrMoney} from 'react-icons/gr';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const formatter = new Intl.NumberFormat('sv-SV', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 2
})

const useStyles = makeStyles((theme) => {

})

function CustomerElement(params){
  const classes = useStyles();
  const theme = useTheme();
  const { addModule } = useMainGridContext();

  const { customersApi } = useAppContext();

  const {
    customers,
    setCustomersFound,
    setCustomersNotFound, 
  } = useInbetFileContext();


  const payment = params.payment

  const [name, setName]           = useState("");
  const [cid, setCid]             = useState("")
  const [amt, setAmt]             = useState("");
  const [email, setEmail]         = useState("");
  const [mun, setMun]             = useState("");
  const [salesman, setSalesman]   = useState("");
  const [telephone, setTelephone] = useState("");
  const [zipCode, setZipCode]     = useState("");

  useEffect(() => {
    if(params.fileType == "ag"){
      setCid(payment.cid);
      setAmt(payment.amt);

      var c = _.filter(customers, function(o) {
        return o.customerid == payment.cid
      }); 

      if(c.length > 0){
        setName(c[0].name);
        setCustomersFound(prev => prev + 1);
      }
      else{
        setCustomersNotFound(prev => prev + 1);
      }
    }
    else if(params.fileType == "bg"){
      setAmt("10");
      setName(decodeURI(params.payment.name));
      getBgCustomer(payment);
    }
    else if(params.fileType == "kn"){
      var c = _.filter(customers, function(o) {
        return o.customerid == payment
      }); 

      if(c.length > 0){
        setName(c[0].name);
        setCid(c[0].customerid);
        setCustomersFound(prev => prev + 1);
      }
      else{
        setCustomersNotFound(prev => prev + 1);
      }
    }
  },[customers])

  const getBgCustomer = (data) => {

    const nameArrData = data.name.toLowerCase().split(" ");
    const surnameData = nameArrData[nameArrData.length - 1]; 
    const familyNameData = nameArrData[0];
    
    var result = _.filter(customers, function(o) {return o.zipCode == data.zip});
    if(result.length > 0){
      var found = false;
      for(var i = 0; i < result.length; i++){
        const nameArr = result[i].name.toLowerCase().split(" ");
        var surname = nameArr[0];
        const familyName = nameArr[nameArr.length - 1];
        
        if(familyNameData == familyName || nameArr.includes(surnameData)){
          setName(result[i].name);
          setCid(result[i].customerid);
          found = true;
          setCustomersFound(prev => prev + 1);
          break;
        }
      }
      if(!found){
        setCid("Hittas ej");
        setCustomersNotFound(prev => prev + 1);
      }
    }
  } 

  return(
    <Grid container xs={12} style={{
      marginTop: "30px",
      padding:"10px"
      }}>
      <Grid container xs={12}>
        <Grid container xs={12} style={{paddingBottom:"10px"}}>
          <Grid container xs={6}>
            {name == "" ?
              <Grid container xs={12}>
                <Grid item xs={2}>
                  <AccountCircleIcon fontSize="large"/>
                </Grid>
                <Grid item xs={10}>
                  <Paper style={{
                    background:theme.palette.background.paper, 
                    borderRadius:"10px",
                    padding:"10px"
                    }}>
                      <Typography>
                      </Typography>
                  </Paper>
                </Grid>
              </Grid>
            :
              <>
                <Grid item xs={2}>
                  <AccountCircleIcon fontSize="large"/>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="p">
                    {name}
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
          <Grid container item xs={6} alignItems="flex-end" justify="flex-end">
            <Grid xs={2}>
              <Tooltip title="Visa kundens ordrar">
                <IconButton size="small" onClick={() => addModule(`::order ${cid}`)}>
                  <ShoppingCartIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid xs={2}>
              <Tooltip title="Visa kundhistorik">
                <IconButton size="small" onClick={() => addModule(`::kh ${cid}`)}>
                  <HistoryIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid xs={2}>
              <Tooltip title="Visa tidigare inbetalningar">
                <IconButton size="small" onClick={() => addModule(`::inbet ${cid}`)}>
                  <ReceiptIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Visa kundprofil">
            <Button 
              size="small" 
              onClick={() => addModule(`::om ${cid}`)}
              variant="outlined"
              fullWidth
            >
              Kundnummer: <b>{cid}</b>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Registrera inbetalning">
            <Button 
              size="small" 
              color="primary" 
              onClick={() => addModule(`::nyinbet ${cid}`)}
              variant="outlined"
              fullWidth
            >
              Betalning: {formatter.format(payment.amt)}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

function AllFiles(params){
  const classes = useStyles();
  const theme = useTheme()
  const { addModule } = useMainGridContext();

  const {
    existingFiles,
    openRawFile,
    deleteFile
  } = useInbetFileContext();


  useEffect(() => {
  },[])

  return(
    <Grid container xs={12}>
      <Table size="small">
        <TableHead>
          <TableCell>
            <b>Namn</b>
          </TableCell>
          <TableCell>
            <b>Storlek</b>
          </TableCell>
          <TableCell>
          </TableCell>

        </TableHead>
        <TableBody>
          {existingFiles.map((f) => {
            return(
              <TableRow 
                hover 
                style={{cursor:"pointer"}}
              >
                <TableCell 
                  onClick={() => addModule(`::formatAg ${f.Key.replace("upload/", "")}`)}
                >
                  <InsertDriveFileIcon /> {f.Key}
                </TableCell>
                <TableCell
                  onClick={() => addModule(`::formatAg ${f.Key.replace("upload/", "")}`)}
                >
                  {f.Size}
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => deleteFile(f.Key)}>
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Grid>
  )
}

function SideBar(params){
  const classes = useStyles();
  const theme = useTheme()

  const {
    getFname,
    existingFiles,
    openRawFile,
    fContent,
    setShowAllFiles
  } = useInbetFileContext();


  const styledTreeItem = (label, value) => {
    return(
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Typography>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
  }, []);

  const fileElement = (label, icon) => {
    return(
      <Grid container xs={12}>
        <Grid>
          <Typography variant="subtitle2">
            {icon}
          </Typography>
        </Grid>
        <Grid>
          {label.length > 10 ? 
            <Typography>{label.slice(0,10)}...{label.slice(label.length - 7)}</Typography> 
            : 
            <Typography>{label}</Typography>
          }
        </Grid>
      </Grid>
    )
  }

  return(
    <div style={{
      position:"fixed", 
      width:"27%", 
      height:"90%", 
      background:theme.palette.background.default,
      borderRadius:"10px",
      padding:"10px",
    }}>
      <Grid container xs={12} 
        style={{marginTop:"10px", marginBottom:"10px"}}
      >
        <Grid container xs={12}>
          <Grid item xs={3}>
            <FolderIcon style={{color:"#ababab"}}/>
          </Grid>
          <Grid item xs={9}>
            <Typography style={{color:"#ababab"}}>
              <b>Innbetalningsfiler</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid 
          container xs={12} 
          justify="flex-end" 
          alignItems="flex-end"
          style={{paddingBottom:"20px"}}
        >
          <Button
            variant="contained"
            component="label"
            variant="outlined"
            size="small"
            color="primary"
            fullWidth
          >
            <CloudUploadIcon fontSize="small"/> Ladda upp 
            <input
              type="file"
              hidden
              onChange={getFname}
            />
          </Button>
        </Grid>
      </Grid>
      <TreeView 
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem 
          nodeId="1" 
          label={styledTreeItem("Alla", existingFiles.length)} 
          onClick={() => setShowAllFiles(true)}
        />
        <TreeItem nodeId="2" label="Senaste">
          {
            existingFiles.slice(existingFiles.length - 5).map((e,i) => {
              return(
                <TreeItem 
                  label={
                    fileElement(e.Key.replace("upload/", ""), <InsertDriveFileIcon fontSize="small"/>)
                  }
                  onClick={() => {
                    openRawFile(e.Key.replace("upload/", ""));
                    setShowAllFiles(false);
                  }}
                />
              )
          })
          }
        </TreeItem>
        <TreeItem 
          nodeId="10" 
          label={"Hur funkar detta?"} 
        />
      </TreeView>

      <Grid item xs={12}>
        <Typography style={{color:"#ababab", marginTop:"25px"}}>
          <b>Filinläsning</b>
        </Typography>
        {
          fContent.length == 0 ? 
            <Typography>Ingen fil har valts</Typography>
            :
            <Grid>
              {
                params.customersFound + params.customersNotFound >= fContent.length ? 
                  <Typography variant="subtitle2">Formatering klar</Typography> 
                  : 
                  <Grid>
                    <Typography variant="subtitle2">
                      Formatering pågår
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={100*(params.customersFound + params.customersNotFound)/fContent.length} 
                    />
                  </Grid>
              }
              <Typography>{fContent.length} st kunder lästes in.</Typography>
              <Typography>{params.customersNotFound} st kunder hittades inte.</Typography>
              <Typography>{params.customersFound} st kunder hittades.</Typography>
            </Grid>
        }
      </Grid>
    </div>
    );
}

function MainContent(params){
  const classes = useStyles();
  const theme = useTheme();

  const {
    fileUploadInProgress, 
    fContent, 
    customers, 
    fileType, 
    setCustomersNotFound,
    setCustomersFound,
    showAllFiles,
    deleteFile,
    selectedFile,
    existingFiles,
    openRawFile,
  } = useInbetFileContext();


  useEffect(() => {
  },[selectedFile, existingFiles]);

  const content = () => {
    if(showAllFiles){
      return (
        <AllFiles 
          files={existingFiles} 
          openRawfile={(f) => openRawFile(f)}
          deleteFile={(f) => deleteFile(f)}
        />
      )
    }

    if(selectedFile == null){
      return(<Typography>Ingen fil har valts</Typography>)
    }
    else{
      return(
        <>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="p">
              <b>{selectedFile.Key}</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              Storlek: {selectedFile.Size} B
            </Typography> 
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              Typ: <b>{fileType.toUpperCase()}</b>
            </Typography> 
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              Senast ändrad: {formatDateTime(new Date(selectedFile.LastModified))}
            </Typography> 
          </Grid>
        </Grid>
        {
          fileUploadInProgress ?
            <Grid 
              container 
              xs={12} 
              alignItems="center" 
              justify="center"
              style={{paddingTop:"20%", paddingBottom: "20%"}}
            >
              <Grid container xs={12} alignItems="center" justify="center">
                <CircularProgress />
              </Grid>
              <Grid container xs={12} alignItems="center" justify="center">
                <Typography>
                  Hämtar fil... 
                </Typography>
              </Grid>
            </Grid>
          :
          <Grid item xs={12}>
            {fContent.map((e) => {
              return(
                <CustomerElement 
                  payment={e} 
                  customers={customers} 
                  fileType={fileType} 
                  setCustomersNotFound={(e) => setCustomersNotFound(e)}
                  setCustomersFound={(e) => setCustomersFound(e)}
                />
              )}
            )
            }
          </Grid>
        }
        </>
      )
    }
  }
  return(
    <Grid container xs={12}>
      {content()}
    </Grid>
  )
}

export default function FormatAg(params){
  const classes = useStyles();
  const theme = useTheme()

  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { session, customersApi } = useAppContext();

  const [firstLoad, setFirstLoad] = useState(true);
  
  const [fContent, setFContent] = useState([]);
  const [fname, setFname] = useState("");
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("ag");
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [showAllFiles, setShowAllFiles] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [customersNotFound, setCustomersNotFound] = useState(0);
  const [customersFound, setCustomersFound] = useState(0);
  const [formattingInProgress, setFormattingInProgress] = useState(false);

  // Utility class to handle file operations in S3
  const Fs = new Files("admin1");

  /**
   * Selects a file and puts it in the main content are
   *
   * @param e - a JS event
  **/
  const getFname = (e) => {
    if(e.target.files[0].name.split(".")[1] !== "txt"){
      notify("error", "Filen måste ha .txt format");
    }
    else{
      setFname(e.target.files[0].name);
      setFile(e.target.files[0]);
      getPresignedUrl(e.target.files[0]);
    }
  }

  /**
   * A presigned URL from AWS grants temporary privileges to the AWS cloud. 
   * In this case a URL that allows file uploads i requested
   *
   * @param file - file to upload
  **/
  const getPresignedUrl = (file) => {
    setFileUploadInProgress(true);
    Fs.initiateFileUpload("text/plain", `upload/${file.name}`, session.idToken.jwtToken).then(res => 
      {
        var url = res.data.curl;
        axios.put(url, file);
        notify("success", `filen: ${file.name} är uppladdad`);
        setFileUploaded(true);
        openRawFile(file.name);
      }
    )
    .catch(err => {
      notify("error", `Kunde inte ladda upp fil ${file.name}, ${err}`)
    });
  }

  const deleteFile = (fname) => {
    Fs.deleteFile(fname).then(res => {
      notify("success", `Filen ${fname} har blivit borttagen.`);
    })
    .catch(err => {
      notify("error", `Filen ${fname} kunde inte tas bort.`)
    });
  }

  /**
   * Download file from '/upload' folder in S3 and determine the type of file
   *
   * @param f - name of file to download from /upload
  **/
  const openRawFile = (f) => {
    setCustomersFound(0);
    setCustomersNotFound(0);
    setFileUploadInProgress(true);

    setSelectedFile(_.filter(existingFiles, function(e) {
      return "upload/"+f == e.Key
    })[0]);

    Fs.readFile(f).then(res => {
      axios.get(res.data.url).then(res => {
        setFileUploadInProgress(false);
        notify("success", `Filen ${f} har lästs in`);
        if(f.toLowerCase().includes("bankgiro")){
          setFileType("bg");
          readBg(res.data);
          return;
        }
        else if(f.toLowerCase().includes("autogiro") || f.toLowerCase().includes("ag")){
          setFileType("ag");
          readAg(res.data);
          return;
        }
        else{
          setFileType("kn");
          readKn(res.data);
          return;
        }
      })
    })
    .catch(err => {
      notify(`Kunde inte läsa in file: ${f}`);
    });
  }

  /**
   * Download file from S3 use given filelist to get raw file
   *
   * @param f - the name of the file to be downloaded
   * @param fs - list of existing files in the folder
  **/
  const openRawFileWithFiles = (f, fs) => {

    setCustomersFound(0);
    setCustomersNotFound(0);

    setFileUploadInProgress(true);

    setSelectedFile(_.filter(fs, function(e) {
      return "upload/"+f == e.Key
    })[0]);

    Fs.readFile(f).then(res => {
      axios.get(res.data.url).then(res => {
        setFileUploadInProgress(false);
        notify("success", "Filen har lästs in");
        if(f.toLowerCase().includes("bankgiro")){
          setFileType("bg");
          readBg(res.data);
          return;
        }
        else if(f.toLowerCase().includes("autogiro") || f.toLowerCase().includes("ag")){
          setFileType("ag");
          readAg(res.data);
          return;
        }
        else{
          setFileType("kn");
          readKn(res.data);
          return;
        }
      })
    })
    .catch(err => {
      notify(`Kunde inte läsa in file: ${f}`);
    });
  }


  /**
   * Parses customerid files
   *
   * @param data - row of cids to be shown in list.
  **/
  const readKn = (data) => {
    var list = data.split("\n");
    var tmp = [];
    var cids = [];

    for(var i = 0; i < list.length; i++){
      var content = list[i];
      cids.push(content);
    }

    customersApi.getGroup(cids).then(res => {
      setCustomers(res.data.recordset);
    });

    setFContent(cids);

    setCustomersFound(0);
    setCustomersNotFound(0);
  }

  /**
   * Formats Autogiro file. The customerid is extracted from the rows
   *
   * @param data - list of rows from .txt file
  **/
  const readAg = (data) => {
    var list = data.split("\n");
    var tmp = [];
    var cids = [];

    for(var i = 1; i < list.length; i++){
      var content = list[i].split(" ")[4]
      if(content !== undefined){
        var cid = content.substring(content.length - 6);
        var amt = content.substring(16, content.length-18)
        if(cid !== ""){
          cids.push(cid);
        }
        tmp.push({cid: cid, amt: amt});
      }
    }

    customersApi.getGroup(cids)
      .then(res => {
        setCustomers(res.data.recordset)
      });
    setFContent(tmp);
    setCustomersFound(0);
    setCustomersNotFound(0);

  }
  
  /**
   * Formats bankgiro file by extracting zipCode, name, adress and municipality,
   * The zipCode is used to find the customerid
   *
   * @param data - rows of the .txt file
  **/
  const readBg = (data) => {
    var list = data.split("\n");
    var tmp = [];
    var customers = [];
    var zips = [];

    var currIndex = 0;
    var currCust = {};

    for(var i = 2; i < list.length-1; i++){
      var row = String(list[i]).trim();
      var rowNext = String(list[i+1]).trim();
      // Customer info only present if 2nd char is not 0
      if(row[1] == "0" && isNaN(rowNext[2])){
        var arr = row.split(" ");
        var amt = arr[arr.length - 1].substring(21,24);
        currCust["amt"] = amt;
        currIndex++;
      }
      else if(row[1] !== "0" && isNaN(row[2])) {
        const prefix = row.substring(0,2);
        if(prefix == "26"){
          // Name
          currCust["name"] = row.substring(2, row.length);
        }
        if(prefix == "27"){
          // Adress & zip
          currCust["adress"] = row.substring(2, row.length-6).trim();
          currCust["zip"] = row.substring(row.length-6, row.length);
          zips.push(currCust["zip"]);
        }
        if(prefix == "28"){
          // Mun
          currCust["mun"] = row.substring(2, row.length);
        }

        currIndex++;
      }

      if(currIndex > 3){
        // Push customer
        currIndex = 0;
        customers.push(currCust);
        currCust = {};
      }
    } 
    customersApi.getGroupWhere("zipCode", zips).then(res => {
      setCustomers(res.data.recordset);
    });
    setFContent(customers);
  }

  useEffect(() => {
    if(customersApi !== null){
      Fs.listFolder("upload/").then(res => {
        // Sort files based on date
        res.data.data.sort(function compare(a,b){
          var dateA = new Date(a.LastModified);
          var dateB = new Date(b.LastModified);
          return dateA - dateB;
        })
        setExistingFiles(res.data.data);

        // Open a file on load if parameter given in cmd
        if(params.process.length > 1 && firstLoad){
          openRawFileWithFiles(params.process[1], res.data.data);
          setFirstLoad(false);
        }
      });
    }
  },[customersApi]);

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color={theme.palette.domain["admin"].main}
        remove={() => onRemoveItem(params.id)}
      >
        FormatAg
      </Header> 
      <Body>
        <InbetFileContext.Provider value={{
            existingFiles,
            getFname,
            openRawFile,
            fContent,
            customersNotFound,
            setShowAllFiles,
            selectedFile,
            fileUploadInProgress,
            customers,
            fileType,
            setCustomersNotFound,
            setCustomersFound,
            showAllFiles,
            deleteFile
          }}>
          <Grid style={{overflowY:"auto", height:"99%", overflowX:"hidden"}}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={4} >
                <SideBar />
              </Grid>
              <Grid item xs={8}>
                <Paper 
                  style={{
                    background:theme.palette.background.default,
                    padding:"10px",
                  }} 
                  elevation={1}>
                    <MainContent />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </InbetFileContext.Provider>
      </Body>
    </Module>
  )
}
