import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';

import {Header, Body, Module} from '../Modules'; 

import Docs from '../../shared/Docs.js';
import { ChromePicker } from 'react-color';

import {
  makeStyles, 
  useTheme, 
} from '@material-ui/core/styles';

import {
  Grid,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Fade,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Collapse,
  Box,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root:{
    height:"100%",
    overflow:"auto"
  },
  sidebar:{
    background:"#ffffff",
    boxShadow:"1px 1px 5px 1px #ebebeb"
  },
  main:{
    background:"#ebebeb",
  },
  docSelector:{
    width:"100%",
  },
  p:{
    fontSize:"9pt"
  }

}));

export default function EditDocument(params){
  const classes = useStyles();
  const theme   = useTheme();
  const { onRemoveItem, addModule, notify } = useMainGridContext();

  const [showColor, setShowColor] = useState(false);

  const [avDocs, setAvDocs] = useState([]);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [exServices, setExServices] = useState([0]);

  const update = (c, e) => {
    setCurrentDoc({...currentDoc, ["color"]:c.hex})
  }

  useEffect(() => {

    const docsApi = new Docs();
    docsApi.getAll()
    .then(res => {
      setAvDocs(res.data.prefs);
    })
    .catch(err => {notify("error", `Nätverks fel. ${err}`)});
  },[]);
  
  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
        color={theme.palette.domain.["admin"].main} 
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} edok 
      </Header>
      <Body>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={2} className={classes.sidebar}>
            <TextField 
              select 
              className={classes.docSelector}
              onChange={(e) => 
                setCurrentDoc(p => avDocs.filter(d => d.name == e.target.value)[0])}
            >
              <MenuItem disabled={true} value={"null"}>
                Dokument
              </MenuItem>
              {avDocs.map((e,i) => (
                <MenuItem key={i} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </TextField>
            {currentDoc !== null ?
              <Grid container style={{background:"#f9f9f9"}}>
                <Grid item xs={12}>
                  <Typography 
                    variant="overline">
                    Namn
                  </Typography>
                  <Typography variant="body1">
                    {currentDoc.name} 
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" style={{width:"100%"}}>
                    Logga 
                  </Typography>
                  <img
                    width="100%"
                    src={currentDoc.logo.url} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline">
                    Färg 
                  </Typography>
                  <Typography 
                    style={{background:`${currentDoc.color}`}}
                    onClick={e => setShowColor(p => !p)}
                  >
                    {currentDoc.color} 
                  </Typography>
                  <Collapse in={showColor}>
                    <div 
                      style={{
                        position:"absolute", 
                        zIndex:1,
                      }} 
                      onClick={() => setShowColor(false)}>
                        <div 
                          style={{position:"absolute", zIndex:2}}
                        >
                          <ChromePicker 
                            color={currentDoc.color}
                            onChange={update}
                          />
                        </div>
                    </div>
                  </Collapse>
                </Grid>

              </Grid>
              :
              null
            }
          </Grid>
          <Grid 
            container 
            item 
            xs={10} 
            className={classes.main}
            alignItems="center"
            justify="center"
          >
            {currentDoc !== null ?
              <div style={{
                background:"white", 
                width:"21cm", 
                height:"29.7cm",
                borderTop: `10px solid ${currentDoc.color}`,
                paddingLeft:"20px",
                paddingRight:"20px",
                boxShadow:"1px 1px 5px 1px #e0e0e0"
              }}>

                <Grid container>
                  <Grid item xs={12} style={{paddingTop:"30px"}}>
                    <img 
                      src={currentDoc.logo.url} 
                      width={String(Number(currentDoc.logo.width.replace("px",""))+ 80) + "px"}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <p className={classes.p}>Ordernummer: XXXXXX</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p className={classes.p}>
                      Kundens namn<br/>
                      Kundens adess<br/> 
                      kundens postnummer + ort
                    </p>
                  </Grid>
                  <Grid item xs={4} style={{paddingLeft:"1cm"}}>
                    <p style={{fontSize:"12pt"}}>Dina tjänster</p>
                    {exServices.map((e,i) => (
                      <Grid 
                        item 
                        xs={12} 
                        style={{
                          borderBottom:`2pt solid ${currentDoc.color}`,
                        }}
                      >
                        <p style={{
                          fontSize:"10pt", color:currentDoc.color, margin:0,padding:0
                        }}>
                          <b>Tjänst {i+1}</b>
                        </p>
                        <p style={{fontSize:"10pt", margin:0,padding:0}}>
                          Lorem ipsum dolor dorem
                        </p>
                        <p style={{
                          textAlign: "right", 
                          fontSize:"10pt",
                          color:currentDoc.color,
                          margin:0,
                          padding:0
                          }}>
                          X XXX,XX kr
                        </p>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={8} style={{paddingLeft:"1cm", paddingTop:"0.5cm"}}>
                    <p style={{fontSize:"8pt", margin:0, padding:0}}>
                      DAGENS DATUM
                    </p>
                    <p 
                    style={{
                      fontSize:currentDoc.data.title.style.fontSize,
                      fontWeight:currentDoc.data.title.style.fontWeight,
                      background: currentDoc.data.title.style.background,
                      color: currentDoc.data.title.style.color,
                      padding:0,
                      margin:0
                    }}>
                      {currentDoc.data.title.text}
                    </p>
                    {currentDoc.data.intro.text.split("::BR::").map(e => (
                      <>
                      <p
                      style={{
                        fontSize:currentDoc.data.intro.style.fontSize,
                        fontWeight:currentDoc.data.intro.style.fontWeight,
                        background: currentDoc.data.intro.style.background,
                        color: currentDoc.data.intro.style.color,
                        padding:0,
                        margin:0,
                        width:"90%",
                        lineHeight: 1.2
                      }}>
                        {e}
                      </p>
                      <br />
                      </>
                    ))}
                    <p>
                      {currentDoc.data.p1.text.split("::BR::").map(e => (
                        <>
                        <p
                        style={{
                          fontSize:currentDoc.data.p1.style.fontSize,
                          fontWeight:currentDoc.data.p1.style.fontWeight,
                          background: currentDoc.data.p1.style.background,
                          color: currentDoc.data.p1.style.color,
                          padding:0,
                          margin:0,
                          width:"90%",
                          lineHeight: 1.2,
                          padding:"10px"
                        }}>
                          {e}
                        </p>
                        <br />
                        </>
                      ))}
                    </p>
                    <p
                    style={{
                      fontSize:currentDoc.data.title2.style.fontSize,
                      fontWeight:currentDoc.data.title2.style.fontWeight,
                      background: currentDoc.data.title2.style.background,
                      color: currentDoc.color,
                      padding:0,
                      margin:0,
                      width:"90%",
                      lineHeight: 1.2,
                      padding:"10px"
                    }}>
                      {currentDoc.data.title2.text}
                    </p>
                    {currentDoc.data.subtitle2 !== undefined ?
                      <>
                        <p
                          style={{
                            fontSize:currentDoc.data.subtitle2.style.fontSize,
                            fontWeight:currentDoc.data.subtitle2.style.fontWeight,
                            background: currentDoc.data.subtitle2.style.background,
                            color: currentDoc.color,
                            padding:0,
                            margin:0,
                            width:"90%",
                            lineHeight: 1.2,
                            padding:"10px"}}
                        >
                          {currentDoc.data.subtitle2.text}
                        </p>
                      </>
                      :
                      null
                    }
                    {
                      currentDoc.data.list2 !== undefined ?
                        <>
                          {currentDoc.data.list2.text.map((e,i) => (
                            <p style={{fontSize:"10pt"}}>
                              {i+1}. {currentDoc.data.list2.text[i]}
                            </p>
                          ))}
                        </>
                        :
                        null
                    }
                    {
                      currentDoc.data.p2 !== undefined ?
                        <>
                        {currentDoc.data.p2.text.split("::BR::").map(e => (
                          <>
                          <p style={{fontSize:"10pt"}}>
                            {e}
                          </p>
                          </>
                        ))}
                        </>
                        :
                        null
                    }
                  </Grid>
                </Grid> 
                  <div style={{position:"static", marginTop:"4cm", width:"100%"}}>
                    <Grid container style={{borderTop:`2pt solid ${currentDoc.color}`}}>
                      <Grid item xs={4}>
                        <p style={{fontSize:"8pt", margin:0,padding:0}}>
                          <b>Telefon</b>
                        </p>
                        <p style={{fontSize:"8pt", margin:0,padding:0}}>
                          031-376 01 50 
                        </p>
                        <p style={{fontSize:"8pt", margin:0,padding:0}}>
                          (öppet vardagar 9-15, lunchstängt 12-13)
                        </p>
                      </Grid>
                      <Grid item xs={4} container alignItems="center" justify="center">
                        <Grid item xs={12}>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}><b>Internet</b></p>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}>www.usec.se</p>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}>info@usec.se</p>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} container alignItems="flex-end" justify="flex-end">
                        <Grid>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}><b>Adress</b></p>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}>
                            Increment Security Group Gothia AB
                          </p>
                          <p style={{fontSize:"8pt", margin:0,padding:0}}>
                            E A Rosengrens gata 29 B, 421 31 Västra Frölunda
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                </div>
              </div>
              :
              null
            }
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
