import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';
import { standardOwnership, isAuthorised } from "../../shared/moduleAuthority";
import FinancialStreams from "../../shared/FinancialStreams";
import Report from '../../shared/pdf/daily-sales-report.jsx';

import {
  getCurrentYear, 
  getCurrentDay, 
  getDaysAhead,
  getTimeFromDate
} from '../../shared/dateHelper.js';

import { 
  isAuthorisedWithAsyncCallback
} from "../../shared/moduleAuthority";

import { Auth } from 'aws-amplify';

import axios from 'axios';
import _ from 'lodash'
import { EditableText } from '../general/UpdatableValue.js';
import VasaDialog from '../../shared/VasaDialog.js';
import Products from '../../shared/Products.js';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import EntityEvents from '../../shared/EntityEvents.js'
import Users from '../../shared/Users.js'
import Auths from '../../shared/Auths.js'
import UserAdmin from '../../shared/UserAdmin.js'
import UserPrefs from '../../shared/UserPrefs.js'
import { 
  getMonthPeriod, 
  formatDateNormal, 
  currentMonthPeriod,
  currentYearPeriod,
} from '../../shared/dateHelper.js';
import { formatter, unFormatInteger } from '../general/format.js';

import { Chart } from "frappe-charts/dist/frappe-charts.esm.js";
import "frappe-charts/dist/frappe-charts.min.css";
import ReactFrappeChart from "react-frappe-charts";
import {
    BarChart, 
    Bar, 
    Cell, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip as ChartTooltip, 
    Legend,
    ResponsiveContainer
} from 'recharts';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
  Tooltip,
  Avatar,
  ButtonGroup,
  Fade,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Menu,
  LinearProgress
} from "@material-ui/core";

import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
  userGridItem: {
  }

}))

function WriteReport(params){
  const { currentUser } = useAppContext();
  const username = params.username
  const openDialog = params.open;
  const setOpenDialog = params.setOpen;

  const [cs, setCs] = useState([]);
  const [os, setOs] = useState([]);
  const [ss, setSs] = useState([]);
  const [ps, setPs] = useState([]);
  const [costs, setCosts] = useState(0);

  const [msg, setMsg] = useState("");
  const [prog, setProg] = useState(0);

  const [tot, setTot]     = useState(0);
  const [currentDayPeriod, tmp] = useState({start: getDaysAhead(0), end: getDaysAhead(1)})

  const getServices = async () => {
    const ss = new UserAdmin(username);
    const sres = await ss.getServices(
      username, 
      currentDayPeriod.start, 
      currentDayPeriod.end 
    );
    setSs(sres.data.recordset);

    const ps = new Products(username);
    const pres = await ps.getAll();

    sres.data.recordset.map(e => {
      var uPrice = pres.data.recordset.filter(p => p.name == e.productCode)[0].unitPrice;
      setCosts(p => p + uPrice*e.amount)
    });

    setPs(pres.data.recordset);
  }

  const getData = async () => {
    const us = new Users(username);
    const ua = new UserAdmin(username);
    setMsg("Hämtar kunder...");
    setProg(0);
    try{
      const cres = await ua.getCustomerOrders(username, currentDayPeriod.start, currentDayPeriod.end);
      setMsg("Hämtar order...");
      var tmp = [];
      var tmpTot = 0;
      cres.data.recordset.map(e => {
        tmp.push({
          "kid": e.customerid,
          "oid": e.orderId,
          "name": e.name,
          "ssn": e.socialSecurityNumber,
          "billing": e.billingInterval,
          "subscriptionLength": e.subscriptionLength,
          "price": e.price
        });
        setTot(p => p + e.price);
      })
      setOs(tmp);
      setMsg("Hämtar tjänster...");
      setProg(50);
      getServices();
      setProg(100);
      setMsg("Klar!");
      if(cres.data.recordset.length == 0){
        setMsg("Det hittas inga order som skapades idag.");
      }
    }
    catch(err){
      setMsg("Något gick fel");
    }
  }

  useEffect(() => {
    if(params.open){
      getData();
    }
  },[params.open])

  return(
    <VasaDialog 
      open={openDialog} 
      handleClose={() => setOpenDialog(false)}
    >
      <Typography variant="h4">
        Dagsrapport: {username}
      </Typography>
      <Typography>
        {msg}
      </Typography>
      <LinearProgress variant="determinate" value={prog}/>
      {os.length > 0 && ss.length > 0 && ps.length > 0 && prog >= 100?
        <PDFDownloadLink
          document={
            <Report 
              user={username}
              writtenBy={currentUser.username}
              customers={cs} 
              orders={os} 
              services={ss}
              products={ps}
              costs={costs}
              ss={[]} 
              meta={{}}
              tot={tot}
            />
          }
          filename="dagsrapport.pdf"
        >
          {({ blob, url, loading, error }) => {
            if(!loading){
              return<p>{url}</p>
            }
            else{
              return <div>Skriver PDF...</div>
            }
          }}
        </PDFDownloadLink>
        :
        null
      }
    </VasaDialog>
  )
}

export function AsyncAutocomplete(params){

  const { currentUser } = useAppContext();
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = autoCompleteOpen && options.length === 0;

  const onChange = params.onChange;

  const usersApi = new Users(currentUser.username);

  useEffect(() => {
    if(!loading){
      return undefined;
    }

    usersApi.getAll().then(res => {
      setOptions(res.data.recordset);
    })
  },[loading])

  return(
    <Autocomplete 
      open={autoCompleteOpen}
      onOpen={() => {setAutoCompleteOpen(true)}}
      onClose={() => {setAutoCompleteOpen(false)}}
      getOptionSelected={(option, value) => option.username === value.name}
      getOptionLabel={(option) => option.username}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Säljare"
          InputProps={{
            ...params.InputProps,
            endAdornment:(
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onChange={(e, v) => onChange(v.username)}
    />
  )

}

export function PermaDeleteDialog(params){
  const classes = useStyles();
  const theme   = useTheme();

  const {currentUser} = useAppContext();
  const { notify } = useMainGridContext();

  const userAdmin = new UserAdmin(currentUser.username);

  const user = params.user;
  const [customerAmount, setCustomerAmount] = useState(0);

  const [keepCustomers, setKeepCustomers] = useState(true);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const [newSman, setNewSman] = useState("");
  const [remove, setRemove]   = useState("");
  const [conf, setConf] = useState(false);

  const removeConfirmation = (e) => {
    setConf(e.target.value.toLowerCase() == "ta bort");
    setRemove(e.target.value);
  }

  const deleteUser = () => {
    if(!keepCustomers){
      setAllUsers(user, newSman);
    }
    userAdmin.deletePerma(user).then(res => {
      notify("success", `${user} har tagits bort permanent.`);
    })
    .catch(err => {
      notify("error", `Kunde inte ta bort användare. ${err}`)
    });
    params.handleClose();
  }

  const setAllUsers = (oldName, newName) => {
    userAdmin.transferCustomers(oldName, newName)
      .then(res => {
        notify("success", `Kunder överförda till ${newName}`)
      }).catch(err => {
        notify("error", `Kunde inte föra över kunder. ${oldName} är fortfarande säljare på alla kunder. ${err}`)
      })
    userAdmin.transferOrders(oldName, newName)
      .then(res => {
        notify("success", `Order överförda till ${newName}`)
      }).catch(err => {
        notify("error", `Kunde inte föra över order. ${oldName} är fortfarande säljare på alla order. ${err}`)
      })
    userAdmin.transferServices(oldName, newName)
      .then(res => {
        notify("success", `Tjänster överförda till ${newName}`)
      }).catch(err => {
        notify("error", `Kunde inte föra över tjänst. ${oldName} är fortfarande säljare på alla tjänster. ${err}`)
      })
  }

  useEffect(() => {
    if(params.user !== null){
      axios.get(`https://rlxysyw958.execute-api.us-east-2.amazonaws.com/dev/count/customers/${params.user}`)
        .then(res => {
          setCustomerAmount(res.data.recordset[0].data);
        }).catch(err => {console.log("err", err)});
    }
  },[params.user]);

  return(
    <VasaDialog 
      open={params.open}
      handleClose={params.handleClose}
    >
      <Grid 
        container 
        spacing={4} 
        style={{
          background:theme.palette.background.paper, 
          borderRadius:"5px",
          padding:"25px"
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            Ta bort {user} permanent.
          </Typography>
          <Typography>
            Detta är en permanent åtgärd som inte kan återställas.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Typography variant="h5">
            Vad ska hända med användarens kunder?
          </Typography>
          <Typography>
            Användaren har {customerAmount} kunder.
          </Typography>
          <FormControlLabel
            control={<Checkbox 
              checked={keepCustomers} 
              label="Behåll kunderna" 
              onClick={() => setKeepCustomers(p => !p)}
            />}
            label={keepCustomers ? 
              `Alla kunder, order och tjänster behåller ${user} som säljare.`
              :
              `Uppdatera säljaren på alla ${user} kunder`
            }
          />
          <Fade in={!keepCustomers}>
            <div>
              <AsyncAutocomplete onChange={(e) => {setNewSman(e)}}/>
            </div>
          </Fade>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            fullWidth 
            label="Skriv 'ta bort' för att bekräfta användarens borttagande."
            value={remove}
            onChange={(e) => removeConfirmation(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Fade in={conf}>
            <Button fullWidth color="primary" onClick={() => deleteUser()}>
              Ta bort användare permanent
            </Button>
          </Fade>
        </Grid>
      </Grid>
    </VasaDialog>
  )
}

export default function UserModule(params){
  const classes = useStyles();
  const theme = useTheme();
  const { onRemoveItem, notify, addModule } = useMainGridContext();

  const { entityEventApi, usersApi, currentUser, entityEventsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [canUpdate, setCanUpdate] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [user, setUser]         = useState({})
  const [loadTime, setLoadTime] = useState(0);
  const [mak, setMak]           = useState(0);
  const [outstanding, setOutstanding] = useState(0);
  const [customer, setCustomer]       = useState(0);
  const [soldToday, setSoldToday]     = useState(0);
  const [fak, setFak]                 = useState(0);
  const [orders, setOrderAmount]      = useState(0);
  const [logins, setLogins]           = useState({});
  const [showAdminOps, setShowAdminOps] = useState(false);
  const [userEvents, setUserEvents] = useState([]);

  const [uPrefs, setUPrefs] = useState({});
  const [salaryChangeBy, setSalaryChangeBy] = useState("");
  const [lastReset, setLastReset]           = useState("");
  const [salaryChange, setSalaryChange]     = useState("");
  const [updateSalary, setUpdateSalary]     = useState(false);
  const [allowUpdate, setAllowUpdate]       = useState(false);
  const [customSalary, setCustomSalary]     = useState("");
  const [baseSalary, setBaseSalary]         = useState(0);
  const [changeFromDate, setChangeFromDate] = useState(0);

  const [outstandingSinceReset, setOutstandingSinceReset] = useState(0);
  const [paymentsSinceReset, setPaymentSinceReset]        = useState(0);

  const [openReportDialog, setOpenReportDialog] = useState(false);

  const [auths, setAuths] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [initPermaDelete, setInitPermaDelete] = useState(false);

  const uname = params.uname;

  const eventsApi = new EntityEvents();
  const userAdmin = new UserAdmin(currentUser.username);
  const streams   = new FinancialStreams();
  const authsApi = new Auths(currentUser.username);

  const handleAuthClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleAuthClose = (e) => {
    setAnchorEl(null);
  }

  const toggleEnableUser= () => {
    usersApi.updateUsername(user.username, "active", !user.active)
      .then(res => {
        notify("success", `active = ${!user.active} för ${user.username}`);
        if(user.active){
          // If user was deactivated. Force a logout of the user.
          forceLogout();
        }
      })
      .catch(err => {
        notify("error", `Kunde inte avaktivera användare. ${err}`);
      });
  }

  const deleteUserPermanentely = () => {
    userAdmin.deletePerma(user.username).then(res => {
      notify("success", `${user.username} har tagits bort permanent.`);
    })
    .catch(err => {
      notify("error", `Kunde inte ta bort användare. ${err}`)
    });
  }

  const forceLogout = () => {
    eventsApi.create({
        entity: "USER", 
        creator: currentUser.username.toLowerCase(), 
        type:"FORCE_LOGOUT", 
        identifier:uname,
        message:"success",
        idColumn: "username"
      })
      .then(res => {
        notify("success", "Användare har loggats ut.");
      })
      .catch(err => {
        notify("error", `Kunde inte logga ut användare. ${err}`)
      });
    usersApi.updateUsername(user.username, "loggedIn", false);
  }


  useEffect(() => {
    var start = new Date();
    // On first load (before render) check if user can update module
    if(firstLoad){
      setFirstLoad(false);
      isAuthorisedWithAsyncCallback(
        params.updateAuths, 
        currentUser, 
        (body)=>isOwner(body)
      ).then(updatable => {
        setCanUpdate(updatable)
        if(updatable){
          authsApi.getAll().then(res => {
            setAuths(res.data.auths);
          }).catch(err => {notify("error", "Kan inte hämta autkoriteter " + err)});
        }
      });

    }
    if(
      Object.keys(user).length == 0 && usersApi !== null||
      (dataFromServer.entity == "users" || dataFromServer.creator == uname) && usersApi !== null
    ){
      getSalaryInfo();

      usersApi.getWhere("username", uname).then(res => {
        if(res.data.recordset[0] !== null && res.data.recordset[0] !== undefined){
          setUser(res.data.recordset[0]);
        }
        else{
          setUser({});
        }
        const end = new Date();
        setLoadTime(end - start);
      });

      var today = String(getCurrentDay());
      var tomorrow = String(getDaysAhead(1));
      entityEventsApi.getUserEventOverInterval(uname, today, tomorrow, 10, 0)
        .then(res => {
          setUserEvents(res.data.recordset);
        });

      // Get user data streams
      getStats();

      usersApi.readLogins(currentYearPeriod(), uname).then(res => {
        var loginDates = res.data.recordset.map(e => formatDateNormal(new Date(e.date)));
        var loginAmount = res.data.recordset.map(e => e.logins);
        setLogins(prev => _.zipObject(loginDates, loginAmount));
      })
    }
  },[dataFromServer, usersApi])

  const getSalaryInfo = () => {
      var uPrefsApi = new UserPrefs(uname);
      uPrefsApi.read(uname).then(res => {
        if(res.data.salary !== undefined){
          setSalaryChangeBy(res.data.salary[0].changeBy);
          setLastReset(res.data.salary[0].lastReset);
          setSalaryChange(res.data.salary[0].customChange);
          setBaseSalary(res.data.salary[0].baseSalary);

          var from = res.data.salary[0].lastReset;

          streams.outstandingFrom(uname, from)
          .then(res => {

            if(res.data.recordset.length == 0){
              setOutstandingSinceReset(0)
            }
            else{
              setOutstandingSinceReset(res.data.recordset[0].data);
            }
          })
          .catch(err => console.log(err));

          streams.paymentStreamSince(uname, from)
          .then(res =>{
            if(res.data.recordset.length == 0){
              setPaymentSinceReset(0)
            }
            else{
              setPaymentSinceReset(res.data.recordset[0].data);
            }
          })
          .catch(err => console.log(err));

          setUPrefs(res);
        }
      });
  }

  const getStats = () => {
    streams.mak(uname).then(res => {
      setMak(getData(res));
    }).catch(err => console.log(err));
    streams.outstanding(uname).then(res => {
      setOutstanding(getData(res));
    }).catch(err => console.log(err));
    streams.paymentStream(uname).then(res => {
      setCustomer(getData(res));
    }).catch(err => console.log(err));
    streams.soldToday(uname).then(res => {
      setSoldToday(getData(res));
    }).catch(err => console.log(err));
    streams.fak(uname).then(res => {
      setFak(getData(res));
    }).catch(err => console.log(err));
    streams.customerCount(uname).then(res => {
      setOrderAmount(getData(res));
    }).catch(err => console.log(err));
  }

  const updateAuth = (v) => {
    if(canUpdate){
      usersApi.updateUsername(uname, "authority", v)
      .then(res => {
        setUser({...user, ["authority"]:v});
        notify("success", `Auktoritet för ${uname} har uppdaterats till ${v}`);
      })
      .catch(err => {
        notify("error", `Kunde inte uppdatera auktoritet. ${err}`);
      })
      userAdmin.updateCognitoAuth(uname, v).then(res => {
        notify("success", "Cognito uppdaterad")
      }).catch(err => {
        notify("error", `Kunde inte uppdatera cognito auktoritet ${err}`)
      });
    }
    else{
      notify("error", `Du har inte auktoriteten att uppdatera denna användaren`);
    }
  }

  const getData = (res) => {
    if(res.data.recordset[0] == undefined || res.data.recordset[0].data == null){
      return "0,00 kr";
    }
    else{
      return res.data.recordset[0].data;
    }
  }

  const isOwner = (body) => {
    return body.username == uname;
  }

  const writeReport = () => {
    alert("write report");
  }

  const calculateSalary = () => {
    return Number(unFormatInteger(paymentsSinceReset)) + Number(salaryChange);
  }
  
  const calculateBaseSalary = () => {
    return Number(unFormatInteger(paymentsSinceReset));
  }

  const onSalaryChangeBlur = () => {
    setUpdateSalary(false)

    if(customSalary == calculateSalary || customSalary.length == 0 || isNaN(customSalary)){
      setCustomSalary("");
      setAllowUpdate(false);
    }
    else{
      setSalaryChange(customSalary - calculateBaseSalary());
      setAllowUpdate(true);
    }
  }

  const updateSalaryInformation = () => {
    setSalaryChange(customSalary - calculateBaseSalary());
    const uPrefsApi = new UserPrefs(currentUser.username);
    const updatedSalary = [
      {
        changeBy: currentUser.username, 
        customChange: customSalary - calculateBaseSalary(), 
        lastReset: lastReset, baseSalary: baseSalary
      }];

    uPrefsApi.update(uname, "salary", updatedSalary)
    .then(res => {
      notify("success", "Lön har uppdaterats");
      setAllowUpdate(false);
    })
    .catch(err => {
      notify("error", `Kunde inte uppdatera lön. ${err}`)
      setAllowUpdate(false);
    });
  }

  const resetUserSalary = () => {
    const uPrefsApi = new UserPrefs(currentUser.username);

    const updatedSalary = [
      {
        changeBy: currentUser.username, 
        customChange: 0, 
        lastReset: getDaysAhead(1), baseSalary: baseSalary
      }];
    uPrefsApi.update(uname, "salary", updatedSalary)
      .then(res => {
        notify("success", "Lön har nollställts");
        setSalaryChange(0);
        setCustomSalary("");
        getSalaryInfo();
      })
      .catch(err => {
        notify("error", `Lön kunde inte nollställas. ${err}`)
      })
  }

  const updateBaseSalary = async (e) => {
    const uPrefsApi = new UserPrefs(currentUser.username);
    setBaseSalary(e);
    const updatedSalary = [
      {
        changeBy: currentUser.username, 
        customChange: salaryChange, 
        lastReset: lastReset, baseSalary: e 
      }];
    return uPrefsApi.update(uname, "salary", updatedSalary);
  }

  const updateLastReset = (e) => {
    const uPrefsApi = new UserPrefs(currentUser.username);
    setLastReset(e);
    const updatedSalary = [
      {
        changeBy: currentUser.username, 
        customChange: salaryChange, 
        lastReset: e, baseSalary: baseSalary 
      }];
    uPrefsApi.update(uname, "salary", updatedSalary)
      .then(res => {
        notify("success", "Från datum uppdaterad");
        setChangeFromDate(false);
        setCustomSalary("");
        getSalaryInfo();
      })
      .catch(err => {
        notify("error", `Kunde inte uppdatera från datum. ${err}`)
        setChangeFromDate(false);
      });
  }

  const handleKeyPress = (e) => {
    if(e.key == "Enter"){
      updateSalaryInformation();
      setAllowUpdate(false);
      setUpdateSalary(false)
    } 
  }

  return(
    <Module 
      auths={params.auths} 
      remove={() => {onRemoveItem(params.id)}}
      ownership={(body) => isOwner(body)}
    >
      <Header color="#00c853" remove={() => {onRemoveItem(params.id)}}>
        {params.id} Användare
      </Header>
      <Body>
        {Object.keys(user).length !== 0 ?
          <Grid container style={{overflowY:"auto", height:"99%"}}>
            <Grid container xs={2} alignItems="center" justify="center">
              <Grid container xs={12} alignItems="center" justify="center">
                <Avatar 
                  className={classes.avatar} 
                  src={`https://vasa.s3.us-east-2.amazonaws.com/${user.profileMedia}`} 
                  alt={user.fullName}
                />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                <Grid xs={5} style={{paddingTop: "5px"}}>
                  <Grid container xs={12}> 
                    <Typography variant="h6">{user.username}</Typography>
                  </Grid>
                  <Grid item xs={12}> 
                    <Typography variant="h4"><b>{user.fullName}</b></Typography>
                  </Grid>
                  {canUpdate ?
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography 
                          style={{paddingTop:"10px", paddingBottom:"10px"}}
                          onClick={(e) => handleAuthClick(e)}
                        >
                          Auktoritet: {user.authority}
                        </Typography>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleAuthClose}
                        >
                          {auths.map((e,i) => (
                            <MenuItem 
                              key={e.name} 
                              onClick={() => updateAuth(e.name)}
                            >
                              {e.name} - {e.description}
                            </MenuItem>
                          ))
                          }
                        </Menu>
                      </Grid>
                      <Grid item xs={12}>
                        <Button 
                          fullWidth
                          size="small"
                          onClick={() => setShowAdminOps(p => !p)}
                          color="primary"
                          variant="outlined"
                        >
                          {showAdminOps ?
                            <KeyboardArrowUpIcon fontSize="small"/>
                            :
                            <KeyboardArrowDownIcon fontSize="small"/>
                          }
                          Administrativa operationer
                        </Button>
                      </Grid>
                      <Collapse in={showAdminOps}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Button 
                              onClick={toggleEnableUser}
                              fullWidth
                              size="small"
                            >
                              {user.active ?
                                "Avaktivera användare"
                                :
                                "Aktivera användare"
                              }
                            </Button>
                          </Grid>
                          <Grid item xs={12}> 
                            <Button 
                              onClick={forceLogout}
                              fullWidth
                              size="small"
                            >
                              Logga ut användare
                            </Button>
                          </Grid>
                          <Grid item xs={12}> 
                            <Button 
                              onClick={() => setInitPermaDelete(true)}
                              fullWidth
                              size="small"
                            >
                              Ta bort användare permanent
                            </Button>
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                    :
                    null
                  }
                  <PermaDeleteDialog 
                    open={initPermaDelete} 
                    handleClose={() => setInitPermaDelete(false)}
                    user={uname}
                  />
                </Grid>
                <Grid item xs={7}>
                    <Grid item xs={12}>
                      <ButtonGroup>
                        <Button onClick={() => addModule(`::sales ${uname}`)}>Försäjning</Button>
                        <Button onClick={() => addModule(`::kunder ${uname}`)}>Kunder</Button>
                        <Button onClick={() => setOpenReportDialog(true)}>Dagsrapport</Button>
                      </ButtonGroup>
                      <Grid container>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline">
                              Utestående 
                            </Typography>
                            <Divider variant="fullWidth" />
                            <Typography variant="p">
                              {outstanding}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline" style={{padding: 0, margin:0}}>
                              Makulerad
                            </Typography>
                            <Divider variant="fullWidth"/>
                            <Typography variant="p" style={{padding:0, margin:0}}>
                              <span style={{background: "#fbe9e7"}}>{mak}</span>
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline" style={{marginTop: "-20px"}}>
                              Kund 
                            </Typography>
                            <Divider variant="fullWidth" />
                            <Typography variant="p">
                              <span style={{background: "#e8f5e9"}}>{customer}</span>
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline" style={{marginTop: "-20px"}}>
                              Sålt idag 
                            </Typography>
                            <Divider variant="fullWidth" />
                            <Typography variant="p">
                              {soldToday}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline" style={{marginTop: "-20px"}}>
                              Fakturerad 
                            </Typography>
                            <Divider variant="fullWidth" />
                            <Typography variant="p">
                              {fak}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4} className={classes.userGridItem}>
                          <Paper elevation={0} style={{padding: "10px"}}>
                            <Typography variant="overline" style={{marginTop: "-20px"}}>
                              Antal kunder 
                            </Typography>
                            <Divider variant="fullWidth" />
                            <Typography variant="p">
                              {orders}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              currentUser.auth == "admin" ?
              <Grid container style={{borderLeft:`7px solid #00c853`, paddingLeft:"10px", marginTop:"50px"}}>
                <Grid item xs={12} className={classes.userGridItem} style={{borderBottom:`1px solid ${theme.palette.border.primary}`}}>
                  {allowUpdate && canUpdate?
                    <>
                    <Grid item xs={6}>
                      <Button color="secondary" fullWidth onClick={() => {setCustomSalary("");setAllowUpdate(false)}}>
                        Avbryt 
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button color="primary" fullWidth onClick={() => updateSalaryInformation()}>
                        Bekräfta
                      </Button>
                    </Grid>
                  </>
                  :
                  <Grid item xs={12}>
                    <Button variant="outlined" onClick={() => resetUserSalary()}>Nollställ</Button>
                    {changeFromDate ?
                      <Button variant="outlined" onClick={() => setChangeFromDate(false)}>Avbryt</Button>
                      :
                      <Button variant="outlined" onClick={() => setChangeFromDate(true)}>Ändra från datum</Button>
                    }
                    {changeFromDate ?
                        <input type="date" onChange={(e) => updateLastReset(e.target.value)}/>
                        :
                        null
                    }
                  </Grid>
                }

                  <Typography variant="overline">
                    Lönegrundande (beräknas från: {lastReset})
                  </Typography>
                  {updateSalary && canUpdate ?
                    <TextField 
                      fullWidth 
                      value={customSalary}
                      label={calculateSalary()}
                      onChange={e => setCustomSalary(e.target.value)}
                      onKeyPress={e => handleKeyPress(e)}
                      onBlur={() => onSalaryChangeBlur()} 
                      style={{fontSize:"24px"}} 
                      autoFocus 
                      inputProps={{style:{fontSize:60}}}
                    />
                    :
                    <Typography variant="h2" onClick={() => {setUpdateSalary(true); setAllowUpdate(true)}}>
                      {customSalary.length == 0 ?
                        <b>{calculateSalary()}</b>
                        :
                        <b>{customSalary}</b>
                      }
                    </Typography>
                  }
                </Grid>
                <Grid item container xs={12}>
                  <Grid container style={{borderBottom:`1px solid ${theme.palette.border.primary}`}}>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        Inbetalningar: 
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} justify="flex-end" alignItems="flex-end">
                      <Typography variant="body1">
                        {paymentsSinceReset}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Personuppdatering: 
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} justify="flex-end" alignItems="flex-end">
                    <Typography variant="body1">
                      {salaryChange} (gjord av {salaryChangeBy})
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            :
              null
            }
            <Grid item xs={12}>
              <Grid container>
                <Grid container xs={12} style={{padding: "10px"}} spacing={2}>
                  <Grid item xs={12} className={classes.userGridItem} style={{marginTop: "10px"}}>
                    <Grid container xs={12} alignItems="center" justify="center">
                      <Paper elevation={0}>
                        <ReactFrappeChart
                          style={{width: "100%"}}
                          type="heatmap"
                          title="Inloggningar"
                          data={{
                            dataPoints:logins,
                            start: new Date(`2022-01-01`),
                            end: new Date(`2022-12-31`)
                          }}
                          countLabel="Inloggningar"
                          discreteDomains={0}
                          width={350}
                          colors={['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']}
                        />                           
                      </Paper>
                    </Grid>
                  </Grid> 
                  <Grid item xs={12} className={classes.userGridItem}>
                    <Paper variant="outlined" elevation={0} style={{overflowY:"auto", height:"250px", padding: "20px"}}>
                      <Typography variant="overline">
                        Händelselogg 
                      </Typography>
                      <Timeline>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Senaste</TimelineContent>
                        </TimelineItem>
                        {userEvents.map(e => (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>{getTimeFromDate(e.created)}: {e.type}</TimelineContent>
                          </TimelineItem>
                        ))
                        }
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>Inga fler händelser hittade</TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Paper>
                  </Grid> 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          :
          <CircularProgress />
        }
      </Body>
      <WriteReport username={user.username} open={openReportDialog} setOpen={(p) => setOpenReportDialog(p)}/>
    </Module>
  )
}
