var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
});

export const daysToPeriod = {
  "30": "1 Månad",
  "60": "2 Månader",
  "90": "3 Månader",
  "120": "4 Månader",
  "150": "5 Månader",
  "180": "6 Månader",
  "210": "7 Månader",
  "240": "8 Månader",
  "270": "9 Månader",
  "300": "10 Månader",
  "330": "11 Månader",
  "365": "1 År"
}

const postfix = {
    "engångsfaktura": "",
    "månadsvis": "/månad",
    "halvårsvis": "/halvår",
    "kvartalsvis": "/kvartal",
    "årsvis": "/år",
  }

const paymentInterval = {
  "månadsvis": 1,
  "kvartalsvis": 3,
  "halvårsvis": 6,
  "årsvis": 12
}

const priceLength = {
    "1 månad": 1,
    "3 månader": 3,
    "6 månader": 6,
    "1 år": 12,
    "2 år": 24,
    "3 år": 36,
    "4 år": 48,
    "5 år": 60
  }

const numberToText = {
  "1": "ett",
  "2": "två",
  "3": "tre",
  "4": "fyra",
  "5": "fem",
  "6": "sex",
  "7": "sju",
  "8": "åtta",
  "9": "nio",
  "10": "tio",
  "11": "elva",
  "12": "tolv"
}

export function priceConstructor(sub, bill, price){
    if(sub === undefined || bill === undefined || price === undefined){
      return 0
    }
    sub = sub.toLowerCase();
    bill = bill.toLowerCase();

    if(bill === "engångsfaktura"){
      return "N/A";
    }

    const length = priceLength[sub];
    const interval = paymentInterval[bill];

    return formatter.format(price/(length/interval)) + postfix[bill];
  } 

/**
 * Calculate the amount needed to be paid each interval 
**/

export function priceConstructorNumber(order){
  if(!isValid(order)){
    return 0;
  }

  if(!isNaN(order.subscriptionLength)){
    order.subscriptionLength = daysToPeriod[order.subscriptionLength]; 
  }

  const sub = String(order.subscriptionLength).toLowerCase();
  const bill = String(order.billingInterval).toLowerCase();
  const price = order.price;


  if(bill === "engångsfaktura"){
    return formatter.format(price);
  }

  const length = priceLength[sub];
  const interval = paymentInterval[bill];

  return formatter.format(price/(length/interval));
} 

/**
 * @params order is a JSON containing order data
 * Calculate the amount of payments needed to pay off service
**/

export function getPaymentAmount(order){
  if(!isValid(order)){
    return 0;
  }

  const sub   = String(order.subscriptionLength).toLowerCase();
  const bill  = String(order.billingInterval).toLowerCase();

  if(bill === "engångsfaktura"){
    return 1;
  }
  return priceLength[sub] / paymentInterval[bill]
}

/**
 * @param order is a JSON object containing information about a order
 *
 * The function will takes the sublength, billing interval and price to
 * the calculate the series of payments needed to pay for the services
*/

export function paymentPlan(order){
  if(order === {} || order === null){
    return [];
  }

  const bill  = order.billingInterval;
  const orderCreationDate = order.orderDate;

  const length   = getPaymentAmount(order);
  const payment  = priceConstructorNumber(order);
  var date  = new Date(orderCreationDate);
  date      = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  var res = [];

  for(var i = 0; i < length; i++){
    // Add payment interval to date
    var inc = paymentInterval[bill.toLowerCase()];
    date.setMonth(date.getMonth() + inc); 

    var year = date.getFullYear();
    var month = formatDate(date.getMonth() + 1);
    var day = formatDate(date.getDate());

    res.push({date: `${year}-${month}-${day}`, payment: payment});
  }
  return res;
}

function isValid(order){
  if(order === null || order === undefined){
    return false;
  }
  return true
}

function formatDate(val){
  return val < 10 ? "0" + val : val;
}

/**
 * convert YYYY-MM-DDTHH:MM:SS.MMMZ into YYYY-MM-DD HH:MM:SS
**/

export function reformatDate(date){
  return date.replace("T", " ").replace("Z", "").slice(0,-4);
}

export function textualSubscriptionLength(subscriptionLength){
  if(subscriptionLength.includes("1")){
    return subscriptionLength.split(" ")[1].toLowerCase() + "s";
  }
  if(subscriptionLength.split(" ").length == 1){
    return subscriptionLength; 
  }
  else{
    return numberToText[subscriptionLength.split(" ")[0]] + " " + subscriptionLength.split(" ")[1].toLowerCase() + "s";
  }

}
