import axios from 'axios';
import config from './apiConfig.js';

export default class Groups{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "f2wf0zht78";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.groups_api_key;
  }
  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/group/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async get(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/read/group`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async groupSold(body){
    this.requestTemplate.body = body;
    console.log("reqTemp", this.requestTemplate);
    return axios.post(`${this.endpoint}/read/group/sold`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async update(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/update/group`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async delete(name){
    return axios.post(`${this.endpoint}/delete/group/${this.user}/${name}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

