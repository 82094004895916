import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';

import {makeStyles} from '@material-ui/core/styles';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import {Header, Body, Module} from '../Modules'; 

import { 
  EditableText, 
} from '../general/UpdatableValue.js';

import {
  Grid,
  Typography,
  Button,
  Box,
  Collapse,
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress
} from "@material-ui/core";

import 'react-data-grid/dist/react-data-grid.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {MdUpdate} from "react-icons/md";

import {
  getPaymentAmount, 
  paymentPlan
} from '../utils.js';

var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
})

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    overflow:"auto", 
    height: "90%", 
    padding:"20px", 
    position: "relative", 
    overflowX: "hidden"
  }
}));

function createData(row){
  row.history =  [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ]

  return row;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: "unset",
    },
  },
  tableContainer: {
    position: "absolute", 
    top: 100, 
    padding: "10px"
  },
  barColorPrimary:{
    background:"#f5f5f5"
  },
  barColorPrimaryDone:{
    background:"#00e676"
  },
  barColorPrimaryInProgress:{
    background:"#ff1744"
  }

});

function Row(params){
  const { row } = params;
  const [open, setOpen] = useState(false);
  const {notify} = useMainGridContext();
  const classes = useRowStyles();
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [totPaid, setTotPaid] = useState(0);
  const updateModule = () => params.onUpdate();
  const { addModule } = useMainGridContext();
  const {ordersApi, paymentsApi, servicesApi} = useAppContext();
  const cid = params.cid

  const postfix = {
      "engångsfaktura": "",
      "månadsvis": "/månad",
      "kvartalsvis": "/kvartal",
      "årsvis": "/år",
    }

  const paymentInterval = {
    "månadsvis": 1,
    "kvartalsvis": 3,
    "årsvis": 12
  }

  const priceLength = {
      "1 månad": 1,
      "3 månader": 3,
      "6 månader": 6,
      "1 år": 12,
      "2 år": 24,
      "3 år": 36,
      "4 år": 48,
      "5 år": 60
    }

  useEffect(() => {
    servicesApi.getWhere("orderNumber", params.row.orderId)
      .then(res => {
        if(res.data.recordset[0] !== undefined){
          setOrderDetails(res.data.recordset[0]);
        }
        console.log("orderDetails", res.data.recordset[0]);
      })
      .catch(err => notify("error", "Kan inte hämta order"));

    paymentsApi.getWhere("orderId", params.row.orderId)
      .then(res => {
        setPaymentHistory(res.data.recordset);
        setTotPaid(0);
        res.data.recordset.map(e => setTotPaid(p => p + e.amount));
        console.log("pHistory", res.data.recordset[0]);
      })
      .catch(err => notify("error", "Kan inte hämta betalningar"));
  },[open]);

  const priceConstructor = (sub, bill, price) => {
    if(sub === undefined || bill === undefined || price === undefined){
      return 0
    }

    sub = sub.toLowerCase();
    bill = bill.toLowerCase();

    if(bill === "engångsfaktura"){
      return "N/A";
    }

    const length = priceLength[sub];
    const interval = paymentInterval[bill];

    return formatter.format(price/(length/interval)) + postfix[bill];
  } 

  if(orderDetails !== null){
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.orderId}</TableCell>
          <TableCell>{orderDetails !== undefined ? formatter.format(orderDetails.price) : "N/A"}</TableCell>
          <TableCell>
          {formatter.format(totPaid)}</TableCell>
          <TableCell>
            <Box width="100%" mr={1}>
              <LinearProgress 
                variant="determinate" 
                value={Math.min(100,(paymentHistory.paid/orderDetails.price)*100)} 
                classes={
                  Math.min(100,(totPaid/orderDetails.price)*100) !== 100 ?
                    {barColorPrimary:classes.barColorPrimaryInProgress,
                     colorPrimary:classes.barColorPrimary
                    }
                  :
                    {barColorPrimary:classes.barColorPrimaryDone,
                     colorPrimary:classes.barColorPrimary
                    }
                }
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {`${Math.floor(totPaid/orderDetails.price*100)}%`}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
            <Collapse in={open} timeout="auto" unmounOnExit>
                <Grid container style={{marginBottom:"20px"}}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Sammanfattning                
                    </Typography>
                  </Grid>
                  <Grid container xs={6} justify="flex-end" alignItems="flex-end">
                    <Button 
                      color="primary" 
                      style={{width:"100%"}}
                      onClick={() => addModule(`::nyinbet ${cid} ${orderDetails.orderId}`)}
                    >
                      Skapa inbetalning  
                    </Button>
                  </Grid>
                </Grid>
                <Grid container style={{marginTop:"20px", marginBottom:"20px"}}>
                  <Grid item xs={4}>
                    <Typography variant="overline">
                      Pris
                    </Typography>
                    <Typography variant="subtitle2">
                      {formatter.format(orderDetails.price)} 
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="overline">
                      Betalning 
                    </Typography>
                    <Typography variant="subtitle2">
                      {orderDetails.billingInterval}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="overline">
                      Avtalslängd 
                    </Typography>
                    <Typography variant="subtitle2">
                      {orderDetails.subscriptionLength} 
                    </Typography>
                  </Grid>
                </Grid>
                <div style={{height: "100px", overflow:"auto", margin: "10px"}}>
                  <Table size="small" style={{height:"100%", overflow:"visible"}}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={1}>
                          <Typography>
                            Betalningsplan
                          </Typography>
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          {getPaymentAmount(orderDetails)
                          } st betalningar 
                          {" " + priceConstructor(
                            orderDetails.subscriptionLength, 
                            orderDetails.billingInterval, 
                            orderDetails.price)
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentPlan(orderDetails).map((row) => {
                        return (
                          <TableRow>     
                            <TableCell component="th" scope="row">
                              {row.date} 
                            </TableCell>
                            <TableCell align="right">
                              {row.payment}
                            </TableCell>
                          </TableRow>
                      )})
                      }
                    </TableBody>
                  </Table>
                </div>
                <Typography variant="h6" gutterBottom component="div">
                 Betalningshistorik 
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Datum</TableCell>
                      <TableCell>Mottagare</TableCell>
                      <TableCell align="right">Betalt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentHistory.map((historyRow) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {historyRow.dateOfPayment.replace("T", " ").replace("Z", "").slice(0,-4)}
                        </TableCell>
                        <TableCell>
                        {
                          historyRow.creator === null ? 
                          "Hittas ej"
                          : 
                          historyRow.creator
                        }
                        </TableCell>
                        <TableCell align="right">
                          <EditableText
                            dbLabel="amount"
                            id={historyRow.id}
                            url={`https://isgg-intelligens.biz/payment/update/where/${historyRow.id}/amount/`}
                            align="left"
                            value={historyRow.amount}
                            onUpdate={() => updateModule()}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
  else{
    return (<Typography>Laddar...</Typography>)
  }
}

export default function PaymentsModule(params){

  const classes = useStyles();
  const { onRemoveItem } = useMainGridContext();
  const cid = params.cid;
  const [payments, setPayments] = useState([]);
  const [sum, setSum] = useState(0);
  const [paying, setPaying] = useState(0);
  const [paid, setPaid] = useState(0);
  const [orders, setOrders] = useState([]);
  const {ordersApi, paymentsApi} = useAppContext();

  useEffect(() => {
    if(paymentsApi !== null){
      update();
    }
  }, [paymentsApi])

  const update = () => {
    paymentsApi.sumFor(cid)
    .then(res => {
      res.data.recordset.map(p => {
        setSum(s => s + p.paid)
        if(p.paid < p.price){
          setPaying(inc => inc + 1);
        }
        else{
          setPaid(inc => inc + 1);
        }
      });
    });
    paymentsApi.getWhere("customerid", cid)
    .then(res => {
      console.log("res payments", res.data.recordset);

      setPayments(res.data.recordset);
    })
    .catch(err => {console.log("error",err)})

    ordersApi.getWhere("customerid", cid)
      .then(res => {
        setOrders(res.data.recordset);
      })
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
        {params.id} Betalningshistorik {cid}
      </Header>
      <Body>
        <Grid 
          container 
          classsName={classes.paymentContainer}
          style={{overflow:"auto", height:"90%", padding:"20px"}}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5">
                KID: {cid}
              </Typography>
              <Typography variant="h4">
                <b>Inbetalningar</b>
              </Typography>
            </Grid>
            <Grid container xs={6} alignItems="flex-end" justify="flex-end">
              <IconButton onClick={update}>
                <MdUpdate />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container style={{margin: "20px"}}>
            <Grid item xs={4}>  
              <Typography variant="overline">
                Inbetalt
              </Typography>
              <Typography variant="subtitle2">
                <span style={{background:"#c9ffe2"}}>{formatter.format(sum)}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}> 
              <Typography variant="overline">
                Betalade 
              </Typography>
              <Typography variant="subtitle2">
                <span style={{background:"#c9ffe2"}}>{paid}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.tableContainer} style={{height:"100%"}}>
            <Table variant="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Ordernummer</TableCell>
                  <TableCell>Pris</TableCell>
                  <TableCell>Betalt</TableCell>
                  <TableCell>Avbetalt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row) => {
                  //var r = createData(row); 
                  return (
                    <Row 
                      cid={cid} 
                      key={row.orderId} 
                      row={row} 
                      onUpdate={() => update()}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )

}
