import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import _ from 'lodash';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

}));

export default function CreateAuth(params){
  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { authsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  // From values
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  const nameExists = (name) => {
    return authsApi.get(name).then(res => {return res.data.name !== undefined});
  }

  const createAuthority = async () => {
    if(name.length !== 0 && description.length !== 0){
      // Check if auth name already exists
      var authNameExists = await nameExists(name);
      if(authNameExists){
        notify("error", "Namnfel. En auktoritet med det givna namnet finns redan.");
      }
      // Create new authority
      var body = {
        name: name.toLowerCase(),
        description: description
      }
      authsApi.create(body).then(res => {
        notify("success", "Ny autkoritet har skapats.")
      }).catch(err =>{
        console.error("API_ERROR: ", err);
        notify("error", "Server fel. Kunde inte skapa ny auktoritet.");
      });
    }
    else{
      console.error("Authority needs a name and description");
      if(name.length == 0){
        setNameError(true);
      }
      if(descriptionError){
        setDescriptionError(true);
      }
    }
  }
  
  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Ny auktoritet
      </Header>
      <Body>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Ny auktoritet</b>
            </Typography>
          </Grid>
          <Grid container>
            <form style={{width:"100%"}}>
              <Grid item xs={12}>
                <TextField 
                  label="Namn"
                  style={{width:"100%"}}
                  onChange={(e) => setName(e.target.value)}
                  required
                  error={nameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Beskrivning"
                  multiline
                  style={{width:"100%"}}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  error={descriptionError}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  style={{width:"100%"}} 
                  color="primary"
                  onClick={() => createAuthority()}
                >
                  Skapa
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
