import React, { useState, useRef, useEffect } from 'react';
import { MainGridContext } from '../../libs/mainGridContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { formatter, unFormatInteger } from '../general/format.js';
import FileUpload from '../general/file-upload'; import { getMime } from '../../shared/file-handler/utils.js'; import SecureFiles from '../../shared/SecureFiles.js';
import VasaDialog from '../../shared/VasaDialog.js';
import VasaEmojis from '../general/Emojis';
import Ticker from 'react-ticker'

import { v4 as uuidv4 } from 'uuid';

import { upload, openS3Folder } from "../../shared/file-handler/handle.js";

import Customers from '../../shared/Customers.js';
import Services from '../../shared/Services.js';
import UserAdmin from '../../shared/UserAdmin.js';
import Orders from '../../shared/Orders.js';
import Products from '../../shared/Products.js';
import Report from '../../shared/pdf/daily-sales-report.jsx';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

import ModuleUtils from '../../shared/ModuleUtils.js';
import { useAppContext } from '../../libs/contextLib';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../general/notifications.js';
import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { useComsContext } from '../../libs/comsContextLib';
import _ from 'lodash';
import axios from 'axios';
import { getTimeFromDate, getCurrentDay, getDaysAhead, formatDateAddTime } from '../../shared/dateHelper.js'

import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';

import UserPrefs from '../../shared/UserPrefs.js';
import Files from '../../shared/Files.js';
import Users from '../../shared/Users.js';
import EntityEvents from '../../shared/EntityEvents.js';
import Goals from '../../shared/Goals.js';
import Bugs from '../../shared/Bugs.js';

import { blue } from '@material-ui/core/colors';

import BugReportDialog from '../BugReportDialog.js';

import { GithubPicker } from 'react-color';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import BugReportIcon from '@material-ui/icons/BugReport';

import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  LinearProgress,
} from '@material-ui/core';

import {
  IconButton, 
  Typography,
  Button,
  Grid,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Tabs,
  Tab,
  Collapse,
  Paper,
  CircularProgress,
  Breadcrumbs,
  Link
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FolderIcon from '@material-ui/icons/Folder';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ColorizeIcon from '@material-ui/icons/Colorize';
import DeleteIcon from '@material-ui/icons/Delete';
import ChatIcon from '@material-ui/icons/Chat';
import {GiAchievement} from 'react-icons/gi';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const getItemsStyle = (isDragging, draggableStyle, color, theme) => ({
  useSelect: "none",
  padding: 10,
  margin: `0 0 10px 0`,
  borderRadius:"5px",
  background: color,
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: "transparent", 
  padding: 10,
  width:"100%"
});

const useStyles = makeStyles((theme) => ({
  root:{
    position:"relative",
    height:"100%", 
    width:"96%", 
    background: "url('https://vasa.s3.us-east-2.amazonaws.com/public/images/macos-big-sur-apple-layers-fluidic-colorful-wwdc-stock-2020-4096x2304-1455.jpg')",
    backgroundSize:"cover",
    borderRadius:"10px",
    padding:"20px",
    color:"white"
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    margin:0,
    padding:0
  },

  nameDark:{
    color: "white",
    textShadow: "0px 0px 10px #00ccff"
  },
  nameLight:{
    color: theme.palette.text.primary
  },

  dataNumber: {
    background: theme.palette.background.default, 
    color: theme.palette.text.primary
  },
  text:{
    color: theme.palette.text.primary
  },
  sideBar:{
    background: theme.palette.background.paper, 
    width: "10%",
    borderRadius:"10px",
    padding:"10px",
    height: "100%",
    width:"100%"
  },
  rowItem:{
    padding:"10px",
    background: theme.palette.background.default,
    cursor: "pointer",
    marginTop:"10px",
    borderRadius:"10px",
  },
  eventRow:{
    zIndex:10,
    background: theme.palette.background.default,
    '&:hover':{
      background:theme.palette.background.paper
    }
  },
  sidebarContainer:{
    position:"absolute", 
    marginTop:"5px", 
    width:"20%", 
    height:"100%",
    zIndex: 4
  },
  sidebarContainerSelected:{
    position:"absolute", 
    marginTop:"5px", 
    width:"20%", 
    height:"100%",
    zIndex: 5
  },

}));

function SecureFile(params){
  const { currentUser, session } = useAppContext();

  const classes = useStyles();
  const theme = useTheme();

  const [src, setSrc] = useState(null);
  const [mime, setMime] = useState("");

  const getFile = (filepath, jwtToken) => {
    const sfs         = new SecureFiles();
    sfs.readFile(filepath, session.idToken.jwtToken).then(res => {
      setSrc(res.data.url);
      var m = getMime(filepath.split(".")[1]);
      setMime(m);
    }).catch(err => console.log("error", err));
  }

  useEffect(() =>{
    if(session !== undefined && session !== null){
      getFile(params.filepath, session);
      console.log("filepath", params.filepath);
    }
  },[session])
   
  const showMedia = () => {
    if(mime !== "video/webm"){
      return(
        <img alt="Kunde inte ladda in fil" src={src} height="100%"/>
      )
    }
    else{
      return(
        <video height="100%" controls loop>
          <source src={src} type={mime} />
        </video>
      )
    }
  }
  return(
    <>
    {showMedia()}
    </>
  )
}

function TickerElement(params){
  return(
    <div style={{width:"100%", background:"#56d3ac"}}>
      <Ticker offset="run-in" speed={10}>
       {() => (
         <Grid container>
           <Grid item xs={2}>
              <img 
                src={`https://vasa.s3.us-east-2.amazonaws.com/public/images/poggers.gif`}
                height={40}
              />
              <img 
                src={`https://vasa.s3.us-east-2.amazonaws.com/public/images/poggers.gif`}
                height={40}
              />
           </Grid>
           <Grid item xs={10}>
             <Typography variant="h6"><b>TOTENKOPF HAR KROSSAT SITT DAGSREKORD!!!!</b></Typography>
           </Grid>
         </Grid>
       )}
    </Ticker>
    </div>
  )
}

function ChatMain(params){
  const { currentUser, session } = useAppContext();

  const divRef = useRef(null);

  const classes = useStyles();
  const theme = useTheme();

  const [events, setEvents] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [currentPost, setCurrentPost] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [mime, setMime] = useState("");
  const [file, setFile] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [bestSales, setBestSales] = useState({"salesman": null, "data": 0});
  const [totSold, setTotSold] = useState(0);

  const sfs = new SecureFiles();

  const {
    dataFromServer
  } = useComsContext();

  const onEmojiClick = (emojiObject, event) => {
    setCurrentPost(prev => prev + emojiObject.native);
  }

  const resetMessage = () => {
    setCurrentPost("");
    setFile("");
    setMime("");
    setFileUpload("");
  }

  useEffect(() => {
    if(events.length > 0){
      divRef.current.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  },[events])

  useEffect(() => {

    var uApi = new Users(currentUser.username);
    uApi.getAll().then((res) => {
      setAllUsers(res.data.recordset);
    })
    var from = getCurrentDay();
    var to = getDaysAhead(1);

    var requestTemplate =  {
      "body": {"from": from, "to": to},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    }
    axios.post("https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/users/best/sold/price", requestTemplate)
      .then(res => {
        if(res.data.recordset.length == 0){
          setBestSales({salesman:"INGEN", data:0});
        }
        else{
          setBestSales(res.data.recordset[0]);
        }
      })
      .catch(err => {console.log("error", err)});
    getPage();
  },[])

  useEffect(() => {
    if(dataFromServer.entity == "public" && dataFromServer.creator !== currentUser.username){
      setEvents([...events, dataFromServer]);
      console.log(dataFromServer);
    }
  },[dataFromServer])

  const getPage = () => {
    var eventsApi = new EntityEvents("admin1");

    eventsApi.getPagePublic(0, 20).then(res => {
      setEvents(p => p.concat(res.data.recordset.filter(e => e.entity == "public" || (e.entity == "orders" && e.type == "CREATE")).reverse()));
    }).catch(res => {
      console.log("error", "Kunde inte hämta händelser");
    })
  }

  const publishPost = (e) => {

    if(e.key == "Enter" && currentPost.length > 0){
      var b = {
        entity: "public", 
        creator: currentUser.username, 
        type:"NEWSFEED_POST", 
        identifier:uuidv4(),
        message:currentPost.replaceAll("\n", "::BR::"),
        idColumn: "username",
        fileUpload:"" 
      }
      if(fileUpload !== ""){
        b.fileUpload = fileUpload;
      }
      var eventsApi = new EntityEvents("admin1");

      eventsApi.create(b)
      .then(res => {
        console.log("Meddelande har skickats");
      })
      .catch(err => {
        notify("error", `Kunde inte skapa meddelande. ${err}`)
      });
      resetMessage();
      
      if(file.name !== undefined && file.name.length > 0 && session !== undefined){
        if(session !== undefined){
          sfs.upload(mime, `uploads/${file.name}`, session.idToken.jwtToken).then(res => {
            var url = res.data.curl;
            axios.put(url, file).then(e => {
              setEvents([...events, b]);
              b.filepath = fileUpload.filepath;
              console.log(b);
            });
          }).catch(err => {notify("error", `Kunde inte ladda upp meddelande. ${err}`)});
        }
      }
      else{
        setEvents([...events, b]);
      }
    }
  }

  const getFname = (e) => {
    setFile(e.target.files[0]);
    setFileUpload({"filepath": `uploads/${e.target.files[0].name}`})
    var fileType = e.target.files[0].name.split(".")[1];
    setMime(getMime(fileType));
  }
  
  return(
    <Grid 
      container 
      style={{border:`1px solid ${theme.palette.border.primary}`, zIndex:1, position:"absolute", height:"50vh", borderRadius:"50px"}}
    >
      <Grid 
        container 
        alignItems="center" 
        justify="center" 
        style={{background:"linear-gradient(to left, #31cbf9, #f93131)", borderRadiusTop:"10px"}}
      >
        <Typography variant="overline" style={{color:"white"}}>
          ISGG <b>CHATT</b>
        </Typography>
      </Grid>
      <Grid 
        container 
        style={{background:"linear-gradient(to bottom left, #ffeb3b 5%, #ffb300 70%)", paddingLeft:"10px"}}
      >
        <Typography variant="overline" style={{color:"white"}}>
          Dagens mvp: <b>{bestSales.salesman}</b>
        </Typography>
      </Grid>

      <Grid container style={{background:theme.palette.background.paper, height:"55vh", overflow:"auto"}}>
        <Grid item xs={12}> 
        {events.map((e,i) => {
          if(e.entity == "public" && allUsers.length > 0){
            var profile = allUsers.filter(u => u.username == e.creator)[0];
            if(profile !== undefined){
              profile = profile.profileMedia;
            }
            else{
              profile = "";
            }

            return(
              <Grid key={i} container ref={divRef}>
                 {e.filepath !== undefined && e.filepath !== null && e.filepath !== "undefined" && e.filepath.length > 0 ?
                    <Grid item xs={12} style={{height:"350px"}}>
                      <SecureFile filepath={e.filepath}/>
                    </Grid>
                    :
                    null
                  }
                <Grid container item xs={1} style={{height:"50px"}}>
                  <Avatar 
                    style={{margin:0, padding:0}}
                    src={`https://vasa.s3.us-east-2.amazonaws.com/${profile}`} 
                    alt={e.creator}
                  />
                </Grid>
                <Grid container item xs={11} style={{paddingTop:"10px", paddingLeft:"10px"}}>
                  <>
                    {e.creator == bestSales.salesman ?
                      <img 
                        src={`https://vasa.s3.us-east-2.amazonaws.com/dashboard_vids/trophy.gif`}
                        height={20}
                      />
                      :
                      null
                    }
                  </>
                  <Typography variant="body1"><b>{e.creator}:</b> {e.message}</Typography>
                </Grid>
              </Grid>
            )
          }
          if(e.entity == "orders" && e.type == "CREATE"){
            return(
              <Grid key={i} container ref={divRef}>
                <Grid container item xs={12} style={{paddingTop:"10px", paddingLeft:"10px", borderLeft:"5px solid #ffcc33"}}>
                  <>
                    {e.creator == bestSales.salesman ?
                      <img 
                        src={`https://vasa.s3.us-east-2.amazonaws.com/dashboard_vids/trophy.gif`}
                        height={20}
                      />
                      :
                      null
                    }
                  </>
                  <Typography variant="overline" style={{fontSize:"12px"}}>💰 <b>{e.creator}</b> {e.identifier} {formatDateAddTime(new Date(e.created), 0)}</Typography>
                </Grid>
              </Grid>
            )
          }
        })}
        </Grid>
      </Grid>
      <Grid container style={{background:theme.palette.background.paper, border: `1px solid ${theme.palette.border.primary}`, borderRadius:"10px"}}>
        <Collapse in={showEmojiPicker}>
          <VasaEmojis onEmojiClick={(o, e) => onEmojiClick(o,e)}/>
        </Collapse>
        <Grid container>
          <Grid item xs={1}>
            <button 
              className={classes.button} 
              style={{border:`none`, width:"100%", height:"100%", background:theme.palette.background.paper}}
              variant="outlined" 
              onClick={() => setShowEmojiPicker(p => !p)}
            >
              <EmojiEmotionsIcon fontSize="small"/>
            </button>
          </Grid>
          <Grid item xs={11}>
            <TextField 
              size="small"
              fullWidth 
              variant="outlined"
              value={currentPost}
              label="Skriv ett meddelande..."
              onChange={(e) => setCurrentPost(e.target.value)}
              onKeyDown={publishPost}
            />
            {mime.length > 0 ?
              <Typography>Laddar upp: <b>{file.name}</b></Typography>
              :
              null
            }
          </Grid>
          <Grid item xs={12}>
            <FileUpload style={{width:"100%"}} getFname={(e) => getFname(e)}/>
          </Grid> 
        </Grid>
      </Grid>
    </Grid>
  )
}

function WriteReport(params){
  const { currentUser } = useAppContext();

  const openDialog = params.open;
  const setOpenDialog = params.setOpen;

  const [cs, setCs] = useState([]);
  const [os, setOs] = useState([]);
  const [ss, setSs] = useState([]);
  const [ps, setPs] = useState([]);
  const [costs, setCosts] = useState(0);

  const [msg, setMsg] = useState("");
  const [prog, setProg] = useState(0);

  const [tot, setTot]     = useState(0);
  const [currentDayPeriod, tmp] = useState({start: getDaysAhead(0), end: getDaysAhead(1)})

  const getServices = async () => {
    const ss = new UserAdmin(currentUser.username);
    const sres = await ss.getServices(
      currentUser.username, 
      currentDayPeriod.start, 
      currentDayPeriod.end 
    );
    setSs(sres.data.recordset);

    const ps = new Products(currentUser.username);
    const pres = await ps.getAll();

    sres.data.recordset.map(e => {
      var uPrice = pres.data.recordset.filter(p => p.name == e.productCode)[0].unitPrice;
      setCosts(p => p + uPrice*e.amount)
    });

    setPs(pres.data.recordset);
  }

  const getData = async () => {
    const us = new Users(currentUser.username);
    const ua = new UserAdmin(currentUser.username);
    setMsg("Hämtar kunder...");
    setProg(0);
    console.log("p", currentDayPeriod);
    try{
      const cres = await ua.getCustomerOrders(currentUser.username, currentDayPeriod.start, currentDayPeriod.end);
      setMsg("Hämtar order...");
      var tmp = [];
      var tmpTot = 0;
      console.log("cres", cres.data.recordset);
      cres.data.recordset.map(e => {
        tmp.push({
          "kid": e.customerid,
          "oid": e.orderId,
          "name": e.name,
          "ssn": e.socialSecurityNumber,
          "billing": e.billingInterval,
          "subscriptionLength": e.subscriptionLength,
          "price": e.price
        });
        setTot(p => p + e.price);
      })
      setOs(tmp);
      setMsg("Hämtar tjänster...");
      setProg(50);
      getServices();
      setProg(100);
      setMsg("Klar!");
      if(cres.data.recordset.length == 0){
        setMsg("Det hittas inga order som skapades idag.");
      }
    }
    catch(err){
      setMsg("Något gick fel");
    }
  }

  useEffect(() => {
    if(params.open){
      getData();
    }
  },[params.open])

  return(
    <VasaDialog 
      open={openDialog} 
      handleClose={() => setOpenDialog(false)}
    >
      <Typography>
        {msg}
      </Typography>
      <LinearProgress variant="determinate" value={prog}/>
      {os.length > 0 && ss.length > 0 && ps.length > 0 && prog >= 100?
        <PDFDownloadLink
          document={
            <Report 
              user={currentUser.username}
              writtenBy={currentUser.username}
              customers={cs} 
              orders={os} 
              services={ss}
              products={ps}
              costs={costs}
              ss={[]} 
              meta={{}}
              tot={tot}
            />
          }
          filename="dagsrapport.pdf"
        >
          {({ blob, url, loading, error }) => {
            if(!loading){
              return<p>{url}</p>
            }
            else{
              return <div>Skriver PDF...</div>
            }
          }}
        </PDFDownloadLink>
        :
        null
      }
    </VasaDialog>
  )
}

function AllBugs(params){
  const classes = useStyles();
  const theme = useTheme();

  const { addModule } = useMainGridContext();
  const { currentUser } = useAppContext();

  const bugsApi = new Bugs(currentUser.username);

  const [bugs, setBugs] = useState([]);
  const [openBugReport, setOpenBugReport] = useState(false);
  const [explain, setExplain] = useState(false);

  useEffect(() => {
    if(params.show){
      bugsApi.getAll().then(res => {
        console.log(res);
        setBugs(res.data.bugs)
      }) 
    }
  },[params.show])


  return(
    <Paper style={{padding:"10px", zIndex:10}}>
      <Grid container className={classes.sidebar}>
        <Typography>
          <BugReportIcon fontSize="small" /> Buggar 
        </Typography>
        <Grid container>
          {bugs.map(e => (
            <>
              <Grid item style={{marginBottom:"10px"}}>
                <Typography 
                  style={{background:`rgba(235, 64, 52, ${e.priority/5})`}}
                >
                  <b>{e.title}</b> (prioritet: {e.priority})
                </Typography>
                <Typography style={{padding:"10px"}}>
                  {e.description}
                </Typography>
                <Divider />
              </Grid>
            </>
          ))}
          <Grid item xs={12}>
            <Button fullWidth onClick={() => setOpenBugReport(true)}>
              Lägg till
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth onClick={() => setExplain(p => !p)}>
              Vad har prioritet för betydelse? 
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={explain}>
              <div style={{padding:"10px"}}>
                <Typography style={{marginBottom:"20px"}}>
                  Prioriteter används för att visa hur viktigt en bug är att fixa.
                  5 är viktigast 1 är minst viktigt. Prioriteter kan bara tilldelas
                  av utvecklare.
                </Typography>
                <Typography style={{background: `rgba(235, 64, 52, 0.2)`}}>
                 1. Buggen leder till mindre besvär som man helst vill vara utan
                </Typography>
                <Typography style={{background: `rgba(235, 64, 52, 0.4)`}}>
                 2. Buggen gör att man jobbar långsammare
                </Typography>
                <Typography style={{background: `rgba(235, 64, 52, 0.6)`}}>
                 3. Buggen gör det besvärligt att göra en funktion 
                </Typography>
                <Typography style={{background: `rgba(235, 64, 52, 0.8)`}}>
                 4. Buggen gör det omöjligt att göra en funktion 
                </Typography>
                <Typography style={{background: `rgba(235, 64, 52, 1)`}}>
                 5. Buggen gör att VASA inte kan användas 
                </Typography>
              </div>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
      <BugReportDialog 
        open={openBugReport}
        title={""}
        handleClose={() => setOpenBugReport(false)} 
      />
    </Paper>
  )
}

function UserLists(params){
  return(
    <Paper style={{padding:"10px", zIndex:10}}>
      <Grid container justify="center" alignItems="center">
        <Typography variant="h6">
          Här byggs det för fullt.
        </Typography>
        <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/construction.gif" height={250}/>
        <Typography variant="subtitle1">
          När det är färdigt ska du kunna skapa och dela diverse listor.
        </Typography>
      </Grid>
    </Paper>
  )
}

function UserFiles(params){
  const [file, setFile] = useState("");
  const [mime, setMime]   = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [currentFolder, setCurrentFolder] = useState({"files":[], "folders":[]});
  const [cPath, setCPath] = useState("");

  const { currentUser, session } = useAppContext();

  const [uploadInProgress, setUploadInProgress] = useState(false);

  useEffect(() => {
    if(currentUser.username !== null && session !== undefined){
      setCPath(`users/${currentUser.username}`);
      const sfs = new SecureFiles();
      openS3Folder(
        `users/${currentUser.username}/`, 
        session, 
        sfs, 
        () => notify("error", "Kunde inte hämta data"), 
        (res) => setCurrentFolder({"files": res.data.content.Contents, "folders": res.data.content.CommonPrefixes}));
    }

  },[currentUser])


  const getFname = (e) => {
    setUploadInProgress(true);
    setFile(e.target.files[0]);
    setFileUpload({"filepath": `uploads/${e.target.files[0].name}`})

    var fileType = e.target.files[0].name.split(".")[1];
    setMime(getMime(fileType));

    const file = e.target.files[0];
    const location = `users/${currentUser.username}/${file.name}`;
    const mime = getMime(fileType);

    const sfs = new SecureFiles();
    upload(
      file, 
      session, 
      mime, 
      sfs, 
      location, 
      () => {notify("error", "Kunde inte ladda upp fil.");setUploadInProgress(false)},
      () => {notify("success", `Filen ${file.name} har laddats upp!`);setUploadInProgress(false)},
    );

  }

  const uploadFile = () => {
    const sfs = new SecureFiles();
    upload(file, session, mime, sfs, () => notify("error", "Kunde inte ladda upp fil."));
  }

  const openFolder = (path) => {
    const sfs = new SecureFiles();
    openS3Folder(
      path, 
      session, 
      sfs, 
      () => notify("error", "Kunde inte hämta data"), 
      (res) => readResult(res, path));
  }

  const readResult = (res, folder) => {
    setCurrentFolder({"files": res.data.content.Contents, "folders": res.data.content.CommonPrefixes})
    setCPath(folder)
  }

  return(
    <Paper style={{padding:"10px", zIndex:10}}>
      <Typography variant="h4">
        Filuppladdningar
      </Typography>
      <Typography variant="body2">
        Notera att filerna inte är krypterade och kan läsas av de användare
        som är auktoriserade till det. Du ska alltså INTE se detta som dina 'privata' filer.
      </Typography>
      <FileUpload title={uploadInProgress ? <CircularProgress /> : "ladda upp"} getFname={(e) => getFname(e)}/>
      <Breadcrumbs>
        <Link onClick={() => {openFolder("")}}>
          isgg 
        </Link>
        {cPath.split("/").map((e,i) => (
          <Link key={i} onClick={() => openFolder(cPath.split("/").slice(0, i+1).join("/") + "/")}>
            {e}
          </Link>
        ))}
      </Breadcrumbs>
      {currentFolder["files"].map((e,i) => {
        return(<p key={i}>{e.Key.replace(cPath, "")}</p>)
      })}
      {currentFolder["folders"].map((e,i) => {
        return(<p key={i} onClick={() => openFolder(e.Prefix)}>{e.Prefix.replace(cPath , "")}</p>)
      })}

    </Paper>
  )
}

function ListElement(params){
  const classes = useStyles();
  const theme = useTheme();

  const { addModule } = useMainGridContext();

  const content = params.content;
  const color = params.color;
  const description = params.description;
  const index = params.index;
  const onUpdate = params.onUpdate;
  const onRemove = params.onRemove;
  const [showColor, setShowColor] = useState(false);

  const update = (color, event) => {
    var item = {"name": content, "color": color.hex, "description": description};
    onUpdate(item);
    setShowColor(false); 
  }

  return(
    <Grid container style={{background:color}}>
      <Grid container item xs={6}>
        <IconButton 
          size="small" 
          onClick={() => addModule(content)} 
        >
          <BookmarkIcon fontSize="small"/>
        </IconButton>
        <Typography variant="subtitle1">
          {content}
        </Typography>
      </Grid>
      <Grid container item xs={6} justify="flex-end" alignItems="flex-end">
        <IconButton onClick={() => onRemove()} size="small">
          <DeleteIcon fontSize="small"/>
        </IconButton>
        <IconButton size="small" onClick={() => setShowColor(prev => !prev)}>
          <ColorizeIcon fontSize="small"/>
        </IconButton>
        <Collapse in={showColor}>
          <div style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
              <GithubPicker onChange={update}/>
            </div>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12} style={{paddingLeft:"27px"}}>
        {description !== undefined ?
          <Typography variant="subtitle2">
            {description}
          </Typography>
          :
          <Typography variant="overline">
           Ingen beskrivning finns 
          </Typography>
        }
      </Grid>
    </Grid>
  )
}

function EventRow(params){
  const classes = useStyles();
  const theme = useTheme();

  const event = params.event;
  const [showDetails, setShowDetails] = useState(false);

  return(
    <Grid 
      container 
      xs={12} 
      className={classes.eventRow}
    >
      <Grid 
        container 
        onClick={() => setShowDetails(prev => !prev)}
        xs={12}
        style={{
          cursor:"pointer"
        }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle2" style={{fontSize:"12px"}}>
            {showDetails ?
              <KeyboardArrowUpIcon fontSize="small" style={{paddingTop:"5px"}}/>
              :
              <KeyboardArrowDownIcon fontSize="small" style={{paddingTop:"5px"}}/>
            }
            {getTimeFromDate(event.created)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" style={{fontSize:"12px"}}>
            {event.entity}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" style={{fontSize:"12px"}}>
            {event.type.split(":")[0]}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Collapse in={showDetails}>
          <Grid container xs={12} style={{width:"100%", padding:"10px"}}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {event.type}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Identifikator: {event.identifier}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>

  );
}

function UserEvents(params){
  const classes = useStyles();
  const theme = useTheme();
  
  const { currentUser } = useAppContext();
  const username = currentUser.username;

  const entityEventsApi = new EntityEvents(username);

  const [events, setEvents]         = useState([]);
  const [loading, setLoading]       = useState(false);
  const [page, setPage]             = useState(0);
  const [allFetched, setAllFetched] = useState(false);

  useEffect(() => {
    if(params.show){
      setEvents([]);
      setPage(0);
      setAllFetched(false);
      fetchEvents();
    }
  },[params.show])

  const fetchEvents = () => {
    setLoading(true);
    var today = String(getCurrentDay());
    var tomorrow = String(getDaysAhead(1));
    entityEventsApi.getUserEventOverInterval(username, today, tomorrow, 10, page)
      .then((res) => {
        setLoading(false);
        if(res.data.recordset.length > 0){
          setEvents(p => p.concat(res.data.recordset));
        }
        else{
          setAllFetched(true);
        }
        setPage(p => p + 1);
    }).catch(err => {
      notify("error", `Kan inte hämta händelser. ${err}`)
      setLoading(false);
    });
  }

  return(
    <Paper style={{padding:"10px", zIndex:10}}>
      <Grid container className={classes.sidebar}>
        <Typography>
          Händelser ({events.length}) {getCurrentDay()}
        </Typography>
        {loading ?
          <Grid container item xs={12} justify="center" alignItems="center">
            <CircularProgress size={20}/>
          </Grid>
          :
          null
        }
        <Grid 
          item 
          xs={12} 
          style={{
            background:theme.palette.background.default, 
            borderRadius:"5px",
            padding:"10px"
          }}
        >
          {events.map(e => {
            return(
              <EventRow event={e} />
            )
          })
          }
        </Grid>
      </Grid>
      <Grid 
        item 
        container 
        xs={12} 
        alignItems="center" 
        justify="center" 
        style={{padding:"20px"}}
      >
        {!allFetched ?
          <Button fullWidth onClick={() => fetchEvents()}>
            Hämta fler
          </Button>
          :
          <Typography>
            Finns inga fler händelser att hämta
          </Typography>
        }
      </Grid>
    </Paper>
  )

}

function GivenGoal(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser }   = useAppContext();
  const username = currentUser.username;

  const { dataStreams }   = useComsContext();

  const goal = params.goal;

  const [dynamicValue, setDynamicValue] = useState(0);
  const [progress, setProgress]         = useState(0);
  const [showDetails, setShowDetails]   = useState(false);

  useEffect(() => {
    var ds = goal.dataStream;
    const dsObj = _.filter(dataStreams, (e) =>{return e.name == ds})[0];
    axios.get(`${dsObj.url}/${username}`).then(res => {
      setDynamicValue(res.data.recordset[0].data);
      var v = unFormatInteger(res.data.recordset[0].data); 
      var p = (v / goal.goal)*100; 
      if(p > 100){
        setProgress(100);
      }
      else{
        setProgress(p);
      }
    });
  },[])

  return(
    <>
      <Grid item xs={4}>
        <Typography>
          <IconButton size="small" onClick={() => setShowDetails(prev => !prev)}>
            {showDetails ?
              <KeyboardArrowUpIcon />
              :
              <KeyboardArrowDownIcon />
            }
          </IconButton>
          {goal.goalName}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          {formatter.format(goal.goal)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          {dynamicValue}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress variant="determinate" value={progress}/>
      </Grid>
      <Collapse in={showDetails}>
        <Grid container xs={12} style={{padding:"10px", paddingBottom: "20px", background: theme.palette.background.default}}>
          <Grid item xs={12}>
            Skapades {goal.time}
          </Grid>
          <Grid item xs={12}>
            Skapare: {goal.creator}
          </Grid>
          <Grid item xs={12}>
            Deadline: {goal.deadline}
          </Grid>
          <Grid item xs={12}>
            Dataström: {goal.dataStream}
          </Grid>
          <Grid item xs={12}>
            Klar: {String(goal.achieved)}
          </Grid>
        </Grid>
      </Collapse>
    </>

  )
}

function UserGoals(params){
  const classes = useStyles();
  const theme = useTheme();
  
  const { currentUser }   = useAppContext();
  const username = currentUser.username;

  const goalsApi = new Goals(username);

  const [adminGoals, setAdminGoals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(params.show){
      goalsApi.getActive(username).then(res => {
        setAdminGoals(res.data.recordset);
      })
    }
  },[params.show])

  return(
    <Paper style={{padding:"10px"}}>
      <Grid container className={classes.sidebar}>
        <Typography>
          <GiAchievement /> Mål 
        </Typography>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Tilldelade mål 📢</b>
          </Typography>
        </Grid>
        <Grid 
          container 
          item 
          xs={12} 
          style={{
            background:theme.palette.background.default,
            padding:"20px"
          }}
        >
          <Grid container>
            {adminGoals.map(row => (
              <GivenGoal goal={row} />
            ))
            }
          </Grid>
          <Typography>
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography>
            Personliga mål
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )

}

function Bookmarks(params){
  const classes = useStyles();
  const theme = useTheme();

  const { addModule, notify } = useMainGridContext();
  const { currentUser } = useAppContext();

  const userPrefs = new UserPrefs(currentUser.username);

  const [bMarks, setBMarks] = useState([]);

  const onDragEnd = (result) => {
    if(!result.destination){
      return;
    }

    const items = reorder(
      bMarks,
      result.source.index,
      result.destination.index
    )

    setBMarks(items);
    updateBookmarks(items);
  }

  const updateBookmarks = (items) => {
    userPrefs.update(currentUser.username, "bookmarks", items);
  }

  const updateBookmark = (item, index) => {
    var tmp = bMarks;
    tmp[index] = item;
    setBMarks(tmp);
    userPrefs.update(currentUser.username, "bookmarks", tmp);
  }

  const remove = (index) => {
    var tmp = bMarks;
    tmp.splice(index, 1);
    setBMarks(tmp);
    userPrefs.update(currentUser.username, "bookmarks", tmp);
  }

  useEffect(() => {
    if(params.bookmarks == null || params.bookmarks == undefined){
      setBMarks([]);
    }
    else{
      setBMarks(params.bookmarks);
    }
  },[params.bookmarks])

  return(
    <Grid container className={classes.sideBar}>
      <Typography>
        <BookmarkIcon fontSize="small" /> Bokmärken
      </Typography>
      <Grid item xs={12} style={{zIndex:5}}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {bMarks.map((item, index) => (
                  <Draggable 
                    key={String(index)} 
                    draggableId={String(index)} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemsStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          item.color,
                          theme
                        )}
                      >
                        <ListElement 
                          content={item.name} 
                          color={item.color}
                          description={item.description}
                          index={index} 
                          onRemove={() => remove(index)}
                          onUpdate={(item) => updateBookmark(item, index)}
                        />
                      </div>
                    )
                    }
                  </Draggable>
                ))
                }
              </div>
            )
            }
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  )
}

function UserAvatar(params){
  const classes = useStyles();
  const theme = useTheme();
  const { currentUser } = useAppContext(); 

  const [profileFname, setProfileFname] = useState(null);
  const [profile, setProfile] = useState(params.profile);

  const allowedFileTypes = ["jpeg", "jpg", "png"]

  const fullName = params.fullName;

  // Utility class to handle file operations in S3
  const Fs = new Files("admin1");
  const usersApi = new Users(currentUser.username);

  useEffect(() => {
    setProfile(params.profile);
  },[params.profile])

  const getFname = (e) => {
    const fType = e.target.files[0].name.split(".")[1];
    if(!allowedFileTypes.includes(fType)){
      notify("error", "Filen måste ha något av formaten: jpeg, jpg, png");
    }
    else{
      setProfileFname(e.target.files[0].name);
      setProfile(e.target.files[0]);
      getPresignedUrl(e.target.files[0], `image/${fType}`);
    }
  }

  const getPresignedUrl = (file, mime) => {
    Fs.initiateFileUpload(mime, `profiles/${file.name}`, "").then(res => 
      {
        var url = res.data.curl;
        axios.put(url, file);
        notify("success", `filen: ${file.name} är uppladdad`);
        updateUserProfile(`profiles/${file.name}`);
      }
    )
    .catch(err => {
      notify("error", `Kunde inte ladda upp fil ${file.name}, ${err}`)
    });
  }

  const updateUserProfile = (fname) => {
    var body = {"value": fname};
    usersApi.updateWhere(currentUser.username, "profileMedia", body).then(res => {
      notify("success", "Profilbild uppdaterad");
    })
    .catch(err => {
      notify("error", `Kunde inte uppdatera profilbild. Felmeddelande: ${err}`)
    });
  }

  return(
    <>
      <IconButton
        component="label"
        style={{margin:0, padding:0}} >
        <Avatar 
          className={classes.avatar} 
          src={`https://vasa.s3.us-east-2.amazonaws.com/${profile}`} 
          alt={`${currentUser.username}`}
        />
        <input
          type="file"
          hidden
          onChange={getFname}
        />
      </IconButton>
    </>
  );
}

function DataStreamElement(params){
  const classes = useStyles();

  const { currentUser } = useAppContext(); 


  const {
    dataFromServer, 
    dataStreams, 
    userPrefs,
    activeGoals
  } = useComsContext();

  const [firstLoad, setFirstLoad] = useState(true);
  const [data, setData]           = useState(null);
  const [name, setName]           = useState(params.name)
  const [loading, setLoading]     = useState(false);

  const ds = _.filter(dataStreams, (e) =>{return e.name == name})[0];

  useEffect(() => {
    if(currentUser.username !== undefined){
      if(firstLoad){
        setLoading(true);
        axios.get(`${ds.url}/${currentUser.username}`).then(res => {
          if(res.data.recordset[0] === undefined || res.data.recordset[0].data === null){
            setData("0,00 kr");
          }
          else{
            setData(res.data.recordset[0].data);
          }
          setLoading(false);
          if(isGoal(name)){
            const goal = _.filter(activeGoals, (g) => {return g.dataStream == name})[0];
            if(unFormatInteger(res.data.recordset[0].data) > goal.goal && !goal.achieved){
              goalAchieved(goal.id);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          setData("KUNDE INTE HÄMTAS");
        });
      } 
      if(firstLoad){
        setFirstLoad(false);
      }
    }
  },[dataFromServer])

  const isGoal = (stream) => {
    const gs = activeGoals.map((e) => {return e.dataStream});
    if(gs.length > 0 && gs.includes(stream)){
      return gs.includes(stream);
    }
    return false;
  }

  const appropriateEvent = () => {
    if(
      Object.keys(dataFromServer).length > 0 &&
      ds.events[dataFromServer.entity.toLowerCase()] !== undefined
    ){
      var operation = dataFromServer.type.split(":")[0].toLowerCase();
      if(ds.events[dataFromServer.entity.toLowerCase()] == operation){
        return true;
      }
    }
    return false;
  }

  const goalAchieved = (id) => {
    const eventsApi = new EntityEvents(currentUser.username);
    const goalsApi = new Goals(currentUser.username);

    eventsApi.create({
      entity: "public", 
      creator: currentUser.username, 
      type:"GOAL_ACCOMPLISHED", 
      identifier:name,
      message:"success",
      idColumn: "goalName" 
    });

    goalsApi.update(id, "achieved", "1");
  }

  return(
    <Grid item style={{paddingRight:"15px"}}>
      <Grid container direction="row">
        <Typography variant="overline">
          {name}
        </Typography>
        {loading ?
          <CircularProgress size={20} />
        :
          <Typography variant="subtitle2" style={{paddingTop:"4px", paddingLeft:"5px"}}> 
            <b>{data}</b>
          </Typography>
        }
      </Grid>
    </Grid>
  );
}

export default function MainUserProfile(params){
  const classes = useStyles();
  const [firstLoad, setFirstLoad] = useState(true);
  const { addModule } = useMainGridContext();

  const [prevEvent, setPrevEvent] = useState({})
  const theme = useTheme();
  const { currentUser, setCurrentUser, setUserProfile } = useAppContext(); 
  const {
    dataFromServer, 
    dataStreams, 
    userPrefs,
  } = useComsContext();

  const [openReportDialog, setOpenReportDialog] = useState(false);

  const [user, setUser] = useState({});
  const [fullName, setFullName]   = useState("");
  const [profile, setProfile]     = useState("");
  const [usersApi, setUsersApi]   = useState(null); 
  const [streams, setStreams]     = useState("");
  const [bMarks, setBMarks] = useState([]);

  const [showBookmarks, setShowBookmarks]   = useState(false);
  const [showLists, setShowLists]           = useState(false);
  const [showFiles, setShowFiles]           = useState(false);
  const [showEvents, setShowEvents]         = useState(false);
  const [showGoals, setShowGoals]           = useState(false);
  const [showBugs, setShowBugs]             = useState(false);
  const [showChat, setShowChat]             = useState(false);
  const [userDataStreams, setUserDataStreams] = useState([]);

  useEffect(() => {
    if(currentUser.username !== null && currentUser.username !== undefined){
      var uApi = new Users(currentUser.username);
      setUsersApi(uApi);
 
      uApi.getWhere("username", currentUser.username).then((res) => {
        setFullName(res.data.recordset[0].fullName);
        setProfile(res.data.recordset[0].profileMedia);
        setUserProfile(res.data.recordset[0].profileMedia) 
      })
    }
    if((prevEvent === dataFromServer || firstLoad) && Object.keys(userPrefs).length > 0){
      setFirstLoad(false);
      setPrevEvent(dataFromServer);
      // Set bookmarks
      setBMarks(userPrefs.bookmarks);
      setUserDataStreams(userPrefs.dataStreams);
    }
  },[currentUser, dataFromServer, dataStreams, userPrefs]);

  const showSideMenu = (menu) => {
    switch(menu){
      case "bookmarks":
        setShowEvents(false);
        setShowBookmarks(prev => !prev);
        setShowLists(false);
        setShowFiles(false);
        setShowGoals(false)
        break;
      case "lists":
        setShowEvents(false);
        setShowBookmarks(false);
        setShowLists(prev => !prev);
        setShowFiles(false);
        setShowGoals(false)
        break;
      case "files":
        setShowEvents(false);
        setShowBookmarks(false);
        setShowLists(false);
        setShowFiles(prev => !prev);
        setShowGoals(false)
        break;
      case "events":
        setShowEvents(prev => !prev);
        setShowBookmarks(false);
        setShowLists(false);
        setShowFiles(false);
        setShowGoals(false)
        break;
      case "goals":
        setShowEvents(false);
        setShowBookmarks(false);
        setShowLists(false);
        setShowFiles(false);
        setShowGoals(prev => !prev)
        break;
      case "bugs":
        setShowBugs(p => !p)
        setShowChat(false)
        break;
      case "chat":
        setShowChat(p => !p)
        setShowBugs(false)
        break;

    }
  }

  return(
    <Grid item xs={12} style={{width:"95%", height:"100%"}}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={1} style={{position:"relative", top:0, margin:0, padding:0}}>
            <UserAvatar fullName={fullName} profile={profile} />
          </Grid>
          <Grid item xs={2}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                {currentUser.username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={
                theme.palette.type == "dark" ? classes.nameDark : classes.nameLight}
              >
                <b>{fullName.split(" ")[0]}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.text}>
                {currentUser.auth}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid 
              container 
              className={classes.dataNumber} 
              style={{borderRadius:"5px", padding:"5px"}}
            >
              <Grid container item xs={11} direction="row">
                {userDataStreams.map((e, i) => (
                  <DataStreamElement name={e} key={i}/>
                ))
                }
              </Grid>
              <Grid 
                container 
                item xs={1} 
                alignItems="flex-end" 
                justify="flex-end" 
                style={{margin:0, padding:0}}
              >
                <IconButton 
                  size="small" 
                  style={{margin:0}} 
                  onClick={() => {addModule("::pds")}}
                >
                  <AddIcon fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {[
                {name: "Profil", module: `::anv ${currentUser.username}`}, 
                {name: "Kunder", module: `::kunder ${currentUser.username}`}, 
                {name: "Dagsrapport", module: `::anv ${currentUser.username}`}, 
                {name: "Statistik", module: `::anv ${currentUser.username}`},
                {name: "Utmaningar", module: `::anv ${currentUser.username}`}, 
                {name: "Jämförelser", module: `:: anv ${currentUser.username}`}]
                  .map((e, i) => {
                    if(e.name == "Dagsrapport"){
                      return(
                        <Grid key={i} item xs={2}>
                          <Button 
                            style={{width:"100%"}} 
                            onClick={() => setOpenReportDialog(true)}
                          >
                          {e.name}
                          </Button>
                        </Grid>
                      )
                    }
                    else{
                      return(
                        <Grid key={i} item xs={2}>
                          <Button 
                            style={{width:"100%"}} 
                            onClick={() => addModule(e.module)}
                          >
                          {e.name}
                          </Button>
                        </Grid>
                      )
                    }
              })
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container style={{width:"97%"}}>
        <Grid item xs={6}>
          <Grid container>
            {[
              {"title": "händelser", "label":"events", "icon": <EventNoteIcon />},
              {"title": "filer", "label":"files", "icon": <FolderIcon />},
              {"title": "listor", "label":"lists", "icon": <FormatListBulletedIcon />},
              {"title": "bokmärken", "label": "bookmarks", "icon": <BookmarkIcon />},
              {"title": "mål", "label": "goals", "icon": <GiAchievement/>}
            ].map((e, i) => (
              <Grid item key={i}>
                <Tooltip title={`Mina ${e.title}`}>
                  <IconButton onClick={() => showSideMenu(e.label)}>
                    {e.icon} 
                  </IconButton>
                </Tooltip>
              </Grid>
            ))

            }
          </Grid>
          {
            [
              {bool: showBookmarks, comp: <Bookmarks bookmarks={bMarks}/>}, 
              {bool: showEvents, comp: <UserEvents show={showEvents}/>}, 
              {bool: showFiles, comp: <UserFiles show={showFiles}/>}, 
              {bool: showLists, comp: <UserLists show={showLists}/>}, 
              {bool: showGoals, comp: <UserGoals show={showGoals}/>}].map((e, i) => (
              <div key={i} className={e.bool ? classes.sidebarContainerSelected : classes.sidebarContainer}>
                <Collapse in={e.bool}>
                  {e.comp}
                </Collapse>
              </div>
            ))
          }
        </Grid>
        <Grid item xs={6}>
            <Grid container justify="flex-end" alignItems="flex-end">
              {[
                {"title": "buggar", "label":"bugs", "icon": <BugReportIcon/>},
                {"title": "chat", "label":"chat", "icon": <ChatIcon/>},
              ].map((e, i) => (
                <Grid key={i} item>
                  <Tooltip title={`${e.title}`}>
                    <IconButton onClick={() => showSideMenu(e.label)}>
                      {e.icon} 
                    </IconButton>
                  </Tooltip>
                </Grid>
              ))

              }
            </Grid>
            {
              [
                {bool: showBugs, comp: <AllBugs show={showBugs} />},
                {bool: showChat, comp: <ChatMain show={showChat} />}
              ].map((e, i) => (
                <div key={i} className={e.bool ? classes.sidebarContainerSelected : classes.sidebarContainer} style={{right:"120px"}}>
                  <Collapse in={e.bool}>
                    {e.comp}
                  </Collapse>
                </div>
              ))
            }
        </Grid>
      </Grid>
      <WriteReport open={openReportDialog} setOpen={(p) => setOpenReportDialog(p)}/>
    </Grid>
  )
}
