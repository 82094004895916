import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import _ from 'lodash';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import axios from 'axios';

import { 
  getMonday, 
  getMonthPeriod, 
  currentMonthPeriod, 
  dateFormatZero,
  getMonthIndexFromName,
  getWeekPeriod,
  getWeek,
  getCurrentDay,
  getDaysAhead,
  getDaysAheadFromDate,
  getTimeFromDate,
} from '../../shared/dateHelper.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import DataStreams from '../../shared/DataStreams.js';

import {
  LineChart, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Line,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  Tooltip,
  Brush,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  TextField,
  Button,
  IconButton,
  Fade,
  Checkbox,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import RemoveIcon from '@material-ui/icons/Remove';
import ColorizeIcon from '@material-ui/icons/Colorize';

import { GithubPicker, SwatchesPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
}));

function DsDistribution(params){
  const [data, setData]  = useState(params.data);
  const dsObj = params.dsObj;

  const from  = params.from;
  const to    = params.to;

  const [prevEvent, setPrevEvent] = useState(-1);

  const colors = ["#8884d8", "#fcba03", "#17cfb0", "#1dde3d", "#f21f18"]
  const [showColor, setShowColor] = useState(false);

  const [keys, setKeys] = useState([]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, 
    cy, 
    midAngle, 
    innerRadius, 
    outerRadius, 
    percent, 
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const key = Object.keys(data[0])[0];

    return(
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {data[index][key]} - {data[index]["data"]} ({(percent*100).toFixed(0)}%)
      </text>
    )
  }

  const correctEvent = (serverMsg, es) => {
    const keys = Object.keys(es);
    if(prevEvent == serverMsg.created){
      return false;
    }

    for(var i = 0; i < Object.keys(es).length; i++){
      // return true if the entity and type of any dataStream event matches 
      // the serverMessage
      if(
        keys.includes(serverMsg["entity"].toLowerCase()) && 
        es[serverMsg["entity"].toLowerCase()] == serverMsg["type"].toLowerCase()
      ){
        setPrevEvent(serverMsg.created);
        console.log("correct message");
        return true;
      }
    }
    return false;

  }

  const {
    dataFromServer, 
  } = useComsContext();

  useEffect(() => {
    if(dataFromServer.created !== undefined && correctEvent(dataFromServer, dsObj.events)){
      var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }

      axios.post(`${dsObj.url}`, requestTemplate).then(res => {
        console.log(res);
        setData(res.data.recordset);
      }).catch(err => console.log("error", err));
    }
  },[dataFromServer])

  return(
    <>
      <Typography style={{textAlign:"center"}}>
        Piechart: {params.stream} <br />Uppdaterades: {getTimeFromDate(new Date())}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius="75%"
            fill="#8884d8"
            dataKey="data"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  )

}


function DsBarchart(params){
  const [data, setData]  = useState(params.data);
  const dsObj = params.dsObj;
  const XKey = params.XKey

  const from  = params.from;
  const to    = params.to;

  const [prevEvent, setPrevEvent] = useState(-1);

  const colors = ["#8884d8", "#fcba03", "#17cfb0", "#1dde3d", "#f21f18"]
  const [color, setColor] = useState(colors[Math.floor(Math.random() * colors.length)]);
  const [showColor, setShowColor] = useState(false);

  const correctEvent = (serverMsg, es) => {
    const keys = Object.keys(es);
    if(prevEvent == serverMsg.created){
      return false;
    }

    for(var i = 0; i < Object.keys(es).length; i++){
      // return true if the entity and type of any dataStream event matches 
      // the serverMessage
      if(
        keys.includes(serverMsg["entity"].toLowerCase()) && 
        es[serverMsg["entity"].toLowerCase()] == serverMsg["type"].toLowerCase()
      ){
        setPrevEvent(serverMsg.created);
        return true;
      }
    }
    return false;

  }

  const {
    dataFromServer, 
  } = useComsContext();

  useEffect(() => {
    if(dataFromServer.created !== undefined && correctEvent(dataFromServer, dsObj.events)){
      alert("update barchart");
      var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }

      axios.post(`${dsObj.url}`, requestTemplate).then(res => {
        setData(res.data.recordset);
      });
    }
  },[dataFromServer])

  const updateColor = (color, event) => {
    setColor(color.hex);
  }


  return(
    <>
      <Typography style={{textAlign:"center"}}>
        Stapeldiagram: {params.stream} <br />Uppdaterades: {getTimeFromDate(new Date())}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={XKey} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="data" stackId="a" fill={color} />
        </ComposedChart>
      </ResponsiveContainer>
      <IconButton size="small" onClick={() => setShowColor(prev => !prev)}>
        <ColorizeIcon fontSize="small"/>
      </IconButton>

      <Collapse in={showColor}>
        <div style={{position:"relative"}}>
          <div style={{position:"absolute"}}>
            <SwatchesPicker onChange={updateColor}/>
          </div>
        </div>
      </Collapse>

    </>
  )

}

function DsTable(params){

  const [data, setData]  = useState(params.data);
  const dsObj = params.dsObj;

  const [mainFrom, setMainFrom] = useState(params.from);
  const [mainTo, setMainTo]     = useState(params.to);


  const [prevEvent, setPrevEvent]   = useState(-1);
  const [updateData, setUpdateData] = useState(false);

  const {
    dataFromServer, 
  } = useComsContext();

  const correctEvent = (serverMsg, es) => {
    const keys = Object.keys(es);
    if(prevEvent == serverMsg.created){
      return false;
    }

    for(var i = 0; i < Object.keys(es).length; i++){
      // return true if the entity and type of any dataStream event matches 
      // the serverMessage
      if(
        keys.includes(serverMsg["entity"].toLowerCase()) && 
        es[serverMsg["entity"].toLowerCase()] == serverMsg["type"].toLowerCase()
      ){
        setPrevEvent(serverMsg.created);
        return true;
      }
    }
    return false;

  }

  useEffect(() => {
    if(dataFromServer.created !== undefined && correctEvent(dataFromServer, dsObj.events)){
      var requestTemplate =  {
        "body": {"from": mainFrom, "to": mainTo},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }

      axios.post(`${dsObj.url}`, requestTemplate).then(res => {
        setData(res.data.recordset);
      });

    }
  },[dataFromServer, updateData])

  const updateDataModule = () => {
    var requestTemplate =  {
      "body": {"from": mainFrom, "to": mainTo},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    }

    axios.post(`${dsObj.url}`, requestTemplate).then(res => {
      setData(res.data.recordset);
    });
  }

  return(
    <>
      <Typography>
        Från: {mainFrom} Till: {mainTo}
      </Typography>
      <input type="date" onChange={(e) => setMainFrom(e.target.value)}/>
      <input type="date" onChange={(e) => setMainTo(e.target.value)}/>
      <button onClick={() => updateDataModule()}>Uppdatera</button>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Uppdaterades: {getTimeFromDate(new Date())}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            {Object.keys(data[0]).map(e => (
              <TableCell>
                <b>{e}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(e => (
            <TableRow>
              {Object.keys(data[0]).map(c => (
                <TableCell>
                  {e[c]}
                </TableCell>
              ))
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default function StreamModule(params){
  const theme = useTheme();
  const classes = useStyles();

  const { 
    entityEventsApi, 
    productsApi, 
    currentUser, 
    userPrefs 
  } = useAppContext();

  const {
    dataFromServer, 
    dataStreams, 
  } = useComsContext();

  const { onRemoveItem, notify } = useMainGridContext();

  const dsApi = new DataStreams(currentUser.username);

  const [data, setData]       = useState(0);
  const [type, setType]       = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [prevEvent, setPrevEvent] = useState(-1);
  const [dataStreamObj, setDataStreamObj]          = useState(null);

  const [updateData, setUpdateData] = useState(null);

  const [mainFrom, setMainFrom] = useState(null);
  const [mainTo, setMainTo] = useState(null);

  const name = useState(params.stream);

  const correctEvent = (serverMsg, es) => {
    const keys = Object.keys(es);
    if(prevEvent == serverMsg.id){
      return false;
    }

    for(var i = 0; i < Object.keys(es).length; i++){
      // return true if the entity and type of any dataStream event matches 
      // the serverMessage
      if(
        keys.includes(serverMsg["entity"].toLowerCase()) && 
        es[serverMsg["entity"].toLowerCase()] == serverMsg["type"].toLowerCase()
      ){
        setPrevEvent(serverMsg.id);
        return true;
      }
    }
    return false;

  }

  useEffect(() => {
    setLoading(true)
    if(dataStreams !== null){
      const dsObj = _.filter(dataStreams, (e) =>{return e.name == params.stream.split(" ")[0]})[0];
      if(params.stream.split(" ")[0] === "text"){
        setType("text");
        const vars = params.stream.split(" ");
        setData(vars.slice(1).join(" "));
        setLoading(false);
      }
      else if(params.stream.split(" ")[0] === "media"){
        setType("media");
        const vars = params.stream.split(" ");
        setData(vars.slice(1).join(" "));
        setLoading(false);
      }

      else if(dsObj !== undefined) {

        setDataStreamObj(dsObj);

        if(firstLoad){
          setType(dsObj.type);
        }
        if(dsObj.type == "siffra"){
          axios.get(`${dsObj.url}/${currentUser.username}`).then(res => {
            if(res.data.recordset[0] == undefined){
              setData("0,00 kr");
            }
            else{
              setData(res.data.recordset[0].data);
            }
            setLoading(false)
          });
        }
        else{
          var date  = new Date();
          var from  = `${date.getFullYear()}-01-01`;
          var to    = `${date.getFullYear()}-12-31`;

          var vars = params.stream.split(" ");
          if(updateData){
            alert("update");
            from  = mainFrom;
            to    = mainTo;
          }
          else if(
            vars.includes("vecka") || 
            vars.includes("månad") || 
            vars.includes("år") ||
            vars.includes("dag"
          )){
            switch(vars[1]){
              case("dag"):
                // Possible formats: dag YEAR MONTH DAYNUMBER, dag daynumber, dag 
                // :: data dagsälj dag 
                if(vars.length == 2){
                  var p = getWeekPeriod(date.getFullYear(), getWeek(date));
                  from = getCurrentDay();
                  to = getDaysAhead(1);
                }
                // ::data dagsälj dag DAYNUMBER 
                if(vars.length == 3){
                  var d = new Date(date.getFullYear(), date.getMonth(), vars[2]);
                  from = `${d.getFullYear()}-${dateFormatZero(d.getMonth()+1)}-${dateFormatZero(Number(vars[2]))}`;
                  to = getDaysAheadFromDate(d, 1);
                }
                // ::data dagsälj dag YEAR MONTH DAYNUMBER 
                if(vars.length == 5){
                  var d = new Date(vars[2], vars[3], vars[4]);
                  from = `${d.getFullYear()}-${dateFormatZero(d.getMonth()+1)}-${dateFormatZero(Number(vars[4]))}`;
                  to = getDaysAheadFromDate(d, 1);
                  alert(`from: ${from}, to: ${to}`);
                }
                break;
              case("vecka"):
                // Possible formats: vecka YEAR WEEKNUMBER, vecka WEEKNUMBER, vecka
                // :: data dagsälj vecka
                if(vars.length == 2){
                  var p = getWeekPeriod(date.getFullYear(), getWeek(date));
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj vecka WEEKNUMBER
                if(vars.length == 3){
                  var p = getWeekPeriod(date.getFullYear(), Number(vars[2]));
                  alert(`f: ${p.start}, t: ${p.end}`);
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj vecka YEAR WEEKNUMBER 
                if(vars.length == 4){
                  var p = getWeekPeriod(vars[2], Number(vars[3]));
                  alert(`from: ${p.start}, to: ${p.end}`);
                  from = p.start;
                  to = p.end;
                }
                break;
              case("månad"):
                // Possible formats: månad YEAR MONTHNUMBER, månad YEAR MONTHNAME, månad MONTNUMBER, månad MONTHNAME, månad
                // ::data dagsälj månad
                if(vars.length == 2){
                  var p = currentMonthPeriod();
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj månad MONTHNAME
                if(vars.length == 3){
                  var p = getMonthPeriod(date.getFullYear(), getMonthIndexFromName(vars[2]));
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj månad MONTHNUMBER
                if(vars.length == 3){
                  var p = getMonthPeriod(date.getFullYear(), vars[2]);
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj månad YEAR MONTHNAME
                if(vars.length == 4){
                  var p = getMonthPeriod(vars[2], getMonthIndexFromName(vars[3]));
                  from = p.start;
                  to = p.end;
                }
                // ::data dagsälj månad YEAR MONTNUMBER
                if(vars.length == 4){
                  var p = getMonthPeriod(vars[2], vars[3]);
                  from = p.start;
                  to = p.end;
                }
                break;
              case("år"):
                // Possible formats: år YEARNUMBER, år
                if(vars.length == 2){
                  from = `${date.getFullYear()}-01-01`;
                  to = `${date.getFullYear()}-12-31`;
                }
                if(vars.length == 3){
                  from = `${params.stream.split(" ")[2]}-01-01`;
                  to = `${params.stream.split(" ")[2]}-12-31`;
                }
                break;
            }
          }

          else if(params.stream.split(" ").length > 2){
            from  = params.stream.split(" ")[1]; 
            to    = params.stream.split(" ")[2]; 
          }

          setMainFrom(from);
          setMainTo(to);


          var requestTemplate =  {
            "body": {"from": from, "to": to},
            "requestContext": {
              "identity": {
                "cognitoUserIdentity": "admin1"
              } 
            }
          }

          console.log("get", dsObj.url);
          axios.post(`${dsObj.url}`, requestTemplate).then(res => {
            setData(res.data.recordset);
            setLoading(false)
          });
        }
      }
      setUpdateData(false);
    }
  },[dataStreams, updateData]);

  const renderType = () =>{
    switch(type){
      case "siffra":
        return(
          <Grid 
            container 
            item 
            justify="center" 
            alignItems="center"
            direction="column"
          >
            <Typography>
              {name} 
            </Typography>
            <Divider />
            <Typography variant="h2">
              <b>{data}</b>
            </Typography>
          </Grid>
        );
      case "datumintervall":
        return(
          <div style={{width:"100%", height:"100%"}}>
            <Typography>
              Från: {mainFrom} Till: {mainTo}
            </Typography>
            <input type="date" onChange={(e) => setMainFrom(e.target.value)}/>
            <input type="date" onChange={(e) => setMainTo(e.target.value)}/>
            <button onClick={() => setUpdateData(true)}>Uppdatera</button>
            <DsBarchart
              data={data} 
              dsObj={dataStreamObj} 
              from={mainFrom} 
              to={mainTo}
              XKey="date"
              stream={params.stream}
            />
          </div>
        )
      case "säljargraf":
        return(
          <div style={{width:"100%", height:"100%"}}>
            <Typography>
              Från: {mainFrom} Till: {mainTo}
            </Typography>
            <DsBarchart
              data={data} 
              dsObj={dataStreamObj} 
              from={mainFrom} 
              to={mainTo}
              XKey="salesman"
              stream={params.stream}
            />
          </div>
        )
      case "distribution":
        return(
          <div style={{width:"100%", height:"100%"}}>
            <Typography>
              Från: {mainFrom} Till: {mainTo}
            </Typography>
            <DsDistribution
              data={data} 
              dsObj={dataStreamObj} 
              from={mainFrom} 
              to={mainTo}
              stream={params.stream}
            />
          </div>
        )
      case "tabell":
        return(
          <div style={{width:"100%", height:"100%"}}>
            <DsTable 
              data={data} 
              dsObj={dataStreamObj} 
              from={mainFrom} 
              to={mainTo}
            />
          </div>
        )
      case "text":
        return(
          <Grid 
            container 
            item 
            justify="center" 
            alignItems="center"
            direction="column"
          >
            <Typography variant="h4">
              {data} 
            </Typography>
          </Grid>
        );
      case "media":
        return(
          <Grid 
            container 
            item 
            justify="center" 
            alignItems="center"
            direction="column"
            style={{width:"100%", height:"100%"}}
          >
            <div style={{height:"100%"}}>
              <img src={data} style={{height:"100%"}}/>
            </div>
          </Grid>
        );

    }
  }

  return(
    <Module auths={params.auths} remove={() => onRemoveItem(params.id)}>
      <Header color={theme.palette.background.paper} remove={() => onRemoveItem(params.id)}>
      </Header>
      <Body>
        <Grid 
          container 
          style={{overflowY: "auto", height:"99%"}} 
          justify="center" 
        >
          <Grid container style={{width:"100%", height:"100%"}}>
            {loading ? null : renderType()}
          </Grid>
        </Grid> 
      </Body>
    </Module>

  )
}
