export default {
  s3: {
    REGION: "us-east-2",
    BUCKET: "vasa"
  },
  apiGateway:{
    REGION: "us-east-2",
    URL: "https://8bpu1fd1fi.execute-api.us-east-2.amazonaws.com/dev"
  },
  cognito:{
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_98Q1eQJZL",
    APP_CLIENT_ID: "oennrl53r4ihcg90bb1p73u6n",
    IDENTITY_POOL_ID: "us-east-2:164ae3fb-c781-48dc-9855-fd60849890ab"
  }
}
