import React, { useState, useEffect, useRef } from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import VasaDialog from '../shared/VasaDialog.js';
import { useMainGridContext } from '../libs/mainGridContextLib.js';
import { useAppContext } from '../libs/contextLib';

import Bugs from '../shared/Bugs.js';
import Users from '../shared/Users.js';

import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({

}));

export default function BugReportDialog(params){
  const classes = useStyles();
  const theme = useTheme();

  const { items, notify }= useMainGridContext();
  const { currentUser }= useAppContext();

  const [title, setTitle] = useState(params.title);
  const [desc, setDesc] = useState("");
  const [exp, setExp] = useState("");
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const bugsApi = new Bugs(currentUser.username);
  const usersApi = new Users(currentUser.username);

  const createBug = () => {
    setLoading(true);
    var body = {
      "title": title,
      "description": desc,
      "expectation": exp,
      "creator": currentUser.username
    } 
    bugsApi.create(body).then(res => {
      console.log(res);
      notify("success", "Din bugg har rapporterats");
      sendEmail(
        {
          type:"email",
          email:{
            subject: "Ny bug har rapporterats i VASA",
            message: `<b>Beskrivning:</b> ${desc}<b><br><br>Förväntat beteende:</b> ${exp}<br><br><b>Skapat av:</b> ${currentUser.username}`,
            text: "",
            reciever: "fillatino@gmail.com"
          }
        }
      )
      setLoading(false);
      setDone(true);
    }) 
    .catch(err => {
      notify("error", `Buggen kunde inte rapporteras, ${err}`);
    });
  }

  const sendEmail = (body) => {
    usersApi.sendCustomNotification(body)
    .then(res => {
      notify("success", "Bug rapporterad!")
    })
    .catch(err => notify("error", "Något gick fel"));
  }

  useEffect(() => {
    var title = items.filter(e => e.i == params.title)[0];
    if(title !== undefined){
      setTitle(`Bug i modul ${title.type}`);
    }
  },[params.title])

  return(
    <VasaDialog
      open={params.open}
      handleClose={params.handleClose}
    >
      <Paper style={{padding:"0px"}}>
        <Grid container>
          <Slide in={!done} direction="right">
            <div style={{width:"100%"}}>
              <Grid item xs={12} style={{padding:"20px"}}>
                <Typography variant="h3">
                  Rapportera en bug
                </Typography>
                <Typography variant="subtitle1">
                  Ibland går inte allting så som det ska...
                </Typography>
              </Grid>
              <Grid container style={{width:"100%"}}>
                <Grid item xs={8} style={{padding:"20px"}}>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Titel"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Beskrivning" 
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      multiline
                      helperText="Vad hände? Och vad gjorde du för att åstadkomma felet."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth 
                      label="Förväntat beteende" 
                      value={exp}
                      onChange={(e) => setExp(e.target.value)}
                      multiline
                      helperText="Vad förväntar du dig ska hända?"
                    />
                  </Grid>
                </Grid>
                <Grid item container justify="flex-end" alignItems="flex-end" xs={4}>
                  <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/hit_computer.gif" height={250}/>
                </Grid>
                {!loading ?
                    <Grid container style={{width:"100%", padding:"20px"}}>
                      <Grid item xs={4}>
                        <Button fullWidth color="secondary" onClick={() => params.handleClose()}>
                          Avbryt
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button fullWidth color="primary" onClick={() => createBug()}>
                          Rapportera 
                        </Button>
                      </Grid>
                    </Grid>
                    :
                    <Grid container item xs={8} justify="center" alingItems="center">
                      <CircularProgress />
                    </Grid>
                }
              </Grid>
            </div>
          </Slide>
          <Slide in={done} direction="left">
            <div style={{width:"100%", position:"absolute", width:"100%"}}>
              <div style={{position:"absolute", top: "0", width:"100%"}}>
                <Grid container style={{width:"100%"}}>
                  <Grid item xs={8}>
                    <Grid item style={{padding:"30px"}}>
                      <Typography variant="h3">
                        Bra jobbat!
                      </Typography>
                      <Typography>
                        Tillsammans likviderar vi alla antiproduktiva element
                        från kontoret för att skapa det perfekta 
                        affärssystemet.
                      </Typography>
                      <Divider />
                      <Typography variant="h5" style={{paddingTop:"20px"}}>
                        <b>Vad händer nu?</b>
                      </Typography>
                      <Typography>
                        1. Ett mail har skickats till en utvecklare.
                      </Typography>
                      <Typography>
                        2. Felet utvärderas och kommer att ignoreras om det identifieras som en dublett. 
                      </Typography>
                      <Typography>
                        3. Om felet anses värdigt kommer det att åtgärdas. Buggens status kommer du kunna se
                        i en lista på hemskärmen.
                      </Typography>
                      <Typography>
                        4. När felet är fixat kommer du att bli informerad via nyhetsflödet.
                      </Typography>
                    </Grid>
                    <Grid item style={{paddingTop:"50px"}}>
                      <Button fullWidth color="primary" onClick={() => {params.handleClose(); setDone(false)}}>
                        Återvänd till arbetet!
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4} alignItems="flex-end" justify="flex-end">
                    <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/comm_prop.jpg" height={450} style={{padding:"-30px"}}/>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Slide>
        </Grid>
      </Paper>
    </VasaDialog>
  )
}
