import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import _ from 'lodash'
import {getTimeFromDate, formatDate} from '../../../shared/dateHelper.js';

import {
  Typography,
  Grid,
  Avatar,
  Tooltip,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

export default function LoginEvent(params){
  const classes = useStyles();
  const theme = useTheme();

  const { profile, event, index } = params;

  const [others, setOthers] = useState("");

  const nextNotLogin = () => {
    var res = 0;
    for(var i = index + 1; i < params.events.length; i++){
      if(params.events[i].type == "LOGIN"){
        res += 1;
      }
      else{
        break;
      }
    }
    setOthers( res );
  }

  useEffect(() => {
    nextNotLogin();
  },[])

  const getProfile = (user) => {
    var profile = _.filter(params.users, (u) => {return u.username == user})[0];
    if(profile !== undefined){
      return profile.profileMedia;
    }
    return null;
  }

  return(
    <Grid item xs={12}>
      <Grid item container xs={12} justify="center" alignItems="center">
        <Tooltip title={`${event.creator} - ${event.created}`}>
          <Avatar
            src={`https://vasa.s3.us-east-2.amazonaws.com/${profile}`} 
          />
        </Tooltip>
        {
          params.events.slice(index, others+index).map(e => {
            var profile = _.filter(params.users, (u) => {
              return u.username == e.creator
            })[0];
            var p = null;
            if(profile !== undefined){
              p = profile.profileMedia;
            }

            return(
              <Tooltip title={e.creator}>
                <Avatar
                src={`https://vasa.s3.us-east-2.amazonaws.com/${p}`} 
                />
              </Tooltip>
            )
          })
        }
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Typography variant="subtitle2" style={{marginLeft:"10px"}}>
          {getTimeFromDate(event.created)} - {` `} 
          {others > 0 ?
            <>{event.creator} {others} har loggat in.</>
          :
            <>{event.creator} har loggat in.</>

          }
        </Typography>
      </Grid>
      <Divider/>
    </Grid>
  )
}

