import React, { useEffect, useState } from "react";

import PnrTextField from './PnrTextField';
import ServiceList from './ServiceList';
import { useAppContext } from '../../libs/contextLib';

// Context
import { 
  NewCustomerContext,
  useCustomerContext
} from '../../libs/newCustomerContextLib.js';

import axios from 'axios';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button, 
  Typography, 
  Grid, 
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slide,
  CircularProgress
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  mainGrid:{
    width: '100%',
  },
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
  },
  large:{
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  caption: {
    color: "gray",
  }

}));

export default function NewOrderForm(paramsl){
  const classes = useStyles();
  const theme = useTheme();

  const [signed, setSigned]                   = useState(false);
  const [customerCreated, setCustomerCreated] = useState(false);
  const [successMsg, setSuccessMsg]           = useState("");
  const [idUnique, setIdUnique]               = useState(false);
  const [avServices, setAvServices]           = useState([]);

  const { productsApi } = useAppContext();
  const {
    validationError, 
    setValidationError,
    errorText,
    setErrorText,
    customerInfo, 
    setCustomerInfo,
    services,
    setServices,
    serviceName, 
    setServiceName,
    billingLen,
    setBillingLen,
    billing,
    setBilling,
    price,
    setPrice,
    amount,
    setAmt,
    currentService,
    setCurrentService
  } = useCustomerContext();

  const pushService = () => {

    // Only add new service if currentService is active
    if(currentService.service.length > 0){

      setServices([...services, currentService]);

      updateCurrentService({"service": "", "price": "", "amount": ""});

    }
  }

  useEffect(() => {
    if(productsApi !== null){
      productsApi.getAll().then(res => {
        setAvServices(res.data.recordset);
      }); 
    }
  },[productsApi])

  const updateCurrentService = (service) => {
    setCurrentService(service); 
    setServiceName(service.service);
    setPrice(service.price);
    setAmt(service.amount);
  }

  const handleChange = (value, e) => {
    customerInfo[value] = e.target.value; 
    setCustomerInfo(customerInfo);
  }

  const handleServiceChange = (value, e) => {
    currentService[value] = e.target.value;
    switch(value){
      case "service":
        setServiceName(e.target.value);
        break;
      case "billingLength":
        setBillingLen(e.target.value);
        break;
      case "billing":
        setBilling(e.target.value);
        break;
      default:
        break;
    }
    if(value == "price"){
      setPrice(e.target.value);
    }
    if(value == "amount"){
      setAmt(e.target.value);
    }
  }

  const {hideValidationError} = useCustomerContext(); 
  const {validateAll} = useCustomerContext();

  const handleSignedChange = (event) => {
    currentService["billingOptions"] = event.target.checked;
    setSigned(event.target.checked);
  }
  return(
    <form>
      <ServiceList 
          currentService={currentService}
          setCurrentService={setCurrentService}
          services={services}
          setServices={setServices}
          updateCurrentService={(s) => updateCurrentService(s)}
          InputProps={{inputProps: {tabIndex: 8 }}}
        />
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Lägg till tjänster
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={avServices}
                className={classes.textfield}
                getOptionLabel={(option) => option["name"]}
                renderInput={
                (params) => <TextField 
                  variant="outlined" {...params} label="Tjänst"
                />}
                style={{width:"100%"}}
                size="small"
                onChange={(e, v) => handleServiceChange("service", {target:{value: v.name}})}
                InputProps={{inputProps: {tabIndex: 9 }}}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField 
                label="Pris" 
                variant="outlined" 
                className={classes.textfield}
                size="small"
                value={price}
                onChange={e => handleServiceChange("price", e)}
                InputProps={{inputProps: {tabIndex: 10 }}}
              /> 
            </Grid>
            <Grid item xs={4}>
              <TextField 
                type="number"
                label="Antal" 
                variant="outlined" 
                className={classes.textfield}
                size="small"
                value={amount}
                onChange={e => handleServiceChange("amount", e)}
                InputProps={{inputProps: {tabIndex: 11 }}}
              /> 
            </Grid>
            <Grid container item xs={12} justify="flex-end" alignItems="flex-end">
              <Button color="primary" onClick={() => pushService()}>
                Lägg till
              </Button>
            </Grid>
            <Grid 
              container 
              spacing={2} 
              style={{background:theme.palette.background.default}}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Orderinformation
                </Typography>
              </Grid>
              <Grid 
                item 
                xs={6}
              >
                <TextField 
                  select 
                  label="Avtalslängd" 
                  variant="outlined" 
                  className={classes.textfield}
                  size="small"
                  onChange={e => handleServiceChange("billingLength", e)}
                  value={billingLen}
                  InputProps={{inputProps: {tabIndex: 12 }}}
                  error={errorText.subscriptionLength.length > 0 ? true : false}
                  onBlur={() => hideValidationError("subscriptionLength")}
                  helperText={errorText.subscriptionLength}
                > 
                  <MenuItem value={"1 Månad"}>1 Månad</MenuItem>
                  <MenuItem value={"3 Månader"}>3 Månader</MenuItem>
                  <MenuItem value={"6 Månader"}>6 Månader</MenuItem>
                  <MenuItem value={"1 År"}>1 År</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <TextField 
                    select 
                    label="Fakturering" 
                    variant="outlined" 
                    className={classes.textfield}
                    size="small"
                    onChange={e => handleServiceChange("billing", e)}
                    value={billing}
                    InputProps={{inputProps: {tabIndex: 13 }}}
                    error={errorText.billing.length > 0 ? true : false}
                    onBlur={() => hideValidationError("billing")}
                    helperText={errorText.billing}
                  > 
                    <MenuItem value={"Engångsfaktura"}>Engångsfaktura</MenuItem>
                    <MenuItem value={"Månadsvis"}>Månadsvis</MenuItem>
                    <MenuItem value={"Kvartalsvis"}>Kvartalsvis</MenuItem>
                    <MenuItem value={"Halvårsvis"}>Halvårsvis</MenuItem>
                    <MenuItem value={"Årsvis"}>Årsvis</MenuItem>
                  </TextField>
                  <FormControlLabel
                    control={
                     <Checkbox
                        checked={signed}
                        onChange={handleSignedChange}
                        inputProps={{'aria-label': 'Faktureringsspecifikationer'}}
                        color="primary"
                        label="Signerad"
                        InputProps={{inputProps: {tabIndex: 13 }}}
                      />
                    }
                    label="Signerad"
                  />
                </FormGroup>
              </Grid>
          </Grid>

        </Grid>
    </form>
  )
}
