import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { useAppContext } from '../../libs/contextLib.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%"
  },
  resize: {
    fontSize: 50 
  },
  isCounted: {

    border: "1px solid #0d47a1",
    borderRadius: "5px"
  },
  notCounted: {
    border: "1px solid #9e9e9e",
    borderRadius: "5px"
  }
}));

export default function NewProductModule(params){
  const classes = useStyles();

  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { productsApi, currentUser } = useAppContext();

  const [productCategories, setProductCategories] = useState([]);
  const [counted, setCounted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitTries, setSubmitTries] = useState(0);
  const [formValues, setFormValues] = useState({
    name: "", 
    category: "", 
    unitPrice: "",
    storage: "",
    minimum: "",
    maximum: "",
    description: "",
    creator: currentUser.username
  });

  const handleSubmit = () => {
    setLoading(true);
    setSubmitTries(prev => prev + 1);
    const requiredFields = ["name", "category", "storage", "minimum", "maximum"];
    if(validateValues(requiredFields)){
      productsApi.create(formValues).then(res => {
        setLoading(false);
        notify("success", `Produkten ${formValues.name} har lagts till i produktkatalogen.`)
      });
    }
    else{
      notify("error", `${getError(requiredFields)} är tomt!`);
      setLoading(false);
    }
  }

  const handleFormChange = (name, event) => {
    const val = event.target.value;
    setFormValues(prevState => ({...prevState, [name]: val}));
  }

  const changeCounted = (e) => {
    if(counted){
      setFormValues(prevState => ({
        ...prevState, 
        ["storage"]: "-1", 
        ["maximum"]: "-1", 
        ["minimum"]: "-1"}
      ))
    }
    else{
      setFormValues(prevState => ({...prevState, ["storage"]: "", ["maximum"]: "", ["minimum"]: ""}))
    }
    setCounted(prev => !prev);
  }

  const getError = (reqs) => {
    for(var i = 0; i < reqs.length; i++){
      if(formValues[reqs[i]].length == 0){
        return reqs[i];
      }
    }
  }

  const validateValues = (reqs) => {
    for(var i = 0; i < reqs.length; i++){
      if(formValues[reqs[i]].length == 0){
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if(productsApi !== null){
      productsApi.getAllCategories().then(res => {
        res.data.categories
          .map(d => {
            var cats = productCategories;
            cats.push(d.name);
            setProductCategories(prev => cats);
          });
      });
    }
  },[productsApi]);

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="#ff9100" remove={() => onRemoveItem(params.id)}>
        {params.id} Ny produkt
      </Header>
      <Body>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <TextField
                  className={classes.textfield}
                  required
                  label="Namn"
                  error={formValues.name.length == 0 && submitTries > 0}
                  onChange={(e) => handleFormChange("name", e)}
                  InputProps={{
                    classes:{
                      input: classes.resize
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.textfield} required>
                      <InputLabel>Kategori</InputLabel>
                      <Select 
                        value={formValues.category} 
                        onChange={(e) => handleFormChange("category", e)}
                        error={formValues.category.length == 0 && submitTries > 0}
                      >
                        {productCategories.map(c => {
                          return <MenuItem value={c}>{c}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Tooltip title="Lägg till kategori">
                          <IconButton onClick={() => addModule("::kat")}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid container xs={6} justify="flex-end" alignItems="flex-end" style={{paddingTop:"40px"}}>
                        <Typography variant="overline">
                          Kan räknas 
                        </Typography>
                        <Checkbox
                          color="primary"
                          checked={counted}
                          onChange={changeCounted}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "5px"}}>
              <Grid item xs={4}>
                <TextField
                  style={{width:"90%"}}
                  required
                  label="Inköpspris"
                  type="number"
                  error={formValues.unitPrice.length == 0 && submitTries > 0}
                  onChange={(e) => handleFormChange("unitPrice", e)}
                />
              </Grid>
              <Grid container xs={8} spacing={2} className={counted ? classes.isCounted : classes.notCounted}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.textfield}
                    required
                    disabled={!counted}
                    label="Antal"
                    type="number"
                    error={formValues.storage.length == 0 && submitTries > 0}
                    onChange={(e) => handleFormChange("storage", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textfield}
                    disabled={!counted}
                    required
                    label="Min."
                    type="number"
                    error={formValues.minimum.length == 0 && submitTries > 0}
                    onChange={(e) => handleFormChange("minimum", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textfield}
                    required
                    label="Max."
                    disabled={!counted}
                    type="number"
                    error={formValues.maximum.length == 0 && submitTries > 0}
                    onChange={(e) => handleFormChange("maximum", e)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginTop: "20px"}}>
                <TextField
                  multiline
                  className={classes.textfield}
                  label="Beskrivning"
                  onChange={(e) => handleFormChange("description", e)}
                  rows={3}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid style={{marginTop: "10px", width:"100%"}}>
              {loading ? 
                <Grid container alignItems="center" justify="center">
                  <CircularProgress size={30}/>
                </Grid>
              :
                <Button color="primary" onClick={handleSubmit} style={{width: "100%"}}>
                  Skapa
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </Body>
    </Module>
  )
}
