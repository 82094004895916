import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import { useAppContext } from '../../libs/contextLib';

import Orders from '../../shared/Orders.js';
import Payments from '../../shared/Payments.js';
import Services from '../../shared/Services.js';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  CircularProgress,
  InputAdornment,
  Button,
} from "@material-ui/core";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
import Alert from '@material-ui/lab/Alert';
import ReactDataGrid from 'react-data-grid';
import { priceConstructorNumber, reformatDate } from '../utils.js';
import 'react-data-grid/dist/react-data-grid.css';
import FaceIcon from '@material-ui/icons/Face';

const ordersApi = new Orders();
const paymentsApi = new Payments();
const servicesApi = new Services();

var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
})


var requestTemplate = {
  "body": null,
  "requestContext":{
    "identity": {
      "cognitoUserIdentity": "admin1" 
    }
  }
}

const useStyles = makeStyles((theme) => ({
  button:{
    width: "100%"
  },
  finishButton:{
    width: "100%",
  },
  orderComponent:{
    marginTop: "25px",
    marginBottom: "75",
  },
  textfield: {
    width: '100%',
    margin: theme.spacing(1)
  },
  registerButton:{
    position:"absolute", 
    bottom: "1%", 
    width:"100%", 
    left:"0%"
  }
}));

export default function NewPaymentModule(params){
  const paramsIncludes = (val) => {
    return Object.keys(params).includes(val);
  }

  const classes = useStyles();
  const { onRemoveItem, notify } = useMainGridContext();
  const {orderApi, paymentsApi, servicesApi, customersApi} = useAppContext();
  const cid = params.cid
  
  const [orders, setOrders] = useState([]);
  const [prevPayments, setPrevPayments] = useState([]);
  const [prevSum, setPrevSum] = useState(0);
  const [payment, setPayment] = useState(0);
  const [successful, setSuccessful] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(paramsIncludes("oid") ? 
    params.oid
    : 
    ""
  );
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    update();
  },[]);

  const update = () => {
    ordersApi.getWhere("customerid", cid)
      .then(res => {
        setOrders(res.data.recordset);
    })
  }

  const updateSelectedOrder = (e, c) => {
    const oid = e.target.value;
    const order = orders[c.props.id];
    setSelectedOrderId(oid);
    setSelectedOrder(order);
    getServices(oid);
    getPayments(oid);
    getPrevPaymentSum(oid);
    setPayment(priceConstructorNumber(order).slice(0,-3));
  }

  const getPrevPaymentSum = (oid) => {
    paymentsApi.sumFor(cid)
      .then(res => {
        const ps = res.data.recordset;
        console.log("oid", oid);
        for(var i = 0; i < ps.length; i++){
          if(ps[i].orderId == oid){
            setPrevSum(ps[i].paid);
            return;
          } 
        }
        setPrevSum(0);
      })
  }

  const getPayments = (oid) => {
    paymentsApi.getWhere("orderId", oid)
      .then(res => {
        setPrevPayments(res.data.recordset)
      });
  }

  const getServices = (oid) => {
    setLoading(true);
    servicesApi.getWhere("orderNumber", oid)
      .then(res => {
        setLoading(false);
        setServices(res.data.recordset);
      });
  }

  const handlePaymentChange = (e) => {
    var tmp = e.target.value.replace(" ", "");
    setPayment(tmp);
  }

  const registerPayment = (e) => {
    var body = {
      "customerid": cid,
      "orderId": selectedOrderId,
      "amount": payment.replace(",", "."),
      "creator": selectedOrder.salesman
    } 
    if(prevSum + parseFloat(payment.replace(",", ".")) > selectedOrder.price){
      setError(true);
      notify("error", "Kundens inbetalningar är större än priset!") ;
    }
    else{
      setError(false);
      paymentsApi.create(body)
        .then(res => {
          console.log(res)
          notify("success", "Ny inbetalning skapad");
          setSuccessful(true);
          update();
        })
    }
  }

  const isOwner = async (body) => {
    return customersApi.get(cid).then(res => {
      var sman = res.data.recordset[0].salesman;
      var hasOwnership = sman == body.username;
      return(sman == body.username);
    });
  }

  return (
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
      ownership={(body) => isOwner(body)}
    >
      <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
        {params.id} Ny inbetalning {cid}
      </Header>
      <Body style={{position: "relative"}}>
        <Grid container>
          <form style={{width: "100%"}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Order"
                  size="small"
                  value={selectedOrderId}
                  variant="outlined"
                  className={classes.textfield}
                  index={0}
                  onChange={(e, c) => updateSelectedOrder(e, c)}
                >
                  {orders.map((order, i)=> {
                    return (
                      <MenuItem key={i} id={i} value={order.orderId}>
                      <Grid container style={{padding: "10px"}}>
                        <Grid item xs={1}> 
                          <Typography variant="overline">
                            ID 
                          </Typography>
                          <Typography variant="subtitle2">
                            {order.orderId}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}> 
                          <Typography variant="overline">
                            Betalning  
                          </Typography>
                          <Typography variant="subtitle2">
                            {order.billingInterval}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}> 
                          <Typography variant="overline">
                            Avtalslängd  
                          </Typography>
                          <Typography variant="subtitle2">
                            {order.subscriptionLength}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}> 
                          <Typography variant="overline">
                            Pris 
                          </Typography>
                          <Typography variant="subtitle2">
                            {order.price}
                          </Typography>
                        </Grid>
                        <Grid container xs={4} justify="flex-end" alignItems="flex-end"> 
                          <Chip
                            icon={<FaceIcon />}
                            label={order.salesman}
                            color="primary"
                          />
                        </Grid>
                      </Grid>
                    </MenuItem>)
                  })
                  }
                </TextField>
              </Grid>
              <Grid container alignItems="center" justify="center">
                {loading ? <CircularProgress /> : null}
              </Grid>
              <Grid item xs={12}>
                <TableContainer variant="small" component={Paper}>
                  <Collapse in={services.length > 0}>
                    {services.length > 0 ?
                      <Table variant="small" style={{padding:"20px"}}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Datum
                            </TableCell>
                            <TableCell align="right">
                              Tjänst 
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {services.map(service => {
                            return(
                              <TableRow>
                                <TableCell>
                                  {service.dateOfSale}
                                </TableCell>
                                <TableCell align="right">
                                  {service.productCode}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow>
                            <TableCell  align="right">
                              Senaste inbetalningen 
                            </TableCell>
                            <TableCell align="right">
                              <b>
                              {
                                prevPayments.length > 0 ? 
                                  reformatDate(prevPayments[prevPayments.length-1].dateOfPayment)
                                  :
                                  "Ingen hittades" 
                              }
                              </b> 
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right">
                              Betalt 
                            </TableCell>
                            <TableCell align="right">
                              <b>{formatter.format(prevSum)}</b> 
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right">
                              Förväntad inbetalning
                            </TableCell>
                            <TableCell align="right">
                              <b>{priceConstructorNumber(selectedOrder)}</b> 
                            </TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                      :
                      null
                    }
                  </Collapse>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <TextField 
                  variant="outlined"
                  label="Belopp" 
                  size="small" 
                  value={payment}
                  onChange={handlePaymentChange}
                  error={error}
                  className={classes.textfield}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kr</InputAdornment>
                  }}
                />
              </Grid>
              <Collapse in={successful}>
              </Collapse>
              <Grid container style={{padding:"10px"}}>
                <Grid xs={12} style={{position:"absolute", bottom: "1%", width:"100%", left:"0%"}}>
                  {payment.length > 0 && payment !== 0?
                    <>
                      <Button 
                        color="primary" 
                        onClick={registerPayment}
                        style={{width:"100%"}}
                      >
                        Skapa inbetalning
                      </Button>
                    </>
                    :
                    null
                  }
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Body>
    </Module>
  )
}
