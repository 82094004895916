import axios from 'axios';
import config from './apiConfig.js';

export default class DataStreams{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;

    this.apiId = "f7lqz1o7u2";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
  }

  async getAll(){
    return axios.get(`${this.endpoint}/read/all`);
  }

  async getName(name){
    return axios.get(`${this.endpoint}/read/stream${name}`);
  }

  async getName(name){
    return axios.get(`${this.endpoint}/read/stream${name}`);
  }

  async updateWhere(name, col, val){
    return axios.post(`${this.endpoint}/update/${name}/${col}/${val}/${this.user}`);
  } 

  async delete(name){
    return axios.post(`${this.endpoint}/delete/${name}/${this.user}`);
  }

  async finStreamOfficeSold(body){
    this.requestTemplate.body = body;
    var url = "https://rlxysyw958.execute-api.us-east-2.amazonaws.com/dev/officeSold";
    return axios.post(url, this.requestTemplate);
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/dataStream/${this.user}`, this.requestTemplate);
  }
}
