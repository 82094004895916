import axios from 'axios';

/**
 * Uses the comparator to check if its equal to the authorisers username
 *  @param body - userdata and module authorities
 *  @param comparator - value that is compared with the username stored in body
**/
export async function standardOwnership(body, comparator){
  if(comparator == null){
    var res = await setTimeout(async () => {
      var r = await standardOwnership(body);
      return r;
    }, 100);
    return res;
  }
  else{
    return comparator == body.username;
  }
}

function needsOwner(auths){
  var ownerAuths = auths.filter(auth => auth.split(":").includes("OWNER"));
  return ownerAuths; 
}

function verifyAuthorities(userAuth, username, auths){
  if(
    !auths.includes(userAuth) && 
    !auths.includes("*") &&
    !auths.includes(username)
  ){
    return false
  }
  else{
    return true;
  }
}

function verifyAuthority(auth, userAuth, username){
  if(
    auth !== userAuth &&
    auth !== "*" &&
    auth !== username
  ){
    return false;
  }
  else{
    return true;
  }
}

export async function isAuthorisedWithAsyncCallback(
  authList, 
  currentUser, 
  callback
){
  if(authList.includes("*")){
    return true;
  }
  for(var i = 0; i < authList.length; i++){
    var main = authList[i].split(":");
    var auth = main[0];
    if(main.length == 1){
      if(currentUser.auth == auth){
        return true;
      }
    }
    else{
      var hasOwnership = await callback({username: currentUser.username});
      if(main[1] == "OWNER" && hasOwnership && auth == currentUser.auth){
        return true;
      }
    }
  }
  return false;

}

/**
 * Return true is currentUser is authorised given an authList
**/
export function isAuthorised(authList, currentUser, comparator){
  if(authList.includes("*")){
    return true;
  }
  for(var i = 0; i < authList.length; i++){
    var main = authList[i].split(":");
    var auth = main[0];
    if(main.length == 1){
      if(currentUser.auth == auth){
        return true;
      }
    }
    else{
      if(
        main[1] == "OWNER" && 
        comparator == currentUser.username && 
        currentUser.auth == auth
      ){
        return true;
      }
    }
  }
  return false;
}

// Determine if a user can open a specific module
export async function canOpenModule(moduleName, userAuth, auths, username, conditions){
  // ownerAuths only contains conditional authorities
  const ownerAuths = needsOwner(auths);

  if(auths.includes("*")){
    return true;
  }
  // ownerAuths.length = 0 means no conditions present
  if(ownerAuths.length == 0){
    return (verifyAuthorities(userAuth, username, auths));
  }
  else{
    var authorised = false
    for(var i = 0; i < auths.length; i++){
      var main = auths[i].split(":");
      // Check if there are any conditions on the authority
      if(main.length == 1){
        if(verifyAuthority(main[0], userAuth, username)){
          return true;
        }
      }
      else{
        var auth = main[0];
        var ownership = main[1];
        if(userAuth == auth || auth == username){
          // Check ownership
          var body = {"username": username, "auths": auths}
          if(Object.keys(conditions).includes("ownership")){
            var isOwner = await conditions.ownership(body);
            if(isOwner){
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}

export function getModule(modName, username) {
    var apiId = "96ltgj7sv5";
    var endpoint = `https://${apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    return axios.get(`${endpoint}/read/${modName}/${username}`);
  }

