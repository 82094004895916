import React, { useState, useEffect } from 'react';
import { MainGridContext } from '../../libs/mainGridContextLib.js';
import ModuleUtils from '../../shared/ModuleUtils.js';
import { useAppContext } from '../../libs/contextLib';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../general/notifications.js';
import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import UserPrefs from '../../shared/UserPrefs';

import { blue } from '@material-ui/core/colors';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
} from '@material-ui/core';

import {
  IconButton, 
  Typography,
  Button,
  Grid,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Tabs,
  Tab,
  Slide
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ExploreIcon from '@material-ui/icons/Explore';
import FaceIcon from '@material-ui/icons/Face';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const useStyles = makeStyles((theme) => ({
  historyButton:{
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor:"pointer",
    fontSize: "12px"
  }
}))

const getStepContent = (i, updStep, done) => {
  switch(i){
    case 0:
      return(
          <Grid container alignItems="center" justify="center">
            <Grid item container xs={12} alignItems="center" justify="center">
              <Typography variant="h6"> 
                Du verkar vara ny här...
              </Typography>
            </Grid>
            <Grid item xs={12} container alignItems="center" justify="center">
              <Typography variant="h3">Välkommen!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button 
                fullWidth 
                color="primary" 
                variant="outlined"
                onClick={() => updStep(1)}
              > 
                Tryck här för att lära dig mer om VASA
              </Button>
            </Grid>
          </Grid>
      )
      break;
    case 1:
      return(
          <Grid container>
            <Typography variant="h6">
              I VASA skapar du ditt egna gränssnitt. <br />
              Detta sker med hjälp av <b>tabbar</b>, <b>arbetsytor</b>, <b>moduler</b> och <b>sidor</b>.
            </Typography>
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/vasa_summary.gif" width="100%"/>
            <br />
            <Button 
              fullWidth 
              color="primary" 
              variant="outlined"
              onClick={() => updStep(2)}
              style={{marginTop:"50px"}}
            > 
              Ehh, ok... Vad är en tab? 
            </Button>
            <Button 
              fullWidth 
              color="secondary" 
              variant="outlined"
              onClick={() => updStep(1)}
            > 
              Tillbaka 
            </Button>

          </Grid>
      )
      break;
    case 2:
      return(
          <Grid container>
            <Typography variant="h4">
              Tabbar i VASA
            </Typography>
            <Typography variant="h6">
             Överst på skärmen hittar du dina öppna tabbar, den första tabben 
             kallas alltid <b>START</b>. Tabbar i VASA fungerara precis 
             som tabbar i Chrome, firefox, safari osv..
             <br />
             <br />
            </Typography>
            <Typography variant="h6">
              För att lägga till en tab trycker du på <AddIcon />.
              <br />
              <br />
            </Typography>
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/add_tab.gif" width="100%"/>

            <Typography variant="h6">
              Lägg till en tab, tryck sedan på START för att komma tillbaka hit igen. Tryck <b>FORTSÄTT</b> när du är klar.
            </Typography>
            <Button 
              fullWidth 
              color="primary" 
              variant="outlined"
              onClick={() => updStep(3)}
              style={{marginTop:"50px"}}
            > 
              Fortsätt
            </Button>
            <Button 
              fullWidth 
              color="secondary" 
              variant="outlined"
              onClick={() => updStep(1)}
            > 
              Tillbaka 
            </Button>

          </Grid>
      )
      break;
    case 3:
      return(
        <Grid container>
          <Typography variant="h6">
            <b>Bra jobbat! 🎉</b>
            <br /><br />
          </Typography>
          <Typography variant="h4">
           <br />
           Arbetsytor 
          </Typography>
          <Typography variant="h6">
            Varje tab öppnar en ny arbetsyta. Den tomma arbetsytan kallas även hemskärmen.
            <br />
            <br />
          </Typography>
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/Vasa_homscreen.jpg" width="100%"/>
          <Typography variant="subtitle2">
            Figur 1. Hemskärmen sammanfattar din profil. Denna visas när inga
            moduler finns öppna
            <br />
            <br />
          </Typography>
          <Typography variant="h6">
            Ett diagram av hemskärmen visas i Figur 1. Överst på hemskärmen 
            ser du din profil. Genom att trycka på avatar 
            cirkeln kan du uppdatera din profilbild. Den vita remsan kommer
            att innehålla siffror.
            <br />
            <br />
            Under profilen finns knappar som kommer att öppna olika menyer som 
            innehåller både personlig och generell information om kontoret.
            Lista ut vad varje knapp gör genom att trycka på den eller
            genom att lägga musen över knappen.
          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => updStep(4)}
            style={{marginTop:"50px"}}
          > 
            Men hur lägger man till kunder och allt sånt?
          </Button>
          <Button 
            fullWidth 
            color="secondary" 
            variant="outlined"
            onClick={() => updStep(2)}
          > 
            Tillbaka 
          </Button>

        </Grid>
      )
      break;
    case 4:
      return(
        <Grid container>
          <Typography variant="h4">
            Svar: Moduler 
          </Typography>
          <Typography variant="h6">
            Allt produktivt arbete kommer att ske genom att öppna fyrkantiga 
            fönster.
            <br />
            <br />
            Varje sådant fönster kallas för en modul och varje modul har en 
            specifik funktion och syfte. Det finns tre sätt att öppna en modul.
            Vi börjar med den enklaste och långsamaste.
            <br />
            <br />
            Längst upp till vänster på skärmen finns det två knappar. Den översta
            knappen kommer att öppna Utforskaren där du kan hitta alla moduler som du
            har tillgång till.
            <br />
            <br />
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/new_customer.gif" width="100%"/>
            Öppna menyn i den andra tabben som du skapade och lista ut hur du
            kan skapa en ny kund. Kom tillbaka och tryck på Fortsätt.
            <br />
            <br />
          </Typography>
          <Typography variant="subtitle2">
            Ledtråd: Gå in på Kunder -> Skapa och tryck på nykund
          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => updStep(5)}
            style={{marginTop:"50px"}}
          > 
            Måste jag alltid gå in på den här jävla menyn och leta?
          </Button>
          <Button 
            fullWidth 
            color="secondary" 
            variant="outlined"
            onClick={() => updStep(3)}
          > 
            Tillbaka 
          </Button>

        </Grid>
      )
      break;
    case 5:
      return(
        <Grid container>
          <Typography variant="h4">
            Nej 
          </Typography>
          <Typography variant="h6">
            Varje kommando har en unik kod som man kan skriva i en kommandorad.
            Modulerna öppnas genom att skriva: <br/><br/><b>::"Modulens namn"</b><br/><br />. För att öppna
            ny kund formuläret skriver du exempelvis '::nykund'. Namnet hittar du 
            i Utforskaren. I ditt dagliga arbete kommer du använda kanske 5-8 moduler 
            dessa kan man komma ihåg namnet på.
            <br />
            <br />
            Ett annat sätt att öppna moduler är via genvägar. Skapa en genväg 
            genom att sätta din mus längst till vänster på skärmen under  
            <ExploreIcon />
            <FaceIcon />
            knapparna
            och tryck på <AddIcon />.
            <br />
            Du kan komma åt formuläret för att lägga till moduler genom att
            trycka på genvägar.
            <br />
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/shortcut.gif" width="100%"/>

            Du kan alltid komma åt dina genvägar genom att lägga musen till vänster
            på skärmen.
            <br />
            <br />
            Testa nu att skapa genvägar till moduler som du tror att du kommer 
            att användar.
          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => updStep(6)}
          > 
            Fortsätt
          </Button>
          <Button 
            fullWidth 
            color="secondary" 
            variant="outlined"
            onClick={() => updStep(4)}
          > 
            Tillbaka 
          </Button>

        </Grid>
      )
      break;
    case 6:
      return(
        <Grid container>
          <Typography>
            Bra! 🥳
          </Typography>
          <Typography variant="h4">
            Spara moduler med sidor och bokmärken 
          </Typography>
          <Typography variant="h6">
            Om du har en specifik modul som du vill spara, kanske en speciell 
            kund eller användare så kan du spara dessa på två olika sätt. Via 
            sidor eller bokmärken.
            <br />
            <br />
            Sidor används till att spara en grupp av moduler om du märker att 
            du ofta tar fram din profil modul och användar modul kan du skapa 
            en sida som innehåller dessa.
            <br />
            <br />
            Sidor skapar genom att trycka på <AddIcon /> längs ner till höger.
            Detta kommer att spara alla moduler som finns på skärmen just nu och
            lägga dom i den nya sidan. Du kan döpa in sidan till va som helst 
            genom att trycka på <EditIcon /> 
            <br />
            <br />
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/new_page.gif" width="100%"/>

          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => updStep(7)}
          > 
            Mer om bokmärken
          </Button>
          <Button 
            fullWidth 
            color="secondary" 
            variant="outlined"
            onClick={() => updStep(5)}
          > 
            Tillbaka 
          </Button>

        </Grid>
      )
      break;
    case 7:
      return(
        <Grid container>
          <Typography variant="h4">
            Bokmärken 
          </Typography>
          <Typography variant="h6">
            På toppen av varje modul finns denna ikon: <BookmarkIcon />
            Trycker man på den får man en dialog för att skapa ett nytt bokmärke.
            Ett bokmärke är som en genväg, den största skillnaden är att man 
            med ett bokmärke kan lägga till en specifik modul. Om du exempelvis 
            snabbt vill öppna kundsidan för en specifik kund kan man 
            skapa ett bokmärke för just den kunden.
            <br />
            <br />
            <img src="https://vasa.s3.us-east-2.amazonaws.com/public/images/new_bookmark.gif" width="100%"/>
            <br />
            <br />
            Alla bokmärken kommer man åt via de personliga knapparna på hemskärmen.
          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => updStep(8)}
          > 
            KLAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAR! 
          </Button>
 
        </Grid>
      )
      break;
    case 8:
      return(
        <Grid container>
          <Typography variant="h4">
            Du kan nu börja använda VASA! 
            <br />
            <br />
          </Typography>
          <Button 
            fullWidth 
            color="primary" 
            variant="outlined"
            onClick={() => done()}
          > 
            Fortsätt
          </Button>
        </Grid>
      )
      break;
  }
}

export default function WelcomeScreen(param){
  const classes = useStyles(); 
  const theme = useTheme();
  const addModule = param.addModule;
  const { moduleUtils, setModuleUtils, currentUser } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);

  const steps = [0,1,2,3,4,5];
  const [currentStep, setCurrentStep] = useState(0);
  const [dir, setDir] = useState("right");

  const [tutorialDone, setTutorialDone] = useState(true);

  const setUserDone = () => {
    const userPrefsApi = new UserPrefs(currentUser.username);
    userPrefsApi.update(currentUser.username, "tutorialDone", true)
    .then(res => {
      notify("success", "Du klarade introduktionen!");
      setTutorialDone(true); 
    });
  }
  
  useEffect(() => {
    setIsLoading(true);
    if(currentUser.username !== null){
      const userPrefsApi = new UserPrefs(currentUser.username);
      userPrefsApi.read(currentUser.username).then(res => {
        setTutorialDone(res.data.tutorialDone);
        setIsLoading(false);
      });
    }
  },[currentUser])

  if(localStorage.latest !== undefined && tutorialDone){
    return(
        <>
          {JSON.parse(localStorage.latest).map((e, i) => {

            var main = e.name.slice(2).split(" ");
            var name = main[0];
            var params = main.splice(1);
            var bg = blue[500];
            var domain = "customers"
            if(moduleUtils !== null){
              domain = moduleUtils.getDomain(name)
            }

            return(
              <Grid key={i} item container xs={3} alignItems="center" justify="center">
                <Tooltip title={`${e.name}`}>
                  <IconButton onClick={() => addModule(e.name)}>
                    <Avatar 
                      className={classes.historyButton}
                      style={{
                        background: theme.palette.domain[domain].main,
                        boxShadow: theme.palette.type == "dark" ? 
                          `1px 1px 5px 1px #000000`: 
                          "1px 1px 5px 1px #e0e0e0",
                      }}
                    >
                      {name} 
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Grid>
            )
          })}
        </>
   )
  }
  else{
    return(
      <Slide in={!isLoading && !tutorialDone} direction={dir}>
        {getStepContent(currentStep, (i) => {
          setDir("left")
          setIsLoading(true); 
          setTimeout(() => {setDir("right"); setIsLoading(false)},500); 
          setCurrentStep(i)
        }, () => setUserDone())}
      </Slide>
    )
  }
}


