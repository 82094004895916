import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAppContext } from '../libs/contextLib';
import {
  TextField, 
  Card, 
  CardContent, 
  Grid, 
  CircularProgress, 
  Typography, 
  Slide, 
  IconButton
} from '@material-ui/core';

import { useHistory  } from "react-router-dom";
import '../App.css';

import UserAdmin from '../shared/UserAdmin.js';
import Users from '../shared/Users.js';
import EntityEvents from '../shared/EntityEvents.js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  
  root: {
    height:"100vh",
    backgroundImage: theme.palette.type === "dark" ? 'url("https://vasa.s3.us-east-2.amazonaws.com/public/images/wallpapersden.com_dark-blue-ios-13-apple_5120x2880.jpg")' :
    'url("https://vasa.s3.us-east-2.amazonaws.com/public/images/flowers.jpg")',
    backgroundSize:"cover"
  },
  loginCard:{
    position:"absolute",
    backgroundImage: theme.palette.type === "dark" ? "linear-gradient(to bottom right, #0f0f0f, #000000)" : "linear-gradient(to bottom right, #fff5fe, #ffffff)",
    width: 500,
    top:"20%",
    right:"5%",
    padding: "20px",
    paddingTop:"100px",
    paddingBottom:"100px",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px black"
  },

  loginForm:{
  },

  textInput:{
    width:"100%",
  },
  loginButton:{
    width:"100%",
    marginTop: 10
  },
  loginLoad:{
    position: "absolute",
    top:"50%",
    right: "50%"
  },
  logoImg:{
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  }
}))

function Login() {
  const classes = useStyles();

  // States
  const [uname, setUname]                 = useState("");
  const [pwd, setPwd]                     = useState("");
  const [isLoading, setIsLoading]         = useState(false);
  const [cardDirection, setCardDirection] = useState("right");
  const [newUser, setNewUser]             = useState(false);
  const [userVerified, setUserVerified]   = useState(false);
  const [code, setCode]                   = useState("");
  const [showPass, setShowPass] = useState(false);

  // Context variables
  const { userHasAuthenticated  } = useAppContext();


  // History
  const history     = useHistory();

  // Api
  const events      = new EntityEvents();
  const userAdmin   = new UserAdmin();

  const verifyUser = async () => {
    try{
      await Auth.confirmSignUp(uname, code).then(res => {
        setUserVerified(true);
      });
    }
    catch(error){
      console.log(`error confirming sign up ${JSON.stringify(error)}`);
    }
  }

  const createLoginEvent = (uname) => {
    events.create({
      entity: "USER", 
      creator: uname, 
      type:"LOGIN", 
      identifier:uname,
      message:"success",
      idColumn: "username"
    });
  }

  async function handleSubmit(event){
    event.preventDefault();
    setCardDirection("left");
    setIsLoading(true);

    try{
      userAdmin.isActive(uname).then(async res => {
        if(res.data.recordset[0] == undefined){
          alert("Det finns ingen användare med detta användarnamnet");
          setIsLoading(false);
        }
        else if(res.data.recordset[0].active){
          Auth.signIn(uname, pwd).then(res => {
            const users = new Users(uname);

            users.updateUsername(uname, "loggedIn", true);
            userHasAuthenticated(true);
            createLoginEvent(uname)

            history.push("home");
          }).catch(err => {
            alert(`Fel lösenord eller användarnamn.`)
            setIsLoading(false);
          });
        }
        else{
          setIsLoading(false);
          alert("Ditt konto har blivit inaktiverat.");
        }
      })
    }
    catch(e){
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
      <Grid 
        container
        spacing={0}
        justify="flex-end"
        className={classes.root}
      >
        <div style={{position:"absolute", top:"92%", color:"white", left:"2.5%"}}>
          <Typography variant="h4">
            VASA 4
          </Typography>
        </div>
        <Grid item xs={3}>
          
          <Slide direction={cardDirection} in={!isLoading}>
            <Card className={classes.loginCard}>
              <div>
              <img 
              src="https://vasa.s3.us-east-2.amazonaws.com/isgg-logo.png" 
              alt="logga"
              height="100"
              className={classes.logoImg}
            />
              </div>
              <CardContent>
                {newUser && !userVerified ?
                <form>
                  <Grid>
                    <TextField 
                      autoFocus
                      className={classes.textInput} 
                      id="standard-basic" 
                      label="Användare" 
                      value={uname}
                      onChange={e => setUname(e.target.value)}
                    />
                    <TextField 
                      autoFocus 
                      className={classes.textInput}
                      label="Verifikationskod"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      helperText="Skriv koden som du mottog via email/SMS"
                    />
                  </Grid>
                  <Grid>
                    <Button 
                      style={{width: "100%"}}
                      onClick={verifyUser}
                    >
                      Verifiera 
                    </Button>
                  </Grid>
                  <Grid>
                    <Button 
                      style={{width: "100%"}}
                      color="secondary"
                      size="small"
                      onClick={(e) => setNewUser(prev => !prev)}
                    >
                      Avbryt
                    </Button>
                  </Grid>
                </form>
                :
                <form 
                  onSubmit={handleSubmit} 
                  className={classes.loginForm} 
                  >
                  <Grid>
                    <Grid>
                      <TextField 
                        autoFocus
                        className={classes.textInput} 
                        id="standard-basic" 
                        label="Användare" 
                        value={uname}
                        onChange={e => setUname(e.target.value)}
                      />
                    </Grid>
                    <Grid container>
                      <Grid item xs={11}>
                        <TextField 
                          className={classes.textInput}
                          label="Lösenord" 
                          type={!showPass ? "password" : ""}
                          value={pwd}
                          onChange={e => setPwd(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1} style={{paddingTop:"8px"}}>
                        <IconButton onClick={() => setShowPass(p => !p)}>
                          {!showPass ? 
                            <VisibilityIcon size="small"/>
                            :
                            <VisibilityOffIcon size="small"/>
                          }
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Button 
                         type="submit"
                         color="primary"
                         className={classes.loginButton}>
                         Logga in
                      </Button>
                    </Grid>
                    <Grid>
                      <Button 
                        size="small" style={{width:"100%"}}
                        onClick={(e) => setNewUser(prev => !prev)}
                      >
                        Ny användare
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                }
              </CardContent>
            </Card>
          </Slide>
          <Slide className={classes.loginLoad} direction="right" in={isLoading}>
            <div>
              <CircularProgress className={classes.logoImg} />
              <p className={classes.logoImg}>Loggar in...</p>
            </div>
          </Slide>
        </Grid>
      </Grid>
  );
}

export default Login;
