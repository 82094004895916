import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../libs/contextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import _ from 'lodash';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  Typography,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  Button,
  Paper,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

export default function FastCommand(params){
  const classes = useStyles();
  const theme = useTheme();

  const open = params.open;
  const onClose = params.onClose;
  const addModule = params.addModule;

  const [defaultCmd, setDefaultCmd] = useState(params.defaultCmd);
  const [parameters, setParameters] = useState("");

  const [command, setCommand] = useState("");

  const handleChange = (e) => {
    setCommand(e.target.value);
  }

  const handleClose = () => {
    onClose();
    params.setActiveCmd("");
  }

  const handleButtonPress = (e) => {
    if(e.key == "Enter"){
      if(defaultCmd == ""){
        addModule("::" + e.target.value);
      }
      else{
        addModule(`::${defaultCmd.name} ${e.target.value}`);

      }
      handleClose();
    }
  }

  useEffect(() => {
    setDefaultCmd(params.defaultCmd);
    setParameters("test")
  },[params.defaultCmd])

  return(
    <Dialog 
      onClose={handleClose} 
      open={open} 
      maxWidth="lg" 
      PaperProps={{
        style:{
          backgroundColor:"transparent",
          boxShadow:"none",
          overflowY: "hidden",
          overflowX: "hidden"
        },
      }}
      BackdropProps={{
        style:{
          backdropFilter: "blur(10px)",
          backgroundColor:"rbga(0,0,30,0.4)"
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField 
            label={defaultCmd.parameters} 
            fullWidth
            onChange={(e) => handleChange(e)}
            onKeyPress={(e) => handleButtonPress(e)}
            autoFocus
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
