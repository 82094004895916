import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import Reactions from '../reactions';

import {
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   padding:"20px",
   background:theme.palette.background.paper,
   borderBottom:`1px solid ${theme.palette.border.primary}`,
   borderTop:`1px solid ${theme.palette.border.primary}`,
 }
}));

export default function CreateEvent(params){
  const classes = useStyles();
  const theme = useTheme();

  const { profile, event, index, media } = params;

  return(
    <Grid 
      item 
      xs={12} 
      key={index}
      className={classes.root}
    >
      <EventProfile 
        profile={profile} 
        creator={event.creator} 
        created={event.created}
f     />
      <Grid item xs={12}>
        <Typography>
          {event.entity == "orders" ? 
            "Har skapat en ny order" 
          : 
            "Har skapat en ny kund"
          }
        </Typography>
      </Grid>
      <Grid container>
        <img src={media} height={75} />
      </Grid>
      <Reactions 
        eventId={event.id}
        like={event.thumbup} 
        confetti={event.confetti} 
        angry={event.angry}
        love={event.love}
      />
    </Grid>
  )
}

