import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';

import {makeStyles} from '@material-ui/core/styles';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Button,
  Paper,
  MenuItem,
  TextField,
  CircularProgress,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  newRow: {
    background: "green",
  },
  tableCell:{
    borderBottom: "none"
  }
}));

function FadeRow(params){
  if(params.new){
    return(
      <Fade in={true}>
        {params.children}
      </Fade>
    )
  }
  else{
    return (<>{params.children}</>);
  }
}
const startTime = new Date();

export default function EvntityEventsModule(params){
  const classes = useStyles();
  const { onRemoveItem } = useMainGridContext();
  const { entityEventsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [events, setEvents] = useState([]);
  const [newRow, setNewRow] = useState(0);
  const [amt, setAmt] = useState(-1);
  const [exEvts, setExEvts] = useState(0);

  useEffect(() => {
    events.unshift(dataFromServer);
    setEvents(events);
    setNewRow(events.length-1);
    setAmt(a => a + 1);

  },[dataFromServer])

  const getRowColor = (row) => {
    if(row.type !== undefined){
      switch(row.type.split(":")[0]){
        case("READ"):
          return ({background: "white"});
        case("CREATE"):
          return ({background: "#ccb9f6ca"});
        case("UPDATE"):
          return ({background: "#fffde7"});
        case("DELETE"):
          return ({background: "#fbe9e7"});
      }
    }
  }

  const formatDateNumber = (num) => {
    if(num < 10){
      return "0" + num;
    }
    return num;
  }

  return (
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} Händelser
      </Header>
      <Body>
        <Typography variant="h4">
          <b>Händelser</b>
        </Typography>
        <Grid container style={{padding: "20px"}}>
          <Grid item xs={4}>
            <Typography variant="overline">
              Start
            </Typography>
            <br />
            <Typography variant="subtitle2">
              {`${formatDateNumber(startTime.getHours())}:${formatDateNumber(startTime.getMinutes())}:${formatDateNumber(startTime.getSeconds())}`} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="overline">
              Utförda 
            </Typography>
            <Typography variant="subtitle2">
              {amt} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="overline">
              Fel  
            </Typography>
            <Typography variant="subtitle2">
              {exEvts} 
            </Typography>
          </Grid>
        </Grid>
        <Grid container
          style={{overflow:"auto", height:"80%", padding: "20px"}}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Tid</b>
                  </TableCell>
                  <TableCell>
                    <b>Entitet</b>
                  </TableCell>
                  <TableCell>
                    <b>Identifikator</b> 
                  </TableCell>
                  <TableCell>
                    <b>Användare</b> 
                  </TableCell>
                  <TableCell>
                    <b>Type</b> 
                  </TableCell>
                  <TableCell>
                    <b>Identifikator</b> 
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.length > 0 ? events.map((row, i) => (
                  <FadeRow new={newRow == i} index={i} newRow={newRow}>
                    <TableRow key={i} style={getRowColor(row)}>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.created !== undefined ? row.created.slice(11, row.created.length-5) : null}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.entity}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.identifier}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.creator}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.type}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="overline">
                          {row.identifier}
                        </Typography>
                      </TableCell>

                    </TableRow>
                  </FadeRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Body>
    </Module>
  );
}
