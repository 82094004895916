import axios from 'axios';
import config from './apiConfig.js';

export default class Auths{
  constructor(){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "0rsabsv6hg";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.officePrefs_api_key;
  }

  async getKey(table, id, created){
    return axios.get(`${this.endpoint}/read/office/preference/specific/${table}/${id}/${created}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getLinkPreview(url){
    this.requestTemplate.body = {"url": url}
    return axios.post(`${this.endpoint}/read/link/preview`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async getLatestLink(){
    return axios.get(`${this.endpoint}/read/office/preference/latest/link`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getLatestMessage(){
    return axios.get(`${this.endpoint}/read/office/preference/latest/importantMessage`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getLatestActivity(){
    return axios.get(`${this.endpoint}/read/office/preference/latest/activity`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getLink(id){
    return axios.get(`${this.endpoint}/read/office/preference/link/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getMessage(id){
    return axios.get(`${this.endpoint}/read/office/preference/importantMessage/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async getActivity(id){
    return axios.get(`${this.endpoint}/read/office/preference/activity/${id}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async createLink(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/office/preference/link`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async createMessage(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/office/preference/message`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async createActivity(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/office/preference/activity`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async update(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/update/office/preference`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }
  async updateArray(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/update/array/preference`,
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async delete(table, id, created, user){
    return axios.delete(`${this.endpoint}/delete/office/preference/${table}/${id}/${created}/${user}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }


}

