import axios from 'axios';
import config from './apiConfig.js';

export default class FinancialStreams{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "rlxysyw958";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
  }
  async officeSold(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/officeSold`, this.requestTemplate);
  }

  async mak(user){
    return axios.get(`${this.endpoint}/mak/${user}`, this.requestTemplate);
  }
  async outstanding(user){
    return axios.get(`${this.endpoint}/outstanding/${user}`, this.requestTemplate);
  }
  async outstandingFrom(user, from){
    return axios.get(`${this.endpoint}/outstanding/since/${from}/${user}`, this.requestTemplate);
  }
  async fak(user){
    return axios.get(`${this.endpoint}/fak/${user}`, this.requestTemplate);
  }
  async soldToday(user){
    return axios.get(`${this.endpoint}/soldToday/${user}`, this.requestTemplate);
  }
  async paymentStream(user){
    return axios.get(`${this.endpoint}/paymentsMonth/${user}`, this.requestTemplate);
  }
  async paymentStreamSince(user, from){
    return axios.get(`${this.endpoint}/paymentsMonth/since/${from}/${user}`, this.requestTemplate);
  }

  async customerCount(user){
    return axios.get(`${this.endpoint}/count/customers/${user}`, this.requestTemplate);
  }
}

