import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import Modules from '../../shared/Modules';

import _ from 'lodash';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  Typography,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  Button,
  Paper,
  IconButton,
  Collapse
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  moduleAvatar:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize:"12px"
  },
  paper: {
    height:"100%", 
    width:"100%", 
    borderRadius: "10px",
    padding: "20px",
    paddingTop:"60px",
    paddingRight:"40px",
    color: "#828282"
  },
  title:{
    height:"15vh", 
    width:"100%", 
    backgroundImage:"url('https://vasa.s3.us-east-2.amazonaws.com/public/images/1b58ef6e3d36a42e01992accf5c52d6eea244353.jpg')",
    borderRadius: "10px",
    backgroundSize: "cover"
  }
}));

function ModuleElement(params){
  const classes = useStyles();
  const theme = useTheme();

  const name          = params.mod.name;
  const domain        = params.mod.color;
  const desc          = params.mod.description;
  const addModule     = params.addModule;
  const handleClose   = params.handleClose;
  const showTextField = params.showTextField;

  const handleOpen = () => {
    if(params.mod.parameters !== undefined){
      showTextField(true);
      params.setActiveModule(params.mod);
      //params.textInputRef.current.focus();
    }
    else{
      addModule("::" + name);
      handleClose();
    }
  }

  return(
    <Tooltip title={desc}>
      <IconButton onClick={() => handleOpen()}>
        <Avatar 
          className={classes.moduleAvatar}
          style={{
            background: theme.palette.domain[domain].main,
          }}
        >
          {name} 
        </Avatar>
      </IconButton>
    </Tooltip>
  )
}

function ModuleContainer(params){
  const classes = useStyles();
  const theme = useTheme();

  const title       = params.title;
  const domain      = params.domain;
  const description = params.description;
  const amount      = params.amount;

  return(
    <Grid>
      <Paper className={classes.paper} style={{
        background: theme.palette.domain[domain].main
      }}>
        <Typography variant="h4" 
          style={{
            color: "white",
            borderLeft: `5px solid ${theme.palette.domain[domain].main}`
          }}
        > 
          <b>{title}</b>
        </Typography>
        <Typography style={{paddingTop:"12px", color:"white"}}>
          {description}
        </Typography>
        <Typography variant="overline" style={{color:"white"}}>
          Antal: {amount}
        </Typography>
      </Paper>
    </Grid>

  )
}

function ModuleSet(params){
  const classes = useStyles();
  const theme = useTheme();

  const [sets, setSets] = useState({"Händelser":[]});
  const [openTextField, setOpenTextField] = useState(false);
  const [activeModule, setActiveModule]   = useState({});
  const [message, setMessage]             = useState("");
  
  const returnFunc    = params.return;
  const title         = params.title
  const groups        = params.groups
  const addModule     = params.addModule;
  const close         = params.close;
  const showTextField = params.showTextField
  const domain        = params.color

  useEffect(() => {
    var tmpSet = {};
    Object.keys(groups).map(e => {
      tmpSet[e] = [];
      groups[e].map(x => {
        tmpSet[e].push(params.modules.getModule(x));
      });
    });
    setSets(tmpSet)

    if(Object.keys(tmpSet).length == 0){
      setMessage("Det verkar inte finnas något här... 😞");
    }
    else{
      setMessage("");
    }

  },[params.modules, params.groups])

  const openParamModule = (e) => {
    if(e.key == "Enter"){
      addModule(`::${activeModule.name} ${e.target.value}`);
      close();
    }
  }

  return(
    <Grid container xs={12} spacing={3}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" 
              style={{
                color:"white",
                borderTop: `4px solid ${theme.palette.domain[domain].main}`,
                borderRadius:"10px",
                padding:"10px",
                paddingTop:"30px"
            }}>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{paddingLeft:"15px"}}>
            {message}
          </Typography>
          <Collapse in={openTextField}>
            <TextField 
              label={
                activeModule.parameters == undefined || activeModule.parameters == null ? 
                  null : 
                  Object.values(activeModule.parameters)
              }
              variant="outlined"
              style={{
                width:"100%", 
                background:"rgba(255,255,255,0.2)",
                border:"none",
                borderRadius:"10px"
              }}
              onKeyPress={openParamModule}
            />
          </Collapse>
        </Grid>
      </Grid>
      {Object.keys(sets).map(set => (
        <Grid item xs={12}>
          <Grid>
            <Paper style={{
              background:"rgba(255,255,255,0.2)",
              padding:"10px",
              borderRadius: "10px"
            }}>
              <Grid container xs={12} spacing={3}>
                {
                  sets[set].map(e => {
                    return(
                      <Grid item xs={3}>
                        <ModuleElement 
                          mod={e}
                          addModule={() => addModule(`::${e.name}`)}
                          handleClose={() => close()}
                          showTextField={() => setOpenTextField(true)}
                          setActiveModule={(s) => setActiveModule(s)}
                        />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Paper>
            <Typography>
              {set} 
            </Typography>
          </Grid>
        </Grid>
        ))
      }
    </Grid>
  )
}

export default function AllModuleDialog(params){
  const classes   = useStyles();
  const inputRef  = React.createRef();
  const theme     = useTheme();

  const { modulesApi, currentUser } = useAppContext();
  const { onClose, open, modules}   = params; 

  const [modsShown, setModsShown]       = useState([]);
  const [mods, setMods]                 = useState([]);
  const [variable, setVariable]         = useState(false);
  const [activeModule, setActiveModule] = useState({parameters: "{}"});

  const addModule = params.addModule;

  const [showDetails, setShowDetails]     = useState(false);
  const [activeGroup, setActiveGroup]     = useState({});
  const [currentTitle, setCurrentTitle]   = useState("");
  const [currentDomain, setCurrentDomain] = useState("admin");
  const [showTutorialInfo, setShowTutorialInfo] = useState(false)

  const handleClose = () => {
    setVariable(false)
    onClose();
  }

  const handleListItemClick = () => {
    onClose();
  }

  const openModuleWithParam = (e) => {
    if(e.key === "Enter"){
      e.preventDefault();
      setVariable(false);
      onClose();
      addModule(`::${activeModule.name} ${e.target.value}`);
    }
  }

  const formatLabel = () => {
    var obj = JSON.parse(activeModule.parameters);
    return String(Object.values(obj)); 
  }

  const updateActiveGroup = (newGroup) => {
    switch(newGroup){
      case("users"):
        var group = filterModules("users");
        setActiveGroup(group);
        setCurrentTitle("Användare")
        setCurrentDomain("users")
        break;
      case("products"):
        var group = filterModules("products");
        setActiveGroup(group);
        setCurrentTitle("Produkter")
        setCurrentDomain("products");
        break;
      case("customers"):
        var group = filterModules("customers");
        setActiveGroup(group);
        setCurrentTitle("Kunder")
        setCurrentDomain("customers");

        break;
      case("admin"):
      default:
        var group = filterModules("admin");
        setActiveGroup(group);
        setCurrentTitle("Admin")
        setCurrentDomain("admin")
        break;
    }
    setShowDetails(true);
  }

  const filterModules = (domain) => {
    var categories = {};
    var ms = _.filter(mods, (e) => {return e.color == domain});
    for(var i = 0; i < ms.length; i++){
      var currCat = ms[i].category;
      if(categories[currCat] === undefined){
        categories[currCat] = [ms[i].name];
      }
      else{
        var list = categories[currCat];
        list.push(ms[i].name);
        categories[currCat] = list;
      }
    }
    return categories;
  }

  const getModuleAmount = (domain) => {
    return _.filter(mods, (e) => {return e.color == domain}).length;
  }

  useEffect(() => {
    if(currentUser !== null){
      var modules = new Modules(currentUser.username);
      modules.getUserModules(currentUser.auth).then(res => {
        setMods(res.data.modules);
      });
    }
  },[currentUser]);

  return(
    <div style={{overflow:"hidden"}}>
    <Dialog 
      onClose={handleClose} 
      open={open} 
      maxWidth="lg" 
      PaperProps={{
        style:{
          backgroundColor:"transparent",
          boxShadow:"none",
          overflowY: "auto",
          overflowX: "hidden"
        },
      }}
      BackdropProps={{
        style:{
          backdropFilter: "blur(10px)",
          backgroundColor:"rbga(0,0,30,0.4)"
        }
      }}
    >
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid>
          <Paper className={classes.title}>
            <Typography variant="h2" style={{paddingTop:"70px", color:"white", paddingLeft:"10px"}}>
              <b>VASA</b> Utforskaren
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid item container xs={8} spacing={3} style={{overflow:"auto", height:"100%"}}>
        <Grid item xs={6} onClick={() => updateActiveGroup("admin")}>
          <ModuleContainer 
            domain={"admin"}
            title={"Admin"} 
            description={"Moduler som hanterar själva VASA systemet."}
            amount={getModuleAmount("admin")}
          />
        </Grid>
        <Grid item xs={6} onClick={() => updateActiveGroup("customers")}>
          <ModuleContainer 
            domain={"customers"}
            title={"Kunder"} 
            description={"Här hittar du moduler för att hantera kunder."}
            amount={getModuleAmount("customers")}
          />
        </Grid>
        <Grid item xs={6} onClick={() => updateActiveGroup("products")}>
          <ModuleContainer 
            domain={"products"}
            title={"Produkter"} 
            description={"Alla moduler som har att göra med produkter hittar du här."}
            amount={getModuleAmount("products")}
          />
        </Grid>
        <Grid item xs={6} onClick={() => updateActiveGroup("users")}>
          <ModuleContainer 
            domain={"users"}
            title={"Användare"} 
            description={"Moduler för att visa, uppdatera, skapa, ta bort användare hittar du här."}
            amount={getModuleAmount("users")}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid style={{overflow:"auto"}}>
            <Paper 
              className={classes.paper} 
              style={{
                background: `linear-gradient(to bottom left, #ffeb3b 5%, #ffb300 70%)`,
                overflowY:"auto"
              }}
              onClick={() => setShowTutorialInfo(p => !p)}
            >
              <Typography variant="h5"  style={{
                backgroundImage:"linear-gradient(to bottom right, #fff9c4 70%, #ffb300 5%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color:"transparent",
                }}
              >  
                <b>Så här</b> <br />hittar du när du jobbar
              </Typography>
              <Collapse in={showTutorialInfo}>
                <div style={{marginTop:"25px", overflowY:"auto"}}>
                  <Typography style={{color:"white", fontSize:"18px", marginBottom:"5px"}}>
                    Det finns <b>tre</b> sätt <br />för att smidigare hitta din modul:
                  </Typography>
                  <Button style={{color:"white", textAlign:"left", justifyContent:"flex-start"}} fullWidth>
                    1. <b>Genvägar</b> 
                  </Button>
                  <Button style={{color:"white", textAlign:"left", justifyContent:"flex-start"}} fullWidth>
                    2. <b>Kommandon</b> <br /> 
                  </Button>
                  <Button style={{color:"white", textAlign:"left", justifyContent:"flex-start"}} fullWidth>
                    3. <b>Sidor</b> <br /> 
                  </Button>
                </div>
              </Collapse>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={4} spacing={3}>
        <Grid item xs={12}>
          <Slide in={showDetails} direction="left">
            <Grid item xs={12} style={{overflow:"auto", height:"70vh"}}>
              <ModuleSet 
                return={() => setShowDetails(false)}
                addModule={(e) => addModule(e)}
                close={() => handleListItemClick()}
                showTextField={() => handleListItemClick()}
                groups={activeGroup}
                modules={modules}
                title={currentTitle}
                color={currentDomain}
              />
            </Grid>
          </Slide>
        </Grid>
      </Grid>
    </Grid>
    </Dialog>
    </div>
  )
}
