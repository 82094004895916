import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';

// Emoji stuff
const i18n = {
  search: 'Sök',
  clear: 'Rensa', // Accessible label on "clear" button
  notfound: 'Ingen emoji hittad',
  skintext: 'Välj automatisk hudton',
  categories: {
    search: 'Sökresultat',
    recent: 'Ofta använda',
    smileys: 'Smileys och känslor',
    people: 'Människor och kroppar',
    nature: 'Djur och natur',
    foods: 'Mat och dryck',
    activity: 'Aktivitet',
    places: 'Semester och platser',
    objects: 'Föremål',
    symbols: 'Symboler',
    flags: 'Falggor',
    custom: 'Eget',
  },
  categorieslabel: 'Emoji kategorier', // Accessible title for the list of categories
  skintones: {
    1: 'Automatisk hudton',
    2: 'Ljus hudton',
    3: 'Medium-Ljus hudton',
    4: 'Medium hudton',
    5: 'Medium-Mörk hudton',
    6: 'Mörk hudton',
  },
}

export default function VasaEmojis(params){

  const theme = useTheme();

  const onEmojiClick = params.onEmojiClick;

  return(
    <Picker 
      style={{width:"100%"}}
      set="apple"
      emoji=""
      theme={theme.palette.type}
      i18n={i18n}
      title="Emojis"
      onClick={onEmojiClick}
    />
  );
}
