import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import Reactions from '../reactions';
import EntityEvents from '../../../shared/EntityEvents.js';

import {
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   background:theme.palette.background.paper,
   borderBottom:`1px solid ${theme.palette.border.primary}`,
   borderTop:`1px solid ${theme.palette.border.primary}`,
 },
 textcontent:{
   padding:"40px",
 }
}));

export default function ImportantMessageEvent(params){
  const classes = useStyles();
  const theme = useTheme();

  const {session, currentUser} = useAppContext();

  const ev      = params.event; // Event
  const profile = params.profile; // provile url

  // Post state
  const [title, setTitle]             = useState("");
  const [description, setDescription] = useState("");
  const [creator, setCreator]         = useState("");
  const [created, setCreated]         = useState("");

  // File states
  const [src, setSrc]                 = useState("");
  const [mime, setMime]               = useState("");

  useEffect(() => {

    if(session !== undefined){
      const table = "importantMessages";
      const id = ev.identifier.split(":")[0];
      const created = ev.identifier.split(":")[1];

      setCreated(new Date(created));

      getEvent(table, id, created);

      if(ev.filepath !== null){
        getFile(ev.filepath, session.idToken.jwtToken);
      }
    }
  },[session])

  const getEvent = (table, id, created) => {
    const offPrefs    = new OfficePrefs();
    offPrefs.getKey(table, id, created).then(res => {
      setTitle(res.data.title);
      setDescription(res.data.description);
      setCreator(res.data.creator);
      setCreated(new Date(res.data.created));
    });
  }

  const getFile = (fpath, jwtToken) => {
    const sfs         = new SecureFiles();
    sfs.readFile(ev.filepath, session.idToken.jwtToken).then(res => {
      setSrc(res.data.url);
      var m = getMime(ev.filepath.split(".")[1]);
      setMime(m);
    });
  }

  const deleteEvent = () => {
    const entityEventsApi = new EntityEvents(currentUser.username);
    entityEventsApi.deleteEntityEvent(ev.id).then(res => {
      setTitle("[BORTTAGET]");
      setDescription("");
    }).catch(err => console.log("does not delete"));

    const offPrefs    = new OfficePrefs();

    const table = "importantMessages";
    const id = ev.identifier.split(":")[0];
    const created = ev.identifier.split(":")[1];

    offPrefs.delete(table, id, created, currentUser.username).then(res => {
      console.log(res);
    })
    .catch(res => console.log("does not delete"));
  }

  return(
    <Grid container className={classes.root}>
      {mime.length > 0 ?
        <ShowMedia mime={mime} src={src}/>
        :
        null
      }
      <div className={classes.textcontent}>
        <EventProfile 
          profile={profile} 
          creator={creator} 
          created={created} 
          remove={() => deleteEvent()}
        />
        <Grid item xs={12}>
            {textParser(title, "h4")} 
        </Grid>
        <Grid item xs={12}>
            {textParser(description, "h6")} 
        </Grid>
      </div>
      <Reactions 
        eventId={ev.id}
        like={ev.thumbup} 
        confetti={ev.confetti} 
        angry={ev.angry}
        love={ev.love}
      />
    </Grid>
    )
}



