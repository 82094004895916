import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import {
  Tooltip,
  Button
} from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme) => ({
  button:{
    background:theme.palette.background.default, 
    color:theme.palette.text.primary
  }
}))


export default function FileUpload(params){
  const classes = useStyles();

  const getFname  = params.getFname;
  const title     = params.title == undefined ? "Bifoga fil" : params.title;

  return(
  <Tooltip title="Ladda upp en fil">
    <Button
      component="label"
      size="small"
      className={classes.button}
      fullWidth
    >
      <AttachFileIcon /> {title} 
      <input
        type="file"
        hidden
        onChange={getFname}
      />
    </Button>
  </Tooltip>
  )
}

