import React, {useState, useEffect} from 'react';

// Context
import { 
  NewCustomerContext, 
  useCustomerContext 
} from '../../libs/newCustomerContextLib.js';

// Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  Grid, 
  Fade,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
  },
}));

/**
 * ServiceList can add, remove and update services that are created by the
 * user in the form
**/

export default function ServiceList(params){
  const classes = useStyles();
  const theme = useTheme();

  const [showList, setShowList] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [index, setIndex] = useState(0);
  
  // JSON, contains servicename, price and amount that has been input 
  const {
    currentService, 
    setCurrentService,
  } = useCustomerContext();
  
  // [JSON], contains all services that have been saved
  const {services, setServices} = useCustomerContext();

  // Updates the service that is currently shown in the form
  const updateService = (e) => {
    const value = e.target.value;
    const i = e.currentTarget.dataset.index;
    if(services[i] !== undefined){
      setSelectedService(value);
      setIndex(i);
      params.updateCurrentService(services[i]);

    }
  }

  // Remove a service from the list
  const handleRemove = (e,i) => {
    e.preventDefault();

    // Remove selected item from services array
    services.splice(i, 1);
    setServices(services);

    setSelectedService("");
    params.updateCurrentService({"service": "", "price": "","amount": ""});

  }

  return(
    <Grid container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Tjänst</b>
            </TableCell>
            <TableCell align="right">
              <b>Pris</b>
            </TableCell>
            <TableCell align="right">
              <b>Antal</b>
            </TableCell>
            <TableCell align="right">
              <b>Ta bort</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((e,i) => (
            <TableRow>
              <TableCell>
                {e.service}
              </TableCell>
              <TableCell align="right">
                {e.price}
              </TableCell>
              <TableCell align="right">
                {e.amount}
              </TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={(e) => handleRemove(e,i)}>
                  <RemoveIcon fontSize="small"/>
                </IconButton>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  )
}
