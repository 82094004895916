import React, { useState, useEffect } from 'react';
import axios from 'axios'; import './App.css';
import { AppContext  } from './libs/contextLib';
import Routes from './Routes';
import _config from './config.js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import EntityEvents from './shared/EntityEvents';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

function App() {
    const [customersApi, setCustomersApi]       = useState(null);
    const [ordersApi, setOrdersApi]             = useState(null);
    const [servicesApi, setServicesApi]         = useState(null);
    const [commentsApi, setCommentsApi]         = useState(null);
    const [paymentsApi, setPaymentsApi]         = useState(null);
    const [productsApi, setProductsApi]         = useState(null);
    const [usersApi, setUsersApi]               = useState(null);
    const [modulesApi, setModulesApi]           = useState(null);
    const [authsApi, setAuthsApi]               = useState(null);
    const [moduleUtils, setModuleUtils]         = useState(null);
    const [userPrefs, setUserPrefs]             = useState(null);
    const [userProfile, setUserProfile]         = useState(null);
    const [serverAuthority, setServerAuthority] = useState(null);
    const [serverUsername, setServerUsername]   = useState(null);
    const [entityEventsApi, setEntityEventsApi] = useState(new EntityEvents());
    const [prefersDarkMode, setPrefersDarkMode] = useState(
      localStorage.theme == null || localStorage.theme == "light" ? false : true);

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = 
      useState({"username": null, "auth": null, "profile": null});

    const [poolData, setPoolData] = useState({
      UserPoolId: _config.cognito.USER_POOL_ID,
      ClientId: _config.cognito.APP_CLIENT_ID,
    });

    const [userPool, setUserPool] = useState( 
      new AmazonCognitoIdentity.CognitoUserPool(poolData)
    )

    const [session, setSession] = useState(() => {
      const p = new AmazonCognitoIdentity.CognitoUserPool(poolData)

      const user = p.getCurrentUser();
      if(user !== null){
        return user.getSession((err, session) => {
          return session;
        });
      }
    });

    useEffect(() => {
      const p = new AmazonCognitoIdentity.CognitoUserPool(poolData)

      const user = p.getCurrentUser();
      // alert(`This is the current user: ${JSON.stringify(user)}`)
      if(user !== null){
        user.getSession((err, session) => {
          setSession(session);
        });
      }
    },[currentUser.username])

    const getCurrentSession = () => {
      const user = userPool.getCurrentUser();
      if(user !== null){
        return user.getSession((err, session) => {
          return session;
        });
      }
    }

    const theme = React.useMemo(
      () => createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          domain: {
            customers: {main: prefersDarkMode ? "#1565c0" : "#64b5f6"},
            admin: {main: prefersDarkMode ? "#d32f2f" : "#9575cd"},
            products: {main: prefersDarkMode ? "#ef6c00" : "#ffb74d"},
            users: {main: prefersDarkMode ? "#43a047" : "#81c784"}
          },
          background: {
            default: prefersDarkMode ? "#000000" : "#f7f7f7",
            paper: prefersDarkMode ? "#0a0a0a" : "#fff"
          },
          text:{primary: prefersDarkMode ? "#cfcfcf" : "rgba(0, 0, 0, 0.87)"},
          select:{primary: prefersDarkMode ? "#1c1c1c" : "#ededed"},
          border:{primary: prefersDarkMode ? "#292929" : "#e3e3e3"}
        },
      }),
      [prefersDarkMode],
    );

    return (
      <AppContext.Provider value={{
        isAuthenticated, 
        userHasAuthenticated, 
        poolData, 
        session, 
        setSession,
        userPool,
        currentUser,
        setCurrentUser,
        customersApi,
        setCustomersApi,
        ordersApi,
        setOrdersApi,
        servicesApi,
        setServicesApi,
        commentsApi,
        setCommentsApi,
        paymentsApi,
        setPaymentsApi,
        entityEventsApi,
        productsApi,
        setProductsApi,
        usersApi,
        setUsersApi,
        modulesApi,
        setModulesApi,
        authsApi,
        setAuthsApi,
        setModuleUtils,
        moduleUtils,
        setServerAuthority,
        setServerUsername,
        serverAuthority,
        serverUsername,
        setPrefersDarkMode,
        prefersDarkMode,
        userPrefs,
        setUserPrefs,
        getCurrentSession,
        userProfile,
        setUserProfile
      }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </AppContext.Provider>
  );
}

export default App;
