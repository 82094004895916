import React, {useState, useEffect} from 'react';
import MUIDataTable from 'mui-datatables';
import _ from 'lodash';
import { formatter,unFormatInteger } from '../general/format.js'

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import { getDaysAhead } from '../../shared/dateHelper.js';

import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  LinearProgress,
  TextField,
  Fade,
  Button
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {EditableText} from '../general/UpdatableValue.js';

import Goals from '../../shared/Goals.js';
import DataStreams from '../../shared/DataStreams.js';

const useStyles = makeStyles((theme) => {

})

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const setAddNew = props.setAddNew;
  const handleClick = () => {
    setAddNew(prev => !prev);
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

function UserSold(params){
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [user, setUser] = useState(params.user)

  useEffect(() => {
    var userRow = _.filter(params.soldToday, (row) => row.salesman == params.user)[0]; 
    if(userRow !== undefined){
      setValue(userRow.data);
    }
  },[params.soldToday])

  return(
    <Typography variant="subtitle2">
      {value} 
    </Typography>
  )
}

function ProgressBar(params){
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState(0)
  const [comparatorValue, setComparatorValue] = useState(params.comparator);
  const [user, setUser] = useState(params.user)

  useEffect(() => {
    var userRow = _.filter(params.soldToday, (row) => row.salesman == params.user)[0]; 
    if(userRow !== undefined){
      const p = (unFormatInteger(userRow.data)/comparatorValue)*100;
      if(p > 100){
        setProgress(100);
      }
      else{
        setProgress(p);
      }
      setPercentage(p);
    }
  },[params.soldToday])

  return(
    <Grid>
      <Typography variant="subtitle2" style={{fontSize:"12px"}}>
        {percentage}%
      </Typography>
      <LinearProgress variant="determinate" value={progress}/>
    </Grid>
  )
}


export default function UserGoals(params){
  const classes = useStyles();
  const theme = useTheme();

  const { onRemoveItem, notify} = useMainGridContext();

  const { currentUser, usersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [goals, setGoals]         = useState([]);
  const [soldToday, setSoldToday] = useState([]);
  const [loadTime, setLoadTime]   = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [addNew, setAddNew]       = useState(false);
  const [users, setUsers]         = useState([]);

  const [addedUsername, setAddedUsername] = useState();
  const [addedGoal, setAddedGoal]         = useState();

  const goalsApi    = new Goals(currentUser.username)
  const dataStreams = new DataStreams(currentUser.username)

  useEffect(() => {
    if(dataFromServer.entity === "goals" || firstLoad){
      goalsApi.getActiveAll().then(res => {
        var object = res.data.recordset;
        var users = res.data.recordset.map(row => row.username);

        dataStreams.finStreamOfficeSold({"users": users}).then(res => {
          setSoldToday(res.data.recordset);
        }).catch(err => {
          notify("error", `Ett fel inträffade. ${err}`);
        })

        setGoals(res.data.recordset);

      }).catch(err => {
        notify("error", "Ett fel inträffade");
      })
    }

    if(usersApi !== null && firstLoad){
      usersApi.getWhere("active", "1").then(res => {
        setUsers(res.data.recordset);
      })
      .catch(err => {
        notify("error", "Kunde inte hämta användare");
      })
      setFirstLoad(false);
    }

  },[dataFromServer, usersApi])

  const columns = [
    {
      name: "username",
      label: "Användare"
    },
    {
      name: "goal",
      label: "Mål",
      options:{
        customBodyRender:(value, tableMeta, updateValue) => {
          var rowIndex = tableMeta.rowIndex;
          var goalId = goals[rowIndex].id;
          return(
            <EditableText 
              value={value}
              updatable={true}
              onUpdate={(e) => goalsApi.update(goalId, "goal", e)}
            />
          )
        }
      }
    },
    {
      name: "soldToday",
      label: "Sålt idag",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <UserSold user={tableMeta.rowData[0]} soldToday={soldToday}/>
          )
        }
      }
    },
    {
      name: "achieved",
      label: "Uppnått",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <ProgressBar 
              user={tableMeta.rowData[0]} 
              comparator={tableMeta.rowData[1]} 
              soldToday={soldToday}
            />
          )
        }
      }
    }

  ]
  
  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      console.log("rowsDeleted", Object.keys(rowsDeleted.lookup));
      deleteGoals(Object.keys(rowsDeleted.lookup));
    },
    customToolbar: () => {
      return (<CustomTableToolbar setAddNew={(e) => setAddNew(e)}/>)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  const createNewGoal = () => {
    var unames = users.map(u => {return u.username});
    if(unames.includes(addedUsername)){
      var body = {
        "goalName": "dagsmål",
        "username": addedUsername,
        "creator": currentUser.username,
        "goal": addedGoal,
        "dataStream": "Sålt idag",
        "achieved": 0,
        "deadline": getDaysAhead(1) 
      }
      goalsApi.create(body).then(res => {
        notify("success", "Nytt mål skapat");
      }) 
    }
    else{
      notify("error", "Användaren finns inte")
    }
  }

  const deleteGoals = (is) => {
    is.map(i => {
      goalsApi.delete(goals[i].id).then(res => {
        notify("success", "Mål har blivit borttaget");
      })
      .catch(err => {
        notify("error", "Kunde inte ta bort mål.")
      });
    })
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color={theme.palette.domain.users.main}
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Alla användare 
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"95%", padding:"20px"}}> 
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Målsättning</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{width: "100%"}}>
              <MUIDataTable
                size="small"
                title={"Mål"}
                data={goals}
                columns={columns}
                options={options}
                style={{width:"100%"}}
              />
            </div>
          </Grid>
          <Fade in={addNew}>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.username}
                  renderInput={(params) => <TextField autoFocus {...params} label="Användarnamn" onChange={(e) => setAddedUsername(e.target.value)}/>}
                  style={{width:"100%"}}
                  size="small"
                  onChange={(e,v) => setAddedUsername(v.username)}
                />
                <Button>
                  Alla aktiva
                </Button>
                <Button>
                  Alla Inloggade 
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField 
                  label="Värde" 
                  type="number"
                  onChange={(e) => setAddedGoal(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid container xs={12} justify="flex-end" alignItems="flex-end">
                <Button onClick={() => createNewGoal()}>
                  Klar
                </Button>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Body>
    </Module>
  )
}
