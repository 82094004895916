export const monthNamesShort = [
  "Jan", 
  "Feb", 
  "Mar", 
  "Apr", 
  "Maj", 
  "Jun", 
  "Jul", 
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec"
]

export const monthNamesLong = [
  "Januari", 
  "Februari", 
  "Mars", 
  "April", 
  "Maj", 
  "Juni", 
  "Juli", 
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December"
]

export function dateFormatZero(num){
  if(num < 10){
    return "0" + num;
  }
  return num;
}

export function ordinalNumberSWE(num){
  switch(String(num)){
    case "1":
    case "2":
      return num + ":a";
    default:
      return num + ":e"
  }
}

export function getTimeFromDate(date){
  const d = new Date(date);
  const hours = dateFormatZero(d.getHours());
  const mins = dateFormatZero(d.getMinutes());
  const secs = dateFormatZero(d.getSeconds());

  return `${hours}:${mins}:${secs}`;
}

export function formatDate(date){
  const d = new Date(date);

  const year = d.getFullYear();
  const month = monthNamesShort[d.getMonth()];
  const day = ordinalNumberSWE(d.getDate());
  const hours = dateFormatZero(d.getHours());
  const mins = dateFormatZero(d.getMinutes());
  const secs = dateFormatZero(d.getSeconds());

  return `den ${day} ${month} ${year} kl ${hours}:${mins}:${secs}`;
}

export function formatDateNormal(date){
  date = new Date(date);
  const year = date.getFullYear();
  const month = dateFormatZero(date.getMonth() + 1);
  const day = dateFormatZero(date.getDate());
  return `${year}-${month}-${day}`; 
}

export function formatDateTime(date){
  const year = date.getFullYear();
  const month = dateFormatZero(date.getMonth() + 1);
  const day = dateFormatZero(date.getDate());
  const h = dateFormatZero(date.getHours());
  const m = dateFormatZero(date.getMinutes());
  const s = dateFormatZero(date.getSeconds());
  return `${year}-${month}-${day} ${h}:${m}:${s}`; 
}

export function formatDateAddTime(date,a){
  const year = date.getFullYear();
  const month = dateFormatZero(date.getMonth() + 1);
  const day = dateFormatZero(date.getDate());
  const h = dateFormatZero(date.getHours());
  const m = dateFormatZero(date.getMinutes());
  const s = dateFormatZero(date.getSeconds());
  return `${year}-${month}-${day} ${(h+a)%24}:${m}:${s}`; 
}

export function currentMonthPeriod(){
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  return getMonthPeriod(year, month);
}

export function currentYearPeriod(){
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  return {start: `${year}-01-01`, end: `${year}-12-31`};
}


export function getMonthPeriod(year, month){
  var date = new Date(`${year}-${dateFormatZero(month)}-01`);
  var start = new Date(date.getFullYear(), date.getMonth(), 1); 
  var end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return {start: formatDateNormal(start), end: formatDateNormal(end)}
}

export function getWeekPeriod(year, weeknum){
  var start = getDateOfISOWeek(weeknum, year);
  var end   = getDateOfISOWeek(weeknum+1, year);
  return {start: formatDateNormal(start), end: formatDateNormal(end)}
}

function getDateOfISOWeek(w, y) {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export function getWeek(date) {
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7);
}

export function getMonday(d){
  d = new Date(d);
  var day = d.getDay();
  var diff = d.getDate() - day + (day === 0 ? -6:1);
  return new Date(d.setDate(diff));
}

export function getDaysOfWeekList(start){
  var daysOfWeek = [];
  daysOfWeek.push(start)
  for(var i = 1; i < 7; i++){
    var tmp = new Date(daysOfWeek[i-1]);
    var day = new Date(tmp.setDate(tmp.getDate()+1));
    daysOfWeek.push(day);
  }

  return daysOfWeek;
}

export function getCurrentYear(){
  var res = new Date();
  return res.getYear();
}

export function getCurrentDay(){
  var d = new Date();
  var year = d.getFullYear();
  var month = dateFormatZero(d.getMonth()+1);
  var day = dateFormatZero(d.getDate());
  return `${year}-${month}-${day}`;
}

export function getDaysAhead(n){
  var d = new Date();
  var year = d.getFullYear();
  var month = dateFormatZero(d.getMonth()+1);
  var day = dateFormatZero(d.getDate() + n);
  return `${year}-${month}-${day}`;
}

export function getDaysAheadFromDate(d,n){
  var year = d.getFullYear();
  var month = dateFormatZero(d.getMonth()+1);
  var day = dateFormatZero(d.getDate() + n);
  return `${year}-${month}-${day}`;
}

export function getMonthIndexFromName(n){
  for(var i = 0; i < monthNamesLong.length; i++){
    if(monthNamesLong[i].toLowerCase() == n.toLowerCase()){
      return i+1;
    }    
  }
  return(1);
}
