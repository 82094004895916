import React from "react";
import { Route, Switch  } from "react-router-dom";
import Login from "./components/Login.js";
import Main from "./components/Main.js";
import DefaultDashboard from './components/Dashboards/default';

export default function Routes(){

  return (
    <Switch>
      <Route exact path="/home">
        <Main style={{overflowX:"hidden"}}/>
      </Route>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/tavla/default">
        <DefaultDashboard/>
      </Route>
    </Switch>
  )

}
