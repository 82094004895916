import React, {useState, useEffect} from 'react';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import axios from "axios"
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {
  LineChart, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Line,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ComposedChart,
  Bar,
  Tooltip
} from 'recharts';

import { 
  formatDateNormal, 
  formatDateTime,
  currentMonthPeriod,
  getMonday,
  getDaysOfWeekList,
  getCurrentDay,
  getDaysAhead,
  getMonthPeriod
} from '../../shared/dateHelper.js';

import {makeStyles} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import Payments from '../../shared/Payments';

import {
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse
} from "@material-ui/core";

var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
});

const useStyles = makeStyles((theme) => ({
}));

export default function UserSales(params){
  const classes = useStyles();
  const { onRemoveItem } = useMainGridContext();
  const { usersApi } = useAppContext();

  const [primaryLoad, setPrimaryLoad] = useState(true);
  const tmpPeriod = currentMonthPeriod();
  const uname = params.uname;
  const d = new Date();
  const monthPeriod = getMonthPeriod(2021, 6);

  const [data, setData]               = useState([]);
  const [payments, setPayments]       = useState([]);
  const [tot, setTot]                 = useState(0);
  const [totPayments, setTotPayments] = useState(0);
  const [showAll, setShowAll]         = useState(false);
  const [allPayments, setAllPayments] = useState([]);


  const [from, setFrom] = useState(monthPeriod.start);
  const [to, setTo]     = useState(monthPeriod.end);

  const updateDate = () => {
    updateChart();
  }

  const getDayIndex = (day, data) => {
    for(var i = 0; i < data.length; i++){
      if(formatDateNormal(day) === formatDateNormal(data[i].date)){
        return i;
      }
    }
    return -1;
  }
  useEffect(() => {
    var pApi = new Payments(uname);
    axios.get(`https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/users/payments/total/${uname}/${from}/${to}`)
    .then(res => {
      console.log("payments result: ", res.data.recordset);
      setPayments(res.data.recordset);
      res.data.recordset.map(e => {
        setTotPayments(p => p + e.data);
      })
    })
    .catch(err => console.log("error", err));

    pApi.getUser(uname, from, to)
      .then(res => {
        setAllPayments(res.data.recordset);
      })
      .catch(err => console.log("error", err));


    axios.get(`https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/users/person/sold/${uname}/${from}/${to}`)
      .then(res => {
        console.log("data result", res.data.recordset);
        setData(res.data.recordset);
        res.data.recordset.map(e => {
          setTot(p => p + e.data);
        })
      })
      .catch(err => {
        alert("error");
        console.log("error", err);
      })
  },[]);

  const updateChart = () => {
    setTot(0);
    setTotPayments(0);
    var pApi = new Payments(uname);

    axios.get(`https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/users/person/sold/${uname}/${from}/${to}`)
    .then(res => {
      console.log("data result", res.data.recordset);
      setData(res.data.recordset);
      res.data.recordset.map(e => {
        setTot(p => p + e.data);
      })
    pApi.getUser(uname, from, to)
      .then(res => {
        setAllPayments(res.data.recordset);
      })
      .catch(err => console.log("error", err));
    axios.get(`https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/users/payments/total/${uname}/${from}/${to}`)
    .then(res => {
      console.log("payments result: ", res.data.recordset);
      setPayments(res.data.recordset);
      res.data.recordset.map(e => {
        setTotPayments(p => p + e.data);
      })
    })
    .catch(err => console.log("error", err));
    })
    .catch(err => {
      alert("error");
      console.log("error", err);
    })
  }

  const isOwner = (body) => {
    return body.username == uname;
  }

  return(
    <Module 
      auths={params.auths} 
      remove={() => onRemoveItem(params.id)}
      ownership={(body) => isOwner(body)}
    >
      <Header color="#00c853" remove={() => onRemoveItem(params.id)}>
        {params.id} Försäljning
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"100%"}}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography>
                Försäljning: {from} till {to}
              </Typography>
              <input type="date" onChange={(e) => setFrom(e.target.value)}/>
              <input type="date" onChange={(e) => setTo(e.target.value)}/>
              <button onClick={() => updateDate()}>Uppdatera</button>
            </Grid>
          </Grid>
          <Grid container style={{paddingLeft:"60px"}}>
            <Grid item xs={4}>
              <Typography variant="overline">
                TOTALT <b>{formatter.format(tot)}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline">
                GENOMSNITT <b>{formatter.format(tot/data.length)}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline">
                INBETALT <b>{formatter.format(totPayments)}</b>
              </Typography>
            </Grid>
          </Grid>
          <div style={{height:"80%", width:"100%"}}>
            <ResponsiveContainer width="100%" height="50%">
              <ComposedChart
                data={data}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <ReferenceLine y={tot/data.length} stroke="red"/>
                <Line type="monotone" dataKey="data" fill={"#000000"} strokeWidth={1}/>
              </ComposedChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height="50%">
              <ComposedChart
                data={payments}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar data={payments} dataKey="data" stackId="b" fill="#17d346"/>
              </ComposedChart>
            </ResponsiveContainer>
            <Button fullWidth onClick={() => setShowAll(p => !p)}>
              Visa inbetalningar
            </Button>
            <Collapse in={showAll}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Kundnummer 
                  </TableCell>
                  <TableCell>
                    OrderId 
                  </TableCell>
                  <TableCell>
                    Betalt 
                  </TableCell>
                  <TableCell>
                    Datum 
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPayments.map(e => (
                  <TableRow>
                    <TableCell>
                      {e.customerid}
                    </TableCell>
                    <TableCell>
                      {e.orderId}
                    </TableCell>
                    <TableCell>
                      {e.amount}
                    </TableCell>
                    <TableCell>
                      {formatDateTime(new Date(e.dateOfPayment))}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </Collapse>
          </div>
        </Grid>
      </Body>
    </Module>
  );
}
