import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';
import _ from 'lodash';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const CustomButton = () => {
  return (
    <Button>
      Lägg till
    </Button>
  )
}

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nyaukt");
  }

  return (
    <React.Fragment>
      <Tooltip title={"Lägg till auktoritet"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({

}));

export default function AllAuths(params){
  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { authsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [auths, setAuths] = useState([]);

  useEffect(() => {
    if(
      (dataFromServer.entity == "authsTable" || auths.length == 0) 
      && authsApi !== null
    ){
      authsApi.getAll().then(res => {
        setAuths(res.data.auths);
      })
    }
  },[dataFromServer, authsApi])

  const columns = [
    {
      name: "name",
      label:"Namn",
      options:{
        customBodyRender: (value, tableMeta, Updatevalue) => {
          return(
            <Button 
              onClick={() => addModule(`::aukt ${value}`)} 
              color="primary"
              variant="outlined"
            >
              {value}
            </Button>
          )
        }
      }
    },
    {
      name: "description",
      label:"Beskrivning",
    }
  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      console.log("rowsDeleted", Object.keys(rowsDeleted.lookup));
      deleteServices(Object.keys(rowsDeleted.lookup));
    },
    customToolbar: () => {
      return (<CustomTableToolbar />)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  const deleteServices = (is) => {
    is.map(i => {
      var name = auths[i].name;
      authsApi.delete(name).then(res => notify("info", "Auktoritet har blivit borttagen."));
    });
  }

  return(
    <Module 
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Alla auktoriteter
      </Header>
      <Body>
        <Grid container>
          <div style={{width: "100%"}}>
            <MUIDataTable
              size="small"
              title={"Auktoriteter"}
              data={auths}
              columns={columns}
              options={options}
              style={{width: "100%"}}
            />
          </div>
        </Grid>
      </Body>
    </Module>
  )
}
