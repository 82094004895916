import axios from 'axios';
import config from './apiConfig.js';

export default class UserAdmin{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "9dx6zat0c5";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.userAdmin_api_key;
  }

  async isActive(uname){
    return axios.get(`${this.endpoint}/user/isActive/${uname}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/admin/create/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async deletePerma(uname){
    return axios.get(`${this.endpoint}/user/delete/cognito/${uname}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async transferCustomers(o, n){
    return axios.get(`${this.endpoint}/user/transfer/customers/${o}/${n}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async transferOrders(o, n){
    return axios.get(`${this.endpoint}/user/transfer/orders/${o}/${n}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async transferServices(o, n){
    return axios.get(`${this.endpoint}/user/transfer/services/${o}/${n}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getServices(user, s, f){
    return axios.get(`${this.endpoint}/user/read/services/${user}/${s}/${f}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async getOrders(user, s, f){
    return axios.get(`${this.endpoint}/user/read/orders/${user}/${s}/${f}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async getCustomers(user, s, f){
    return axios.get(`${this.endpoint}/user/read/customers/${user}/${s}/${f}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async getCustomerOrders(user, s, f){
    return axios.get(`${this.endpoint}/user/read/customers/orders/${user}/${s}/${f}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateCognitoAuth(uname, a){
    return axios.get(`${this.endpoint}/user/update/cognito/auth/${uname}/${uname}/${a}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getUserCustomerOrders(uname, s, f){
    return axios.get(`${this.endpoint}/user/get/user/customers/orders/${uname}/${s}/${f}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }


}
