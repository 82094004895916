import axios from 'axios';
import config from './apiConfig.js';

export default class Goals{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "4x5vp52bcl";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.goals_api_key;
  }
  
  async get(){
    return axios.get(`${this.endpoint}/read/goal/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(id){
    return axios.get(`${this.endpoint}/read/specific/${id}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getCol(col, val){
    return axios.get(`${this.endpoint}/read/col/${col}/${val}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getActive(username){
    return axios.get(`${this.endpoint}/read/get/active/${username}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getActiveAll(){
    return axios.get(`${this.endpoint}/read/get/active`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/read/create/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }

  async update(id, col, val){
    return axios.post(`${this.endpoint}/update/${id}/${col}/${val}/${this.user}`, 
      {},
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }

  async delete(id){
    return axios.delete(`${this.endpoint}/delete/${id}`, 
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }

}
