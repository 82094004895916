import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import EntityEvents from '../../shared/EntityEvents.js'
import Recs from '../../shared/Reactions.js'
import { useAppContext } from '../../libs/contextLib';

import {
  Typography,
  Grid,
  Avatar,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   borderTop:`1px solid ${theme.palette.border.primary}`,
   borderBottom:`1px solid ${theme.palette.border.primary}`,
 },
}));

export default function Reactions(params){
  const classes = useStyles();
  const theme   = useTheme();

  const { currentUser } = useAppContext();
  const {eventId} = params;

  const [like, setLike]         = useState(params.like);
  const [angry, setAngry]       = useState(params.angry);
  const [confetti, setConfetti] = useState(params.confetti);
  const [love, setLove]         = useState(params.love);

  const [userReaction, setUserReaction] = useState(null);
  const [history, setHistory] = useState([]);

  const getReaction = (type) => {
    switch(type){
      case "angry":
        return angry;
      case "thumbup":
        return like;
      case "confetti":
        return confetti;
      case "love":
        return love;
    }
  }

  const incReaction = (type, inc) => {
    switch(type){
      case "angry":
        setAngry(p => p+inc);
        break;
      case "thumbup":
        setLike(p => p+inc);
        break;
      case "confetti":
        setConfetti(p => p+inc);
        break;
      case "love":
        setLove(p => p+inc);
        break;
      default:
        break;
    }
  }

  const registerReaction = (reaction, current) => {
    setUserReaction({...userReaction, ["type"]: reaction});
    incReaction(reaction,1);
    if(userReaction == null){
      incrementReaction(reaction, current);
    }
    else{

      const eventsApi = new EntityEvents(currentUser.username);
      const reactionsApi = new Recs(currentUser.username);

      eventsApi
        .updateWhere(eventId, userReaction.type, getReaction(userReaction.type)-1);

      reactionsApi.delete(userReaction.id);
      incReaction(userReaction.type, -1);

      // Only increment the reaction if its a different reaction 
      if(userReaction.type !== reaction){
        incrementReaction(reaction, current);
      }
    }
  }

  const incrementReaction = (reaction, current) => {
    const eventsApi = new EntityEvents(currentUser.username);
    const reactionsApi = new Recs(currentUser.username);

    eventsApi.updateWhere(eventId, reaction, current + 1).then(res => {
      reactionsApi.create(eventId, currentUser.username, reaction, "entityEvents");
    });
  }

  const deleteReaction = () => {
  } 

  useEffect(() => {
    const eventsApi = new EntityEvents(currentUser.username);
    const reactionsApi = new Recs();

    reactionsApi.read(eventId, currentUser.username).then(res => {
      var data = res.data.recordset;
      for(var i = 0; i < data.length; i++){
        if(data[i].creator == currentUser.username){
          setUserReaction(data[i]);
        }
      }
      setHistory(data);
    }).catch(err => console.log(err));
  },[])

  return(
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        {userReaction !== null ?
          <Typography>
            Du har reagerat med {userReaction.type} på detta 
          </Typography>
          :
          null
        }
      </Grid>
      <Grid 
        container 
        item xs={6} 
        alingItems="flex-end" 
        justify="flex-end"
      >
        <Button 
          style={
            userReaction !== null && userReaction.type == "thumbup" ? 
              {fontSize:"24px"}
            :
              {fontSize:"16px"}
          } 
          onClick={() => registerReaction("thumbup", like)}
        >
          {like} 👍
        </Button>
        <Button 
          style={
            userReaction !== null && userReaction.type == "angry" ? 
              {fontSize:"24px"}
            :
              {fontSize:"16px"}
          } 
          onClick={() => registerReaction("angry", angry)}
        >
          {angry} 😡
        </Button>
        <Button 
          style={
            userReaction !== null && userReaction.type == "confetti" ? 
              {fontSize:"24px"}
            :
              {fontSize:"16px"}
          } 
          onClick={() => registerReaction("confetti", confetti)}
        >
          {confetti} 🎉
        </Button>
        <Button 
          style={
            userReaction !== null && userReaction.type == "love" ? 
              {fontSize:"24px"}
            :
              {fontSize:"16px"}
          } 
          onClick={() => registerReaction("love", love)}
        >
          {love} 😂 
        </Button>
      </Grid>
    </Grid>
  )
}
