export const formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
})

export const formatNumber = new Intl.NumberFormat();

/**
 * Recieved a string in a currency format: X.XXX,XX kr and returns
 * an Integer
**/
export const unFormatInteger = (str) => {
 if(str !== null && str !== 0){
  if(str.charAt(0) == '0'){
    return str.slice(0,-3).replace("\u00A0", "").replace(",", ".");
  }
  else{
    return str.slice(0,-6).replace("\u00A0", "").replace(".", "");
  }
 }
 return 0;
}

/**
 * Recieved a string in a currency format: X.XXX,XX kr and returns
 * an float 
**/
export const unFormatFloat = (str) => {
 if(str !== null){
  return str.slice(0,-3).replace(".", "").replace(",",".");
 }
  return 0;
}


export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
