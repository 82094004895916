import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';

import axios from 'axios';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  Fade,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";

import {MdUpdate} from "react-icons/md";
import successGif from "../../assets/success.gif";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  editableText:{
    "&:hover":{
      cursor: "pointer",
      backgroundColor: theme.palette.select.primary
    }
  }
}));

export function EditableCounter(params){

  var theme = useTheme();
  if(params.theme !== undefined){
    theme = params.theme()
  }

  const useStyles = makeStyles((theme) => ({
    editableText:{
      "&:hover":{
        cursor: "pointer",
        backgroundColor: theme.palette.select.primary
      }
    }
  }));

  const classes = useStyles();
  const style = params.style;

  const { notify } = useMainGridContext();

  const value = params.value;
  const updateCallback = params.updateCallback;
  const updatable = params.updatable;

  const [update, setUpdate] = useState(false);
  const [currentValue, setCurrentValue] = useState(params.value);
  const [darkBg, setDarkBg] = useState(false);

  const updateValue = () => {
    if(currentValue !== value){
      updateCallback(currentValue).then((res) => {
        notify("success", "Värde uppdaterat");
      }).catch(err => {
        notify("error", `Kunde inte uppdatera värde. Felmeddelande: ${err}`);
      });
    }
    setUpdate(false);
  }

  const handleKeyPress = (e) => {
    if(e.key == "Enter"){
      updateValue();
    }
  }

  const authorise = () => {
    if(!updatable){
      notify("error", "Du har inte behörighet att uppdatera denna module");
    }
    else{
      setUpdate(true);
    }
  }

  return(
    <div style={{width:"100%"}}>
      {!update ? 
        <Typography 
          onClick={() => authorise()} 
          onMouseEnter={() => setDarkBg(true)}
          onMouseLeave={() => setDarkBg(false)}
          className={classes.editableText}
          style={style !== undefined ? style : null}
          variant="subtitle2"
        >
          {currentValue}
        </Typography>
      :
        <TextField
          style={{width:"100%"}}
          autoFocus
          onBlur={() => updateValue()}
          type="number"
          label="Antal"
          size="small"
          defaultValue={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      }
    </div>
  )
}

export function EditableSelect(params){
  var theme = useTheme();
  if(params.theme !== undefined){
    theme = params.theme()
  }

  const useStyles = makeStyles((theme) => ({
    editableText:{
      "&:hover":{
        cursor: "pointer",
        backgroundColor: theme.palette.select.primary
      }
    }
  }));
  const classes = useStyles();

  const { notify } = useMainGridContext();
  const updatable = Object.keys(params).includes("updatable") ?
    params.updatable
    :
    true;

  const dbLabel = params.dbLabel;
  const id = params.id;
  const apiUrl = params.url;
  const align = params.align;
  const list = params.list

  const [value, setValue] = useState(params.value);
  const [updLoading, setUpdLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [update, setUpdate] = useState({"old": value, "new": value});
  const [open, setOpen] = useState(true);
  
  useEffect(() => {
    setValue(params.value);
    setOpen(true);
  },[params.value])

  const handleKeyDown = (event) => {
    var value = event.target.value; 
    if(event.key == "Enter"){
      hideTextField();
    }
  }

  const handleChange = (event) => {
    setOpen(false);
    setUpdate({"old": value, "new": event.target.value});
    // What ?
    setTimeout(() => setShowTextfield(false), 10);

    updateValue(event.target.value).then(res => {
      setValue(event.target.value);
      setSuccess(true);
      notify("success", "Värde har uppdaterats");
      setUpdLoading(false);
    });
  } 

  const updateValue = (val) => {
    setUpdLoading(true);
    return params.updateFunction(val);
  }

  const hideTextField = (event) => {
    setUpdate({"old": value, "new": event.target.value});
    setValue(event.target.value);
    event.currentTarget.blur();
    updateValue(event.target.value).then(res => {
      setSuccess(true);
      setUpdLoading(false);
    });
  }

  const renderValue = () => {
    if(showTextfield){
      return(
        <Select
          onChange={e => handleChange(e)} 
          style={{width:"100%"}}
          value={value} 
          onBlur={() => setShowTextfield(false)}
          open={open}
        >
          {list.map(elem => {
            return(
              <MenuItem value={elem}>
                {elem}
              </MenuItem>
          )
          })}
        </Select>
      )
    }
    if(success){
      setTimeout(() => setSuccess(false), 500);
      return <img src={successGif} style={{width:"20px"}}/>
    }
    if(updLoading){
      return(<CircularProgress size={20}/>)
    }
    return (
      <Typography variant="subtitle2">
        {value}
      </Typography>
    )
  }

  const showTextField = () => {
    if(updatable){
      setShowTextfield(true); 
      setOpen(true);
    }
    else{
      notify("error", "Du har inte behörighet att uppdatera denna modulen");
    }
  }

  return(
    <Grid container direction="row"
      onMouseEnter={() => setShowIcon(true)} 
      onMouseLeave={() => setShowIcon(false)}
      onClick={() => {showTextField()}}
    >
      <Grid item xs={10}>
        {renderValue()}
      </Grid>
      <Grid container xs={2}>
        {showIcon ? <Fade in={showIcon}><MdUpdate /></Fade> : null}
      </Grid>
    </Grid>
  );

}

export function EditableText(params){
  var theme = useTheme();
  if(params.theme !== undefined){
    theme = params.theme()
  }

  const useStyles = makeStyles((theme) => ({
    editableText:{
      "&:hover":{
        cursor: "pointer",
        backgroundColor: theme.palette.select.primary
      }
    }
  }));
  const classes = useStyles();

  const { notify } = useMainGridContext();

  const updatable = params.updatable;

  const dbLabel = params.dbLabel !== undefined ? params.dbLabel : null;
  const id      = params.id !== undefined ? params.id : null;
  const apiUrl  = params.url !== undefined ? params.url : null;
  const align   = params.align !== undefined ? params.align : "";
  const variant = Object.keys(params).includes("variant") ? 
    params.variant
    :
    "p";
  const onUpdate = Object.keys(params).includes("onUpdate") ? 
    params.onUpdate
    :
    null;

  const formatter = params.formatter;

  const [value, setValue]                 = useState(params.value == null || params.value.length == 0 ? "INGET" : params.value);
  const [updLoading, setUpdLoading]       = useState(false);
  const [success, setSuccess]             = useState(false);
  const [showIcon, setShowIcon]           = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [hasChanged, setHasChanged]       = useState(false);
  const [update, setUpdate]               = useState({"old": value, "new": value});
  
  useEffect(() => {
    if(params.value == null || params.value.length == 0){
      setValue("...");
    }
    else{
      setValue(params.value);
    }
  },[params.value])

  const handleKeyDown = (event) => {
    var value = event.target.value; 
    if(event.key == "Enter"){
      hideTextField();
    }
  }

  const handleChange = (event) => {
    setUpdate({"old": value, "new": event.target.value});
  } 

  const updateValue = () => {
    setUpdLoading(true);
    if(Object.keys(params).includes("onUpdate")){
      return onUpdate(update.new);
    }
    else{
      return axios.post(apiUrl + update.new);
    }
  }

  const hideTextField = () => {
    if(update.old !== update.new){
      try{
        updateValue().then(res => {
          setValue(update.new);
          setSuccess(true);
          setUpdLoading(false);
          notify("success", `Upddatering har genomförts.`);
        })
        .catch(err => {
          notify("error", `Kunde inte uppdatera värde. Felmeddelande: ${err}`)
        });
      }
      catch(err){
        notify("error", `updateValue() funktion måste vara en Promise. ${err}`);
        setUpdLoading(false);
      }
    }
    setShowTextfield(false);
  }


  const authorise = () => {
    if(!updatable){
      notify("error", "Du har inte behörighet att uppdatera denna module");
    }
    else{
      setShowTextfield(true);
    }
  }

  const renderValue = () => {
    if(showTextfield){
      return(
        <TextField 
          onBlur={hideTextField} 
          style={{width:"100%", align:align}}
          onKeyDown={handleKeyDown} 
          defaultValue={value} 
          autoFocus
          inputProps={
            align.length > 0 && align == "flex-end" ? {
              style:{textAlign:"right"}
            }
            :
              {style:{textAlign:"none"}}
          }
        />
      )
    }
    if(success){
      setTimeout(() => setSuccess(false), 500);
      return <img src={successGif} style={{width:"20px"}}/>
    }
    if(updLoading){
      return(<CircularProgress size={20}/>)
    }
    return (
      <Typography variant={variant}>
        {formatter !== undefined ? formatter(value) : value}
      </Typography>
    )
  }

  return(
    <Grid container direction="row"
      onMouseEnter={() => setShowIcon(true)} 
      onMouseLeave={() => setShowIcon(false)}
      onClick={() => authorise()}
      onChange={handleChange}
      className={classes.editableText}
    >
      <Grid item xs={12} container alignItems={align} justify={align}>
        {renderValue()}
      </Grid>
    </Grid>
  );
 }
 
export function EditableAutocomplete(params){
  var theme = useTheme();
  if(params.theme !== undefined){
    theme = params.theme()
  }

  const useStyles = makeStyles((theme) => ({
    editableText:{
      "&:hover":{
        cursor: "pointer",
        backgroundColor: theme.palette.select.primary
      }
    }
  }));

  const classes = useStyles();

  const { notify } = useMainGridContext();

  const nameCol = params.nameCol !== undefined ? params.nameCol : "name";
  const dbLabel = params.dbLabel !== undefined ? params.dbLabel : null;
  const id = params.id !== undefined ? params.id : null;
  const apiUrl = params.url !== undefined ? params.url : null;
  const updateFunction= params.updateFunction; 
  const align = params.align !== undefined ? params.align : null;
  const list = params.list
  const updatable = Object.keys(params).includes("updatable") ?
    params.updatable 
    : 
    true

  const [value, setValue]                 = useState(params.value);
  const [updLoading, setUpdLoading]       = useState(false);
  const [success, setSuccess]             = useState(false);
  const [showIcon, setShowIcon]           = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [hasChanged, setHasChanged]       = useState(false);
  const [update, setUpdate]               = useState({"old": value, "new": value});
  const [open, setOpen]                   = useState(true);
  
  useEffect(() => {
    setValue(params.value);
  },[params.value])

  const isValue = (v) => {
    for(var i = 0; i < params.list.length; i++){
      if(Object.values(params.list[i]).includes(v)){
        return true;
      }
    }
    return false;
  }

  const handleChange = (event) => {
    const v = event.target.value;
    if(isValue(v)){
      execUpdate(v);
    }
    else{
      setShowTextfield(false);
    }
  } 

  const execUpdate = (newVal) => {
    if(newVal !== null){
      newVal = newVal[nameCol];
      setOpen(false);
      setUpdate({"old": value, "new": newVal});
      // What ?
      setTimeout(() => setShowTextfield(false), 10);

      params.updateFunction(newVal).then(res => {
        setValue(newVal);
        setSuccess(true);
        notify("success", "Värde har uppdaterats");
        setUpdLoading(false);
        if(Object.keys(params).includes("updateParent")){
          params.updateParent();
        }
      });
    }
  }

  const updateValue = (val) => {
    setUpdLoading(true);
    return params.updateFunction(val);
  }

  const handleKeyDown = (event) => {
    if(event.key == "Enter"){
      handleChange(event);
    }
  }

  const renderValue = () => {
    if(showTextfield){
      return(
        <Autocomplete
          options={list}
          getOptionLabel={(option) => option[nameCol]}
          renderInput={(params) => <TextField autoFocus {...params} label={value} onKeyDown={e => handleKeyDown(e)} onBlur={() => setShowTextfield(false)}/>}
          style={{width:"100%"}}
          size="small"
          onChange={(e,v) => execUpdate(v)}
        />
      )
    }
    if(success){
      setTimeout(() => setSuccess(false), 500);
      return <img src={successGif} style={{width:"20px"}}/>
    }
    if(updLoading){
      return(<CircularProgress size={20}/>)
    }
    return (
      <Typography variant="subtitle2">
        {value}
      </Typography>
    )
  }

  const openTextField = () => {
    if(updatable){
      setShowTextfield(true); 
      setOpen(true);
    }
    else{
      notify("error", "Du har inte behörighet att uppdatera denna modulen");
    }
  }

  return(
    <Grid container direction="row"
      onMouseEnter={() => setShowIcon(true)} 
      onMouseLeave={() => setShowIcon(false)}
      onClick={() => {openTextField()}}
    >
      <Grid item xs={11}>
        {renderValue()}
      </Grid>
      <Grid container xs={1}>
        {showIcon ? <Fade in={showIcon}><MdUpdate /></Fade> : null}
      </Grid>
    </Grid>
  );

}


