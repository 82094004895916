import axios from 'axios';
import config from './apiConfig.js';

export default class Products{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "xfrp2fhfnc";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.products_api_key;
  }
  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getAllDetailed(){
    return axios.get(`${this.endpoint}/read/detailed`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getAllCategories(){
    return axios.get(`${this.endpoint}/get/category/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getCategory(name){
    return axios.get(`${this.endpoint}/get/category/name/${name}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(pid){
    return axios.get(`${this.endpoint}/read/${pid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/read/where/${col}/${val}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getKeyFigures(name){
    return axios.get(`${this.endpoint}/read/keyNumbers/${name}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateWhere(oid, col, val){
    return axios.post(`${this.endpoint}/update/${oid}/${col}/${val}/${this.user}`,
      {},
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async categoryUpdateWhere(id, col, val){
    return axios.get(`${this.endpoint}/update/category/${id}/${col}/${val}/${this.user}`,
  {headers: {
    'x-api-key': this.apiKey
  }}
 );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async createCategory(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/category`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async deleteCategory(id){
    return axios.delete(`${this.endpoint}/delete/category/${id}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async delete(pid){
    return axios.delete(`${this.endpoint}/delete/${pid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

