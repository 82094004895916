import React, { useState, useEffect } from 'react';

import Users from '../../shared/Users.js';
import { useAppContext } from '../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import {getTimeFromDate} from '../../shared/dateHelper.js';
import { useComsContext } from '../../libs/comsContextLib';

import { HotKeys, GlobalHotKeys } from 'react-hotkeys';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  Typography,
  Tooltip,
  Grid
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const keyMap = {
  TOGGLE_ALL_LOGIN: "shift+s"
}

const useStyles = makeStyles((theme) => ({
  userProfileAvatar: {
    marginTop:"20px", 
    cursor: "pointer",
    zIndex:4
  },
  userSlideInComponent:{
    position:"absolute", 
    backgroundColor: theme.palette.background.paper, 
    width:"400px",
    height:"55px",
    right: "0px",
    marginTop:"-45px",
    boxShadow: `1px 1px 5px 1px #${theme.palette.background.default}`,
    zIndex:3,
    borderTopLeftRadius:"5px", 
    borderBottomLeftRadius:"5px", 
    padding: "10px",
    paddingTop: "0"
  }
}))

function UserProfile(params){
  const classes = useStyles(); 

  var addModule = params.addModule;
  var user = params.user;
  
  const [showSpecs, setShowSpecs] = useState(params.active);

  useEffect(() => {
    setShowSpecs(params.active);
  },[params.active]);

  return(
    <div 
      onMouseEnter={() => setShowSpecs(true)} 
      onMouseLeave={() => setShowSpecs(false)}
    >
      <Avatar 
        className={classes.userProfileAvatar} 
        onClick={() => addModule(`::anv ${user.username}`)}
        src={`https://vasa.s3.us-east-2.amazonaws.com/${user.profileMedia}`}
        alt={user.username}
      />
      <Slide in={showSpecs} direction="left">
        <div className={classes.userSlideInComponent}>
          <Grid container>
            <Grid item xs={6} style={{margin:0, padding:0}}>
              <Typography variant="overline" style={{margin:0, padding:0}}>
                {user.authority} 
              </Typography>
            </Grid>
            <Grid item xs={6} style={{margin:0, padding:0}}>
              <Typography variant="overline" style={{margin:0, padding:0}}>
                Loggade in: {getTimeFromDate(user.lastLogin)} 
              </Typography>
            </Grid>
            <Grid item xs={6} style={{margin:0, padding:0}}>
              <Typography variant="p" style={{margin:0, padding:0}}>
                <b>{user.fullName}</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Slide>
    </div>
  )
}

export default function LoggedInSidebar(params){

  const classes = useStyles(); 
  const theme = useTheme();

  const addModule = params.addModule;
  
  const [loggedInUsers, setLoggedInUsers] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const { currentUser, moduleUtils, setModuleUtils} = useAppContext();
  const { dataFromServer } = useComsContext();

  const [firstRender, setFirstRender] = useState(true);


  useEffect(() => {
    if(firstRender || dataFromServer.entity == "FORCE_LOGOUT"){
      var usersApi = new Users(currentUser.username);
      usersApi.getWhere("loggedIn", "1").then(res => {
        setLoggedInUsers(res.data.recordset);
      });
    }
  },[dataFromServer]);

  const handlers = {
    TOGGLE_ALL_LOGIN: () => {
      if(showAll){
        setShowAll(false);
      }
      else{
        setShowAll(true);
      }
    }
  }

  return(
    <div style={{
      position:"absolute", 
      height:"100vh", 
      width:"50px", 
      right:0,
      top:"35px",
      right:"20px",
      zIndex:4
      }}
    >
      <HotKeys keyMap={keyMap} handlers={handlers}>
        {showAll ?
          <Fade in={showAll}>
            <div style={{marginTop:"40px"}}>
              <Tooltip title="Inloggade">
                <Avatar 
                  className={classes.userProfileAvatar} 
                  onClick={() => setShowAll(false)}
                  style={{
                    backgroundColor:"#00c853"
                  }}
                >
                  <CloseIcon />
                </Avatar>
              </Tooltip>
              {loggedInUsers.map(e => {
                return(
                  <Tooltip title={e.fullName}>
                    <UserProfile 
                      user={e} 
                      active={showUser} 
                      addModule={(c) => addModule(c)} 
                    />
                  </Tooltip>
                );
              })
              }  
              <Avatar className={classes.userProfileAvatar}
                onClick={() => setShowUser(prev => !prev)}
              >
                {!showUser ?
                  <VisibilityIcon />
                :
                  <VisibilityOffIcon />
                }
              </Avatar>
            </div>
          </Fade>
        :
        <Tooltip title="Inloggade">
          <Avatar 
            onClick={() => setShowAll(true)} 
            className={classes.userProfileAvatar}
            style={{
              backgroundColor:"#00c853",
              borderRadius:"5px",
              padding:"25px",
              marginTop:"40px"
            }}
          > 
            {loggedInUsers.length}
          </Avatar>
        </Tooltip>
        }
      </HotKeys>
    </div>
  )
}
