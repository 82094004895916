import axios from 'axios';
import config from './apiConfig.js';

export default class Auths{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "lkb030fjyl";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.auths_api_key;
  }
  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(name){
    return axios.get(`${this.endpoint}/read/${name}/${this.user}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async delete(name){
    return axios.delete(`${this.endpoint}/delete/${name}/${this.user}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async getFunctionality(authName){
    return axios.get(`${this.endpoint}/get/functionality/${authName}`,
      {headers:{
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, 
      this.requestTemplate,
      {headers:{
        'x-api-key': this.apiKey
      }}
  );
  }
}

