import React, { useState, useEffect } from 'react';
import { MainGridContext } from '../../libs/mainGridContextLib.js';
import ModuleUtils from '../../shared/ModuleUtils.js';
import { useAppContext } from '../../libs/contextLib';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../general/notifications.js';
import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { textParser } from '../../shared/text/parse';
import {getTimeFromDate, formatDate, getWeekPeriod, getWeek, getMonthPeriod} from '../../shared/dateHelper.js';
import { formatter, unFormatInteger } from '../general/format.js';
import { useComsContext } from '../../libs/comsContextLib';

import axios from 'axios';

import DataStreams from '../../shared/DataStreams';
import Challenges from '../../shared/Challenges';
import OfficePrefs from '../../shared/OfficePrefs';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Users from '../../shared/Users.js';

import { 
  formatDateNormal, 
  formatDateTime
} from '../../shared/dateHelper.js';

import { blue } from '@material-ui/core/colors';

import {
  LineChart, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Line,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  Brush,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
  Tooltip 
} from 'recharts';

import { 
  getCurrentDay,
  getDaysAhead,
} from '../../shared/dateHelper.js';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
} from '@material-ui/core';

import {
  IconButton, 
  Typography,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Tabs,
  Tab,
  Slide,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    background:theme.palette.background.paper, 
    padding:"50px", 
    height:"250px", 
    borderTopRightRadius:"10px", 
    borderBottomRightRadius:"10px",
    boxShadow: theme.palette.type == "light" ? "1px 1px 1px 1px #dedede" : "1px 1px 5px 1px #000000",
    zIndex:100,
    cursor: "pointer",
    '&:hover':{
      boxShadow: "1px 1px 5px 1px #e685ff",
    }
  }
}))

function ChallengeHolders(params){
  var [holders, setHolders] = useState([]);

  useEffect(() => {
    if(params.challenge.length > 0 && params.challenge[0].holders !== undefined && params.challenge[0].holders.length > 0){
      setHolders(params.challenge[0].holders);
    }
  },[params])

  return(
    <div style={{position:"relative"}}>
      <div style={{position:"absolute", background:"rgba(255,255,255,0.1)", borderRadius:"10px", padding:"20px", overflow:"auto"}}>
        {holders.map((e,i) => (
          <Typography key={i}>
            {e}
          </Typography>
        ))}
      </div>
    </div>
  )
}

function Leaderboard(params){
  const classes = useStyles(); 
  const theme = useTheme();
  const {dataFromServer} = useComsContext();

  const { currentUser } = useAppContext();

  const [allUsers, setAllUsers]     = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [userUpdate, setUserUpdate] = useState("");
  const [maxList, setMaxList]       = useState([]);
  const [topList, setTopList]       = useState([]);
  const [from, setFrom]             = useState(getCurrentDay());
  const [to, setTo]                 = useState(getDaysAhead(1));
  const [interval, setInterval]     = useState("d");
  const [intervalMapping, setIntervalMapping] = useState({"d":"Dag", "w":"Vecka", "m":"Månad"})

  const [intervalCount, setIntervalCount] = useState(0);

  useEffect(() => {
    var uApi = new Users(currentUser.username);
    uApi.getAll().then((res) => {
      setAllUsers(res.data.recordset);
    })

    var requestTemplate =  {
      "body": {"from": from, "to": to},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    }

    axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/group/sold/price", requestTemplate).then(res => {
      setTopList(res.data.recordset.filter(e => e.salesman.toLowerCase() !== "julia.sayah"));
      setLastUpdate(new Date());
      setUserUpdate("");
    }).catch(err => console.log("error", err));

    axios.get("https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/office/max").then(res => {
      setMaxList(res.data.recordset);
    });

  },[])

  useEffect(() => {
    if(dataFromServer.entity == "orders" && dataFromServer.type == "CREATE"){
      var from = getCurrentDay();
      var to = getDaysAhead(1);
      getTopList(from, to);
    }
  },[dataFromServer])

  const getMax = (u, data) => {
    var max = maxList.filter(m => m.salesman !== null && u !== null ? u.toLowerCase() == m.salesman.toLowerCase(): 0);
    if(max[0] !== undefined){
      if(Number(unFormatInteger(data)) === Math.floor((max[0].max))){
        return (
          <Grid container>
            <Typography>
              {formatter.format(max[0].max)}
            </Typography>
            <Typography style={{
              background:"linear-gradient(to bottom left, #f48b29 5%, #f43729 70%)", 
              borderRadius:"20px", 
              padding:"5px", 
              fontSize:"12px", 
              color:"white", 
              marginLeft:"10px"
              }}>
              <b>NYTT!</b>
            </Typography>
          </Grid>
        );
      }
      else{
        return (
          <Typography>
            {formatter.format(max[0].max)}
          </Typography>
        );
      }
    }
    else{
      return <Typography>0</Typography>;
    }
  }

  const getTopList = (from, to) => {
    var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }
      axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/group/sold/price", requestTemplate).then(res => {
        setTopList(res.data.recordset.filter(e => e.salesman.toLowerCase() !== "julia.sayah"));
        setLastUpdate(new Date());
      }).catch(err => console.log("error", err));
  }

  const selectDay = () => {
    setInterval("d");
    setFrom(getCurrentDay());
    setTo(getDaysAhead(1));
    getTopList(getCurrentDay(), getDaysAhead(1)); 
  }

  const selectWeek = () => {
    setInterval("w");
    setIntervalCount(getWeek(new Date));
    var weekNum = getWeek(new Date());
    var week = getWeekPeriod(2021, weekNum);
    setFrom(week.start);
    setTo(week.end);
    getTopList(week.start, week.end); 
  }

  const selectMonth = () => {
    setInterval("m");
    setIntervalCount((new Date()).getMonth()+1);
    var month = getMonthPeriod(2021, (new Date()).getMonth()+1);
    setFrom(month.start);
    setTo(month.end);
    getTopList(month.start, month.end); 
  }

  const mvInterval = (i) => {
    setIntervalCount(p => p + i);

    var from = null;
    var to = null;
    switch(interval){
      case "d":
        if(i < 0){
          from = (getDaysAhead(intervalCount+i));
          to = (getDaysAhead(intervalCount));
        }
        else{
          from = getDaysAhead(intervalCount);
          to = getDaysAhead(intervalCount+i);
        }
        break;
      case "w":
        var p = getWeekPeriod(2021, intervalCount+i)  
        from = p.start;
        to = p.end;
        break;
      case "m":
        var p = getMonthPeriod(2021, intervalCount+i)  
        from = p.start;
        to = p.end;
        break;
      default:
        break;
    }

    setFrom(from);
    setTo(to);
    getTopList(from, to);
  }

  return(
    <Grid container style={{background:theme.palette.background.default, borderRadius:"5px", padding:"50px"}}>
      <Grid container item xs={12} style={{marginBottom:"50px"}}>
        <Grid item xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={5}>
              <Typography variant="h2">
                <b>Ledarlista <span style={{color:"#2c53c9"}}>{intervalMapping[interval]}</span></b>
              </Typography>
            </Grid>
            <Grid item xs={7} style={{paddingTop:"25px"}}>
              <Typography variant="h5">
                <Button onClick={() => mvInterval(-1)}>{'<'}</Button>{from} - {to}<Button onClick={() => mvInterval(1)}>{'>'}</Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={2} style={{
              borderLeft:`1px solid ${theme.palette.border.primary}`, 
              borderTop:`1px solid ${theme.palette.border.primary}`, 
              borderBottom:`1px solid ${theme.palette.border.primary}`}}
            >
              <Button onClick={() => selectDay()}>
                D
              </Button>
            </Grid>
            <Grid item xs={2} style={{
              borderTop:`1px solid ${theme.palette.border.primary}`, 
              borderBottom:`1px solid ${theme.palette.border.primary}`}}
            >
              <Button onClick={() => selectWeek()}>
                V
              </Button>
            </Grid>
            <Grid item xs={2} style={{
              borderTop:`1px solid ${theme.palette.border.primary}`, 
              borderBottom:`1px solid ${theme.palette.border.primary}`,
              borderRight:`1px solid ${theme.palette.border.primary}`,
              }}
            >
              <Button onClick={() => selectMonth()}>
                M
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid item xs={1} style={{paddingBottom:"10px"}}>
        <b>#</b> 
      </Grid>
      <Grid item xs={4} style={{paddingBottom:"10px"}}>
        <b>Användare</b>
      </Grid>
      <Grid item xs={4} style={{paddingBottom:"10px"}}>
        <b>Sålt idag</b>
      </Grid>
      <Grid item xs={3}style={{paddingBottom:"10px"}}>
        <b>Dagsrekord</b>
      </Grid>
      {topList.map((e,i) => {
        return(
          <Grid container key={i}>
            <Grid item xs={1}>
              <Typography>
                {i+1}
              </Typography>
            </Grid>
            <Grid container item xs={4}>
              <UserProfile users={allUsers} user={e.salesman}/>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {e.data} 
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {getMax(e.salesman, e.data)} 
            </Grid>
          </Grid>
      )})}
    </Grid>

  )
}

function BarChartCustom(params){
  const [dataStream, setDataStream] = useState(params.dataStream);
  const [data, setData] = useState([]);
  const { currentUser }           = useAppContext();
  const [color, setColor]   = useState(params.color);
  const [comparator, setComparator] = useState([]);
  const [comparatorData, setComparatorData] = useState([]);

  const getData = (ds, requestTemplate) => {
    axios.post(ds.url, requestTemplate).then(res => {
      setData(res.data.recordset);
      var sum = 0;
      var count = 0;
      res.data.recordset.map((o) => o.data > 100 ? sum += o.data: null);
      res.data.recordset.map((o) => o.data > 100 ? count++:null);
      console.log("res", res.data.recordset);
      console.log(`mean ${dataStream}:`, sum/count);
      console.log("tot", sum);
    }).catch(err => console.log("Could not fetch data"));
  }

  const getDataComparator = (ds, compURL, requestTemplate) => {
    axios.post(ds.url, requestTemplate).then(res => {
      var vanillaData = (res.data.recordset);
      axios.post(compURL.url, requestTemplate).then(res2 => {
        var result = [];
        var compData = res2.data.recordset; 
        for(var i = 0; i<vanillaData.length; i++){
          var p = vanillaData[i].data/compData[i].data;
          if(p > 1){
            result.push({"date": vanillaData[i].date, "data": 0});
          }
          else{
            result.push({"date": vanillaData[i].date, "data": p});
          }
        }
        setData(result);
      })
    }).catch(err => console.log("Could not fetch data"));
  }
  

  useEffect(() => {
    const ds = new DataStreams(currentUser.username);

    ds.getAll().then(res => {
      var compURL = null;
      if(dataStream == "makintervall"){
        compURL = (res.data.modules.filter((e) => {return e.name =="dagsäljfsg"})[0]);
      }
      const streamObj = res.data.modules.filter((e) => {return e.name == dataStream})[0];

      var requestTemplate =  {
        "body": params.body,
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }    
      if(dataStream == "makintervall"){
        getDataComparator(streamObj, compURL, requestTemplate);
      } 
      else{
        getData(streamObj, requestTemplate);
      }

    })
  },[])

  return(
    <Grid container>
      {params.align == "left" ?
        <>
          <Grid item xs={7}>
            <div style={{height:`${params.height}`}}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="data" stackId="a" fill={color} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5" style={{paddingLeft:"50px"}}>
              {params.title}
            </Typography>
            {params.text.split("<br>").map((e,i) => (
              <Typography key={i} variant="body1" style={{paddingTop:"10px", paddingLeft:"50px"}}>
                {e}
              </Typography>
            ))}
          </Grid>
        </>
        :
        <>
          <Grid item xs={5}>
            <Typography variant="h5" style={{paddingLeft:"50px"}}>
              {params.title}
            </Typography>
            {params.text.split("<br>").map((e,i) => (
              <Typography variant="body1" key={i} style={{paddingTop:"10px", paddingLeft:"50px"}}>
                {e}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={7}>
            <div style={{height:`${params.height}`}}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="data" stackId="a" fill={color} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
        </>
      }
    </Grid>
  )
}

function TopSalesman(params){

  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const { currentUser } = useAppContext();
  const [user, setUser] = useState("");
  const [profile, setProfile] = useState("");
  const [allUsers, setAllUsers]     = useState([]);

  useEffect(() => {
    var uApi = new Users(currentUser.username);
    uApi.getAll().then((res) => {
      setAllUsers(res.data.recordset);
      setUser(params.user)
      if(params.user !== null){
        var u = res.data.recordset.filter(e => e.username.toLowerCase() == params.user.toLowerCase())[0];
        if(u !== undefined){
          setProfile(u.profileMedia);
        }
      }
    })
  },[params.user])
  return(
    <Paper style={{width:"100%", height:"100%", backgroundImage:`url(https://vasa.s3.us-east-2.amazonaws.com/${profile})`, backgroundSize:"cover"}}>
      <div style={{position:"relative"}}>
        <div style={{position:"absolute"}}>
          <Typography variant="h5" style={{margin:"10px", padding:"10px", zIndex:1, color:"white", background:"black", lineHeight:1.0, borderRadius:"10px", opacity:0.7}}>
            <span style={{fontSize:"15px", padding:0, lineHeight:0}}>Dagens Säljare</span> <br />
            <b>{user}</b>
          </Typography>
        </div>
      </div>
      <figure style={{width:"100%", heigth:"100%", overflow:"hidden", margin:0, borderRadius:"10px"}}>
      </figure>
    </Paper>
  )

}

function UserProfile(params){
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [profile, setProfile] = useState("");

  useEffect(() => {
    setUsers(params.users);
    setUser(params.user);
    if(params.user !== null){
      var u = params.users.filter(e => e.username.toLowerCase() == params.user.toLowerCase())[0];
      if(u !== undefined){
        setProfile(u.profileMedia);
      }
    }
  },[params.users, params.user])

  return(
    <>
      <Avatar style={{height:theme.spacing(5), width:theme.spacing(5)}}src={`https://vasa.s3.us-east-2.amazonaws.com/${profile}`}/>
      <Typography variant="body1" style={{paddingLeft:"10px", paddingTop:"8px"}}>
        {user}
      </Typography>
    </>
  )
}

export default function OfficeStats(params){
  const classes = useStyles(); 
  const theme = useTheme();

  const { currentUser }                     = useAppContext();
  const [bestSales, setBestSales]           = useState({"salesman": null, "data": 0});
  const [totSold, setTotSold]               = useState(0);
  const [hourly, setHourly]                 = useState([]);
  const [topList, setTopList]               = useState([]);
  const [maxList, setMaxList]               = useState([]);
  const [allUsers, setAllUsers]             = useState([]);
  const [allChallenges, setAllChallenges]   = useState([]);

  const [message, setMessage]       = useState("");
  const [link, setLink]             = useState("");
  const [activity, setActivity]     = useState("");
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [userUpdate, setUserUpdate] = useState("");

  const [hourlyDs, setHourlyDs] = useState(null);
  const [totSalesDs, setTotSalesDs] = useState(null);

  const {
    dataFromServer, 
  } = useComsContext();

  useEffect(() => {
    const ds = new DataStreams(currentUser.username);
    const challenges = new Challenges(currentUser.username);
    var offPrefs = new OfficePrefs();
    ds.getAll().then(res => {
      const bestUserDs = res.data.modules.filter((e) => {return e.name == "bästa-säljare"})[0];
      const hourlySales = res.data.modules.filter((e) => {return e.name == "tsälj"})[0];
      const totSales = res.data.modules.filter((e) => {return e.name == "kontor-sålt-idag"})[0];

      setHourlyDs(hourlySales);
      setTotSalesDs(totSales);

      var from = getCurrentDay();
      var to = getDaysAhead(1);

      var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }
      // Sales stats
      getBestSales(bestUserDs, requestTemplate);
      getHourly(hourlySales, requestTemplate);
      getTotSales(totSales, requestTemplate); 

      //challenges.readAll().then(res => {
      //  setAllChallenges(res.data.prefs);
      //})
      //.catch(err => {
      //  console.log("Couldn't fetch challenges");
      //})

      // Office preferences
      offPrefs.getLatestMessage().then(res => {
        setMessage(res.data.prefs[res.data.prefs.length-1]);
      }).catch(err => {console.log("error " + err)});

      axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/group/sold/price", requestTemplate).then(res => {
        setTopList(res.data.recordset);
        setLastUpdate(new Date());
        setUserUpdate("");
      }).catch(err => console.log("error", err));

      axios.get("https://ykumcb4nq0.execute-api.us-east-2.amazonaws.com/dev/office/max").then(res => {
        setMaxList(res.data.recordset);
      });

      var uApi = new Users(currentUser.username);
      uApi.getAll().then((res) => {
        setAllUsers(res.data.recordset);
      })

    });
  },[]);

  useEffect(() => {
    if(dataFromServer.entity == "orders" && dataFromServer.type == "CREATE"){
      var from = getCurrentDay();
      var to = getDaysAhead(1);

      var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      }

      getHourly(hourlyDs, requestTemplate);
      getTotSales(totSalesDs, requestTemplate); 

    }
  },[dataFromServer])

  const getBestSales = (ds, requestTemplate) => {
    axios.post(ds.url, requestTemplate).then(res => {
      if(res.data.recordset[0] !== undefined){
        setBestSales(res.data.recordset[0]);
      }
      else{
        setBestSales({"salesman": "INGEN", data:"0"});
      }
    }).catch(err => {setBestSales({"salesman": "Kan inte hämta", data:"0"})});

  }
  const getHourly= (ds, requestTemplate) => {
    if(ds !== null){
      axios.post(ds.url, {}).then(res => {
        setHourly(res.data.recordset);
      }).catch(err => {console.log("error " + err)})
    }
  }
  const getTotSales= (ds, requestTemplate) => {
    axios.get(ds.url, requestTemplate).then(res => {
      if(res.data.recordset[0].data !== undefined){
        setTotSold(res.data.recordset[0].data);
      }
      else{
        setTotSold("INGEN HAR SÅLT");
      }
    }).catch(err => {setBestSales("KAN INTE HÄMTA")});
  }

  const getMax = (u, data) => {
    var max = maxList.filter(m => m.salesman !== null ? u.toLowerCase() == m.salesman.toLowerCase(): 0);
    if(max[0] !== undefined){
      if(Number(unFormatInteger(data)) === Math.floor((max[0].max))){
        return (
          <Grid container>
            <Typography>
              {formatter.format(max[0].max)}
            </Typography>
            <Typography style={{
              background:"linear-gradient(to bottom left, #f48b29 5%, #f43729 70%)", 
              borderRadius:"20px", 
              padding:"5px", 
              fontSize:"12px", 
              color:"white", 
              marginLeft:"10px"
              }}>
              <b>NYTT!</b>
            </Typography>
          </Grid>
        );
      }
      else{
        return (
          <Typography>
            {formatter.format(max[0].max)}
          </Typography>
        );
      }
    }
    else{
      return <Typography>0</Typography>;
    }
  }

  return(
    <Grid container item xs={12} justify="center" alignItems="center" direction="column">
      <Grid container direction="row" item xs={8} spacing={2} style={{marginTop:"300px"}}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Sammanfattning
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TopSalesman user={bestSales.salesman}/>
        </Grid>
        <Grid item xs={9}>
          <Paper style={{
            background:theme.palette.background.paper,
            padding:"25px", 
            borderRadius:"10px"
          }}>
            <Typography variant="h2" style={{paddingTop:"40px", paddingBottom:"20px"}}>
              <b>Dag <span style={{color:"#2c53c9"}}>{totSold}</span></b>
            </Typography>
            <div style={{height:"200px"}}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={hourly}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Bar dataKey="data" stackId="a" fill="#647fea" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} style={{marginTop:"100px", padding:"20px", background:"linear-gradient(to bottom left, #647fea 5%, #4a69e2 70%)", borderRadius:"10px"}}>
            <Leaderboard />
          </Paper>
        </Grid>
        <Grid item xs={12} style={{paddingTop:"50px"}}>
          <Paper elevation={4} style={{marginTop:"100px", padding:"30px", background:"linear-gradient(to bottom left, #e236dd 5%, #5215b5 70%)", borderRadius:"10px"}}>
          <Grid container spacing={2}>
            <Grid container style={{background:theme.palette.background.paper, padding:"75px", paddingTop:"100px", borderRadius:"10px"}}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    <b>Grafer att hålla koll på 🤩</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop:"20px", paddingBottom:"100px"}}>
                  <Typography variant="overline">
                    Makuleringar (som % av den totala försäljningen) 2021 
                  </Typography>
                  <BarChartCustom 
                    dataStream="makintervall" 
                    body={{"from": "2021-01-01", "to": "2021-12-31"}} 
                    align="left"
                    title="Inbetalningar och Makuleringar, är kontorets livskraft👩‍❤️‍👨"
                    text="I slutet av dagen handlar försäljning om att maximera inbetalningar och 
                    minimera makuleringar. Även om försäljningen är ungefär lika hög 
                    (kanske lite högre) än i Januari så har andelen makuleringar gått ner."
                    height="125px"
                    color="#EB7343"
                  />
                  <Typography variant="overline">
                    Inbetalningar 2021 
                  </Typography>
                  <BarChartCustom 
                    dataStream="inbetintervall" 
                    body={{"from": "2021-01-01", "to": "2021-12-31"}} 
                    align="left"
                    title=""
                    text="I Januari varierade mak mellan 30%-60%, i Juni varierade den mellan 20%-40% 
                    vilket fortfarande är ganska högt men mycket bättre än tidigare. 
                    Inbetalningar har gått upp lite men ingenting att skriva hem om. Detta kommer väl att ändras när de nya
                    kunderna börjar betala sina fakturor."
                    height="125px"
                    color="#719E58"
                  />
                  <Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingBottom:"20px"}}>
                  <BarChartCustom 
                    dataStream="dagsäljfsg" 
                    body={{"from": "2021-01-01", "to": "2022-12-31"}} 
                    align="right"
                    title="Hur var 2021 jämfört med 2020?🤪"
                    text="Snitt sålt/dag 2021: 77 624,90 kr. Snitt sålt/dag 2020: 54 718,60 kr. En ökning på 41%! Försäljningen har kanske inte ökat jätte mycket 
                    men den har blivit mer konsistent. Om vi fick liknande ökning detta året måste vi snitta 110 000 kr/dag! 🚀🚀🚀"
                    height="125px"
                    color="#5215b5"
                  />
                  <BarChartCustom 
                    dataStream="dagsäljfsg" 
                    body={{"from": "2020-05-01", "to": "2020-12-31"}} 
                    align="right"
                    title=""
                    text=""
                    height="125px"
                    color="#fe2c54"
                  />

                </Grid>
              </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} style={{marginBottom:"100px", marginTop:"200px"}}>
          <Paper elevation={4} style={{borderRadius:"10px", height:"100%"}}>
            <Grid container>
              <Grid container item xs={12} style={{
                width:"100%",
                borderRadius:"10px",
                padding:"20px",
                }}>
                <Grid item xs={4}>
                  <img style={{width:"100%"}} src="https://vasa.s3.us-east-2.amazonaws.com/public/images/nyet.jpg" />
                </Grid>
                <Grid item xs={8} style={{background:"rgba(0,0,0,0.0)", padding:"40px", borderRadius:"10px"}}>
                    <Typography variant="h5">
                      VASA uppdatering #21: Nykterist uppdateringen
                    </Typography>
                    <Typography style={{height:"100%"}}>
                      <br/>
                      3 år abonemang möjliga på ::nykund
                      <br />
                      <br />
                      Fixa problem med att öppna kunder från ::kunder
                      <br />
                      <br />
                      Lägg till uppsagd status
                      <br />
                      <br />
                      Lägg till signerad på ::order
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
  //<video width="100%" controls loop>
  //  <source src="https://vasa.s3.us-east-2.amazonaws.com/public/images/whale.webm" type="video/webm" />
  //</video>

}
