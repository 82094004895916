// React
import React, { useState, useEffect } from "react";

import Customers from '../../shared/Customers.js';

// Context
import { 
  NewCustomerContext, 
  useCustomerContext 
} from '../../libs/newCustomerContextLib.js';
import { useAppContext } from '../../libs/contextLib';

// HTTP requests
import axios from 'axios';

// Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from "@material-ui/core/Fade"
import ErrorIcon from '@material-ui/icons/Error';
import TextField from '@material-ui/core/TextField';


// Styling 
const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
  },
  textfieldValid:{
    width: '100%',
    margin: theme.spacing(1),
    '& input:valid + fieldset':{
      borderColor: 'green',
      borderWidth: 1,
    }
  },

}));

/**
 * A textfield component that checks if the content is a unique ssn, based
 * on the state of the component show different adornments.
*/

export default function PnrTextField(params){
  const classes = useStyles();
  const theme = useTheme();

  const [idUnique, setIdUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  // ador = adornment
  const [ador, setAdor] = useState(false);
  const { customersApi, currentUser } = useAppContext();

  // JSON, contins text to be displayed when error occures for a specific field 
  const {errorText, customerExists, setCustomerExists} = useCustomerContext();
  // Current info that has been input by user
  const {customerInfo} = useCustomerContext();
  // Funcion to hide texftield error for a specific field
  const {hideValidationError} = useCustomerContext();

  const [pnr, setPnr] = useState(customerInfo.socialSecurityNumber);


  const pnrUnique = (val) => {
    if(val.length > 0){

      setAdor(true);
      setLoading(true);
      
      // Get ssn where its equal to val, if result.len == 0 then id is unique
      var customersApi = new Customers(currentUser.username);
      customersApi.getWhere("socialSecurityNumber", val.replace("-", ""))
        .then(res => {
          var recieved = res.data.recordset;
          setLoading(false);
          if(recieved.length == 0){
            setIdUnique(true);
            setCustomerExists(false);
          }
          else{
            setIdUnique(false);
            setCustomerExists(true);
          }
        }).catch(err => alert("Kan inte hämta"));
    }
  }
  
  // Show adormnet to the left of text adormnet shown is based on current state
  // of component
  const showAdornment = () => {
    if(loading){
      return(
        <InputAdornment position="start">
          <Fade in={loading}>
            <CircularProgress size={20} color='inherit'/>
          </Fade>
        </InputAdornment>
      )

    }
    // When ador == true show adornment based on current idUnique state
    if(idUnique && ador){
      return(
        <InputAdornment position="start">
          <Fade in={idUnique}>
            <CheckIcon style={{color: "green"}}/>
          </Fade>
        </InputAdornment>
      )
    }
    if(!idUnique && ador){
      return(
        <InputAdornment position="start">
          <Fade in={!idUnique}>
            <ErrorIcon style={{color: "#f44336"}}/>
          </Fade>
        </InputAdornment>
      )
    }
  }

  const updatePnr = (e) => {
    params.handleChange('socialSecurityNumber', e)
    const regex = /^([0-9]){0,10}$/i;
    if(regex.test(e.target.value)){
      setPnr(e.target.value)
    }
  }

  return(
    <TextField 
      error={errorText.socialSecurityNumber.length > 0 ? true : false}
      label="Personnummer/Orgnummer" 
      value={pnr}
      className={idUnique ? classes.textfieldValid : classes.textfield}
      style={{marginBottom: "65px"}}
      size="small"
      onChange = {e => updatePnr(e)}
      onBlur={
        (e) => {
          hideValidationError("socialSecurityNumber"); 
          pnrUnique(e.target.value)
      }
      }
      helperText={errorText.socialSecurityNumber}
      InputProps={ador ? {
        startAdornment:(
          showAdornment()
        ),
        inputProps: {tabIndex: 2,}
      }
      :
        {
          startAdornment:(
            showAdornment()
          ),
          inputProps: {tabIndex: 2,}
        }

      }
    /> 
  )
}
