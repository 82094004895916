import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import Sheets from "../Integrations/Google/Sheets"

import { unFormatInteger } from '../general/format.js';

import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';

import MUIDataTable from 'mui-datatables';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';

import VasaDialog from '../../shared/VasaDialog.js';

import { EditableText } from '../general/UpdatableValue.js';

import PdfDocument from '../../shared/pdf/create-pdf.jsx'; 

import Docs from "../../shared/Docs.js";
import Products from "../../shared/Products.js";

import _ from 'lodash';

import { 
  getMonthPeriod, 
  formatDateNormal, 
  currentMonthPeriod,
  formatDateTime,
  getDaysAheadFromDate
} from '../../shared/dateHelper.js';
import { formatter } from '../general/format.js';

import {
  makeStyles, 
  useTheme, 
  MuiThemeProvider, 
  withStyles, 
  createMuiTheme 
} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Button,
  Typography,
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  Fade,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Collapse,
  Box,
  TextField,
  LinearProgress
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import UpdateIcon from '@material-ui/icons/Update';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';

const datePickerTheme = createMuiTheme({
  palette: {
    primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
          },
    secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
    },
  }
})

const useStyles = makeStyles((theme) => ({
  expandableRowHeader: {
    paddingTop:"25px",
  },
  tableOrderSummary: {
    background: theme.palette.background.default,
    padding: "10px",
    borderRadius: "5px"
  }
}));

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nykund");
  }

  return (
    <React.Fragment>
      <Tooltip title={"Lägg till"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

const RenderPdf = (params) => {

  const [customer, setCustomer] = useState({})
  const [ss, setSS]             = useState([]);
  const [order, setOrder]       = useState({});
  const [docMeta, setDocMeta]   = useState([]);
  const [products, setProducts] = useState({});
  const [types, setTypes] = useState({});
  const [currentUrl, setCurrentUrl] = useState(null);
  const [undefinedService, setUndefinedService] = useState(false);

  var show = true;

  const getDocTypeFromService = (service) =>{
    var serviceCategory = params
      .products
      .filter(e => e.name == service.productCode)[0]
      .category;


    var type = params.categories.filter(e => e.name == serviceCategory)
    return type[0]
  }

  useEffect(() => {
    var sundef = false;
    setDocMeta(params.docMeta);
    setOrder(params.order);
    setCustomer(p => params
                      .cInfo
                      .filter((e) => e.customerid == params.order.customerid)[0]
    );

    const tmp = params.sInfo.filter((e) => e.orderNumber == params.order.orderId);
    tmp.map(e => e.price = formatter.format(e.price));

    tmp.map(e => {
      if(params.products.filter(p => p.name == e.productCode)[0] !== undefined){
        e["description"] = params
                         .products
                         .filter(p => 
                           p.name == e.productCode)[0].description
      }
      else{
        setUndefinedService(true);
        sundef = true;
      }
    });
    setSS(tmp);
    
    var ts = [];
    // For each service get the doctype needed by accessing its category
    if(!sundef){ 
      for(var i = 0; i < tmp.length; i++){
        var type = getDocTypeFromService(tmp[i]);
        if(type !== undefined && !ts.includes(type.doc)){
          ts.push(type.doc);
        }
      }
    }

    setTypes(ts);
  },[]);

  useEffect(() => {
    if(currentUrl !== null){
      window.open(currentUrl);
    }
  },[params.openUrl])

  return(
    <>
          <Grid container>
            <Grid item xs={4}>
              <p>orderId: {order.orderId}</p>
            </Grid>
            <Grid item xs={4}>
              {customer !== undefined ?
              <p>Namn: {customer.name}</p>
              :
              <p>Ingen kund hittad</p>
              }
              {undefinedService ?
                <p>En eller flera tjänster i ordern existerar inte</p>
                :
                null
              }
            </Grid>
            <Grid item xs={4}>
              {docMeta[0] !== undefined && ss.length > 0 && customer !== undefined?
                <>
                {types.map((e,i) => (
                <PDFDownloadLink 
                  key={i}
                  document={
                    <PdfDocument 
                      meta={docMeta.filter(d => d.name == e)[0]} 
                      customer={customer}
                      order={order}
                      services={ss}
                    />} 
                  filename="test.pdf">
                  {({ blob, url, loading, error }) => {
                    if(!loading && show){
                      setCurrentUrl(url);
                      return<p>{url}</p>
                    }

                    else{
                      return <div>loading</div>
                    }
                  }}
                </PDFDownloadLink>
                ))}
                </>
                : null
                }
            </Grid>
          </Grid>
     </>
  )
}

const CustomSelectedToolbar = (props) => {
  const theme = useTheme();

  const { addModule } = useMainGridContext();
  const { customersApi, ordersApi, servicesApi } = useAppContext();

  const [rows, setRows] = useState(props.selectedRows);
  const [data, setData] = useState(props.displayData);
  const [orders, setOrders] = useState(props.orders);

  const [openDialog, setOpenDialog] = useState(false);

  const [cInfo, setCInfo] = useState([]);
  const [oInfo, setOInfo] = useState([]);
  const [sInfo, setSInfo] = useState([]);
  const [docMeta, setDocMeta] = useState([]);
  const [products, setProducts] = useState([]);
  const [cats, setCats] = useState([]);

  const [msg, setMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const [openUrl, setOpenUrl] = useState(false);

  useEffect(() => {
    setOInfo([]);

    setRows(props.selectedRows);
    setData(props.displayData);
    setOrders(props.orders);
  },[props.selectedRows, props.displayData, props.orders])

  const handleClick = async () => {
    setOpenDialog(true);
    var tmpCids = [];
    rows.data.map(e => tmpCids.push(orders[e.dataIndex].customerid));

    var tmpOids= [];
    rows.data.map(e => tmpOids.push(orders[e.dataIndex].orderId));
    getData(tmpOids, tmpCids);
    
  }

  const getData = async (oids, cids) => {

    const docsApi     = new Docs();
    const productsApi = new Products("user");

    setMsg("Hämtar dokument...")
    setProgress(10);
    var docs      = await docsApi.getWhere("type", "ob")  
    setMsg("Hämtar kunder...")
    setProgress(20);
    var custs     = await customersApi.getGroup(cids);
    setMsg("Hämtar order...")
    setProgress(30);
    var ords      = await ordersApi.getGroup(oids);
    setMsg("Hämtar tjänster...")
    setProgress(50);
    var services  = await servicesApi.getGroup("orderNumber", oids);
    setMsg("Hämtar produkter...")
    setProgress(100);

    var products  = await productsApi.getAll();
    var cats  = await productsApi.getAllCategories(); 
    
    setCInfo(custs.data.recordset);
    setSInfo(services.data.recordset);
    setDocMeta(docs.data.docs);
    setProducts(products.data.recordset);

    setCats(cats.data.categories);

    setOInfo(p => ords.data.recordset);
  }

  return (
    <React.Fragment>
      <Tooltip title={"Skriv ob"}>
        <Button onClick={handleClick}>
          Skriv OB
        </Button>
      </Tooltip>
      <VasaDialog open={openDialog} handleClose={() => setOpenDialog(false)}>
        <div style={{background: theme.palette.background.paper, padding:"20px", overflow:"auto"}}>
          <Typography variant="h5">Orderbekräftelser</Typography>
          <Typography>{msg}</Typography>
          <LinearProgress variant="determinate" value={progress} />
          {oInfo.map((e,i) => {
          return(
            <div key={i}>
              <RenderPdf 
                cInfo={cInfo} 
                sInfo={sInfo} 
                order={e} 
                docMeta={docMeta}
                products={products}
                categories={cats}
                openUrl={openUrl}
              />
            </div>)
          })}
          <button onClick={() => setOpenUrl(true)}>Öppna alla</button>
        </div>
      </VasaDialog>
    </React.Fragment>
  )
}


function Comments(params){
  const classes = useStyles();
  const theme = useTheme();

  const { commentsApi, currentUser } = useAppContext();
  const { notify } = useMainGridContext();

  const [showTextField, setShowTextField] = useState(false);

  const [comments, setComments] = useState(params.comments);
  const cid = params.cid
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    setComments(params.comments);
  },[params.comments])

  const writeComment = () => {
    setShowTextField(false);

    var body = {
      "author": currentUser.username,
      "content": newComment,
      "regarding": cid 
    }

    commentsApi.create(body)
      .then(res => {
        notify("success", `Ny kommentar har skapats för kunden ${cid}`);
        body.time = "Skapades precis";
        setComments([...comments, body]);
      })
      .catch(err => {
        notify("error", `Kunde inte skapa kommentar. Felmeddelande: ${err}`);
      })
  }
  
  return(
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Table size="small" className={classes.tableOrderSummary}>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Kommentar</b>
              </TableCell>
              <TableCell>
                <b>Skrivet</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              comments !== null ?
                comments.map((comms, i)=> {
                  return(
                    <TableRow key={i}>
                      <TableCell>
                        <b>{comms.author}</b>: {comms.content}
                      </TableCell>
                      <TableCell>
                        {comms.time}
                      </TableCell>
                    </TableRow>
                  )
              })
              :
                null
            }
          </TableBody>
        </Table>
      </Grid>
      <Grid item container xs={12} alignItems="flex-end" justify="flex-end">
        <Fade in={!showTextField}>
          <Tooltip title="Lägg till kommentar">
            <IconButton onClick={() => setShowTextField(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Fade>
        <Fade in={showTextField}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <TextField 
                style={{width:"100%"}}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" fullWidth onClick={() => setShowTextField(false)}>
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="primary" onClick={writeComment} fullWidth>
                Skriv
              </Button>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  )
}

function OrderSummary(params){
  const classes = useStyles();
  const theme = useTheme();

  const {customersApi, commentsApi, ordersApi, servicesApi} = useAppContext();

  const updateStaticTable = params.updateStaticTable;
  const updateStaticTableFilter = params.updateStaticTableFilter;

  const [cid, setCid] = useState(params.cid.props.children);
  const [oid, setOid] = useState(params.oid.props.children);
  const [currOrder, serCurrOrder] = useState(params.order);
  const [open, setOpen] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [services, setServices] = useState(null);
  const [comments, setComments] = useState(null);

  useEffect(() => {
    if(customersApi !== null && commentsApi !== null){
      setOpen(true);
      if(customer == null){
        customersApi.getWhere("customerid", cid).then(res => {
          setCustomer(res.data.recordset[0]);
        }).catch(err => console.log("err", err));
        servicesApi.getWhere("orderNumber", oid).then(res => {
          console.log("res", res);
          setServices(res.data.recordset);
        }).catch(err => console.log("err", err));
        commentsApi.getWhere("regarding", cid).then(res => {
          setComments(res.data.recordset);
        }).catch(err => console.log("err", err));
      }
    }
    return function cleanup(){
      setOpen(false);
    }
  },[params.cid, params.oid, customersApi, commentsApi, servicesApi]);

  const updateServicePrice = async (s, newPrice) => {
    const sid = s.serviceId;

    const currServicePrice = s.price;
    const currOrderPrice = unFormatInteger(String(currOrder[2].props.children));

    const currOrderFSG = currOrder[5]== null ? 0 : currOrder[5];

    const newOrderPrice = Number(currOrderPrice) - Number(currServicePrice) + Number(newPrice);
    const newOrderFsg = currOrderFSG - Number(currServicePrice)*0.8 + Number(newPrice)*0.8;
    servicesApi.updateWhere(s.serviceId, "price", newPrice);
    ordersApi.updateWhere(oid, "price", newOrderPrice);
    ordersApi.updateWhere(oid, "fsg", newOrderFsg);
    updateStaticTableFilter(oid, "price", newOrderPrice);
    updateStaticTableFilter(oid, "fsg", newOrderFsg);
  }

  return(
    <TableRow>
      <TableCell colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={8}>
                <Typography>
                  Kund
                </Typography>
                <Table size="small" className={classes.tableOrderSummary}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Namn</b>
                      </TableCell>
                      <TableCell>
                        <b>Personnummer</b>
                      </TableCell>
                      <TableCell>
                        <b>Adress</b>
                      </TableCell>
                      <TableCell>
                        <b>Postnummer</b>
                      </TableCell>
                      <TableCell>
                        <b>Ort</b>
                      </TableCell>
                      <TableCell>
                        <b>Tel.</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    customer !== null && customer !== undefined?
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {customer.name}
                          </TableCell>
                          <TableCell>
                            {customer.socialSecurityNumber}
                          </TableCell>
                          <TableCell>
                            {customer.adress}
                          </TableCell>
                          <TableCell>
                            {customer.zipCode}
                          </TableCell>
                          <TableCell>
                            {customer.municipality}
                          </TableCell>
                          <TableCell>
                            {customer.telephone}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    :
                      null
                  }
                </Table>
                <Typography>
                  Tjänster
                </Typography>
                <Table size="small" className={classes.tableOrderSummary}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Tjänst</b>
                      </TableCell>
                      <TableCell>
                        <b>Antal</b> 
                      </TableCell>
                      <TableCell>
                        <b>Pris</b> 
                      </TableCell>
                      <TableCell>
                        <b>Identifikator</b> 
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      services !== null ?
                        services.map((s,i) => {
                          return(
                            <TableRow key={i}>
                              <TableCell>
                                {s.serviceId}
                              </TableCell>
                              <TableCell>
                                {s.productCode}
                              </TableCell>
                              <TableCell>
                                {s.amount}
                              </TableCell>
                              <TableCell>
                                <EditableText 
                                  dbLabel="identifier"
                                  id="0"
                                  value={s.price}
                                  url="https://isgg-intelligens.biz"
                                  align="center"
                                  onUpdate={(nv) => updateServicePrice(s, nv)}
                                  theme={() => params.theme()}
                                  updatable={true}
                                />
                              </TableCell>
                              <TableCell>
                                <EditableText 
                                  dbLabel="identifier"
                                  id="0"
                                  value={s.identifier === "undefined" || s.identifier == null || s.identifier.length == 0 ? "INGEN" : s.identifier}
                                  url="https://isgg-intelligens.biz"
                                  align="center"
                                  onUpdate={(nv) => servicesApi.updateWhere(s.serviceId, "identifier", nv)}
                                  theme={() => params.theme()}
                                  updatable={true}
                                />
                              </TableCell>
                            </TableRow>
                          )
                      })
                      :
                        null
                    }
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={4}>
                <Comments comments={comments} cid={cid}/>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>  
    </TableRow>
  )
}

function OrderTable(params){
  const classes = useStyles();
  const theme = useTheme();
  const { addModule, notify } = useMainGridContext();
  const { ordersApi } = useAppContext();

  const [orders, setOrders]  = useState(params.orders);
  const title   = params.title;
  const prefersDarkMode = theme.palette.type == "dark";
  const [anchorEl, setAnchorEl] = useState(null);
  const [currRow, setCurrRow] = useState(null);

  const [mak, setMak] = useState(0);
  const [fak, setFak] = useState(0);
  const [ord, setOrd] = useState(0);

  useEffect(() => {
    setOrders(params.orders)
  },[ordersApi, params.orders]);

  useEffect(() => {
    setOrders(orders);
  },[orders])

  const getMuiTheme = () => createMuiTheme({
     palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        domain: {
          customers: {main: prefersDarkMode ? "#1565c0" : "#64b5f6"},
          admin: {main: prefersDarkMode ? "#d32f2f" : "#9575cd"},
          products: {main: prefersDarkMode ? "#ef6c00" : "#ffb74d"},
          users: {main: prefersDarkMode ? "#43a047" : "#81c784"}
        },
        background: {
          default: prefersDarkMode ? "#141414" : "#f7f7f7",
          paper: prefersDarkMode ? "#212121" : "#fff"
        },
        text:{primary: prefersDarkMode ? "#cfcfcf" : "rgba(0, 0, 0, 0.87)"},
        select:{primary: prefersDarkMode ? "#1c1c1c" : "#ededed"}
     },
    overrides:{
      MUIDataTableBodyRow:{
        root:{
          borderBottom:"1px solid #737373",
          '&:nth-child(odd)':{
            backgroundColor: theme.palette.background.default,
          },
          '&:nth-child(even)':{
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      MUIDataTableHeadCell:{
        root:{
          fontWeight:"bold",
          position:"sticky"
        }
      },

    }
  });

  const handleUserStatusClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleUserStatusClose= (e) => {
    setAnchorEl(null);
  }

  const handleUpdate = (col, val) => {
    const rowIndex = currRow.rowIndex;
    const oid = currRow.rowData[0];
    
    updateStaticTable(rowIndex, col, val);

    if(ordersApi !== null){
      ordersApi.updateWhere(oid, col, val).then(res => {
        notify("success", `${oid} uppdaterad ${col} -> ${val}`);
      })
        .catch(err => {notify("error", `Kunde inte uppdatera order. Felmeddelande: ${err}`)});
    }
    else{
      notify("error", "Order API är inte tillgänglig.")
    }

    setAnchorEl(null);
  }

  const updateStaticTable = (index, col, val) => {
    var tmpOrders = orders;
    tmpOrders[index][col] = val;
    setOrders(p => (p = tmpOrders));
  }

  const updateStaticTableFilter = (oid, col, val) => {
    var tmpOrders = orders;
    var index = 0;
    for(var i = 0; i < tmpOrders.length; i++){
      if(tmpOrders[i].orderId == oid){
        index = i;
        break;
      }
    }
    alert("thing: " + index);
    tmpOrders[index][col] = val;
    setOrders(p => (p = tmpOrders));

  }

  const columns = [
    {
      name: "orderId",
      label: "OID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <Button 
              variant="outlined" 
              onClick={() => addModule(`::order ${tableMeta.rowData[1]}`)}
            >
              {value}
            </Button>
        )}
      }
    },
    {
      name: "customerid",
      label: "KID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => addModule(`::om ${value}`)}
            >
              {value}
            </Button>
        )}
      }
    },
    {
      name: "price",
      label: "Pris",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <Typography variant="subtitle2">
              {formatter.format(value)}
            </Typography>
          )
        } 
      }
    },
    {
      name: "subscriptionLength",
      label: "Avtalslängd",
    },
    {
      name: "billingInterval",
      label: "Fakturering",
    },
    {
      name: "fsg",
      label: "FSG",
    },
    {
      name: "salesman",
      label: "Säljare",
    },
    {
      name: "orderDate",
      label: "Regdatum",
      setCellHeaderProps: () => ({
        style:{
          position:"sticky"
        }
      }),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="subtitle2">
              {formatDateTime(new Date(value))}
            </Typography>
        )}
      }
    },
    {
      name: "status",
      label: "Status",
      sortMethod: (a, b) => Number(a)-Number(b),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var bg = "black";
          var bgColor = "rgba(255,255,2550.1)";
          var rIndex = tableMeta.rowIndex;
          const val = value.toLowerCase();
          if(val == "makulerad"){
            bg = "red";
            bgColor = "rgba(255,0,0,0.1)";
          }
          if(val == "fakturerad"){
            bg = "rgba(255,200,0)";
            bgColor = "rgba(255,200,0,0.1)";
          }
          if(val == "kund"){
            bg = "green";
          }
          if(val == "order"){
            bg = "rgba(0,116,255)"; 
            bgColor = "rgba(0,116,255,0.1)"; 
          }
          if(val == "uppsagd"){
            bg = "rgba(0,0,0)"; 
            bgColor = "rgba(0,0,0,0.1)"; 
          }

          return (
            <>
              <Chip 
                label={value} 
                color="primary" 
                onClick={(e) => {setCurrRow(tableMeta); handleUserStatusClick(e);}}
                style={{
                  border: `2px solid ${bg}`,
                  color: bg,
                  backgroundColor: bgColor,
                  fontWeight: "bold"
                }}
              />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleUserStatusClose}
              >
                <MenuItem onClick={(e) => handleUpdate("status", "order")}>
                  Order 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "fakturerad")}>
                  Fakturerad 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "kund")}>
                  Kund 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "makulerad")}>
                  Makulerad 
                </MenuItem>
              </Menu>
            </>
        )}
      }
    },

  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    download: false,
    print: false,
    expandableRows: true,
    expandableRowsOnClick: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return(<OrderSummary 
        theme={() => getMuiTheme()} 
        order={rowData} 
        oid={rowData[0]} 
        cid={rowData[1]}
        rowMeta={rowMeta}
        updateStaticTable={(i,c,v) => updateStaticTable(i, c, v)}
        updateStaticTableFilter={(oid,c,v) => updateStaticTableFilter(oid, c, v)}
      />)
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => 
      (<CustomSelectedToolbar 
        selectedRows={selectedRows} 
        displayData={displayData}
        orders={orders}
      />),
    customToolbar: () => (<CustomTableToolbar />),
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage:"Rader per sida",
        displayRows: "av"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }
  return(
    <div style={{width:"100%", background:theme.palette.background.paper}}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          size="small"
          title={title}
          data={orders}
          columns={columns}
          options={options}
          style={{width: "100%", background:theme.palette.background.paper}}
        />
      </MuiThemeProvider>
    </div>
  )
}

function TableDatePicker(params){
  const classes = useStyles();
  const theme   = useTheme();

  const getOrders = params.getOrders;

  const [from, setFrom] = useState(params.from);
  const [to, setTo] = useState(params.to);
  const [update, setUpdate] = useState(false);
  const [dateInterval, setDateInterval] = useState(true);

  const updateFrom = (newVal) => {
    setFrom(formatDateNormal(new Date(newVal)));
    setUpdate(true);
  }

  const updateTo = (newVal) => {
    setTo(getDaysAheadFromDate(new Date(newVal),1));
    setUpdate(true);
  }

  const handleYearDateChange = (e) => {
    const start = new Date();
    const p  = getMonthPeriod(e.getFullYear(), e.getMonth()+1); 
    setFrom(p.start);
    setTo(getDaysAheadFromDate(p.end, 1));
    getOrders(p.start, p.end);
  }

  return(
    <Grid 
      container 
      xs={6} 
      style={{
        backgroundColor: theme.palette.background.default,
        paddingTop:"7px", 
        paddingLeft:"20px", 
        borderRadius:"5px", 
      }}

    >
      <Grid item xs={2}>
        {dateInterval ?
          <Button onClick={() => setDateInterval(false)} size="small">
            Månad 
          </Button>
          :
          <Button onClick={() => setDateInterval(true)} size="small">
            Intervall 
          </Button>
        }
      </Grid>
      {dateInterval ?
        <>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="yyyy-MM-dd"
                value={from}
                style={{fontSize:"12px"}}
                size="small"
                renderInput={({ inputRef, inputProps, InputProps}) => (
                  <React.Fragment>
                    <input size="small" 
                      style={{fontSize:"12px"}}
                      ref={inputRef} 
                      {...inputProps} 
                    />
                      {InputProps?.endAdornment}
                  </React.Fragment>
                )}
                onChange={(e) => updateFrom(e)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item justify="center" alignItems="center" style={{paddingTop:"5px"}} xs={1}>
              <ArrowRightAltIcon />
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="yyyy-MM-dd"
                value={to}
                renderInput={({ inputRef, inputProps, InputProps}) => (
                  <input ref={inputRef} {...inputProps} />
                )}
                onChange={(e) => updateTo(e)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            <Fade in={update}>
              <IconButton onClick={() => {getOrders(from, to); setUpdate(prev => !prev)}}>
                <UpdateIcon />
              </IconButton>
            </Fade>
          </Grid>
        </>
        :
        <Grid item xs={10} style={{paddingBottom:"10px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <DatePicker
                views={["year", "month"]}
                minDate={new Date("2017-01-01")}
                maxDate={new Date()}
                value={from}
                onChange={(e) => handleYearDateChange(e)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      }
    </Grid>
  )
}

function GeneralStatsRow(params){
  const classes = useStyles();
  const theme   = useTheme();

  const amount    = params.amount;
  const loadTime  = params.loadTime 

  const ord = params.ord;
  const mak = params.mak;
  const fak = params.fak;

  return(
    <Grid container item xs={12} style={{marginBottom:"20px"}}>
      <Grid 
        container 
        xs={12} 
        style={{
          background:theme.palette.background.default,
          borderRadius:"5px",
          padding:"5px"
        }}
      >
        <Grid item xs={2}>
          <Typography variant="overline">
           Antal 
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography variant="overline">
            {amount} st
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">
           Inladdningstid 
          </Typography>
        </Grid>
        <Grid container item xs={5}>
          <Typography variant="overline">
            {loadTime} ms
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">
            Makulerad: {mak} 
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">
            Fakturerad: {fak}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">
            Order: {ord}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function OrderBook(params){
  const classes = useStyles();
  const theme   = useTheme();

  const { onRemoveItem, addModule, notify } = useMainGridContext();
  const { ordersApi, currentUser } = useAppContext();
  const { dataFromServer } = useComsContext();
  const [firstLoad, setFirstLoad] = useState(true);

  const [hasUpdated, setHasUpdated] = useState(false);

  const [orders, setOrders] = useState([]);
  const [loadTime, setLoadTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(currentMonthPeriod().start);
  const [toDate, setToDate] = useState(currentMonthPeriod().end);

  const [mak, setMak] = useState(0);
  const [fak, setFak] = useState(0);
  const [ord, setOrd] = useState(0);

  const getOrders = (from, to) => {
    var group = ["makulerad", "fakturerad", "order", "ohanterad", "uppsagd"];
    const start = new Date();

    setOrders([]);
    nullifyStats();
    setIsLoading(true);

    ordersApi
      .getGroupWhere(from, to, "status", group)
      .then(res => {
        setOrders(res.data.recordset);
        
        setStats(res.data.recordset);

        var tt = (new Date()) - start;
        setLoadTime(tt);
        setIsLoading(false);
      })
      .catch(err => {
        notify("error", `Kunde inte hämta data. Felmeddelande: ${err}`);
        setIsLoading(false);
      });
  }


  const setStats = (data) => {
    var mak = countCol(data, "status", "makulerad");
    var fak = countCol(data, "status", "fakturerad");
    var ord = countCol(data, "status", "order");
    setMak(mak);
    setFak(fak);
    setOrd(ord);
  }

  const nullifyStats = () => {
    setMak(0);
    setFak(0);
    setOrd(0);
  }

  const updateOrder = (oid, col, val) => {
    console.log(`${oid}: ${col} -> ${val}`);
  }

  const countCol = (data, col, comp) => {
    var arr = _.filter(data, function(o){return o[col].toLowerCase() == comp});
    return arr.length;
  }

  useEffect(() => {
    if(ordersApi !== null){
      if(firstLoad) {
        console.log("dataFromServer", dataFromServer);
        getOrders(fromDate, toDate);
        setFirstLoad(false);
      }
      else if(dataFromServer.entity == "orders" && dataFromServer.creator !== currentUser.username){
        setHasUpdated(true);
      }
    }
  },[ordersApi, dataFromServer]);
  
  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
        color={theme.palette.domain.customers.main} 
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Orderbok
      </Header>
      <Body>
        <Grid 
          container 
          style={{overflowY: "auto", height:"95%", overflowX:"hidden"}}
        >
          <TableDatePicker 
            from={fromDate} 
            to={toDate} 
            getOrders={(f, t) => getOrders(f, t)}
          />
          {hasUpdated ?
            <Typography>
              *Tabellen har uppdaterats sedan du öppnade den.
            </Typography>
            :
            null
          }
          <Fade in={!isLoading}>
            <div style={{width:"100%"}}>
              <OrderTable 
                orders={orders} 
                title={
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h5"><b>Orderbok</b></Typography>
                      <Sheets newRows={orders}/>
                      <p>Hitta ditt sheet <a href="https://docs.google.com/spreadsheets/d/1lE1OaV1IHd_ZDQDKex804FZlmrZc8iNdvZBiy60nwOE/edit#gid=0" target="_blank">här</a></p>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" style={{color:theme.palette.text.secondary}}>Från {fromDate} till {toDate}</Typography>
                    </Grid>
                  </Grid>
                  }
              />
              <GeneralStatsRow 
                amount={orders.length} 
                loadTime={loadTime} 
                mak={mak}
                fak={fak}
                ord={ord}
              />
            </div>
          </Fade>
          <Fade in={isLoading}>
            <Grid container justify="center" alignItems="center" style={{background:theme.palette.background.default}}>
              <CircularProgress />
            </Grid>
          </Fade>
        </Grid>
      </Body>
    </Module>
  )
}
