import axios from 'axios';
import config from './apiConfig.js';

export default class Payments{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "bpxhgdyi14";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.payments_api_key;
  }
  async get(){
    return axios.get(`${this.endpoint}/read/all/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(oid){
    return axios.get(`${this.endpoint}/read/${oid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getUser(user, from, to){
    return axios.get(`${this.endpoint}/read/user/${user}/${from}/${to}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/read/where/${col}/${val}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateWhere(oid, col, val){
    return axios.post(`${this.endpoint}/update/${oid}/${col}/${val}/${this.user}`,
      {},
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async sumFor(cid){
    return axios.get(`${this.endpoint}/sum/for/customerid/${cid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

