import React, {useState, useEffect} from 'react';
import * as locales from 'react-date-range/dist/locale';
import MUIDataTable from 'mui-datatables';
import { addDays } from 'date-fns';

import AnimatedNumber from 'react-animated-number';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { 
  getMonthPeriod, 
  formatDateNormal, 
  currentMonthPeriod,
  formatDateTime
} from '../../shared/dateHelper.js';

import UserAdmin from '../../shared/UserAdmin';

import { formatter } from '../general/format.js';

import {makeStyles, useTheme, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Button,
  Typography,
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Collapse,
  Box,
  TableHead,
  Fade,
  CircularProgress,
  Chip,
  TextField
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DateRange from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  expandableRowHeader: {
    paddingTop:"25px",
  },
  hidden:{
    display:"none"
  },
  visible:{
    visibility:"visible"
  }
}));

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nykund");
  }

  return (
    <React.Fragment>
      <Tooltip title={"Lägg till"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

function DatePickerText(params){
  const classes = useStyles();

  const [chooseDate, setChooseDate] = useState(false);
  const [value, setValue]           = useState(params.value)

  return(
    <>
      <p className={chooseDate ? classes.hidden : classes.visible} onClick={() => setChooseDate(p => !p)}>
        {value}
      </p>
      <Grid container className={chooseDate ? classes.visible : classes.hidden}>
        <Grid item>
          <TextField value={value} onChange={e => setValue(e.target.value)}/>
        </Grid>
        <Grid item>
          <button onClick={() => {params.dateFn(value); setChooseDate(p => !p)}} >
            Klar
          </button>
        </Grid>
      </Grid>
    </>
  )
}

function ServiceRow(params){
  const classes = useStyles();
  const e = params.e;
  const ss = params.ss;
  const pms = params.pms;
  const [openService, setOpenService] = useState(false);

  const getBackground = (status) =>{
    if(status == null){
      return "white";
    }
    switch(status.toLowerCase()){
      case "makulerad":
        return "#ff0000";
      case "fakturerad":
        return "yellow";
      case "kund":
        return "green"
      case "order":
        return "#0074ff"
      case "ohanterad":
      default:
        return "white"
    }
  }

  return(
    <>
    <TableRow>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpenService(!openService)}
        >
          {openService ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </TableCell>
      <TableCell>
        {e.orderId}
      </TableCell>
      <TableCell style={{"background": getBackground(e.status)}}>
        {e.status}
      </TableCell>
      <TableCell>
        {e.price}
      </TableCell>
      <TableCell>
        {e.fsg}
      </TableCell>
      <TableCell>
        {e.subscriptionLength}
      </TableCell>
      <TableCell>
        {e.billingInterval}
      </TableCell>
      <TableCell>
        {
          e.billingOptions == "NEJ" || 
          e.billingOptions === "NULL" ||
          e.billingOptions === null ||
          e.billingOptions == "inget"? `❌ (${e.billingOptions})` :`✅ (${e.billingOptions})`}
      </TableCell>
      <TableCell>
        {e.salesman}
      </TableCell>
      <TableCell>
        {formatDateNormal(e.orderDate)}
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={openService} unmountOnExit>
          <Box sx={{margin: 1}}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>
                  Tjänster
                </Typography>
                
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tjänst</TableCell>
                      <TableCell>Antal</TableCell>
                      <TableCell>Pris</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ss.filter(s => e.orderId == s.orderNumber).map(x => {
                      return(
                        <TableRow>
                          <TableCell>{x.productCode}</TableCell>
                          <TableCell>{x.amount}</TableCell>
                          <TableCell>{x.price}</TableCell>
                        </TableRow>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Betalningar
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Summa</TableCell>
                      <TableCell>Datum</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pms.filter(p => e.orderId == p.orderId).map(x => {
                      return(
                        <TableRow>
                          <TableCell>{x.amount}</TableCell>
                          <TableCell>{x.dateOfPayment}</TableCell>
                        </TableRow>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
    </>

  )

}

function OrderSpecifications(params){
  
  const classes = useStyles();

  const orderId = params.orderId;
  const cid = params.cid;
  const { addModule } = useMainGridContext();
  const [services, setServices] = useState([]);

  const [ss, setSs]   = useState([]);
  const [os, setOs]   = useState([]);
  const [c, setC]     = useState({"dateOfRegistration": "", "socialSecurityNumber": "2022"});
  const [ps, setPs]   = useState(0);
  const [pms, setPms] = useState([]);
  const [cms, setCms] = useState([]);
  const [age, setAge] = useState(0);

  const [currOrder, setCurrOrder] = useState({})

  const [open, setOpen] = useState(false);
  const [openService, setOpenService] = useState(false);
  const { servicesApi, customersApi } = useAppContext(); 

  useEffect(() => {
    customersApi.getCustomerFull(cid).then(res => {
      const r = res.data;
      const customer = JSON.parse(r.c).recordset[0]
      setC(p => p = customer);
      
      if(!isNaN(customer.socialSecurityNumber) && customer.socialSecurityNumber !== null){
        if(customer.socialSecurityNumber.length > 10){
          setAge(p => p = 2022 - parseInt(customer.socialSecurityNumber.substring(0,4)))
        }
        else{
          setAge(2022 - parseInt("19" + customer.socialSecurityNumber.substring(0,2)))
        }
      }
      else{
        if(customer.socialSecurityNumber !== null){
          setAge(2022 - parseInt("19" + customer.socialSecurityNumber.substring(0,2)))
        }
      }

      setOs(JSON.parse(r.o).recordset);
      JSON.parse(r.o).recordset.map(e => 
        {
          if(e.orderId == orderId){
            setCurrOrder(e);
        }
      })
      setSs(p => p = JSON.parse(r.s).recordset)
      setCms(JSON.parse(r.cm).recordset)
      let paid = 0;
      JSON.parse(r.p).recordset.map(e => paid += e.amount);
      setPs(paid);
      setPms(JSON.parse(r.p).recordset);
    })
  },[]) 

  return(
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width:"100%" }} colSpan={5}>
            <Box margin={1} style={{width:"100%"}}>
              <Typography variant="h4">
                👤 {c.name} ({age}) från {c.municipality}
              </Typography>
              <Grid container style={{marginBottom:"20px"}}>
                <Grid item xs={4}>
                  <Tooltip title="Adress">
                    <Typography variant="p">
                      🏠 {c.adress}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="Telefonnummer">
                    <Typography variant="p">
                      📞 {c.telephone} 
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="Postnummer">
                    <Typography variant="p">
                      🔢 {c.zipCode}
                    </Typography>
                  </Tooltip>
                </Grid>

              </Grid>

              <Typography variant="overline">
                Kund sedan <b>{c.dateOfRegistration.substring(0,10)}</b> 
                {" "}order registrerades <b>{formatDateNormal(currOrder.orderDate)} {" "}</b>
                 har totalt betalat <b>{" " } {formatter.format(ps)}</b>
              </Typography>
              <Table size="small" style={{width:"100%"}}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell><b>Alla order</b></TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Pris</TableCell>
                    <TableCell>FSG</TableCell>
                    <TableCell>Avtalslängd</TableCell>
                    <TableCell>Betalning</TableCell>
                    <TableCell>Signerad</TableCell>
                    <TableCell>Säljare</TableCell>
                    <TableCell>Skapad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {os.map(e => {
                    return(
                      <ServiceRow e={e} ss={ss} pms={pms} orderId={e.orderId}/>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width:"100%" }} colSpan={5}>
          <Box margin={1} style={{width:"100%"}}>
              <Table size="small" style={{width:"100%"}}>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Kommentarer</b></TableCell>
                    <TableCell>Användare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cms.map(e => {
                    return(
                      <TableRow>
                        <TableCell>
                          {formatDateNormal(e.time)}<br/>
                          {e.content}
                        </TableCell>
                        <TableCell>
                          {e.author}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

function AnimatedStat(params){
  const classes = useStyles();

  const [color, setColor] = useState(params.color);
  const [desc, setDesc]   = useState(params.desc);
  const [tot, setTot]     = useState(params.tot);
  const [val, setVal]     = useState(params.val);
  const [title, setTitle]     = useState(params.title);
  
  useEffect(() => {
    setVal(params.val)
    setTot(params.tot)
  },[params.val])

  return(
    <>
      <Tooltip title={desc}>
      <div style={{padding:"5px", width:"100%", borderRadius:"5px", background: "rgba(237, 237, 237, 0.1)"}}>
        <Typography variant="overline" style={{color: `${color}`}}>
          <b>{title} ({((val/tot)*100).toFixed(0)}%)</b>
        </Typography>
        <AnimatedNumber
          value={val}
          style={{
              fontSize:24,
              padding: "5px",
          }}
          duration={1000}
          formatValue={(n) => `${formatter.format(n.toFixed(0))}`}
        />
      </div>
      </Tooltip>
    </>

  )
}

export default function UserCustomers(params){
  const classes = useStyles();
  const theme = useTheme();

  const [moduleColor, setModuleColor] = useState("white");

  const { onRemoveItem, addModule, notify } = useMainGridContext();
  const { usersApi, moduleUtils, currentUser, ordersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const tmpPeriod = currentMonthPeriod();

  const [loading, setLoading]           = useState(false);
  const [vals, setVals]                 = useState({mak: 0, fak: 0, out: 0, cust: 0,tot: 1})
  const [customers, setCustomers]       = useState([]);
  const [fromDate, setFromDate]         = useState(tmpPeriod.start);
  const [toDate, setToDate]             = useState(tmpPeriod.end);
  const [specificDate, setSpecificDate] = useState(true);
  const [updActive, setUpdActive]       = useState(false);
  const [pickDates, setPickDates]       = useState(false);
  const [stopUpdate, setStopUpdate]     = useState(false)
  const [state, setState]               = useState( {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      findIndex: (x) => console.log("Hello")
    });

  const [showFetch, setShowFetch]       = useState(false);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const [orders, setOrders] = useState([]);

  const prefersDarkMode = theme.palette.type == "dark";
  const uname = params.uname == undefined ? currentUser.username : params.uname;

  useEffect(() => {
    var start = new Date();
    if(usersApi !== null){
      if(
        customers.length === 0 && !stopUpdate
      ){
        setLoading(true);
        var period = {
          start: formatDateNormal(new Date(fromDate)), 
          end: formatDateNormal(new Date(toDate))
        };

        getOrders(period.start, period.end);
      }
    }
  },[usersApi]);

  useEffect(() => {
    if(moduleUtils !== undefined){
      var domain = moduleUtils.getModule("kunder").color;
      setModuleColor(theme.palette.domain[domain].main);
    }
  },[moduleUtils])

  const getData = () => {
    setLoading(true)
    setUpdActive(false);
    const period = {start: formatDateNormal(selectionRange.startDate), end: formatDateNormal(selectionRange.endDate)};

    usersApi.readCustomers(period, uname).then(res => {
      console.log("res", res);
      setLoading(false);
      setCustomers(prev => res.data.recordset);
    })
  }

  const handleYearDateChange = (e) => {
    const start = new Date();
    const p  = getMonthPeriod(e.getFullYear(), e.getMonth()+1); 
    setFromDate(p.start);
    setToDate(p.end);
    usersApi.readCustomers(p, uname).then(res => {
      setCustomers(prev => res.data.recordset);
      var end = new Date();
    });
  }

  const handleFromDateChange = (e) => {
    setFromDate(e);
    setUpdActive(true);
  }

  const handleToDateChange = (e) => {
    setToDate(e);
    setUpdActive(true);
  }

  const getMuiTheme = () => createMuiTheme({
    palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        domain: {
          customers: {main: prefersDarkMode ? "#1565c0" : "#64b5f6"},
          admin: {main: prefersDarkMode ? "#d32f2f" : "#9575cd"},
          products: {main: prefersDarkMode ? "#ef6c00" : "#ffb74d"},
          users: {main: prefersDarkMode ? "#43a047" : "#81c784"}
        },
        background: {
          default: prefersDarkMode ? "#141414" : "#f7f7f7",
          paper: prefersDarkMode ? "#212121" : "#fff"
        },
        text:{primary: prefersDarkMode ? "#cfcfcf" : "rgba(0, 0, 0, 0.87)"}
     },
    overrides:{
      MUIDataTableBodyRow:{
        root:{
          borderBottom:"1px solid #737373",
          '&:nth-child(odd)':{
            backgroundColor: theme.palette.background.default,
          },
          '&:nth-child(even)':{
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      MUIDataTableHeadCell:{
        root:{
          fontWeight:"bold",
          position:"sticky"
        }
      },
    }
  })

  const columns = [
    {
      name: "orderId",
      label: "OID",
    },
    {
      name: "customerid",
      label: "KID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<Button variant="outlined" color="primary" onClick={() => {setStopUpdate(p => !p); addModule(`::om ${value}`)}}>{value}</Button>)
        }
      }
    },
    {
      name: "name",
      label: "Namn",
    },
    {
      name: "socialSecurityNumber",
      label: "Personnummer",
    },
    {
      name: "telephone",
      label: "Tel.",
    },
    {
      name: "price",
      label: "Pris",
    },
    {
      name: "fsg",
      label: "FSG",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var bg = "black";
          var bgColor = "rgba(255,255,2550.1)";
          var rIndex = tableMeta.rowIndex;
          const val = value.toLowerCase();
          if(val == "makulerad"){
            bg = "red";
            bgColor = "rgba(255,0,0,0.1)";
          }
          if(val == "fakturerad"){
            bg = "rgba(255,200,0)";
            bgColor = "rgba(255,200,0,0.1)";
          }
          if(val == "kund"){
            bg = "green";
          }
          if(val == "order"){
            bg = "rgba(0,116,255)"; 
            bgColor = "rgba(0,116,255,0.1)"; 
          }
          if(val == "ohanterad"){
            bg = "rgba(0,116,255)"; 
            bgColor = "rgba(0,116,255,0.1)"; 
          }
          if(val == "uppsagd"){
            bg = "rgba(0,0,0)"; 
            bgColor = "rgba(0,0,0,0.1)"; 
          }
          return (
            <>
              <Chip 
                label={value} 
                color="primary" 
                style={{
                  border: `2px solid ${bg}`,
                  color: bg,
                  backgroundColor: bgColor,
                  fontWeight: "bold"
                }}
              />
            </>
        )}
      }
    },

    {
      name: "orderDate",
      label: "Regdatum ",
    },
  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    download: false,
    print: false,
    expandableRows: true,
    expandableRowsOnClick: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <OrderSpecifications cid={rowData[1].props.children} orderId={rowData[0]}/>
      )
    },
    customToolbar: () => (<CustomTableToolbar />),
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  const isOwner = (body) => {
    return body.username === uname;
  }

  const getOrders = (from, to) => {
    var group   = ["niclas"];
    const start = new Date();

    const userAdminApi = new UserAdmin(uname); 
    if(typeof from !== String){
      from = formatDateNormal(from);
    }
    if(typeof to !== String){
      to = formatDateNormal(to);
    }

    setOrders([]);
     
    userAdminApi.getUserCustomerOrders(uname, from, to)
    .then(res => {
      setShowFetch(false);
      setOrders(res.data.recordset);
      console.log("orders", res.data.recordset)
      var tt = (new Date()) - start;
      setLoading(false);

      let makTmp = 0;
      let fakTmp = 0;
      let custTmp = 0;
      let tot = 0;
      res.data.recordset.map(e => {
        if(e.status.toLowerCase() == "makulerad"){
          makTmp += e.fsg;
        }
        if(e.status.toLowerCase() == "fakturerad"){
          fakTmp += e.fsg;
        }
        if(e.status.toLowerCase() == "kund"){
          custTmp += e.fsg;
        }
        tot += e.fsg;
      })
      setVals(p => p = {"mak": makTmp, "fak": fakTmp, "out": tot - makTmp - fakTmp - custTmp, "tot": tot == 0 ? 1 : tot});

    })
    .catch(err => {
      notify("error", `Kunde inte hämta data. Felmeddelande: ${err}`);
      setLoading(false);
    });
  }

  return(
    <Module 
      auths={params.auths} 
      remove={() => onRemoveItem(params.id)}
      ownership={(body) => isOwner(body)}
    >
      <Header color={moduleColor} remove={() => onRemoveItem(params.id)}>
        {params.id} Kunder 
      </Header>
      <Body>
        <Grid container style={{overflowY: "auto", height:"95%", overflowX:"hidden"}}>
          <Grid container item xs={12} style={{marginTop:"5px"}}>
            <Grid item xs={3}>
              <Typography variant="h4">Kunder</Typography>
              <Typography variant="p">
                {uname}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container justify="flex-end">
                <Tooltip title="Ändra datumintervall">
                  <Typography variant="h5" 
                    style={{
                      paddingLeft:"40px", 
                      paddingRight:"20px", 
                      boxShadow:"1px 1px 1px 1px #e6e6e6", 
                      borderRadius:"5px",
                      cursor:"pointer"
                    }}
                    onClick={() => setPickDates(p => !p)}
                  >
                    {formatDateNormal(selectionRange.startDate)} - {formatDateNormal(selectionRange.endDate)}
                    <span>
                    {!pickDates ?
                     <IconButton
                        onClick={() => {setPickDates(p => !p)}}><DateRange />
                      </IconButton>
                      :
                      <IconButton
                        onClick={() => {setPickDates(p => !p)}}><CloseIcon />
                      </IconButton>
                    }
                    </span>
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <AnimatedStat 
                color="#000000"
                desc="Summan av allt FSG"
                tot={vals.tot}
                val={vals.tot}
                title="Total"
              />
            </Grid>
            <Grid item xs={2}>
              <AnimatedStat 
                color="#000000"
                desc="FSG av alla order som inte är kund eller makulerade."
                tot={vals.tot}
                val={vals.out}
                title="Utestående"
              />
            </Grid>
            <Grid 
              container 
              item 
              xs={4} 
              direction="column" 
              alignItems="center" 
              justifyContent="center"
            >
              <Grid item>
                <AnimatedStat 
                  color="#ffc800"
                  desc="FSG av all order som är fakturerade."
                  tot={vals.tot}
                  val={vals.fak}
                  title="Fakturerad"
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="flex-end">
                <div>
                  <AnimatedStat 
                    color="#f72f2f"
                    desc="FSG av all order som är makulerade"
                    tot={vals.tot}
                    val={vals.mak}
                    title="Makulerad"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Collapse in={pickDates} style={{width:"100%"}}>
            <Grid container item xs={12} style={{width:"100%"}}>
              <div style={{width:"100%"}}>
                <DateRangePicker
                  onChange={item => {setSelectionRange(item.selection); setShowFetch(true)}}
                  ranges={[selectionRange]}
                  direction="horizontal"
                  style={{width:"100%", background:theme.palette.background.paper}}
                  locale={locales["sv"]}
                />
                <Fade in={showFetch}>
                  <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Button 
                      onClick={() => {setShowFetch(false);getOrders(selectionRange.startDate, selectionRange.endDate);setPickDates(false)}} 
                      style={{width:"20%", background:"#4f9bff", color:"white"}}>Hämta
                    </Button>
                  </div>
                </Fade>
              </div>
            </Grid>
          </Collapse>
          <Grid container style={{height:"100%", background: theme.palette.background.default}}>
            <div style={{width: "100%"}}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  size="small"
                  data={orders}
                  columns={columns}
                  options={options}
                  style={{width: "100%"}}
                />
              </MuiThemeProvider>
            </div>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
