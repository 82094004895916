import React, { useState, useEffect } from 'react';
import { NewCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { useAppContext } from '../../libs/contextLib';

import NewOrderForm from '../NewCustomer/NewOrderForm';
import CustomerSummary from '../NewCustomer/CustomerSummary';
import FinishOrder from '../NewCustomer/FinishOrder';

import {makeStyles } from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Slide from "@material-ui/core/Slide"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import gif from "../../assets/customer_created_animation.gif";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  mainGrid:{
    width: '100%',
  },
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
  },
  large:{
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  caption: {
    color: "gray",
  }
}));

function getSteps(){
  return ['Skriv', 'Inspektera', 'Skapa'];
}

function getStepContent(step, params){
  
  switch (step) {
    case 0:
      return (
        <NewOrderForm params={params} />
      );
    case 1:
      return (
        <CustomerSummary />
      );
    case 2:
      return <FinishOrder newOrder={true}/>
    default:
      return <Typography>Steget finns inte</Typography>
  }
}

export default function NewOrderModule(params){

  const { onRemoveItem, addModule } = useMainGridContext();

  const classes = useStyles();

  const [fsg, setFsg] = useState(0);
  const [loadingFSG, setLoadingFSG] = useState(false);

  // Stepper
  const [activeStep, setActiveStep]           = useState(0);
  const [skipped, setSkipped]                 = useState(new Set()); 
  const [validationError, setValidationError] = useState(false);
  const [changeStep, setChangeStep]           = useState(true);
  const [stepDir, setStepDir]                 = useState(true);
  const steps = getSteps();

  // Service data
  const [services, setServices]         = useState([]);
  const [serviceName, setServiceName]   = useState("");
  const [billingLen, setBillingLen]     = useState("");
  const [billing, setBilling]           = useState("");
  const [price, setPrice]               = useState("");
  const [amount, setAmt]                = useState("");
  const [nextDisabled, setNextDisabled] = useState(false);
  const [createdCid, setCreatedCid]     = useState(false);
  const [comments, setComments]         = useState([]);

  const { customersApi } = useAppContext();

  const [currentService, setCurrentService] = useState({
    "service": "",
    "price": "",
    "amount": "",
  });

  const [errorText, setErrorText] = useState({
    "service": "",
    "price": "",
    "amount": "",
    "subscriptionLength": "",
    "billing": "",
  });

  const [customerInfo, setCustomerInfo] = useState({
    "customerid": "0",
    "name": "",
    "adress": "",
    "socialSecurityNumber": "",
    "zipCode": "",
    "municipality": "",
    "telephone": "",
    "email": "",
    "salesman": ""
  });

  useEffect(() => {
    if(customersApi !== null){
      customersApi.get(params.cid)
        .then(res => {
          const info = res.data.recordset[0];
          setCustomerInfo(info);
        });
    }
  },[customersApi, params.cid]);

  const validateAll = () => {
    if(
      currentService.service !== undefined && 
      currentService.service.length > 0 &&
      currentService.price.length > 0 &&
      currentService.amount.length > 0 &&
      activeStep == 0
    ){
      services.push(currentService);
    }

    else if(services.length == 0){
      return false;
    }
    
    for(var i = 0; i < services.length; i++){
      if(services[i].service.length == 0){
        return false;
      }
      if(services[i].price.length == 0){
        return false;
      }
      if(services[i].amount.length == 0){
        return false;
      }
    }

    if(billing.length == 0){
      return false;
    }
    if(billingLen.length == 0){
      return false;
    }

    return true;
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if(validateAll()){
      setStepDir("left")
      setChangeStep(false);

      setTimeout(() => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        setStepDir("right");
        setChangeStep(true);
      }, 400);
    }
  }

  const handleBack = () => {
    setStepDir("right");
    setChangeStep(false);

    setTimeout(() => {
      setCurrentService({});
      setActiveStep((prevActiveStep) => prevActiveStep - 1);

      setStepDir("left");
      setChangeStep(true);
    }, 400);
  }

  const handleReset = () => {
    setServiceName("");
    setBilling("");
    setBillingLen("");
    setPrice("");
    setAmt("");
    setServices([]);
    setActiveStep(0);
  }

  const hideValidationError = (field) => {
    setValidationError(false);
  }

  const isOwner = async (body) => {
    if(customerInfo.customerid == "0"){
      var res = await setTimeout(async () => {
        var r =  await isOwner(body);
        return r;
      }, 100);
      return res;
    }
    else{
      alert(body.username == customerInfo.salesman);
      return body.username === customerInfo.salesman;
    }
  }

  return(
    <NewCustomerContext.Provider value={{
        customerInfo, 
        setCustomerInfo, 
        errorText, 
        setErrorText, 
        validationError,
        setValidationError,
        services,
        serviceName,
        validateAll,
        setServiceName,
        setServices,
        billingLen,
        setBillingLen,
        billing,
        setBilling,
        price,
        setPrice,
        amount,
        setAmt,
        hideValidationError,
        currentService,
        setCurrentService,
        setNextDisabled,
        handleNext,
        setCreatedCid,
        fsg,
        setFsg,
        comments,
        setComments,
        loadingFSG,
        setLoadingFSG
      }}
    >
      <Module
        auths={params.auths}
        remove={() => onRemoveItem(params.id)}
        index={params.id}
        ownership={(body) => isOwner(body)}
      >
        <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
          {params.id} Ny order
        </Header>
        <Body>
          <Grid container>
            <Grid container justifyItems="center" align="center" direction="column">
              <Typography variant="h4" style={{marginTop: "50px"}}>
                <b>Ny order {params.cid}</b>
              </Typography>
              <Typography variant="p">
                Lägg till en order på {customerInfo.name} ({customerInfo.customerid}) 
              </Typography>
            </Grid>
            <form style={{width: "100%"}}>
              {activeStep === steps.length ? (
                <Grid container alignItems="center" justify="center" direction="column">
                    <img src={gif} alt="successGif" style={{height: "50%", width:"50%"}}/>
                    <Typography>
                      {customerInfo.name}
                    </Typography>
                    <Button onClick={() => addModule(`::om ${customerInfo.customerid}`)}>
                      Öppna 
                    </Button>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                          <Button 
                            onClick={handleReset}
                            style={{width: "100%"}}
                          >
                            <Grid container>
                              <Grid item xs={1}>
                                <AccountCircleIcon />
                              </Grid>
                              <Grid item xs={11}>
                               Ny kund 
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            onClick={params.remove}
                            style={{width: "100%"}}
                          >
                            <Grid container>
                              <Grid item xs={11}>
                                Stäng
                              </Grid>
                              <Grid container xs={1} alignItems="flex-end" justify="flex-end">
                                <CloseIcon />
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                      </Grid>
                </Grid>
              ) : (
                <Grid xs={12}>
                  <div style={{overflow: "hidden"}}>
                    <Slide direction={stepDir} in={changeStep} timeout={{enter:200, exit:200}}>
                      <div style={{padding: "10px"}}>
                        {getStepContent(activeStep, params)}
                      </div>
                    </Slide>
                  </div>
                    {activeStep === 1 ?
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          <Button 
                            onClick={handleBack}
                            style={{width: "100%"}}
                          >
                            <ArrowBackIosIcon style={{fontSize:"medium"}}/>
                            Tillbaka
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            onClick={handleNext}
                            style={{width: "100%"}}
                          >
                            Skapa order
                            <ArrowForwardIosIcon style={{fontSize:"medium"}}/>
                          </Button>
                        </Grid>
                      </Grid>
                      :
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            onClick={handleNext}
                            style={{width: "100%"}}
                            disabled={nextDisabled ? true : false}
                          >
                            Nästa
                            <ArrowForwardIosIcon style={{fontSize:"medium"}}/>
                        </Button>
                        </Grid>
                      </Grid>
                    }
                </Grid>
              )
              }
             </form>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </Grid>
        </Body>
      </Module>
    </NewCustomerContext.Provider> 
  )

}
