import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { useMainGridContext } from '../../../libs/mainGridContextLib.js';
import { useAppContext } from '../../../libs/contextLib';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import VasaEmojis from '../../general/Emojis';
import FileUpload from '../../general/file-upload';

import EntityEvents from '../../../shared/EntityEvents.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import { getMime } from '../../../shared/file-handler/utils.js';

import EventIcon from '@material-ui/icons/Event';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';

import OfficePrefs from '../../../shared/OfficePrefs.js'

import {
  Typography,
  Button,
  Grid,
  Tooltip,
  Collapse,
  TextField,
  IconButton,
  MenuItem
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button:{
    background:"transparent", 
    border:"none", 
    color:theme.palette.text.primary, 
    cursor:"pointer",
    width:"100%", height:"100%"
  },
}));

export default function LinkShareForm(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser } = useAppContext();
  const { notify } = useMainGridContext();

  const [current, setCurrent]                 = useState("title");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [title, setTitle]             = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl]                 = useState("");
  const [imgUrl, setImgUrl]           = useState("");
  const [type, setType]               = useState("Annat");

  const [defaultTitle, setDefaultTitle] = useState("");

  const offPrefs = new OfficePrefs();

  const onEmojiClick = (emojiObject, event) => {
    if(current == "title"){
      setTitle(prev => prev + emojiObject.native);
    }
    else{
      setDescription(prev => prev + emojiObject.native);
    }
  }

  const publish= () => {
    var body = {
      creator: currentUser.username,
      title: title,
      description: description,
      url: url,
      type: type,
      img: imgUrl
    }
    offPrefs.createLink(body).then(res => {
      notify("success", "Viktigt meddelande är publicerad");
    })
    .catch(err => {notify("error", `Kunde inte publicera. ${err}`)});
  }

  useEffect(() => {
    const apiKey = "AIzaSyB9mlk5GeQkWaI-K6lYkwJhAW5igOrt-4A";
      offPrefs.getLinkPreview(url).then(res => {
        setTitle(res.data.title)
        setDescription(res.data.description)
        setImgUrl(res.data.images[0]);
      });
  },[url])

  return(
    <>
      <Grid item xs={12}>
        <Collapse in={showEmojiPicker}>
          <VasaEmojis onEmojiClick={(o, e) => onEmojiClick(o,e)}/>
        </Collapse>
      </Grid>

      <Grid container item xs={12}>
        <Grid 
          item 
          container
          xs={1} 
          justify="center" 
          alignItems="center"
          style={{paddingTop:"10px"}}
        >
          <IconButton 
            onClick={() => setShowEmojiPicker(prev => !prev)}
          >
            <EmojiEmotionsIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <TextField 
            fullWidth 
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => setCurrent("title")}
            multiline
          />
          <TextField 
            fullWidth
            label="Beskrivning"
            onChange={(e) => setDescription(e.target.value)}
            onFocus={() => setCurrent("description")}
            value={description}
            multiline
          />
          <TextField 
            style={{marginTop:"10px"}}
            fullWidth
            label="https://..."
            variant="outlined"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
            multiline
          />
          {url.length > 0 ?
            <img src={imgUrl} alt="Hittar ingen miniatyr"/>
            :
            null
          }
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="flex-end">
        <Button onClick={publish}>
          <PublishIcon /> Publicera
        </Button>
      </Grid>
    </>
  )
}

