import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';
import { EditableText, EditableSelect} from '../general/UpdatableValue.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDate} from '../../shared/dateHelper.js'

import Docs from '../../shared/Docs.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import AddIcon from '@material-ui/icons/Add';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip,
  Fade,
  MenuItem
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

}))

const CustomTableToolbar = (props) => {
  const handleClick = () => {
    props.onAdd();
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default function CategoryModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify } = useMainGridContext();
  const { entityEventsApi, productsApi, currentUser } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [cats, setCats] = useState([]);
  const [docs, setDocs] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryOb, setNewCategoryOb] = useState("");
  const [showNew, setShowNew] = useState(false);

  const [updatable, setUpdatable] = useState(false);

  useEffect(() => {
    canUpdate(params.updateAuths);
    if(
      (cats.length == 0 || dataFromServer.entity == "categoriesTable") &&
      productsApi !== null)
    {
      productsApi.getAllCategories().then(res => setCats(res.data.categories));
      var docsApi = new Docs();
      docsApi.getAll()
        .then(res => setDocs(res.data.prefs))
        .catch(err => console.log(err));

    }
    else{
      console.log(productsApi);
    }
  }, [productsApi, dataFromServer]);

  const addRow = () => {
    setShowNew(prev => !prev);
  }

  const createCategory = () => {
    var body = {name: newCategoryName, tax: "0.8", creator: currentUser.username};
    productsApi.createCategory(body).then(res => {
      notify("success", `${newCategoryName} har skapats.`);
      setShowNew(false);
    });
  }

  const updateNewCategoryName = (e) => {
    setNewCategoryName(e.target.value);
  }

  const deleteServices = (is) => {
    is.map(i => {
      var id = cats[i].id;
      console.log("remove", id);
      productsApi.deleteCategory(id)
        .then(res => notify("info", "Kategori har blivit borttagen."))
        .catch(err => notify("error", "Något gick fel när databasen kontaktades."));
    });
  }

  const options = {
    filterType: 'checkbox',
    customToolbar: () => {
      return(<CustomTableToolbar onAdd={() => addRow()}/>)
    },
    onRowsDelete: (rowsDeleted, data) => {
      deleteServices(Object.keys(rowsDeleted.lookup));
    },
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "name",
      label: "Namn",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableText
            dbLabel="name"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            onUpdate={(nv) => productsApi.categoryUpdateWhere(tableMeta.rowData[0], "name", nv)}
            updatable={true}
          />)
        },
        sort: true
      }
    },
    {
      name: "doc",
      label: "Dokument",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<EditableSelect
            dbLabel="name"
            id="0" 
            value={value}
            url="https://isgg-intelligens.biz"
            align="left"
            list={docs.map(e => {return e.name})}
            updateFunction={(nv) => 
              productsApi.categoryUpdateWhere(tableMeta.rowData[0], "doc", nv)}
            updatable={true}
        />)}
    }},
    {
      name: "creator",
      label: "Skapare",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "created",
      label: "Skapad",
      options: {
        filter: false,
        sort: true
      }
    },
  ];

  const canUpdate = (authList) => {
    if(authList.includes("*")){
      setUpdatable(true);
      return;
    }
    for(var i = 0; i < authList.length; i++){
      var main = authList[i].split(":");
      var auth = main[0];
      if(main.length == 1){
        if(currentUser.auth == auth){
          setUpdatable(true);
          return;
        }
        else{
          setUpdatable(false);
          return;
        }
      }
    }
    setUpdatable(false);
    return;
  }

  return (
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="#ff9100" remove={() => onRemoveItem(params.id)}>
        {params.id} Produktkategorier
      </Header>
      <Body>
        <Grid container>
          <div style={{width: "100%"}}>
            <MUIDataTable
              title="Produktkategorier"
              data={cats}
              columns={columns}
              options={options}
              style={{width: "100%"}}
            />
          </div>
          <Fade in={showNew}>
            <Grid container style={{marginTop: "20px"}} spacing={2}>
              <Grid item xs={12}>
                <TextField 
                  onChange={updateNewCategoryName}
                  label="Namn" 
                  style={{width:"100%"}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  onChange={(e) => setNewCategoryOb(e.target.value)}
                  label="Orderbekräftelse" 
                  style={{width:"100%"}}
                  value={newCategoryOb}
                  select
                >
                  {
                    docs.map((e,i) => (
                      <MenuItem key={e.name} value={e.name}>
                        {e.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid container xs={12} alignItems="flex-end" justify="flex-end">
                <Button 
                  color="default" 
                  style={{
                    borderRadius:"5px", 
                    paddingRight: "25px", 
                    paddingLeft: "25px"}}
                >
                  Avbryt
                </Button>
                <Button 
                  color="primary" 
                  onClick={createCategory}
                  style={{
                    borderRadius:"5px", 
                    paddingRight: "25px", 
                    paddingLeft: "25px"}}
                >
                  Lägg till 
                </Button>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Body>
    </Module>
  )
}
