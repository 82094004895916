import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';

import {
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => {

})

export default function NewFastAccess(params){
  const { onRemoveItem, notify} = useMainGridContext();
  const { modulesApi } = useAppContext();

  const [fas, setFas] = useState([]);
  const [modules, setModules] = useState([]);
  const [newModuleName, setNewModuleName] = useState("");

  useEffect(() => {
    if(modulesApi !== null){
      console.log("new fast access");
      if(localStorage.fastAccess !== undefined){
        setFas(JSON.parse(localStorage.fastAccess));
        modulesApi.get().then(res => {
          console.log(res.data.modules);
          setModules(res.data.modules);
        });
      }
      else{
        modulesApi.get().then(res => {
          setModules(res.data.modules);
        });
      }
    }
  },[modulesApi])

  const changeNewModuleName = (e) => {
    console.log(e.currentTarget.value);
    setNewModuleName(e.currentTarget.value);
  }

  const addFas = (e) => {
    var fa = [];
    if(localStorage.fastAccess === undefined){
      fa = ["nykund", "anv", newModuleName];
    }
    else{
      fa = JSON.parse(localStorage.fastAccess);
      fa.push(newModuleName);
    }
    localStorage.setItem("fastAccess", JSON.stringify(fa));
    notify("success", "Ny snabbis har lagts till");
    setFas(JSON.parse(localStorage.fastAccess));
    setNewModuleName("");
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Ny snabbåtkomst
      </Header>
      <Body>
        <Grid container>
          <Typography variant="h4">
            Ny snabbis
          </Typography>
          <Grid xs={12}>
            <Table size="small" style={{border:"1px solid #eeeeee", marginTop:"20px"}}>
              <TableHead>
                <TableRow>
                  <TableCell><b>Dom här finns redan sparade</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fas.map((e) => {
                  return(
                    <TableRow key={e}>
                      <TableCell>{e}</TableCell>
                    </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid  xs={12}>
            <Autocomplete
              options={modules}
              getOptionLabel={(option) => option.name }
              onChange={(x,modObj) =>setNewModuleName(modObj.name)}
              style={{width:"100%"}}
              renderInput={
                (params) => <TextField onChange={(e)=>changeNewModuleName(e)} {...params} label="Den nya modulens namn" 
              />}
            />
            <Button color="primary" style={{width:"100%"}} onClick={(e) => addFas(e)}>
              Skapa
            </Button>
          </Grid>
        </Grid>
      </Body>
    </Module>
  );

}
