import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { 
  CurrentCustomerContext, 
  useCurrentCustomerContext } from '../../libs/currentCustomerContext';

import { EditableText } from '../general/UpdatableValue';

import Customers from '../../shared/Customers.js';
import { canOpenModule, getModule } from '../../shared/moduleAuthority.js';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  Grid, 
  Typography,
  Avatar,
  Button,
  Fade,
  TextField,
  CircularProgress,
  Select,
  Menu,
  MenuItem,
  Chip,
  Tooltip
} from '@material-ui/core';
import {Header, Body, Module} from '../Modules'; 
import {BiUserPin} from "react-icons/bi";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {BsHouseDoor} from "react-icons/bs";
import {CgPassword} from "react-icons/cg";
import {GoLocation} from "react-icons/go";
import {AiOutlinePhone, AiOutlineMail} from "react-icons/ai";
import {MdUpdate} from "react-icons/md";
import FaceIcon from '@material-ui/icons/Face';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UpdateIcon from '@material-ui/icons/Update';
import successGif from "../../assets/success.gif";
import errorGif from "../../assets/error.gif";
import {deepPurple} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  button:{
    width: "100%",
  },
  infoRow:{
    borderRadius: "5px",
    '&:hover':{
      cursor: "pointer"
    }
  },
  buttonContainer:{
    //position: "absolute",
    bottom: "1%",
    left: "0%",
    padding: "10px",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  personInfo:{
    background: theme.palette.background.default, 
    padding:"10px", 
    borderRadius:"5px"
  }
}))

function CommentSection(params){
  const classes = useStyles();
  const theme = useTheme();

  const { notify } = useMainGridContext();
  const { commentsApi, currentUser } = useAppContext();

  const [newComment, setNewComment] = useState("");
  const [showCommentButtons, setShowCommentButtons] = useState(false);
  const [cid, setCid] = useState(null); 
  const [comments, setComments] = useState([]);

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  }

  const postComment = (e) => {
    var body = {
      "author": currentUser.username,
      "content": newComment,
      "regarding": cid 
    }
    if(newComment.length > 0){
      commentsApi.create(body)
        .then(res => {
          fetchComments();
          notify("success", "Ny kommentar skapad");
        })
        .catch(err => 
          notify("error", `Kund inte skapa kommentar. Felmeddelande: ${err}`
        ));
      setNewComment("");
      setShowCommentButtons(false);
    }
    else{
      notify("error", "Du måste skriva något!");
    }
  }

  const fetchComments = () => {
    commentsApi.getWhere("regarding", params.record.customerid)
      .then(res => {
        setComments(res.data.recordset.reverse());
      }).catch(err => console.log("cant fetch comments"));
  }

  useEffect(() => {
    if(params.record !== null){
      setCid(params.record.customerid);
      fetchComments();
    }
  },[params.record])

  return(
    <Grid container>
      <Typography variant="h6" className={classes.personInfo}>
        Kommentarer
      </Typography>
      <Grid container className={classes.personInfo}>
        <TextField
          label="Skriv en kommentar"
          multiline
          rowMax={10}
          value={newComment}
          onChange={handleNewCommentChange}
          style={{
            width:"100%", 
            background:theme.palette.background.paper, 
            borderRadius:"5px"
          }}
          onFocus={() => setShowCommentButtons(true)}
        />
        <Fade in={showCommentButtons}>
          <Grid container>
            <Grid item xs={6}>
              <Button 
                fullWidth
                color="secondary" 
                onClick={() => {setShowCommentButtons(false); setNewComment("")}}
              >
                Avbryt 
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth color="primary" onClick={postComment}>
                Kommentera 
              </Button>
            </Grid>
          </Grid>
        </Fade>
        <Grid container spacing={2}>
          {comments.map(comment => {
             return(
               <Grid 
                 item 
                 xs={12} 
               >
               <Grid container>
                 <Grid xs={2}>
                   <Avatar className={classes.purple}>
                     <FaceIcon size="large" />
                   </Avatar>
                 </Grid>
                 <Grid container xs={10}>
                   <Grid item item xs={6}>
                     <Typography variant="subtitle2">
                       <b>{comment.author}</b>
                     </Typography>
                   </Grid>
                   <Grid container xs={6} alignItemms="flex-end" justify="flex-end">
                     {comment.time !== null ?
                     <Typography variant="subtitle2">
                       {comment.time.replace("T", " ").replace("Z", "")}
                     </Typography>
                     :
                      <Typography variant="subtitle2">
                        null
                      </Typography>
                     }
                   </Grid>
                 </Grid>
                 <Typography>
                   {comment.content}
                 </Typography>
               </Grid>
             </Grid>
             )
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

function CustomerGeneral(params){
  const classes = useStyles();
  const theme = useTheme();
  
  const customer = params.customer;

  const { customersApi } = useAppContext();

  const [name, setName]       = useState(null);
  const [cid, setCid]         = useState(null);
  const [created, setCreated] = useState(null);
  const [sman, setSman]       = useState(null);

  const meta      = params.meta;
  const updatable = params.updatable;
  const loadTime  = params.loadTime;

  useEffect(() => {
    if(params.record !== null){
      setName(params.record.name);
      setCid(params.record.customerid);
      setSman(params.record.salesman);
      setCreated(params.record.dateOfRegistration);
    }
  },[params.record])

  return(
    <Grid container xs={12}>
      <Grid container xs={2} justify="center" alignItems="center">
        <Avatar className={classes.large} >
          <AccountCircleIcon className={classes.large}/>
        </Avatar>
      </Grid>
      <Grid container xs={10}>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              KID: {cid} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              Skapad: {created} 
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="flex-end" justify="flex-end">
            <Typography variant="subtitle2">
              Inladdningstid: {loadTime}s 
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EditableText 
            onUpdate={(e) => customersApi.updateWhere(params.record.customerid, "name", e)}
            value={name}
            variant="h3"
            updatable={true}
            style={{textAlign:"right"}}
          />
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              Säljare: {sman} 
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function CustomerInfoContainer(params){
  const classes = useStyles();
  const theme = useTheme();

  const setSuccess  = params.setSuccess;
  const updatable   = params.updatable;

  const [ssn, setSsn]     = useState(params.ssn);
  const [adr, setAdr]     = useState(params.adr);
  const [mun, setMun]     = useState(params.mun);
  const [zip, setZip]     = useState(params.zip);
  const [tel, setTel]     = useState(params.tel);
  const [email, setEmail] = useState(params.email);
  const [cid, setCid]     = useState(params.cid);
  const record = params.record;

  const [isLoading, setIsLoading]   = useState(true);

  useEffect(() => {
    if(params.record !== null){
      setSsn(record.socialSecurityNumber);
      setAdr(record.adress);
      setMun(record.municipality);
      setZip(record.zipCode);
      setTel(record.telephone);
      setEmail(record.email);
      setCid(record.customerid);
    }
  },[params.record]);

  return(
    <Grid container>
      <Typography variant="h6" style={{background:theme.palette.background.default, padding:"10px", borderRadius:"5px"}}>
        Personuppgifter {updatable ? '*' : ''}
      </Typography>
      <Grid container>
        <Grid container xs={12} spacing={2} className={classes.personInfo}>
          <Grid item xs={4}>
            <InfoRow 
              icon={<BiUserPin size={20}/>} 
              label="Personnummer" 
              dbLabel="socialSecurityNumber"
              cid={cid}
              value={ssn}
              updatable={updatable}
              setSuccess={() => setSuccess(true)}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={2} className={classes.personInfo}> 
          <Grid item xs={4}>
            <InfoRow 
              icon={<BsHouseDoor size={20}/>} 
              label="Adress" 
              dbLabel="adress"
              cid={cid}
              value={adr} 
              updatable={updatable}
              setSuccess={() => setSuccess(true)}
            />
          </Grid>
          <Grid item xs={4}>
            <InfoRow 
              icon={<CgPassword 
              size={20}/>}
              label="Postnummer" 
              dbLabel="zipCode"
              cid={cid}
              value={zip} 
              updatable={updatable}
              setSuccess={() => setSuccess(true)}
            />
          </Grid>
          <Grid item xs={4}>
            <InfoRow 
              icon={<GoLocation size={20}/>} 
              label="Ort" 
              dbLabel="municipality"
              cid={cid}
              value={mun} 
              updatable={updatable}
              setSuccess={() => setSuccess(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} className={classes.personInfo}>
        <Grid item xs={4}>
          <InfoRow 
            icon={<AiOutlinePhone size={20}/>} 
            label="Telefon" 
            dbLabel="telephone"
            cid={cid}
            value={tel} 
            updatable={updatable}
            setSuccess={() => setSuccess(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoRow 
            icon={<AiOutlineMail size={20}/>} 
            label="E-mail" 
            dbLabel="email"
            cid={cid}
            value={email == undefined || email.length == 0 ? "..." : email} 
            updatable={updatable}
            setSuccess={() => setSuccess(true)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

function CustomerFooter(params){
  const classes = useStyles();
  const theme = useTheme();

  const { addModule } = useMainGridContext();

  const [paymentAnchor, setPaymentAnchor] = useState(null);
  const [orderAnchor, setOrderAnchor]     = useState(null);

  const [orderSelectOpen, setOrderSelectOpen] = useState(false);
  const [paymentSelectOpen, setPaymentSelectOpen] = useState(false);
  const [cid, setCid] = useState(null);

  const modLinks = params.modLinks;
  const record = params.record;

  const handlePaymentClick = (e) => {
    setPaymentAnchor(e.currentTarget);
  }

  const handleOrderClick = (e) => {
    setOrderAnchor(e.currentTarget);
  }

  useEffect(() => {
    if(params.record !== null){
      setCid(params.record.customerid);
    }
  },[params.record])


  return( 
    <Grid container className={classes.buttonContainer}>
      {modLinks["kh"] ?
        <Grid xs={4} container justify="center" alignItems="center"> 
          <Button 
            color="primary" 
            variant="outlined"
            className={classes.button}
            onClick={() => {
              addModule(`::kh ${cid}`)
            }}
          >
            Historik
          </Button>
        </Grid>
        :
        null
      }
      <Grid xs={4} container justify="center" alignItems="center">
        <Button
          color="primary"
          variant="outlined"
          onClick={(e) => {handlePaymentClick(e)}}
          fullWidth
        >
          Betalning
        </Button>
        <Menu
          anchorEl={paymentAnchor}
          keepMounted
          open={Boolean(paymentAnchor)}
          onClose={(e) => setPaymentAnchor(null)}
        >
          {modLinks["nyinbet"] ?
            <MenuItem 
              value="Skapa ny" 
              onClick={() => {
                addModule(`::nyinbet ${cid}`); 
                setPaymentSelectOpen(false);
                setPaymentAnchor(null);
            }}
            >
              Skapa ny
            </MenuItem>
          :
            null
          }
          {modLinks["inbet"] ?
            <MenuItem 
              value="Historik" 
              onClick={() => {
                addModule(`::inbet ${cid}`); 
                setPaymentSelectOpen(false)
                setPaymentAnchor(null);
              }
              }
              onMouseExit={() => setPaymentSelectOpen(false)}
            >
              Historik 
            </MenuItem>
          :
            null
          }
        </Menu>
      </Grid>
      <Grid 
        xs={4} 
        container 
        justify="center" 
        alignItems="center" 
        onMouseExit={() => setOrderSelectOpen(false)}
      > 
        <Button
          onClick={(e) => handleOrderClick(e)}
          fullWidth
          color="primary"
          variant="outlined"
        >
          Order
        </Button>
        <Menu 
          anchorEl={orderAnchor}
          fullWidth
          keepMounted
          open={Boolean(orderAnchor)}
          onClose={(e) => setOrderAnchor(null)}
        >
          {modLinks["nyorder"] ?
            <MenuItem 
              value="Skapa ny" 
              onClick={() => {
                addModule(`::nyorder ${cid}`); 
                setOrderSelectOpen(false);
                setOrderAnchor(null);
              }
              }>
              Skapa ny
            </MenuItem>
          :
            null
          }
          {modLinks["order"] ?
            <MenuItem 
              value="Visa alla" 
              onClick={() => {
                addModule(`::order ${cid}`); 
                setOrderSelectOpen(false);
                setOrderAnchor(null);
              }
              }
              onMouseExit={() => setOrderSelectOpen(false)}
            >
            Visa alla
            </MenuItem>
          :
            null
          }
        </Menu>
      </Grid>
    </Grid>
  )

}

function InfoRow(params){
  const classes = useStyles();
  const theme = useTheme();

  const { notify } = useMainGridContext();
  const label = params.label;
  const dbLabel = params.dbLabel;
  const cid = params.cid;
  const [value, setValue] = useState(params.value);
  const [updLoading, setUpdLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [update, setUpdate] = useState({"old": value, "new": value});

  const updatable = params.updatable === undefined ? true : params.updatable;
  const { customersApi } = useAppContext();

  useEffect(() => {
    if(params.value == null || params.value.length == 0){
      setValue("...");
    }
    else{
      setValue(params.value);
    }
  },[params.value])

  const handleKeyDown = (event) => {
    if(event.key === "Enter"){
      hideTextField();
    }
  }

  const handleChange = (event) => {
    setUpdate({"old": value, "new": event.target.value});
  } 

  const updateValue = () => {
    setUpdLoading(true);
    return customersApi.updateWhere(cid, dbLabel, update.new);
  }

  const hideTextField = () => {
    if(update.old !== update.new){
      updateValue().then(res => {
        setValue(update.new);
        setSuccess(true);
        setUpdLoading(false);
      });
    }
    setShowTextfield(false);
  }

  const renderValue = () => {
    if(showTextfield){
      return(
        <TextField 
          onBlur={hideTextField} 
          style={{width:"100%"}}
          onKeyDown={handleKeyDown} 
          defaultValue={value} 
          autoFocus
        />
      )
    }
    if(success){
      setTimeout(() => setSuccess(false), 500);
      return <img src={successGif} alt="successGif" style={{width:"20px"}}/>
    }
    if(updLoading){
      return(<CircularProgress size={20}/>)
    }
    return (
      <Typography variant="p">
        {value}
      </Typography>
    )
  }

  return(
    <Grid container spacing={0} style={{marginTop: "5px"}}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
         {params.icon !== undefined ? params.icon : null} {label}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.infoRow} 
        onMouseEnter={() => setShowIcon(true)} 
        onMouseLeave={() => setShowIcon(false)}
        onClick={() => {
          if(updatable){setShowTextfield(true)}
          else{
            notify("error", "Du har inte behörighet att uppdatera denna modulen")
          }
        }
        }
        onChange={handleChange}
      >
        <Grid container style={{background:theme.palette.background.paper, borderRadius:"5px"}}>
          <Grid item xs={10}>
            {renderValue()}
          </Grid>
          <Grid container xs={2} alignItems="flex-end" justify="flex-end">
            {showIcon ? <Fade in={showIcon}><MdUpdate /></Fade> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function GeneralInfoRow(params){

  const classes = useStyles();
  const theme = useTheme();

  return(
    <Grid container>
      <Typography variant="h5" className={classes.personInfo}>
        Övrigt
      </Typography>
      <Grid container xs={12} className={classes.personInfo}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Typography variant="subtitle2">
              Autogiro 
            </Typography>
            <Typography variant="subtitle2">
              Ja 
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Typography variant="subtitle2">
              Kundtyp 
            </Typography>
            <Typography variant="subtitle2">
              Privatkund
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

}

function MetaInfo(params){

  const [meta, setMeta] = useState(params.meta);
  const { dataFromServer } = useComsContext();

  useEffect(() =>{
    setMeta(params.meta);
  },[params.meta, dataFromServer])
  
  return(
    <Grid container style={{marginTop: "20px", marginBottom: "20px"}}>
      <Grid item xs={4}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Tooltip title="säljare">
            <Chip
              label={meta.sman}
              icon={<FaceIcon />}
              color="primary"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{}}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Tooltip title="senast ändrad">
            <Chip
              label={meta.upd}
              icon={<MdUpdate size={30}/>}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Tooltip title="skapad">
            <Chip
              label={meta.created == null ? null : meta.created.slice(0,10)}
              icon={<AccessTimeIcon />}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )

}

export default function CustomerPageModule(params){

  const classes = useStyles();
  const { 
    addModule, 
    notify, 
    onRemoveItem, 
    currentOwner, 
    setCurrentOwner 
  } = useMainGridContext();
  const {customersApi, 
        commentsApi, 
        currentUser, 
        entityEventsApi, 
        modulesApi} = useAppContext();
  const { dataFromServer } = useComsContext();
  const [updatable, setUpdatable] = useState(false);
  const [modLinks, setModLinks] = useState({
    kh: false, 
    nyinbet: false, 
    inbet: false, 
    nyorder: false, 
    order: false 
  });

  const [loading, setLoading] = useState(true);
  const [updateIcon, setUpdateIcon] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loadTime, setLoadTime] = useState(0);

  const [showCommentButtons, setShowCommentButtons] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState([]);

  const [modulesSet, setModulesSet] = useState(false)

  const [record, setRecord] = useState(null);
  const [name, setName] = useState(null);
  const [ssn, setSsn] = useState(null);
  const [adr, setAdr] = useState(null);
  const [zip, setZip] = useState(null);
  const [mun, setMun] = useState(null);
  const [tel, setTel] = useState(null);
  const [email, setEmail] = useState(null);
  const [customer, setCustomer] = useState(
    {
      "name": null, 
      "cid": null,
      "ssn": null,
      "adr": null,
      "zip": null,
      "tel": null,
      "email": null,
      "ag": null,
    });

  const userCanReadModule = (modName) => {
    return modulesApi.getName(modName);
  }

  const [meta, setMeta] = useState({
    "sman": null,
    "upd": null,
    "created": null,
    "type": null
  });
  const [currentSalesman, setCurrentSalesman] = useState(null);

  const cid = params.cid;


  const getValue = () => {
    if(customersApi !== null){
    setLoading(true);
    var start  = new Date();
    customersApi.get(cid)
      .then(res => {
        var end = new Date();
        setLoadTime((end - start)/1000);
        if(res.data.recordset.length == 0){
          setLoading(false)
          setError(true);
          return;
        }
        var record = res.data.recordset[0];
        // Set customer values
        var tmpCustomer = {};
        tmpCustomer.name = record.name;
        tmpCustomer.cid = record.customerid;
        meta.sman = record.salesman;
        setCurrentSalesman(record.salesman);
        meta.upd = "N/A";
        meta.created = record.dateOfRegistration;
        meta.type = "N/A";
        setMeta(meta);

        // setMeta(meta);

        setCustomer(customer);
        setSsn(record.socialSecurityNumber);
        setAdr(record.adress);
        setMun(record.municipality);
        setZip(record.zipCode);
        setTel(record.telephone);
        setEmail(record.email);
        setName(record.name);
        setRecord(record);

        setLoading(false);
        setSuccess(true);

        commentsApi.getWhere("regarding", record.customerid)
          .then(res => {
            setComments(res.data.recordset)
          }).catch(err => console.log("net err"));
      })
      .catch(err => {
        notify("error", "Det gick tyvär inte att hämta kunden... Kontrollera din nätverksanslutning och testa igen. :)");
      })
    }
  }

  const setModuleRead = (modNames, uname, uAuth, conditions) => {
    modulesApi.get()
      .then(res => {
        let d = res.data.modules;
        var res = {};
        modNames.map(modName => {
          for(var i = 0; i < d.length; i++){
            if(d[i].name == modName){
              res[modName] = 
                canOpenModule(
                  modName, 
                  uAuth, 
                  d[i].auths, 
                  uname, 
                  conditions
                ); 
            }
          } 
        })
        setModLinks(prev => (res));
      }).catch(err => {notify("error", `Kan inte hämta moduler för att berättiga åtkomst`)});
  }

  useEffect(() => {
    canUpdate(params.updateAuths);
  },[dataFromServer, customersApi]);

  useEffect(() => {
    if(modulesApi !== null && !modulesSet){
      getValue();
      const uname = currentUser.username;
      const uAuth = currentUser.auth;
      setModuleRead(
        ["kh", "nyinbet", "inbet", "nyorder", "order"], 
        uname,uAuth,
        {ownership:(body) => isOwner(body)}
      );
      setModulesSet(p => true)
    }
  },[modulesApi])

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  }

  const postComment = (e) => {
    var body = {
      "author": "admin1",
      "content": newComment,
      "regarding": cid 
    }
    commentsApi.create(body)
      .then(res => {getValue(); setNewComment("")});
  }

  const topIcon = () => {
    if(loading){
      return (
        <CircularProgress 
          onMouseLeave={() => setUpdateIcon(false)} 
          size={70}
        />)
    }
    if(success){
      setTimeout(() => setSuccess(false), 500);
      return <img src={successGif} style={{width:"80px"}} color="secondary"/>
    }
    if(error){
      return <img src={errorGif} style={{width:"80px"}} color="secondary"/>
    }
    if(updateIcon) {
      return (
        <Fade in={updateIcon}>
          <UpdateIcon
            className={classes.large}
            color="disabled"
            onMouseLeave={() => setUpdateIcon(false)}
            onMouseEnter={() => setUpdateIcon(true)}
            style={{cursor: "pointer"}}
            onClick={() => getValue()}
          />
        </Fade>
      )
    }
    return(  
      <Avatar 
        className={classes.large} 
        onMouseEnter={() => setUpdateIcon(true)}
        onMouseLeave={() => setUpdateIcon(false)}
      >
        <Fade in={!updateIcon}>
          <AccountCircleIcon className={classes.large}/>
        </Fade>
      </Avatar>
    );
  }

  const isOwner = async (body) => {
    if(meta.sman == null){
      const cApi = new Customers("admin1");

      var res = await cApi.getOwner(cid);
      res = res.data.recordset[0].salesman;

      var bool = (res.trim() === body.username.trim())
      return res.trim() === body.username.trim();
    }
    else{

      res = meta.sman.replace("\r", "") == body.username;
      return res;
    }
  }

  const canUpdate = (authList) => {
    if(authList.includes("*")){
      setUpdatable(true);
      return;
    }
    for(var i = 0; i < authList.length; i++){
      var main = authList[i].split(":");
      var auth = main[0];
      if(main.length == 1){
        if(currentUser.auth == auth){
          setUpdatable(true);
          return;
        }
        else{
          setUpdatable(false);
        }
      }
      else{
        if(main[1] == "OWNER" && meta.sman == currentUser.username){
          setUpdatable(true);
          return;
        }
        else{
          setUpdatable(false);
        }
      }
    }
    setUpdatable(false);
    return;
  }

  return(
    <Module 
      auths={params.auths} 
      ownership={(x) => isOwner(x)}
      remove={() => onRemoveItem(params.id)} 
      style={{position:"relative"}}
    >
      <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
        {params.id} Kund sida {cid}
      </Header>
      <Body>
        <Grid container style={{overflowY:"auto", height:"99%", overflowX:"hidden"}}>
          <CustomerGeneral
            record={record}
            meta={meta}
            updatable={updatable}
            loadTime={loadTime}
          />

          <div style={{width:"100%", paddingTop:"20px", paddingBottom:"20px"}}>
            <CustomerFooter record={record} modLinks={modLinks} />
          </div>

          <CustomerInfoContainer 
            setSuccess={(e) => setSuccess(e)}
            updatable={updatable}
            record={record}
          />
 
          <div style={{width:"100%", marginTop:"50px", marginBottom:"50px"}}>
            <GeneralInfoRow/>
          </div>
          <CommentSection record={record} />
        </Grid>
      </Body>
    </Module>
  )
}
