import axios from 'axios';
import config from './apiConfig.js';

export default class Challenges{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "48tkd8dwc3";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.challenges_api_key;
  }

  async readAll(){
    return axios.get(`${this.endpoint}/read/all/`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async read(name){
    return axios.get(`${this.endpoint}/user/isActive/${name}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

}
