import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';

import axios from 'axios';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Files from '../../shared/Files.js';

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  Fade
} from "@material-ui/core";


const useStyles = makeStyles((theme) => {

})

export default function InbetFile(params){
  const classes = useStyles();
  const theme = useTheme()

  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { session } = useAppContext();

  const [fname, setFname] = useState("");
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const Fs = new Files("admin1");

  const getFname = (e) => {
    if(e.target.files[0].name.split(".")[1] !== "txt"){
      notify("error", "Filen måste ha .txt format");
    }
    else{
      setFname(e.target.files[0].name);
      setFile(e.target.files[0]);
    }
  }

  const openRawFile = () => {
    Fs.readFile("6ba1a710-6156-11eb-917d-e55990654b86.txt").then(res => {
      console.log(res.data.url);
      axios.get(res.data.url).then(res => {
        console.log(res);
      })
    });
  }

  const getPresignedUrl = () => {
    //console.log("token", session.idToken.jwtToken);
    Fs.initiateFileUpload("text/plain", ".txt", session.idToken.jwtToken).then(res => 
      {
        var url = res.data.curl;
        axios.put(url, file);
        notify("success", `filen: ${fname} är uppladdad`);
        setFileUploaded(true);
      }
    ).catch(err => {notify("error", `Kunde inte ladda upp file ${fname}, ${err}`)});
  }

  return(
    <Module 
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
          color={theme.palette.domain["admin"].main} 
          remove={() => onRemoveItem(params.id)}
      >
        Inbetfil
      </Header>
      <Body>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">
              <b>Inbetfil</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="p" style={{width:"50%", color:"#757575"}}>
              Ladda upp en inbetalningsfil med specificerad format som innehåller
              information om kunders inbetalningar.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{marginTop: "50px"}}>
            <TextField 
              fullWidth 
              value={fname !== "" ? fname : "Ingen fil har valts"}
              style={{marginTop:"10px", marginBottom:"10px", borderRadius:"10px", background:"rgba(255,255,255,0.2)", borderBottom:"none"}}
              variant="filled"
              disabled
              size="small"
            />
            <Button
              variant="contained"
              component="label"
              variant="outlined"
            >
              Välj en fil 
              <input
                type="file"
                hidden
                onChange={getFname}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Fade in={fname !== ""}>
              <Button 
                style={{width:"100%", marginTop:"50px"}} 
                color="primary" 
                variant="outlined"
                onClick={getPresignedUrl}
              >
                Ladda upp
              </Button>
            </Fade>
          </Grid>
          <Fade in={fileUploaded}>
            <Grid container xs={12}>
              <Grid item xs={2}>
                <Button style={{width:"100%"}} onClick={openRawFile}>
                  Rå fil
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Button style={{width:"100%"}}>
                  Formattera fil
                </Button>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Body>
    </Module>
  )

}

