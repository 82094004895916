import React, { useState, useEffect } from 'react';

import Users from '../../shared/Users.js';
import { useAppContext } from '../../libs/contextLib';
import {getTimeFromDate} from '../../shared/dateHelper.js';
import { useComsContext } from '../../libs/comsContextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import { HotKeys, GlobalHotKeys } from 'react-hotkeys';

import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  Button,
  Paper
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  gridToolBar: {
    position: "fixed",
    bottom: 0,
    right: 0,
    background: theme.palette.background.paper,
    zIndex:100,
    borderRadius:0
  },
  pageSelected: {
    borderBottom:`5px solid ${theme.palette.domain.admin.main}`, 
  },
  pageDefault:{
    border:`1px solid ${theme.palette.background.default}`, 
  }
}))

function PageButton(params){
  const classes = useStyles();
  const theme   = useTheme();

  const handleOpenTab = params.handleOpenTab;
  const removePage    = params.removePage;
  const activeTab     = params.activeTab;

  const i = params.i;
  const e = params.e;

  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState("");

  const updateName = () => {
    setEditName(p => !p);
  }

  const updatePageName = (e) => {
    if(e.key == "Enter"){
      params.updateStateName(i, newName);
      setEditName(false);
    }
  }

  return(
    <div 
      className={activeTab == i ? classes.pageSelected : classes.pageDefault}
    >
      <Grid item>
        {editName ? 
          <TextField 
            autoFocus
            fullWidth 
            label="Namn"
            value={newName}
            onKeyPress={updatePageName}
            onChange={(e) => setNewName(e.target.value)}
          />
          :
            <Button
              size="small"
              onClick={() => handleOpenTab(e)}
            >
              {e}
            </Button>
        }
        <IconButton
          onClick={() => updateName()}
          size="small"
        >
          <EditIcon fontSize="small"/>
        </IconButton>
        <IconButton
          onClick={() => removePage(i)}
          size="small"
        >
          <ClearIcon fontSize="small"/>
        </IconButton>
      </Grid>
    </div>
  )
}

export default function BottomToolbar(params){
  const classes = useStyles();
  const theme   = useTheme();
  
  const createNewTab = params.createNewTab;
  const openTab      = params.openTab;
  const activeTab    = params.activeTab;
  const setActiveTab = params.setActiveTab;

  const [open, setOpen]                 = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [pgs, setPgs]                   = useState(localStorage.tabs == undefined ? [] : JSON.parse(localStorage.tabs));
  const [editName, setEditName]         = useState(false);

  useEffect(() => {setPgs(pgs)},[pgs]);

  const handleOpen = () => {
    //setOpen(true);
    setDeleteActive(prev => !prev);
    if(deleteActive){
      params.remove();
    }
    setTimeout(() => setDeleteActive(false), 1000);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleRemoveAll = () => {
    params.remove();
    setOpen(false);
  }

  const handleOpenTab = (index) => {
    setActiveTab(index);
    openTab(index, activeTab);
  }

  const addTab = () => {
    createNewTab("sida" + (pgs.length+1));
    setPgs(p => [...p, {"name": "sida"+(p.length+1), "items":[]}]);
  }

  const removePage = (index) => {
    var pages = pgs;
    pages.splice(index, 1);
    localStorage.setItem("tabs", JSON.stringify(pages));
    setPgs(p => p.filter((e,i) => e !== index));
  }

  /**
   * Update the value in the pgs state variable at a specified index
   *
   * @param i - the index to be updated
   * @param n - the new name
  **/
  const updateStateName = (i, n) => {
    var pages = pgs;
    var oldKey = Object.keys(pages)[i];

    alert(JSON.stringify(pages));
    pages[i].name = n;
    setPgs(pages);
    localStorage.setItem("tabs", JSON.stringify(pages));
  }

  return(
    <Paper className={classes.gridToolBar}>
      <Grid container>
        <Grid item>
          <Tooltip title="Dubbelklicka för att stänga alla moduler">
            <Button 
              aria-label="delete" 
              className={classes.margin} 
              onClick={handleOpen}>
              <DeleteIcon style={deleteActive ? {color:"#ff7961"} : null}/>
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Hemskärm">
            <Button 
              onClick={() => handleOpenTab(0)}
              style={activeTab == 0 ? {borderBottom:`5px solid black`, borderRadius: 0} : null}
            >
              <HomeIcon /> 
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          {pgs !== undefined ?
            <Grid item container>
              {
                pgs.filter((e,i) => i !== 0).map((e,i) => (
                  <PageButton 
                    key={i}
                    handleOpenTab = {() => handleOpenTab(i+1)}
                    removePage={(e) => removePage(e)}
                    activeTab={activeTab}
                    e={e.name}
                    i={i+1}
                    updateStateName={(i,n) => updateStateName(i,n)}
                  />
                ))
              }
            </Grid>
            :
              null
          }
        </Grid>
        <Grid item>
          <Tooltip title="Lägg till sida">
            <Button 
              className={classes.margin} 
              onClick={() => addTab()}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
}
