import axios from 'axios';
import config from './apiConfig.js';

export default class UserPrefs{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "wefqz0yey9";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.userPrefs_api_key;
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/preference/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async readAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async read(name){
    return axios.get(`${this.endpoint}/read/preference/${name}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async update(name, col, val){
    this.requestTemplate.body = {"value": val};
    return axios.post(`${this.endpoint}/update/${name}/${col}/unused/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async delete(name){
    return axios.delete(`${this.endpoint}/delete/${name}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}
