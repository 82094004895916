import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { getCompletion } from "gpt3";
import { useAppContext } from '../../libs/contextLib';

import {makeStyles, useTheme} from '@material-ui/core/styles';

import {
  Button,
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField
} from "@material-ui/core";
import {Header, Body, Module} from '../Modules'; 

const useStyles = makeStyles((theme) => ({
}));

export default function OpenAIModule(params){
  const classes = useStyles();
  const theme = useTheme();

  const { addModule, notify, onRemoveItem } = useMainGridContext();
  const { currentUser } = useAppContext();

  const [result, setResult] = useState("");
  const [prompt, setPrompt] = useState("Det var en gång");
  const [qas, setQas] = useState([]);

  useEffect(() => {
    complete();
  },[])

  const complete = () => {
    getCompletion(prompt,
      {},
      {
        openAIKey:'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',
        openAISecretKey:'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'
      }).then(res => {
        console.log("res", res); 
        setResult(res)
        setQas([...qas, {q: prompt, a: res}]);
      });
  }

  return(
    <Module
      auths={params.auths} 
      remove={() => onRemoveItem(params.id)} 
      style={{position:"relative"}}
      ownership={true}
    >
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} openai labbet
      </Header>
      <Body>
        <Grid container style={{overflow:"auto"}}>
          <Typography variant="h4">
            <b>OpenAI</b> demo
          </Typography>
          <Grid container style={{marginTop:"20px"}}>
            {qas.map(e => (
              <Grid item xs={12}>
                <Typography>{e.q} <b>{e.a.replace(e.q, "")}</b></Typography>
              </Grid>
            ))}
            <Button onClick={() => setQas([])}>Rensa</Button>
          </Grid>
          <Grid container style={{marginTop:"50px", marginBottom:"50px"}}>
            <Grid item xs={12}>
              <TextField
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                fullWidth
                multiline
              />
              <Button fullWidth onClick={() => complete()}>
                Skicka
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}

