import React, { useEffect, useState } from "react";

import PnrTextField from './PnrTextField';
import ServiceList from './ServiceList';

import MaskedInput from 'react-text-mask';
import axios from 'axios';
import notify from '../general/notifications.js';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import Products from '../../shared/Products.js';

// Context
import { 
  useCustomerContext
} from '../../libs/newCustomerContextLib.js';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button, 
  Typography, 
  Grid, 
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Fade,
  Slide
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  mainGrid:{
    background: theme.palette.background.default,
    borderRadius: "5px",
    paddingRight:"10px"
  },
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
    borderRadius: "5px"
  },
  large:{
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  caption: {
    color: "gray",
  }

}));

const key = "dbe223bd580f15a67fe8b46c5d49b603d232db72";

async function fetchAdressData(val){
  if(val.length !== 6){
    return(axios.get(`https://api.papapi.se/lite/?query=${val}&format=json&apikey=${key}`));
  }
  return;
}

function ZipCodeMask(params){
  const { inputRef, ...other } = params;

  return(
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

function TelephoneMask(params){
  const { inputRef, ...other } = params;

  return(
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

function MaskedTextfield(params){
  const classes = useStyles(); 

  const field = params.field;
  const label = params.label;
  const inputProps = params.inputProps;
  const inputComponent = params.inputComponent
  const handleBlur = params.handleBlur;
  const helperText = params.formatHelper;

  const {
    setCustomerInfo, 
    customerInfo, 
  } = useCustomerContext();

  const [value, setValue] = useState(customerInfo[field]);

  useEffect(() => {
    setValue(customerInfo[field]);
  },[customerInfo, field])

  const handleChange = (value, e) => {
    customerInfo[value] = e.target.value; 
    setCustomerInfo(customerInfo);
    setValue(e.target.value);
  }

  return(
    <FormControl className={classes.textfield}>
      <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
      <Input
        onChange={(e) => handleChange(field, e)}
        name="textmask"
        inputComponent={inputComponent}
        InputProps={inputProps}
        onBlur={handleBlur}
        value={value}
        selectionStart={1}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )

}

function FormTextfield(params){

  const classes = useStyles(); 
  
  const field       = params.field;
  const label       = params.label;
  const inputProps  = params.inputProps;
  const type        = params.type;

  const [value, setValue] = useState("");

  const {
    setCustomerInfo, 
    customerInfo, 
    errorText, 
    hideValidationError
  } = useCustomerContext();

  const handleChange = (value, e) => {
    if(type == "alpha"){
      const regex = /^([^0-9])*$/i;
      if(e.target.value === '' || regex.test(e.target.value)){
        setValue(e.target.value);
        customerInfo[value] = e.target.value; 
        setCustomerInfo(customerInfo);
      }
    }
    if(type == "numeric"){
      const regex = /^([0-9])*$/i;
      if(e.target.value === '' || regex.test(e.target.value)){
        setValue(e.target.value);
        customerInfo[value] = e.target.value; 
        setCustomerInfo(customerInfo);
      }
    }
    if(type == undefined || type == null){
      setValue(e.target.value);
      customerInfo[value] = e.target.value; 
      setCustomerInfo(customerInfo);
    }
  }

  useEffect(() => {
    setValue(customerInfo[field]);
  },[customerInfo, field])

  return(
    <TextField 
      error={errorText[field].length > 0 ? true : false}
      label={label} 
      value={value}
      className={classes.textfield} 
      size="small"
      onChange={e => handleChange(field, e)}
      InputProps={inputProps}
      onBlur={() => hideValidationError(field)}
      helperText={errorText[field]}
    />
  )
}

export default function NewCustomerForm(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser }   = useAppContext();
  const [avServices, setAvServices] = useState([]);

  const {
    old,
    setOld,
    signed,
    setSigned,
    services, 
    setServices, 
    serviceName, 
    setServiceName, 
    billingLen, 
    setBillingLen, 
    billing, 
    setBilling, 
    price, 
    setPrice, 
    amount, 
    setAmt,
    currentService,
    setCurrentService,
    validationError,
    errorText, 
    setErrorText,
    customerInfo, 
    setCustomerInfo,
    customerExists,
    setCustomerExists
  } = useCustomerContext();


  const { entityEventsApi, productsApi, serverAuthority } = useAppContext();
  const { dataFromServer } = useComsContext();
  //const { notify } = useMainGridContext();

  const updateCurrentService = (service) => {
    setCurrentService(service); 
    setServiceName(service.service);
    setPrice(service.price);
    setAmt(service.amount);
  }

  const handleChange = (value, e) => {
    if(value == "socialSecurityNumber"){
      customerInfo[value] = e.target.value.replace("-", ""); 
    }
    else{
      customerInfo[value] = e.target.value; 
    }
    setCustomerInfo(customerInfo);
  }

  const handleServiceChange = (value, e) => {
    if(value == "price" && e.target.value.includes(",")){
      return;
    }
    var x = e.target.value;
    setCurrentService({...currentService, [value]: x});
    switch(value){
      case "service":
        setServiceName(e.target.value);
        return;
      case "billingLength":
        setBillingLen(e.target.value);
        return;
      case "billing":
        setBilling(e.target.value);
        return;
      default:
        break;
    }
    if(value == "price"){
      setPrice(e.target.value);
    }
    if(value == "amount"){
      setAmt(e.target.value);
    }
  }

  const {hideValidationError} = useCustomerContext(); 
  const {validateAll} = useCustomerContext();

  const pushService = () => {
    
    // Only add new service if currentService is active
    if(currentService.service.length > 0 ){

      setServices([...services, currentService]);

      updateCurrentService({"service": "", "price": "", "amount": ""});
      setServiceName("");
      console.log("services", [...services, currentService]);
    }
  }

  const handleSignedChange = (event) => {
    currentService["billingOptions"] = event.target.checked;
    setSigned(event.target.checked);
  }

  const handleOldChange = (event) => {
    setOld(event.target.checked);
  }

  const getCityFromZip = (e) => {
    fetchAdressData(e.target.value.replace(" ", "")).then(res => {
      setCustomerInfo({
        ...customerInfo, 
        ["municipality"]:res.data.results[0].city
      })
    }).catch(err => {notify("error", `Något gick fel när adress uppgifter skulle hämtas. Felmeddelande: ${err}`)});
  }

  useEffect(() => {
    const productsApi = new Products(currentUser.username);
    productsApi.getAll().then(res => {
      setAvServices(res.data.recordset);
    }); 
  },[])

  return(
    <Grid container>
      <Grid item xs={12}>
        <form 
          id="new-customer-form" 
        >
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <b>Personuppgifter</b> 
              </Typography>
            </Grid>
            <Grid 
              item 
              xs={6} 
            >
              <FormTextfield
                label="Namn"
                field="name"
                inputProps = {{inputProps: {tabIndex: 1}}}
                type="alpha"
              />
              <FormTextfield
                label="Adress"
                field="adress"
                inputProps = {{inputProps: {tabIndex: 3}}}
              />
              <MaskedTextfield
                label="Postnummer"
                field="zipCode"
                inputComponent={ZipCodeMask}
                inputProps = {{inputProps: {tabIndex: 4}}}
                handleBlur={(e) => getCityFromZip(e)}
                formatHelper="Format: 123 45"
              />
              <MaskedTextfield
                label="Telefon"
                field="telephone"
                inputProps = {{inputProps: {tabIndex: 6}}}
                inputComponent={TelephoneMask}
                handleBlur={null}
                formatHelper="XXX-XX XX XX"
              />
            </Grid>
            <Grid 
              item 
              xs={6} 
            >
              <PnrTextField 
                handleChange = {(value, e) => handleChange(value, e)}
              />
              <FormTextfield
                label="Ort"
                field="municipality"
                inputProps = {{inputProps: {tabIndex: 5}}}
                type="alpha"
              />
              <FormTextfield
                label="E-mail"
                field="email"
                inputProps = {{inputProps: {tabIndex: 7}}}
              />
            </Grid>
            <Grid item xs={6}>
              <Fade in={serverAuthority == "admin"}>
                <FormControlLabel
                    control={
                     <Checkbox
                        checked={old}
                        onChange={handleOldChange}
                        inputProps={{'aria-label': 'Gammal kund'}}
                        color="primary"
                        label="Gammal kund"
                        InputProps={{inputProps: {tabIndex: 13 }}}
                      />
                    }
                    label="Gammal kund"
                  />
              </Fade>
            </Grid>
            <Grid item xs={6}>
              <Fade in={old && serverAuthority == "admin"}>
                <div>
                  <FormTextfield
                    label="Kundnummer"
                    field="customerid"
                    inputProps = {{inputProps: {tabIndex: 70}}}
                    type="numeric"
                  />
                </div>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <Fade in={old && serverAuthority == "admin"}>
              <p>
                OBS! Gammla kundnummer kan ha order + kommentarer redan kopplade
                till sig. Det är bäst att skapa kunden utan 
                tjänster och sedan skapa dessa om det behövs efter. 
              </p>
              </Fade>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h5">
            <b>Order</b>
          </Typography>
          <ServiceList 
            currentService={currentService}
            setCurrentService={setCurrentService}
            services={services}
            setServices={setServices}
            updateCurrentService={(s) => updateCurrentService(s)}
            InputProps={{inputProps: {tabIndex: 8 }}}
          />
          <Grid 
            container 
            xs={12} 
            spacing={2} 
            style={{borderRadius:"5px", paddingRight:"5px"}}
          >
            <Grid item xs={4}>
              <Autocomplete
                options={avServices}
                className={classes.textfield}
                getOptionLabel={(option) => option["name"]}
                renderInput={
                (params) => <TextField 
                  variant="outlined" {...params} label="Tjänst" value={serviceName}
                />}
                style={{width:"100%"}}
                size="small"
                onChange={(e, v) => {
                  if(v !== null){
                    handleServiceChange("service", {target:{value: v.name}
                })}}}
                InputProps={{inputProps: {tabIndex: 9 }}}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField 
                label="Pris" 
                variant="outlined" 
                className={classes.textfield}
                size="small"
                value={price}
                onChange={e => handleServiceChange("price", e)}
                InputProps={{inputProps: {tabIndex: 10 }}}
              /> 
            </Grid>
            <Grid item xs={4}>
              <TextField 
                type="number"
                label="Antal" 
                variant="outlined" 
                className={classes.textfield}
                size="small"
                value={amount}
                onChange={e => handleServiceChange("amount", e)}
                InputProps={{inputProps: {tabIndex: 11 }}}
              /> 
            </Grid>
            <Grid container item xs={12} justify="flex-end" alignItems="flex-end">
              <Button color="primary" onClick={() => pushService()}>
                Lägg till
              </Button>
            </Grid>
          </Grid>
          <Grid 
            container 
            spacing={2} 
          >
            <Grid 
              item 
              xs={6}
            >
              <TextField 
                select 
                label="Avtalslängd" 
                variant="outlined" 
                className={classes.textfield}
                size="small"
                onChange={e => handleServiceChange("billingLength", e)}
                value={billingLen}
                InputProps={{inputProps: {tabIndex: 12 }}}
                error={errorText.subscriptionLength.length > 0 ? true : false}
                onBlur={() => hideValidationError("subscriptionLength")}
                helperText={errorText.subscriptionLength}
              > 
                <MenuItem value={"1 Månad"}>1 Månad</MenuItem>
                <MenuItem value={"3 Månader"}>3 Månader</MenuItem>
                <MenuItem value={"6 Månader"}>6 Månader</MenuItem>
                <MenuItem value={"1 År"}>1 År</MenuItem>
                <MenuItem value={"2 År"}>2 År</MenuItem>
                <MenuItem value={"3 År"}>3 År</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <TextField 
                  select 
                  label="Fakturering" 
                  variant="outlined" 
                  className={classes.textfield}
                  size="small"
                  onChange={e => handleServiceChange("billing", e)}
                  value={billing}
                  InputProps={{inputProps: {tabIndex: 13 }}}
                  error={errorText.billing.length > 0 ? true : false}
                  onBlur={() => hideValidationError("billing")}
                  helperText={errorText.billing}
                > 
                  <MenuItem value={"Engångsfaktura"}>Engångsfaktura</MenuItem>
                  <MenuItem value={"Månadsvis"}>Månadsvis</MenuItem>
                  <MenuItem value={"Kvartalsvis"}>Kvartalsvis</MenuItem>
                  <MenuItem value={"Halvårsvis"}>Halvårsvis</MenuItem>
                  <MenuItem value={"Årsvis"}>Årsvis</MenuItem>
                </TextField>
                <FormControlLabel
                  control={
                   <Checkbox
                      checked={signed}
                      onChange={handleSignedChange}
                      inputProps={{'aria-label': 'Faktureringsspecifikationer'}}
                      color="primary"
                      label="Signerad"
                      InputProps={{inputProps: {tabIndex: 13 }}}
                    />
                  }
                  label="Signerad"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}


