import axios from 'axios';
import config from './apiConfig.js';

export default class Modules{
  constructor(user){

    this.requestTemplate = config.requestTemplate;
    this.user = user;

    this.apiId = "p5oz1qy8cg";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = "m15BqUVggE4cNNDYlub5R4iZuKYP6Jo788j4TKpz";

  }
  async get(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getName(name){
    return axios.get(`${this.endpoint}/read/${name}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async update(body){
    this.requestTemplate.body = body
    return axios.post(`${this.endpoint}/update/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
  );
  }

  async delete(name){
    return axios.delete(`${this.endpoint}/delete/${name}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getUserModules(auth){
    return axios.get(`${this.endpoint}/read/user/${this.user}/${auth}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

