import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { useAppContext } from '../../libs/contextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import { Auth } from 'aws-amplify';

import Users from '../../shared/Users.js';
import UserPrefs from '../../shared/UserPrefs.js';
import Modules from '../../shared/Modules';

import {
  TextField, 
  Avatar, 
  Typography,
  Tooltip,
  Grid,
  Button,
  Paper,
  IconButton,
  Collapse,
  Fade
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import UpdateIcon from '@material-ui/icons/Update';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

import Picker from 'emoji-picker-react';

const useStyles = makeStyles((theme) => ({
  settingsRow:{
    marginTop:"20px",
    "&:hover":{
      cursor:"pointer",
      background:theme.palette.background.default,
    }
  },
  historyButton:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    cursor:"pointer",
    fontSize: "12px"
  }
}));

function UpdateShortcuts(params){
  const classes = useStyles();

  const { currentUser } = useAppContext();

  const [shortcuts, setShortcuts]           = useState([]);
  const [modules, setModules]               = useState([]);
  const [newShortcut, setNewShortcut]       = useState(false);
  const [current, setCurrent]               = useState(-1);
  const [showUpdate, setShowUpdate]         = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);


  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const addModule = (v) => {
    if(v.name !== null){
      var ss = [];
      if(localStorage.fastAccess !== undefined){
        ss = JSON.parse(localStorage.fastAccess);
      }
      ss.push({module: v.name, customName: v.name, description: ""});
      localStorage.setItem("fastAccess", JSON.stringify(ss));
      setShortcuts(ss);
      setNewShortcut(false);
    }
  }
  
  const removeShortcut = (i) => {
    var ss = JSON.parse(localStorage.fastAccess);
    ss.splice(i,1);
    setShortcuts(ss);
    localStorage.setItem("fastAccess", JSON.stringify(ss));
  }

  /**
   * Show update shortcut form and initiate values with localStorage
   *
   * @param i: the index to be updated
  **/
  const initUpdateShortcut = (i) => {
    setShowUpdate(true);
    setCurrent(i);
    setName(shortcuts[i].customName);
    setDesc(shortcuts[i].description);
  }

  /**
   * Set the shortcut name and description
  **/
  const updateShortcut = () => {
    var ss = JSON.parse(localStorage.fastAccess);
    ss[current].customName = name;
    ss[current].description = desc;
    localStorage.setItem("fastAccess", JSON.stringify(ss));
    alert(`Genväg har uppdaterats`);
    setShowUpdate(false);
  }

  const onEmojiClick = (event, emojiObject) => {
    setName(prev => prev + emojiObject.emoji);
  }

  useEffect(() => {
    if(localStorage.fastAccess !== undefined){
      setShortcuts(JSON.parse(localStorage.fastAccess));
    }

    const mods      = new Modules();
    mods.get().then(res => {
      setModules(res.data.modules);
    });
  },[])

  return(
    <Grid container>
      {shortcuts.map((e, i) => (
        <Grid item xs={1}>
          <Grid item container xs={12} alignItems="center" justify="center">
            <Tooltip title={e.description}>
              <Avatar className={classes.historyButton} style={{fontSize:"20px"}}>
                {e.customName}
              </Avatar>
            </Tooltip>
          </Grid>
          <Grid item container xs={12} alignItems="center" justify="center">
            <Tooltip title="Uppdatera">
              <IconButton size="small" onClick={() => initUpdateShortcut(i)}>
                <EditIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Ta bort">
              <IconButton size="small" onClick={() => removeShortcut(i)}>
                <ClearIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={1}>
        <IconButton 
          onClick={() => setNewShortcut(p => !p)} 
          className={classes.historyButton}
        >
          <AddIcon /> 
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Fade in={newShortcut}>
          <Autocomplete
            options={modules}
            getOptionLabel={(o) => {return `${o.name}: ${o.description}`}}
            renderInput={(p) => <TextField {...p} fullWidth label="Moduler" />}
            fullWidth
            onChange={(e,v) => addModule(v)}
          />
        </Fade>
      </Grid>
      <Collapse in={showUpdate} style={{width:"100%"}}>
        <Collapse in={showEmojiPicker}>
          <Picker 
            onEmojiClick={onEmojiClick} 
            disableAutoFocus={true}
          />
        </Collapse>
        <Grid 
          container 
          justify="center" 
          alignItems="center" 
          direction="column" 
          spacing={2}
        >
          <Grid container item xs={6}>
            <Grid item xs={1}>
              <IconButton onClick={() => setShowEmojiPicker(prev => !prev)}>
                <EmojiEmotionsIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <TextField 
                fullWidth 
                label="Namn/Ikon" 
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <TextField 
              fullWidth 
              label="Beskrivning" 
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
            />
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Button fullWidth color="secondary" onClick={() => setShowUpdate(false)}>
                Avbryt 
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth color="primary" onClick={() => updateShortcut()}>
                Uppdatera
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  )

}

function UpdatePassword(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser } = useAppContext();
  const [userNotification, setUserNotification] = useState(null);
  const [usesEmail, setUsesEmail] = useState(false);
  
  const usersApi = new Users(currentUser.username);
  const [currentPsw, setCurrentPsw] = useState("");
  const [confPsw, setConfPsw]       = useState("");
  const [newPsw, setNewPsw]         = useState("");

  const [psw1, setPsw1]           = useState("password");
  const [psw2, setPsw2]           = useState("password");
  const [psw3, setPsw3]           = useState("password");

  const updatePsw = () => {
    if(confPsw == newPsw){
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, currentPsw, newPsw)  
        })
        .then(data => {
          alert("Ditt lösenord har blivit uppdaterat.");
          setCurrentPsw("");
          setConfPsw("");
          setNewPsw("");
          sendEmail(
            {
              type:"email",
              email:{
                subject: "VASA lösenord uppdatering",
                message: "Ditt VASA lösenord har uppdaterats. Om det inte är du som har gjort uppdateringen kontakta administrationen omgående",
                text: "Ditt VASA lösenord har uppdaterats. Om det inte är du som har gjort uppdateringen kontakta administrationen omgående",
                reciever: userNotification
              }
            }
          )

        })
        .catch(err => {
          alert(`Kunde inte uppdatera lösenord. ${JSON.stringify(err)}`)
          sendEmail(
            {
              type:"email",
              email:{
                subject: "VASA lösenord uppdatering",
                message: "Ett uppdateringsförsök har gjorts på ditt VASA lösenord. Om det inte är du som har gjort det kontakta administrationen omgående.",
                text: "Ett uppdateringsförsök har gjorts på ditt VASA lösenord. Om det inte är du som har gjort det kontakta administrationen omgående.",
                reciever: userNotification 
              }
            }
          )
        })
    }
    else{
      alert("Bekräftat lösenord stämmer inte överens.");
    }
  }

  const sendEmail = (body) => {
    usersApi.sendCustomNotification(body)
      .catch(() => alert("Kunde inte skriva notifikation."));
  }

  useEffect(() => {
    usersApi.getWhere("username", currentUser.username).then((res) => {
      const data = res.data.recordset[0];
      if(data.email.length !== 0){
        setUsesEmail(true);
        setUserNotification(data.email);
      }
      else{
        setUserNotification(data.telephone);
      }
    })
  },[])

  return(
    <Grid 
      container 
      item
      xs={12} 
      style={{padding:"20px"}}
    >
      <Grid container xs={12}>
        <Grid item xs={11}>
          <TextField 
            label="Nuvarande lösenord" 
            fullWidth
            type={psw1}
            value={currentPsw}
            onChange={(e) => setCurrentPsw(e.target.value)}
          />
        </Grid>
        <Grid 
          item 
          container 
          xs={1} 
          style={{paddingTop:"10px"}} 
          justify="flex-end" 
          alignItems="flex-end"
        >
          <IconButton 
            onClick={() => {
              setPsw1(prev => prev == "password" ? prev = "" : prev = "password")
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={11}>
          <TextField 
            label="Nytt lösenord" 
            value={newPsw}
            type="password"
            type={psw2}
            fullWidth
            onChange={(e) => setNewPsw(e.target.value)}
          />
        </Grid>
        <Grid 
          item 
          container 
          xs={1} 
          style={{paddingTop:"10px"}} 
          justify="flex-end" 
          alignItems="flex-end"
        >
          <IconButton 
            onClick={() => {
              setPsw2(prev => prev === "password" ? prev = "" : prev = "password")
            }}>
            <VisibilityIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={11}>
          <TextField 
            label="Bekräfta lösenord" 
            fullWidth
            type="password"
            onChange={(e) => setConfPsw(e.target.value)}
            type={psw3}
            value={confPsw}
          />
        </Grid>
        <Grid 
          item 
          container 
          xs={1} 
          style={{paddingTop:"10px"}} 
          justify="flex-end" 
          alignItems="flex-end"
        >
          <IconButton 
            onClick={() => {
              setPsw3(prev => prev === "password" ? prev = "" : prev = "password")}}
          >
            <VisibilityIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Button 
        onClick={() => updatePsw()}
        fullWidth
      >
        Uppdatera
      </Button>
    </Grid>
  )
}

export default function UserPreferences(params){
  const classes = useStyles();
  const theme = useTheme();

  const { notify } = useMainGridContext();

  const [showChangePsw, setShowChangePsw] = useState(false);
  const [showUpdShortcuts, setShowUpdShortcuts] = useState(false);

  return(
    <Grid container xs={12}>
      <Paper style={{height:"100%", width:"100%", padding:"20px"}}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Användarpreferenser 
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.settingsRow}>
          <Grid container item xs={12}>
            <Grid item xs={11}>
              <Typography 
                variant="h6" 
                onClick={() => setShowChangePsw(prev => !prev)}
                style={{paddingTop:"10px"}}
              >
                Ändra lösenord
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => setShowChangePsw(p => !p)}>
                <UpdateIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={showChangePsw}>
            <UpdatePassword />
          </Collapse>
        </Grid>
        <Grid 
          item 
          xs={12} 
          className={classes.settingsRow} 
        >
          <Grid 
            container 
            item 
            xs={12} 
            onClick={() => setShowUpdShortcuts(p => !p)}
          >
            <Grid item xs={11}>
              <Typography variant="h6">
                Genvägar 
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => setShowUpdShortcuts(p => !p)}>
                <UpdateIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={showUpdShortcuts}>
            <UpdateShortcuts />
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  )
}
