import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import { EditableText, EditableAutocomplete } from '../general/UpdatableValue.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDateNormal} from '../../shared/dateHelper.js'
import Groups from '../../shared/Groups.js'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { formatter, unFormatInteger } from '../general/format.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import {
  Grid,
  Collapse,
  Typography,
  Chip,
  Paper,
  Fade,
  Divider,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip,
  Checkbox,
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';

import { GithubPicker } from 'react-color';

import ReactDataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

const useStyles = makeStyles((theme) => ({
  resize:{
    fontSize: 50
  }
}));

const CustomButton = () => {
  return (
    <Button>
      Lägg till
    </Button>
  )
}

const CustomTableToolbar = (params) => {
  const handleClick = () => {
    params.setNewGroup(p => !p);
    console.log("clicked on icon!");
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

function NewGroupForm(params){
  const classes = useStyles();
  const theme = useTheme();
  const {notify} = useMainGridContext();
  
  const [allUsers, setAllUsers] = useState([]);
  const [api, setApi] = useState(params.api);

  useEffect(() => {
    setAllUsers(params.allUsers);
  },[params.allUsers]);

  useEffect(() => {
    setApi(params.api);
  },[params.api])


  const [name, setName]             = useState("");
  const [goal, setGoal]             = useState(0);
  const [members, setMembers]       = useState([]);
  const [currMember, setCurrMember] = useState("");
  const [showPublic, setShowPublic] = useState(false);
  const [color, setColor]           = useState("black");
  const [fromText, setFromText]     = useState(false);

  const randomName = () => {
    const adjectives = [
      "Modiga", 
      "Ivriga", 
      "Coola", 
      "Hoppande", 
      "Rika", 
      "Dansande", 
      "Pigga", 
      "Kaxiga"
    ];
    const animals = [
      "Pandor", 
      "Pingviner", 
      "Koalor", 
      "Lejon", 
      "Valar", 
      "Triceratopsar", 
      "Giraffer", 
      "Tigrar"
  ];

    setName(`${adjectives[Math.floor(Math.random()*adjectives.length)]} ${animals[Math.floor(Math.random()*animals.length)]}`);
  }

  const handleMemberChange = (e) => {
    setMembers(p => [...p, e.username]);
    console.log("handlememberchange");
  }

  const updateMembers = (e) =>{
    setMembers(p => [...p, e.username]);
    console.log("update members");
  }

  const handleShowPublicChange = (e) => {
    setShowPublic(p => !p);
  }

  const updateColor = (color, event) => {
    setColor(color.hex);
  }

  const removeMember = (index) => {
    setMembers(p => p.filter((e,i) => i !== index));
  }

  const createGroup = () => {
    const body = {
      name: name,
      users: members,
      goal: goal,
      color: color,
      showGlobal: showPublic,
      description: "test"
    }
    api.create(body)
      .then(res => notify("success", `Gruppen: ${name} har skapats`))
      .catch(err => notify("error", "Kunde inte skapa ny grupp."));
  }

  const showFromText = () => {
    setFromText(p => !p);
  }

  const extractMembers = (str) => {
    allUsers.map(e => {
      if(str.includes(e.username)){
        setMembers(p => [...p, e.username]);
      }
    });
  }

  return(
    <form autocomplete="off" style={{width:"100%", marginTop:"50px"}}>
      <Typography variant="h4">
        Ny grupp
      </Typography>
      <Grid container spacing={3} style={{padding:"20px"}}>
        <Grid item xs={6}>
          <TextField 
            label="Namn" 
            style={{width:"95%"}}
            InputProps={{classes: {input: classes.resize}}}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button onClick={randomName}>Slumpa</Button>
        </Grid>
        <Grid iten xs={6} style={{marginBottom:"20px"}}>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPublic}
                    onChange={(e) => handleShowPublicChange(e)}
                    name="private"
                    color="primary"
                  />
                }
                label="Visa på Tavla"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline">
                Färg
              </Typography>
              <div style={{width:"100%", background:color, height:"50px"}}>
              </div>
              <GithubPicker onChange={updateColor}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid iten xs={6}>
          <TextField 
            type="number"
            label="Månadsmål" 
            style={{width:"95%"}}
            onChange={(e) => setGoal(e.target.value)}
          />
        </Grid>
         <Grid iten xs={6}>
           <Button onClick={() => showFromText()}>
            Från text
          </Button>
           {
             !fromText ?
              <Autocomplete
                options={allUsers}
                getOptionLabel={(option) => option["username"]}
                renderInput={
                (params) => <TextField 
                  {...params} label="Välj medlemmar" value="lalal" onKeyDown={e => updateMembers(e)}
                />}
                style={{width:"100%"}}
                onChange={(e, v) => {
                  if(v !== null){
                    handleMemberChange(v)}}}
              />
            :
            <TextField onChange={(e) => extractMembers(e.target.value)}style={{width:"100%"}} />
          }
          <Typography variant="overline">
            Medlemmar
          </Typography>
          <Grid container>
            {members.map((e,i) => (
              <Grid container> 
                <Grid item xs={1}>
                  <Button onClick={() => removeMember(i)}>
                    -
                  </Button>
                </Grid>
                <Grid item xs={11}>
                 <Typography>
                   {e}
                 </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Fade in={members.length > 0 && name.length > 0 && goal > 0}>
            <div>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={() => createGroup()}
                style={{width:"100%"}}>Skapa</Button>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </form>
  )
}

function GroupSold(params){
  const classes = useStyles();
  const theme = useTheme();
  
  const [api, setApi] = useState({});
  const [name, setName] = useState(params.name);
  const [sold, setSold] = useState(0);

  useEffect(() => {
    if(params.sold !== undefined && params.sold !== null){
      var sold = 0;
      console.log("SOLD: ", params.sold);
      params.sold.map(e => sold += e.sold);
      setSold(sold);
    }
  },[params.sold])

  return(
    <>
      {formatter.format(sold)}
    </>
  )
}

function GroupSummary(params){
  const classes = useStyles();
  const theme = useTheme();
  const {notify} = useMainGridContext();

  const [open, setOpen]           = useState(false);
  const [group, setGroup]         = useState({users: []});
  const [groupSold, setGroupSold] = useState({});
  const [total, setTotal]         = useState(0);
  const [allUsers, setAllUsers]   = useState(params.allUsers);
  const [groupApi, setGroupApi]   = useState(params.groupApi);
  const [newMember, setNewMember] = useState("");
  const [usersSorted, setUsersSorted] = useState([]);

  useEffect(() => {
    if(params.groupSold !== {} && Object.keys(params.groupSold).length > 0){
      setGroupSold(params.groupSold[params.group.id]);
      console.log("GROUP SOLD", params.groupSold)
      var tmp = params.groupSold[params.group.id];
      console.log("SORTED", tmp.sort((a,b) => a.sold - b.sold));

      tmp =  tmp.sort((a,b) => a.sold - b.sold).reverse();
      var tmp2 = [];
      tmp.map(e => tmp2.push(e.salesman));
      tmp = tmp.concat(getExcluded(tmp2, params.group.users));

      setUsersSorted(tmp);

      params.groupSold[params.group.id].map(e => setTotal(p => p + e.sold))
    }
  },[params.groupSold]);

  useEffect(() => {
    setAllUsers(params.allUsers);
  },[params.allUsers])

  useEffect(() => {
    setOpen(true);
    setGroup(params.group);

    return function cleanup(){
      setOpen(false);
    }
  },[])

  const getExcluded = (a, b) => {
    var res = [];
    b.map(e => {
      if(!a.includes(e)){
        res.push({"salesman": e, "sold": 0});
      }
    })
    return res;
  }

  const handleMemberChange = (e) => {
    setNewMember(e.username);
  }

  const removeMember = (e) => {
    var members = group.users;
    var removed = members[e]
    members.splice(e,1);
    updateGroup("users", members, `${removed} har blivit borttagen från ${group.id}`);
    setGroup(p => ({...p, ["users"]: members}));
  }
  const updateMembers = (e) => {
    if(e.key == "Enter"){
      var members = group.users;
      members.push(newMember);
      updateGroup("users", members, `${newMember} tillagd i gruppen: ${group.id}`);
      setGroup(p => ({...p, ["users"]: members}));
    }
  }

  const updateGroup = (col, val, msg) => {
    var body = {name: group.id, col: col, val: val};
    groupApi.update(body).then(res => {
      notify("success", msg)
      params.update(group.id);
    }).catch(err => notify("error", err));

  }

  return(
    <TableRow>
      <TableCell colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={3}>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={6}>
                <Typography>
                  Medlemmar
                </Typography>
                <table style={{width:"100%"}}>
                  <tr>
                    <th></th>
                    <th>Användarnamn</th>
                    <th>Sålt (FSG ex. Mak)</th>
                    <th>Andel av tot.</th>
                  </tr>
                  {usersSorted.map((e,i) => {
                    return(
                    <tr>
                      <td><Button onClick={e => removeMember(i)}>-</Button></td>
                      <td>{e.salesman}</td>
                      <td>{formatter.format(e.sold)}</td>
                      <td>{((e.sold / total)*100).toFixed(2) + "%"}</td>
                    </tr>
                    )
                  })}
                </table>
                  <Button>
                    Lägg till
                  </Button>
                  <Autocomplete
                    options={allUsers}
                    getOptionLabel={(option) => option["username"]}
                    renderInput={
                    (params) => <TextField 
                      variant="outlined" {...params} label="Användare" value="lalal" onKeyDown={e => updateMembers(e)}
                    />}
                    style={{width:"100%"}}
                    size="small"
                    onChange={(e, v) => {
                      if(v !== null){
                        handleMemberChange(v)}}}
                    variant="outlined"
                  />

              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

function UpdatePublicState(params){
  const { notify } = useMainGridContext();

  const [currentState, setCurrentState] = useState(params.state) 

  useEffect(() =>{
    setCurrentState(String(params.state));
  },[]) 

  const getIcon = () => {
    switch(String(currentState)){
      case "true":
        return <CheckIcon onClick={() => updateModule(false)}/>
      case "false":
        return <CloseIcon onClick={() => updateModule(true)}/>
      case "loading":
        return <CircularProgress />
      default: 
        return <CheckIcon />
    }
  }

  const updateModule = (val) => {
    var body = {name: params.id, col: "showGlobal", val: val};
    setCurrentState("loading")
    params.groupsApi.update(body).then(res => {
      notify("success", "Gruppen har uppdaterats")
      setCurrentState(val);
    }) 
    .catch(err => {
      notify("error", "Kan inte uppdatera grupp!") 
      setCurrentState(!val);
    })

  }

  return(
    <>
      {getIcon()}
    </>
  )
}


export default function GroupModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { currentUser, usersApi }           = useAppContext();
  const { dataFromServer }                  = useComsContext();

  const [allUsers, setAllUsers] = useState([]);
  
  const [groupsApi, setGroupsApi] = useState(null);

  const [groups, setGroups]       = useState([]);
  const [groupSold, setGroupSold] = useState({});
  const [update, setUpdate]       = useState(0);
  const [currState, setCurrState] = useState({});
  const [newGroup, setNewGroup]   = useState(false);
  const [setConfirm, setSetConfirm] = useState(false);

  const deleteGroup = (index) => {
    var group = groups.filter((e,i) => i == index)[0];
    if(group.id !== "filter"){
      groupsApi.delete(group.id)
        .then(res => {notify("success", `Gruppen: ${group.id} har tagits bort`)})
        .catch(err => notify("error", `Kunde inte ta bort ${group.id}`));
    }
    else{
      notify("error", "Du får inte ta bort FILTER!")
    }
  }

  useEffect(() => {
    if(usersApi !== null){
      usersApi.getAll().then(res => {
        setAllUsers(res.data.recordset);
      })
    }
  },[usersApi])

  useEffect(() => {
    if(currentUser.username !== null){
      var gs = new Groups(currentUser.username);
      setGroupsApi(gs); 
      gs.getAll().then(res => {
        setGroups(res.data.groups);
        res.data.groups.map((e,i) => {
          var body = {name: e.id, from:"2021-08-01", to: "2021-08-31"};
          gs.groupSold(body).then(r=> {
            setGroupSold(p => ({...p, [e.id]: r.data.recordset}));
          });
        });
      });
    }
  }, [currentUser, update])

  useEffect(() => {
  },[update])

  const updateModule = (name) => {
    setUpdate(p => p +1);
  }

  const updateGroup = (id, col, val) => {
    var body = {name: id, col: col, val: val};
    groupsApi.update(body).then(res => {
      notify("success", "Gruppen har uppdaterats")
    }) 
    .catch(err => {
      notify("error", "Kan inte uppdatera grupp!") 
    })
  }

  const columns = [
    {
      name: "color",
      label: "F",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, UpdateValue) => {
          return(
            <div style={{background:`${value}`, height:"20px", width:"20px"}}>
            </div>
          )
        },
        sort: true
      }
    },

    {
      name: "id",
      label: "Namn",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, UpdateValue) => {
          return(
            <Typography variant="h5">
              <b>{value}</b>
            </Typography>
          )
        },
        sort: true
      }
    },
    {
      name: "goal",
      label: "Månadsmål",
      options: {
        filter: false,
        customBodyRender:(value, tableMeta, UpdateValue) => {
          return(
            <Typography>
              {formatter.format(value)}
            </Typography>
          )
        },
        sort: true
      }
    },
    {
      name: "tmp",
      label: "Sålt (FSG ex. Mak)",
      options: {
        customBodyRender: (value, tableMeta, UpdateValue) => {
          return(
            <GroupSold api={groupsApi} update={() => updateModule()} sold={groupSold[groups[tableMeta.rowIndex].id]}/>
          )
        },
        filter: false,
        sort: true
      }
    },
    {
      name: "created",
      label: "Skapad",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, UpdateValue) => {
          return(
            <>
            {formatDateNormal(value)}
            </>
          )
        },
        sort: true
      }
    },
    {
      name: "showGlobal",
      label: "Publik",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, UpdateValue) => {
          return(
            <>
              <UpdatePublicState state={value} id={groups[tableMeta.rowIndex].id} groupsApi={groupsApi} />
            </>
          )
        },
        sort: true
      }
    }
  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    expandableRows: true,
    onTableInit:(action, state) => {
      return state;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      return(
        <GroupSummary 
          groupApi={groupsApi} 
          allUsers={allUsers} 
          update={(i) => updateModule(i)}
          group={groups[rowMeta.dataIndex]} 
          groupSold={groupSold}
        />)
    },
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      Object.keys(rowsDeleted.lookup).map(e => {
        deleteGroup(e);
      })
    },
    customToolbar: () => {
      return (<CustomTableToolbar setNewGroup={(b) => setNewGroup(b)}/>)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} Grupper 
      </Header>
      <Body>
        <Grid container style={{overflowY:"auto", height:"95%", overflowX:"hidden"}}>
          <Grid item xs={12}>
          <Typography variant="h3"><b>Grupper</b></Typography>
          </Grid>
          <Grid container>
            <div style={{width: "100%"}}>
              <MUIDataTable
                size="small"
                title={""}
                data={groups}
                columns={columns}
                options={options}
                style={{width: "100%"}}
              />
            </div>
            <Fade in={newGroup}>
              <Grid container>
                <NewGroupForm api={groupsApi} allUsers={allUsers}/>
              </Grid>
            </Fade>
            <Fade in={false}>
              <Grid container>
                <Button>Bekräfta</Button>
                <Button>Avbryt</Button>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}

