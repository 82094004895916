import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import Reactions from '../reactions';
import {getTimeFromDate, formatDate} from '../../../shared/dateHelper.js';
import { useMainGridContext } from '../../../libs/mainGridContextLib.js';
import _ from 'lodash';
import EntityEvents from '../../../shared/EntityEvents.js';

import {
  Typography,
  Grid,
  Avatar,
  Button,
  Collapse,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   background:theme.palette.background.paper,
   borderBottom:`1px solid ${theme.palette.border.primary}`,
   borderTop:`1px solid ${theme.palette.border.primary}`,
 }
}));

export default function Acitivty(params){
  const classes = useStyles();
  const theme = useTheme();

  const {session, currentUser} = useAppContext();
  const { notify } = useMainGridContext();

  const event = params.event;
  const profile = params.profile;
  const index = params.index
  const users = params.users;

  const [showAll, setShowAll] = useState(false);

  // Post state
  const [title, setTitle]             = useState("");
  const [description, setDescription] = useState("");
  const [creator, setCreator]         = useState("");
  const [date, setDate]               = useState("");
  const [created, setCreated]         = useState("");
  const [willCome, setWillCome]       = useState([]);

  // File states
  const [src, setSrc]                 = useState("");
  const [mime, setMime]               = useState("");

  useEffect(() => {
    if(session !== undefined){
      const table = "activities";
      const id = event.identifier.split(":")[0];
      const created = event.identifier.split(":")[1];

      setCreated(new Date(created));

      getEvent(table, id, created);

      if(event.filepath !== null){
        getFile(event.filepath, session.idToken.jwtToken);
      }
    }
  },[session])

  const updateWillCome = () => {
   if(!willCome.includes(currentUser.username)){ 
    const body = {
      "id"        : event.identifier.split(":")[0],
      "created"   : event.identifier.split(":")[1],
      "table"     : "activities",
      "col"       : "willCome",
      "val"       : [...willCome, currentUser.username],
      "user"      : currentUser.username
    }

    const offPrefs    = new OfficePrefs();

    offPrefs.updateArray(body).then(res => {
      setWillCome([...willCome, currentUser.username]);
      notify("success", "Kul att du kommer, admin1 har blivit informerad");
    }).catch(err => {notify("error", `Kan inte anmäla. ${err}`)})
   }
  }

  const getEvent = (table, id, created) => {
    const offPrefs    = new OfficePrefs();
    offPrefs.getKey(table, id, created).then(res => {
      setTitle(res.data.title);
      setDescription(res.data.description);
      setCreator(res.data.creator);
      setCreated(new Date(res.data.created));
      setDate(res.data.date);
      setWillCome(res.data.willCome);
    });
  }

  const getFile = (fpath, jwtToken) => {
    const sfs         = new SecureFiles();
    sfs.readFile(event.filepath, session.idToken.jwtToken).then(res => {
      setSrc(res.data.url);
      var m = getMime(event.filepath.split(".")[1]);
      setMime(m);
    });
  }

  const remove = () => {
    const entityEventsApi = new EntityEvents(currentUser.username);
    entityEventsApi.deleteEntityEvent(event.id).then(res => {
      setTitle("[BORTTAGET]");
      setDescription("");
    }).catch(err => console.log("does not delete"));

    const offPrefs    = new OfficePrefs();

    const table = "importantMessages";
    const id = event.identifier.split(":")[0];
    const created = event.identifier.split(":")[1];

    offPrefs.delete(table, id, created, currentUser.username).then(res => {
      console.log(res);
    })
    .catch(res => console.log("does not delete"));
  }

  return(
    <Grid
      container
      item
      xs={12}
      key={index}
      className={classes.root}
    >
      <Grid item xs={2}>
        <Typography variant="h1">
          🥂 
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <EventProfile 
          profile={profile} 
          creator={creator} 
          created={created} 
          remove={() => remove()}
        />
        <Typography variant="h2">
          {title}
        </Typography>
        <Typography variant="h4">
          {formatDate(date)}
        </Typography>
        <Typography variant="body1">
          {description}
        </Typography>
        {willCome.length > 0 ?
          <Typography style={{paddingTop:"20px"}}>
            {willCome.length} har svarat att dom kommer
          </Typography>
          :
          <Typography style={{paddingTop:"20px"}}>
            Ingen har sagt att dom kommer ännu.
          </Typography>
        }
        {willCome.includes(currentUser.username) ?
          <>
            <Typography>
              Du har svarat att du kommer! :)
            </Typography>
            <Button fullWidth onClick={() => setShowAll(p => !p)}>
              Vilka fler kommer?
            </Button>
            <Collapse in={showAll}>
              {willCome.map(p => {
                var profile = _.filter(params.users, (u) => {return u.username == p})[0];
                var m = profile.profileMedia;
                return(
                  <Grid container>
                    <Tooltip title={event.creator}>
                      <Avatar
                        src={`https://vasa.s3.us-east-2.amazonaws.com/${m}`} 
                      />
                    </Tooltip>
                    <Typography style={{paddingLeft:"10px"}}>
                      {p}
                    </Typography>
                  </Grid>
              )
              })}
            </Collapse>
          </>
        :
          <Button fullWidth variant="outlined" onClick={() => updateWillCome()}>
            Jag kommer
          </Button>
        }
      </Grid>
      <Reactions 
        eventId={event.id}
        like={event.thumbup} 
        confetti={event.confetti} 
        angry={event.angry}
        love={event.love}
      />
    </Grid>
  )
}
