import axios from 'axios';
import config from './apiConfig.js';

export default class SecureFiles{
  constructor(){
    this.requestTemplate = config.requestTemplate;
    this.apiId = "wgcnp33l72";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.files_api_key;
  }
  async upload(contentType, filename, tkn){
    this.requestTemplate.body = {"contentType": contentType, "path":filename};
    return axios.post(`${this.endpoint}/files/private/create`,this.requestTemplate,
      {headers: {
        'Authorization': tkn 
      }}
    )
  }

  async readFile(fname, tkn){
    var requestTemplate= {
      "body": {"path": fname},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    }
    return axios.post(`${this.endpoint}/files/private/read/file`, requestTemplate,
      {headers: {
        'Authorization': tkn 
      }}
    );
  }

  async readFolder(folder, tkn){
    var requestTemplate= {
      "body": {"path": folder},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    }
    return axios.post(`${this.endpoint}/files/private/read/folder`, requestTemplate,
      {headers: {
        'Authorization': tkn 
      }}
    );
  }

  async getFileLink(entity, identifier){
    return axios.get(`${this.endpoint}/files/link/get/${entity}/${identifier}`);
  }

  async getFileLinks(entities, ids){
    this.requestTemplate.body = {"entities": entities, "ids": ids};
    return axios.get(`${this.endpoint}/files/link/get/group`, this.requestTemplate);
  }

}
