import React, { useState, useEffect } from 'react';

import Users from '../../shared/Users.js';
import { useAppContext } from '../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import {getTimeFromDate} from '../../shared/dateHelper.js';

import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import {
  TextField, 
  Avatar, 
  Modal, 
  Backdrop,
  Fade,
  Slide,
  Dialog,
  DialogTitle,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton
} from '@material-ui/core';

import {
  TouchApp as TouchAppIcon,
  Add as AddIcon,
} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  historyButton:{
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor:"pointer",
    fontSize: "12px"
  }
}));

export default function FastAccess(params){
  const classes = useStyles();
  const theme   = useTheme();

  const moduleUtils   = params.moduleUtils;
  const a11yProps     = params.a11yProps;
  const addModule     = params.addModule;
  const setFCmd       = params.setFCmd;
  const setActiveCmd  = params.setActiveCmd;

  const [showBottomBar, setShowBottomBar] = useState(false);

  const handleOpenModule = (name, index) => {
    var ps = moduleUtils.hasParams(name);
    if(ps){
      // Open FastCommand
      setActiveCmd(moduleUtils.getModule(name));
      setFCmd(true);
    }
    else{
      addModule(`::${name}`);
    }
  }

  return(
    <div 
      style={{width:"100%", height:"150%"}}
      onMouseEnter={() => setShowBottomBar(true)} 
      onMouseLeave={() => setShowBottomBar(false)}
    >
      <Fade in={showBottomBar}>
        <Grid 
          item
          container 
          direction="column"
          xs={12} 
          style={{top:"0px", position:"relative"}} 
        >
          {JSON.parse(localStorage.fastAccess).map((e,i) => {
            var col = "white";
            if(moduleUtils !== null){
              col = moduleUtils.getDomain(e.module);
            }
            return(
              <Tooltip key={i} title={`::${e.module}`}>
                <Grid item xs={1}>
                  <IconButton 
                    onClick={(x) => handleOpenModule(e.module, i)}
                  >
                    <Avatar
                      className={classes.historyButton}
                      style={{
                        background: theme.palette.domain[col] === undefined ? 
                        "transparent" : theme.palette.domain[col].main,
                        boxShadow: theme.palette.type == "dark" ?
                        `1px 1px 5px 1px #000000`:
                        "1px 1px 5px 1px #e0e0e0",
                        fontSize:"20px"
                      }}
                    >
                      {e.customName}
                    </Avatar>
                  </IconButton>
                </Grid>
              </Tooltip>
            )
          })}
          <Grid item xs={1}>
            <Tooltip title="Lägg till">
              <IconButton onClick={() => params.setShowUserPrefs(true)}>
                <Avatar  
                  className={classes.historyButton} 
                  style={{
                    background:"transparent",
                    color: theme.palette.type == "dark" ? "white" : "black"
                  }}
                >
                  <AddIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Fade>
      <Fade in={!showBottomBar}>
        <div style={{position:"fixed", left:"0%", top: "92%"}}>
          <IconButton>
            <TouchAppIcon />
          </IconButton>
        </div>
      </Fade>
    </div>
  )
}
