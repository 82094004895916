import React, { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import ShowMedia from '../../../shared/file-handler/show-file.jsx';
import {getMime }from '../../../shared/file-handler/utils.js';
import OfficePrefs from '../../../shared/OfficePrefs.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import {textParser} from '../../../shared/text/parse.js'; 
import EventProfile from '../EventProfile';
import Reactions from '../reactions';
import EntityEvents from '../../../shared/EntityEvents.js';

import {
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 root: {
   background: theme.palette.background.paper,
   borderTop: `1px solid ${theme.palette.border.primary}` 
 }
}));

export default function UserPostEvent(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser } = useAppContext();
  const {session} = useAppContext();

  const {profile, event, index } = params;

  const [message, setMessage] = useState(event.message);

  // File states
  const [src, setSrc]                 = useState("");
  const [mime, setMime]               = useState("");

  useEffect(() => {
    if(session !== undefined){
      if(event.filepath !== null){
        getFile(event.filepath, session.idToken.jwtToken);
      }
    }
  },[session])

  const getFile = (fpath, jwtToken) => {
    const sfs         = new SecureFiles();
    sfs.readFile(event.filepath, session.idToken.jwtToken).then(res => {
      setSrc(res.data.url);
      var m = getMime(event.filepath.split(".")[1]);
      setMime(m);
    });
  }

  const deleteEvent = () => {
    const entityEventsApi = new EntityEvents(currentUser.username);
    entityEventsApi.deleteEntityEvent(event.id).then(res => {
      setMessage("[BORTTAGET]");
    }).catch(err => console.log("does not delete"));
  }

  return(
    <Grid item key={index} xs={12} className={classes.root}> 
      {mime.length > 0 ?
        <ShowMedia mime={mime} src={src}/>
        :
        null
      }
      <div style={{padding:"20px"}}>
        <EventProfile 
          profile={profile} 
          creator={event.creator} 
          created={event.created} 
          remove={() => deleteEvent()}
        />
        <Grid item xs={12} style={{padding:"25px", paddingLeft:"50px"}}>
          <Typography variant="h6">
            {message}
          </Typography>
        </Grid>
      </div>
      <Reactions 
        eventId={event.id}
        like={event.thumbup} 
        confetti={event.confetti} 
        angry={event.angry}
        love={event.love}
      />
    </Grid>
  )
}

