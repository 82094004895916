import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';

import {
  Dialog
} from '@material-ui/core';

export default function VasaDialog(params){

  const handleClose = params.handleClose;
  const open = params.open;

  return(
    <Dialog 
      onClose={handleClose} 
      open={open} 
      maxWidth="lg" 
      fullWidth={true}
      PaperProps={{
        style:{
          backgroundColor:"transparent",
          boxShadow:"none",
          overflowY: "hidden",
          overflowX: "hidden"
        },
      }}
      BackdropProps={{
        style:{
          backdropFilter: "blur(10px)",
          backgroundColor:"rbga(0,0,30,0.4)"
        }
      }}
    >
      {params.children}
    </Dialog>
  )
}

