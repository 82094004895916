import axios from 'axios';
import config from './apiConfig.js';

export default class Files{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "4ndol3c1hl";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.files_api_key;
  }

  async initiateFileUpload(contentType, filename, tkn){
    this.requestTemplate.body = {"contentType": contentType, "filename":filename};
    return axios.post(`${this.endpoint}/files/initiate-upload`,this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readFile(fname){
    this.requestTemplate.body = {"filename": fname};
    return axios.post(`${this.endpoint}/files/read`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async deleteFile(fname){
    this.requestTemplate.body = {"filename": fname};
    return axios.post(`${this.endpoint}/files/delete`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async listFolder(folder){
    this.requestTemplate.body = {"folder": folder};
    return axios.post(`${this.endpoint}/files/list`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}
