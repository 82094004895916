import React, {useState, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';
import { formatter } from '../../components/general/format.js';


import { useMainGridContext } from '../../libs/mainGridContextLib';
import {
  getPaymentAmount, 
  paymentPlan,
  priceConstructor,
  textualSubscriptionLength
} from '../../components/utils.js';

import axios from 'axios';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Files from '../../shared/Files.js';
import {formatDateTime} from '../../shared/dateHelper.js';

import Customers from '../../shared/Customers.js';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font
} from '@react-pdf/renderer'

import {
  Table,
  TableHeader,
  DataTableCell,
  TableCell,
  TableBody
} from '@david.kucsai/react-pdf-table';

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  Fade,
  Paper,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {

})

Font.register("https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf", {family:"roboto"});

const styles = StyleSheet.create({
  page:{
    marginTop:"10pt",
    flexDirection: "column",
    backgroundColor: 'white',
  },
  section:{
    margin: 20,
    padding: 10,
    flexGrow: 1
  },
  headerContent:{
    marginTop:20,
    marginLeft:20,
    marginRight:20,
    flexDirection: "column",
  },
  headerRow:{
    padding: 10,
    flexDirection: "row"
  },
  headerRight:{
    paddingLeft:"10pt",
    flexDirection: "column",
    width:"11cm"
  },
  headerLeft:{
    flexDirection: "column",
    paddingLeft:"2cm",
    width:"9.5cm",
  },
  image:{
    width: "150px",
    height:"50px"
  },
  p:{
    fontSize:"9pt",
    fontWeight:"medium",
  },
  pBold:{
    fontSize:"9pt",
    fontWeight:"medium",
    fontFamily:"roboto",
    fontWeight:700
  },

  p2:{
    fontSize:"10pt",
    lineHeight:1.2,
  },
  p2Bold:{
    fontFamily:"roboto",
    fontSize:"10pt",
    lineHeight:1.2,
    fontWeight:700
  },
  p3:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454"
  },
  p3Bold:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454",
    fontFamily:"roboto",
    fontWeight:700
  },
  h4:{
    fontSize:"24pt",
    fontWeight:"bold",
  },
  h4Bold:{
    fontSize:"24pt",
    fontFamily:"roboto",
    fontWeight:700
  },
  h5:{
    fontSize:"18pt",
    fontWeight:"bold",
  },
  h5Bold:{
    fontSize:"18pt",
    fontWeight:"bold",
    fontFamily:"roboto",
    fontWeight:700
  },

  textContainer:{
    marginTop:10
  },
  body:{
    margin:50,
    marginTop:10,
    padding:10,
    height: "70%"
  },
  column:{
    flexDirection:"column"
  },
  row:{
    flexDirection:"row"
  },
})

function DocumentListElement(params){
  const title       = params.title;
  const description = params.description;
  const value       = params.value
  const meta        = params.meta

  return(
    <View style={[styles.textContainer, {borderBottom: `2pt solid ${meta.color}`, paddingBottom:10}]}>
      <Text style={[styles.p2Bold, {color:`${meta.color}`}]}>{title}</Text>
      <Text style={styles.p}>{description}</Text>
      <Text style={{fontSize:"8pt", color:`${meta.color}`, textAlign:"right", marginTop:"5pt"}}>{value}</Text>
    </View>

  )
}

function DocumentList(params){
  //const [es, setEs] = useState(params.elements);
  const meta = params.meta
  const listItems = params.listItems
  const es = [
    {
      name: "ID-assistans", 
      description: "Spärrar din identitet om någon försöker kapa den.", 
      price:"100"
    },
    {
      name: "Kortspärr", 
      description: "Spärra dina kort och värdehandlingar met ETT telefonnummer", 
      price:"100"
    },
    {
      name: "Säkerhetskortet", 
      description: "Skyddar din plånbok mot skimning.", 
      price:"100"
    },
    {
      name: "Nyckelbricka", 
      description: "Fäst på nyckelbrickan och skyddar nycklarna.", 
      price:"100"
    }
  ]
  return(
    <View style={{padding:0, marginTop:0}}>
      <Text style={{fontSize:"12pt"}}>Dina tjänster</Text>
      {
        listItems.map((e) => (
          <DocumentListElement 
            title={e.productCode} 
            description={e.description} 
            value={e.price}
            meta={meta}
          />
        ))
      }
    </View>
  )
}

function DocumentColumn(params){
  const classes = useStyles();

  const [width, setWidth] = useState(params.width);
  
  return(
    <View style={[styles.textContainer], {width:`${width}cm`}}>
      {params.children}
    </View>
  )
}

function PdfText(params){
  const [text, setText]   = useState("")
  const [style, setStyle] = useState({}) 

  useEffect(() => {
    setText(params.text);
    setStyle(params.style);
  },[])

  return(
    <>
      {text.split("::BR::").map(e => (
        <>
        <Text 
          style={{
            background:style.background,
            color: style.color,
            fontFamily:"roboto",
            fontSize: style.fontSize,
            fontWeight: style.fontWeight,
          }}>
          {e}
        </Text>
        <Text style={styles.p2}>
          {" "}
        </Text>
        </>
      ))}
    </>
  )
}

export default function Report(params){
  const classes = useStyles();
  const theme = useTheme()

  const meta = params.meta;

  const [oInfo, setOInfo] = useState([]);

  return(
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{flexDirection: "row"}}>
            <View style={styles.headerContent}>
              <Image
                src='https://vasa.s3.us-east-2.amazonaws.com/isgg-logo.png'
                style={{width: "50pt", height: "50pt"}}
              />
            </View>
            <View>
              <Text style={{fontSize:"14pt", fontWeight:"bold", paddingTop:"30pt"}}>
                Dagsrapport: {params.user} 
              </Text>
              <Text style={{fontSize:"10pt"}}>
                Skriven: {formatDateTime(new Date())} av {params.writtenBy}
              </Text>
            </View>
          </View>
          <View style={styles.body}>
            <Text>
              Kund- och orderinformation
            </Text>
            <View style={{flexDirection: "row", borderBottom:"1pt solid #000000"}}>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                KID 
              </Text>
              <Text style={{fontSize:"10pt", width:"100pt"}}>
                Namn 
              </Text>
              <Text style={{fontSize:"10pt", width:"100pt"}}>
                Personnummer 
              </Text>
              <Text style={{fontSize:"10pt", width:"100pt"}}>
                Avtalslängd
              </Text>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                Betalning 
              </Text>
              <Text style={{fontSize:"10pt", width:"75pt"}}>
                Pris 
              </Text>
            </View>
            {params.orders.map(e => ( 
              <View style={styles.row}>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {e.kid} 
                </Text>
                <Text style={{fontSize:"10pt", width:"100pt"}}>
                  {e.name}
                </Text>
                <Text style={{fontSize:"10pt", width:"100pt"}}>
                  {e.ssn}
                </Text>
                <Text style={{fontSize:"10pt", width:"100pt"}}>
                  {e.subscriptionLength}
                </Text>
                <Text style={{fontSize:"10pt", width:"100pt"}}>
                  {e.billing}
                </Text>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {e.price}
                </Text>
              </View>
            ))}
            <Text style={{paddingTop:"30pt"}}>
              Tjänster
            </Text>
            <View style={{flexDirection: "row", borderBottom:"1pt solid #000000"}}>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                KID
              </Text>
              <Text style={{fontSize:"10pt", width:"100pt"}}>
                Tjänst 
              </Text>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                Antal 
              </Text>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                Pris 
              </Text>
              <Text style={{fontSize:"10pt", width:"50pt"}}>
                Kostnad 
              </Text>
            </View>
            {params.services.map(e => ( 
              <View style={styles.row}>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {e.customerid} 
                </Text>
                <Text style={{fontSize:"10pt", width:"100pt"}}>
                  {e.productCode}
                </Text>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {e.amount}
                </Text>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {e.price}
                </Text>
                <Text style={{fontSize:"10pt", width:"50pt"}}>
                  {
                    params
                      .products
                      .filter(p=> p.name == e.productCode)[0]
                      .unitPrice * e.amount
                  }
                </Text>
              </View>
            ))}
            <View style={{borderTop:"1pt solid #000000", marginTop:"30pt"}}>
              <View style={{flexDirection:"row"}}>
                <Text style={{fontSize:"10pt", width:"450pt"}}>
                  Totalt sålt: 
                </Text>
                <Text style={{fontSize:"10pt"}}>
                  {params.tot}
                </Text>
              </View>
              <View style={{flexDirection:"row"}}>
                <Text style={{fontSize:"10pt", width:"450pt"}}>
                  Moms: 
                </Text>
                <Text style={{fontSize:"10pt"}}>
                  {formatter.format(params.tot * 0.2)}
                </Text>
              </View>
              <View style={{flexDirection:"row"}}>
                <Text style={{fontSize:"10pt", width:"450pt"}}>
                  Kostnader: 
                </Text>
                <Text style={{fontSize:"10pt"}}>
                  {formatter.format(params.costs)}
                </Text>
              </View>
              <View style={{flexDirection:"row"}}>
                <Text style={{fontSize:"10pt", width:"450pt"}}>
                  FSG: 
                </Text>
                <Text style={{fontSize:"10pt"}}>
                  {formatter.format(params.tot * 0.8 - params.costs)} 
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
}
