import axios from 'axios';
import config from './apiConfig.js';

export default class Users{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "tr7nab796a";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.users_api_key;
  }
  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(uid){
    return axios.get(`${this.endpoint}/read/id/${uid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getWhere(col, val){
    return axios.get(`${this.endpoint}/read/col/${col}/${val}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateWhere(uid, col, body){
    this.requestTemplate.body = body; 
    
    return axios.post(`${this.endpoint}/update/${col}/nothing/${uid}/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateUsername(uname, col, val){
    return axios.post(`${this.endpoint}/update/username/${uname}/${col}/${val}/${this.user}`,
      {},
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readMak(period, user){
    return axios.get(`${this.endpoint}/stats/mak/2020-01-01/2020-12-01/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readOutstanding(period, user){
    return axios.get(`${this.endpoint}/stats/outstanding/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );

  }

  async readSoldPerDay(period, user){
    return axios.get(`${this.endpoint}/stats/sold/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readLogins(period, user){
    return axios.get(`${this.endpoint}/stats/logins/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readPayments(period, user){
    return axios.get(`${this.endpoint}/stats/payments/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async readCustomers(period, user){
    return axios.get(`${this.endpoint}/stats/customers/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
  async readOrders(period, user){
    return axios.get(`${this.endpoint}/stats/orders/${period.start}/${period.end}/${user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async sendCustomNotification(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/send/custom/notification`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

