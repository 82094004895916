import React, { useState, useEffect } from 'react';
import { GoogleSpreadsheet, GoogleSpreadsheetWorksheet } from "google-spreadsheet";
import { 
  Button, 
  Checkbox, 
  FormControlLabel
} from '@material-ui/core';

export default function Sheets(params){
  // Config variables
  const [SPREADSHEET_ID, setSpId] = useState("1lE1OaV1IHd_ZDQDKex804FZlmrZc8iNdvZBiy60nwOE");
  const [SHEET_ID, setID] = useState(0);
  const [CLIENT_EMAIL, setCe] = useState("vasa-338@gam-project-6y4-0ra-5vh.iam.gserviceaccount.com");
  const [PRIVATE_KEY, pk] = useState("-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDD2bxEvcevNRP5\nyaUfxWXPg8oG99LBOg4/DaoOANk1YpxzcevSvBIeL6AVGdN/Tbs/n2XP1v8oSYSf\nPi9pPxcFd1d0nJ00pGlhYZta/jHoKpwm0R+TyPCXKNxkdJi3eU6affPO1QtzjCSI\nrAfIe6a3S8Mh+Lvu6yVff0w/mBrYopfO0onNjfbK9medb8+l4ONomlUlusxtj9ey\nacX4dwHCu+GBtKKC856ARevIuSj2cnJX+axFLe0vdJ0XU5ba5bynJYIwZmR8azLY\nOQZ1EekPurf9+B2lDuIEEtVJwx2V0FhIdB3Wwn0a2MSa4pJTa7igMOq8o4KNOGvi\niG/zKvkhAgMBAAECggEAFth1fGxJ2H9/yMWzkAjx9xqnrJfoevc3dbX1lgQV0kMi\nPlo12MNs9p32FbgJQoKR+towYWXyJiQrRvsVQzjjidTxu+3P7bXXAA/HdD4OGser\npuCChzXrkKpoyQQL020gVOen0sLpvs0EbvfoQYPg/uUkyjfuTjCaT7E09v8adJYQ\niUBEYxyU40fsR0A+GdeEZFDXvaiDtNCu32AIUL3eLtUGyXTtRVB4QwEcRkkbfgqn\nJ7/XJhhcCz65D6qjgq/UXd/nDY840TcRTYUBusKT+xX+yXyS72ELpvUhL6ObkbNG\niHkt5QZbpcx3B23EILFAvG34g4wjEX9NreS4NBOnBQKBgQDo4V5BWrQvAP6+yfFC\nwNvdoRnbUQaRX6gUwrxydYStC/bVN/T2h2D3lZwZ4JCcROXwEJyrYN3w+ovMKRSV\n9hQirA3MNZNFgYBro7eEywSc9HIMn3z0HvEr9dG69/g5NiKUM0dolKrJcej9ry7c\nc0QFJ47YMV/77bQcDwF9gB3NLQKBgQDXS0IMPjXLYGwxPxquJPev2UrYMoJoEQxl\n+49MeFVc64E5cibr5JwEeM/NON/dc28VfbRbmhz/G7FxhXvNOIZjSAL+QSNkwKMD\nzAY7fdsCKWZCa9r/EFwL1SZEOld/RkJKNOnJ40HZCv3DfenKRUXvEnDDwbvXRarE\nCVPTbBDcRQKBgQCLOCfL9DihnPut/TXhDUCjpyq5BSBjCgeGmcBkwMKXXP6sCu7D\n7rzWZjdS9QAem8XGlXfDsH4eEPpADBMFb34sGCWLfj/Tno6ySsZseZ4BYtVHnJTr\nsA/HkwZVuuZG9YkIhftblvzevt1lwzPFkJcKadKohiP2jO1OlN30NAIc5QKBgQCm\nOzuSDG6oy5h+JcVJ9M0x/8rkR4udIpy6GZQ5bSgsCMLMQjF/LhKw+T8pq+JeMITy\nyQ57M/FzoERP7Mr8V8qb2bxF6LGVV5AGnH+ra6+waZrFalW7XzxS/o7/WzS31t3U\nQirJcSkklnH9gbkELpyizVA4Ndv9iZMirjkFI4mZKQKBgCk7xec2u8Uk2+jhx7pA\nJYnVtCxSF+hhMRGbmP89wuI+SOb/fWzcM8r7Kj3D4Lo+CNuQlOGDEwwbvYiJbRoK\nVcacXbJrzR3v5zekDIFikLilzREfWSJXiT+3ZwttGuHKlUFNooIyakph6ie6ZMFT\nmTQ/xgqNOZ25x+LXxwZ7K30m\n-----END PRIVATE KEY-----\n");

  const [doc, setDoc] = useState(new GoogleSpreadsheet(SPREADSHEET_ID));

  const [newRows, setNewRows] = useState(params.newRows)

  const [replaceOld, setReplaceOld] = useState(false)

  useEffect(() => {
    setNewRows(params.newRows)
  },[params.newRows])

  const handleUpload = (row) => {
    console.log(replaceOld)
    console.log(CLIENT_EMAIL)
    console.log(PRIVATE_KEY)
    console.log("doc", doc)
    if(replaceOld){
      deleteOldRows().then(() => {
        appendSpreadsheet(row)
      })
      .catch(err => {
        console.log(err)
      })
    }
    else{
      appendSpreadsheet(row)
    }
  }

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      row.map((e,i) => {
        row[i].orderDate = e["orderDate"].replace("T", "").replace("Z", "").slice(0,-4)
      })
      const result = await sheet.addRows(row);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const deleteOldRows = async () => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      await sheet.clear()
      await sheet.setHeaderRow(Object.keys(newRows[0]))
    } catch (e) {
      console.log('Error: ', e);
    }
  }

  return(
    <>
    <Button onClick={() => handleUpload(newRows)}>
      Uppdatera sheets
    </Button>
    <FormControlLabel
      control={
       <Checkbox
          checked={replaceOld}
          onChange={() => setReplaceOld(p => !p)}
          inputProps={{'aria-label': 'Replace'}}
          color="primary"
          label="Ersätt gamla värden"
        />
      }
      label="Ersätt gamla värden"
    />
    </>
  )
}

