import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';
import { EditableText } from '../general/UpdatableValue.js';
import Bugs from '../../shared/Bugs.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDate} from '../../shared/dateHelper.js'
import { EditableCounter } from '../general/UpdatableValue.js';

import BugReportDialog from '../BugReportDialog.js';

import {
  makeStyles, 
  useTheme, 
  fade, 
  createMuiTheme, 
  MuiThemeProvider
} from '@material-ui/core/styles';

import {Header, Body, Module} from '../Modules'; 
import FaceIcon from '@material-ui/icons/Face';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  TableRow,
  TableCell,
  Collapse
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
}));

function BugSummary(params){

  const bugs = params.bugs.filter(e => e.id == params.id)[0]; 
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    return function cleanup(){
      setOpen(false);
    }
  },[])

  return(
    <TableRow>
      <TableCell colSpan={12}>
        <Collapse in={open} unmountOnExit>
          <Grid container style={{width:"100%"}}>
            <Grid item xs={12}>
              <Typography>
                <b>Beskrivning:</b> {bugs.description} 
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Förväntat beteende:</b> {bugs.expectation} 
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Skapat av:</b> {bugs.creator} 
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    props.setValue(true);
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default function BugsModule(params){
  const classes = useStyles();
  const theme   = useTheme(); 

  const [bugs, setBugs]             = useState([]);
  const [currRow, setCurrRow]       = useState(null);
  const [anchorEl, setAnchorEl]     = useState(null);
  const [prioAnchor, setPrioAnchor] = useState(null);
  const [firstLoad, setFirstLoad]   = useState(true);
  const [openBug, setOpenBug]       = useState(false);

  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const { entityEventsApi, productsApi, currentUser } = useAppContext();
  const { dataFromServer } = useComsContext();

  const prefersDarkMode = theme.palette.type == "dark";

  const bugsApi = new Bugs(currentUser.username);

  const handleBugStatusClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleBugStatusClose = (e) => {
    setAnchorEl(null);
  }


  const deleteBugs = (ids) => {
    for(var i = 0; i < ids.length; i++){
      alert(`Delete id: ${bugs[ids[i]].id}`)
      bugsApi.delete(bugs[ids[i]].id)
        .then(res => {
          notify("success", "Bug har tagits bort");
        })
        .catch(err => {
          notify("error", `Kan inte ta bort bug. ${err}`);
        });
    }
    setBugs(bugs.filter(e => !ids.includes(e.id)));

  }

  const handleUpdate = (col, val) => {
    const rowIndex = currRow.rowIndex;
    const id = currRow.rowData[0];
    alert(`update: ${id}, ${col} = ${val}`) 
    updateStaticTable(rowIndex, col, val);

    if(bugsApi !== null){
      bugsApi.update(id, col, val)
      .then(res => {
        notify("success", `${id} uppdaterad ${col} -> ${val}`);
      })
      .catch(err => {
          notify("error", `Kunde inte uppdatera bug. Felmeddelande: ${err}`)
      });
    }
    else{
      notify("error", "Order API är inte tillgänglig.")
    }

    setAnchorEl(null);
    setPrioAnchor(null);
  }

  const updateStaticTable = (index, col, val) => {
    var tmpBugs= [...bugs];
    tmpBugs[index][col] = val;
    setBugs(tmpBugs);
  }


  useEffect(() => {
    if(dataFromServer.entity == "bugs" || firstLoad){
      setFirstLoad(false);
      bugsApi.getAll().then(res => {
        setBugs(res.data.bugs);
      });
    }
  },[dataFromServer])

  const getMuiTheme = () => createMuiTheme({
     palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        domain: {
          customers: {main: prefersDarkMode ? "#1565c0" : "#64b5f6"},
          admin: {main: prefersDarkMode ? "#d32f2f" : "#9575cd"},
          products: {main: prefersDarkMode ? "#ef6c00" : "#ffb74d"},
          users: {main: prefersDarkMode ? "#43a047" : "#81c784"}
        },
        background: {
          default: prefersDarkMode ? "#141414" : "#f7f7f7",
          paper: prefersDarkMode ? "#212121" : "#fff"
        },
        text:{primary: prefersDarkMode ? "#cfcfcf" : "rgba(0, 0, 0, 0.87)"},
        select:{primary: prefersDarkMode ? "#1c1c1c" : "#ededed"}
     },
    overrides:{
      MUIDataTableBodyRow:{
        root:{
          borderBottom:"1px solid #737373",
          '&:nth-child(odd)':{
            backgroundColor: theme.palette.background.default,
          },
          '&:nth-child(even)':{
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      MUIDataTableHeadCell:{
        root:{
          fontWeight:"bold",
          position:"sticky"
        }
      },

    }
  });

  const columns = [
    {
      name: "id",
      label: "ID",
      options:{
        display: false
      }
    },
    {
      name: "title",
      label: "Titel",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <>
              <Typography
                onClick={(e) => {setCurrRow(tableMeta); handleBugStatusClick(e)}}
              >
                {value}
              </Typography>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleBugStatusClose}
              >
                <MenuItem onClick={(e) => handleUpdate("status", "Inte påbörjad")}>
                  Inte påbörjad 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "Utvärderas")}>
                  Utvärderas 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "Pågående")}>
                  Pågående 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "Fix utvärderas")}>
                  Fix utvärderas 
                </MenuItem>
                <MenuItem onClick={(e) => handleUpdate("status", "Klart")}>
                  Klart 
                </MenuItem>
              </Menu>
            </>
          )
        }
      }
    },
    {
      name: "tags",
      label: "Taggar",
    },
    {
      name: "priority",
      label: "Prioritet",
      options:{
        sortable: true,
        customSort: (data, colIndex, order, meta) => {
           return data.sort((a, b) => {
            return (
              Number(a.data[colIndex]) < Number(b.data[colIndex]) ? -1: 1 ) * (order === 'desc' ? 1 : -1);
            });
         },
         customBodyRender: (value, tableMeta, updateValue) => {
           const id = tableMeta.rowData[0];
           return(
             <>
               <Typography 
                 variant="subtitle1" 
                 onClick={(e) => {setCurrRow(tableMeta); setPrioAnchor(e.currentTarget)}}
                 style={{background:`rgba(235, 64, 52, ${value/5})`}}
               >
                 {Number(value)}
               </Typography>
                <Menu
                  anchorEl={prioAnchor}
                  keepMounted
                  open={Boolean(prioAnchor)}
                  onClose={() => setPrioAnchor(null)}
                >
                  <MenuItem onClick={(e) => handleUpdate("priority", "1")}>
                    1 
                  </MenuItem>
                  <MenuItem onClick={(e) => handleUpdate("priority", "2")}>
                    2 
                  </MenuItem>
                  <MenuItem onClick={(e) => handleUpdate("priority", "3")}>
                    3 
                  </MenuItem>
                  <MenuItem onClick={(e) => handleUpdate("priority", "4")}>
                    4 
                  </MenuItem>
                  <MenuItem onClick={(e) => handleUpdate("priority", "5")}>
                    5 
                  </MenuItem>
                </Menu>

             </>
           )
         }
      }
    },
  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    setTableProps: () => ({size:"small"}),
    onRowsDelete: (rowsDeleted, data) => {
      deleteBugs(Object.keys(rowsDeleted.lookup));
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return(<BugSummary id={rowData[0]} bugs={bugs}/>)
    },
    customToolbar: () => {
      return (<CustomTableToolbar setValue={(b) => setOpenBug(b)}/>)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa buggar",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header 
        color={theme.palette.domain.admin.main} 
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Buggar
      </Header>
      <Body>
        <Grid 
          style={{overflowY: "auto", height:"95%", overflowX:"hidden"}}
          container
        >
          <Typography variant="h3">
            Buggar
          </Typography>
          <Grid container style={{height:"100%"}}>
            <div style={{width: "100%"}}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  size="small"
                  title={"Lager"}
                  data={bugs}
                  columns={columns}
                  options={options}
                  style={{width: "100%"}}
                />
              </MuiThemeProvider>
              <Typography style={{marginTop:"25px"}} variant="h6">
                Prioriteter 
              </Typography>
              <Typography style={{background: `rgba(235, 64, 52, 0.2)`}}>
               1. Buggen leder till mindre besvär som man helst vill vara utan
              </Typography>
              <Typography style={{background: `rgba(235, 64, 52, 0.4)`}}>
               2. Buggen gör att man jobbar långsammare
              </Typography>
              <Typography style={{background: `rgba(235, 64, 52, 0.6)`}}>
               3. Buggen gör det besvärligt att göra en funktion 
              </Typography>
              <Typography style={{background: `rgba(235, 64, 52, 0.8)`}}>
               4. Buggen gör det omöjligt att göra en funktion 
              </Typography>
              <Typography style={{background: `rgba(235, 64, 52, 1)`}}>
               5. Buggen gör att VASA inte kan användas 
              </Typography>
            </div>
          </Grid>
          <BugReportDialog
            title={""}
            open={openBug}
            handleClose={() => setOpenBug(false)}
          />
        </Grid>
      </Body>
    </Module>
  )
}
