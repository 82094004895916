import React, { useState, useEffect } from 'react';
import { NewCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { useAppContext } from '../../libs/contextLib';

import _ from 'lodash';

import ServiceList from './ServiceList'; 
import NewCustomerForm from './NewCustomerForm';
import CustomerSummary from './CustomerSummary';
import FinishOrder from './FinishOrder';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Slide from "@material-ui/core/Slide"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import gif from "../../assets/customer_created_animation.gif";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  mainGrid:{
    width: '100%',
  },
  textfield: {
    width: '100%',
    margin: theme.spacing(1),
  },
  large:{
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  caption: {
    color: "gray",
  }
}));

function getSteps(){
  return ['Skriv', 'Inspektera', 'Skapa'];
}

function getStepContent(step, params){
  
  switch (step) {
    case 0:
      return (
        <NewCustomerForm params={params} />
      );
    case 1:
      return (
        <CustomerSummary />
      );
    case 2:
      return <FinishOrder />
    default:
      return <Typography>Steget finns inte</Typography>
  }
}

export default function NewCustomerModule(params){

  const { onRemoveItem, addModule, notify } = useMainGridContext();
  const { currentUser }   = useAppContext();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set()); 
  const steps = getSteps();
  const [validationError, setValidationError] = useState(false);

  const [changeStep, setChangeStep] = useState(true);
  const [stepDir, setStepDir] = useState(true);

  const [services, setServices] = useState([]);
  const [signed, setSigned] = useState(false);
  const [old, setOld] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [billingLen, setBillingLen] = useState("");
  const [billing, setBilling] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmt]     = useState("");
  const [nextDisabled, setNextDisabled] = useState(false);
  const [createdCid, setCreatedCid] = useState(false);
  const [fsg, setFsg] = useState(0);
  const [comments, setComments] = useState([]);
  const [customerExists, setCustomerExists] = useState(false);

  const [loadingFSG, setLoadingFSG] = useState(false);

  const [currentService, setCurrentService] = useState({
    "service": "",
    "price": "",
    "amount": "",
  });

  const [errorText, setErrorText] = useState({
    "name": "",
    "socialSecurityNumber": "",
    "adress": "",
    "zipCode": "",
    "municipality": "",
    "telephone": "",
    "email": "",
    "service": "",
    "price": "",
    "amount": "",
    "subscriptionLength": "",
    "billing": "",
    "customerid": "",
  });


  const [customerInfo, setCustomerInfo] = useState({
    "customerid": "0",
    "name": "",
    "adress": "",
    "socialSecurityNumber": "",
    "zipCode": "",
    "municipality": "",
    "telephone": "",
    "email": "",
    "salesman": currentUser.username 
  });

  const validateField = (field) => {
    if(field == "email"){
      return false;
    }
    if(customerInfo[field].length === 0) 
    {
      showValidationError(field);
      return true;
    }
    if(customerInfo[field].length > 0)
    {
      hideValidationError(field);
      return false;
    }

    return false;
  }

  const showValidationError = (field) => {
    errorText[field] = `Kund måste ha ${field}`;
    setErrorText(errorText);
    setValidationError(true);
  }

  const hideValidationError = (field) => {

    errorText[field] = "";
    setErrorText(errorText);
    setValidationError(false);
  }

  const validateAll = () => {
    var fields = Object.keys(customerInfo);
    for(var i = 0; i < fields.length; i++){
      var isError = validateField(fields[i]);
      if(isError){
        return false;
      }
    }

    if(
      currentService.service !== undefined && 
      currentService.service.length > 0 &&
      currentService.price.length > 0 &&
      currentService.amount.length > 0 &&
      activeStep == 0
    ){
      services.push(currentService);
    }

    else if(services.length == 0 && customerInfo.customerid == '0'){
      return false;
    }

    if(loadingFSG){
      notify("info", "Vänta medans FSG beräknas...");
      return false;
    }

    if(customerInfo.socialSecurityNumber.length < 10){
      notify("error", "Kund måste ha 10 siffrors personnummer/orgnummer");
      return false;
    }
    
    if(customerInfo.customerid == '0'){
      for(var i = 0; i < services.length; i++){
        if(services[i].service.length == 0){
          return false;
        }
        if(services[i].price.length == 0){
          return false;
        }
        if(services[i].amount.length == 0){
          return false;
        }
      }

      if(billing.length == 0){
        return false;
      }
      if(billingLen.length == 0){
        return false;
      }
    }
    if(customerExists){
      notify("error", "Det finns redan en kund med detta personnummer.")
      return false
    }

    return true;
  }

  const isStepOptional = (step) => {
    return step === 1;
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if(validateAll()){
      setStepDir("left")
      setChangeStep(false);

      setTimeout(() => {

        if(isStepSkipped(activeStep)){
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        setStepDir("right");
        setChangeStep(true);
      }, 400);
    }
  }

  const handleBack = () => {
    setStepDir("right");
    setChangeStep(false);

    setTimeout(() => {
      setCurrentService({});
      setActiveStep((prevActiveStep) => prevActiveStep - 1);

      setStepDir("left");
      setChangeStep(true);
    }, 400);
  }

  const handleSkip = () => {
    if(!isStepOptional(activeStep)){
      throw new Error("Kan inte skippa ett steg som inte är valfritt");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    })
  }

  const handleReset = () => {
    customerInfo.name = "";
    customerInfo.socialSecurityNumber = "";
    customerInfo.telephone = "";
    customerInfo.email = "";
    customerInfo.adress = "";
    customerInfo.zipCode = "";
    customerInfo.municipality = "";

    setCustomerInfo(customerInfo);
    setServiceName("");
    setBilling("");
    setBillingLen("");
    setPrice("");
    setAmt("");
    setServices([]);
    setActiveStep(0);
  }

  const setCustomer = () => {
    customerInfo.name = "Filip Zlatoidsky";
    customerInfo.socialSecurityNumber = "12345678910";
    customerInfo.telephone = "111234567";
    customerInfo.email = "fillatino@gmail.com";
    customerInfo.adress = "Gamla Göteborgsvägen 12";
    customerInfo.zipCode = "511 63";
    customerInfo.municipality = "Mark";

    setCustomerInfo(customerInfo);
  }

  return(
    <NewCustomerContext.Provider value={
      {
        old,
        setOld,
        signed,
        setSigned,
        customerInfo, 
        setCustomerInfo, 
        errorText, 
        setErrorText, 
        validateAll,
        validationError,
        setValidationError,
        services,
        serviceName,
        setServiceName,
        setServices,
        billingLen,
        setBillingLen,
        billing,
        setBilling,
        price,
        setPrice,
        amount,
        setAmt,
        hideValidationError,
        currentService,
        setCurrentService,
        setNextDisabled,
        handleNext,
        setCreatedCid,
        fsg,
        setFsg,
        comments,
        setComments,
        loadingFSG,
        setLoadingFSG,
        customerExists,
        setCustomerExists
      }}>
      <Module 
        auths={params.auths} 
        remove={() => onRemoveItem(params.id)} 
        index={params.id}
      >
        <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
          {params.id} Ny kund
        </Header>
        <Body>
          <Grid container style={{overflowY:"auto", height:"95%", padding: "20px"}}>
            <form style={{width: "100%"}}>
              {activeStep === steps.length ? (
                <Grid container alignItems="center" justify="center" direction="column">
                    <img src={gif} style={{height: "50%", width:"50%"}}/>
                    <Typography>
                      {customerInfo.name} har registrerats med kundnummret: {createdCid}
                    </Typography>
                    <Button onClick={() => addModule(`::om ${createdCid}`)}>
                      Öppna
                    </Button>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                          <Button 
                            onClick={handleReset}
                            style={{width: "100%"}}
                          >
                            <Grid container>
                              <Grid item xs={1}>
                                <AccountCircleIcon />
                              </Grid>
                              <Grid item xs={11}>
                               Ny kund 
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            onClick={params.remove}
                            style={{width: "100%"}}
                          >
                            <Grid container>
                              <Grid item xs={11}>
                                Stäng
                              </Grid>
                              <Grid 
                                container 
                                xs={1} 
                                alignItems="flex-end" 
                                justify="flex-end"
                              >
                                <CloseIcon />
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                      </Grid>
                </Grid>
              ) : (
                <Grid xs={12}>
                  <div style={{overflow: "hidden"}}>
                    <Slide direction={stepDir} in={changeStep} timeout={{enter:200, exit:200}}>
                      <div style={{padding: "10px"}}>
                        {getStepContent(activeStep, params)}
                      </div>
                    </Slide>
                  </div>
                    {activeStep == 1 ?
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          <Button 
                            onClick={handleBack}
                            style={{width: "100%"}}
                          >
                            <ArrowBackIosIcon style={{fontSize:"medium"}}/>
                            Tillbaka
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            onClick={handleNext}
                            style={{width: "100%"}}
                          >
                            Skapa order
                            <ArrowForwardIosIcon style={{fontSize:"medium"}}/>
                          </Button>
                        </Grid>
                      </Grid>
                      :
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            onClick={handleNext}
                            style={{width: "100%"}}
                            disabled={nextDisabled ? true : false}
                          >
                            Nästa
                            <ArrowForwardIosIcon style={{fontSize:"medium"}}/>
                        </Button>
                        </Grid>
                      </Grid>
                    }
                </Grid>
              )
              }
             </form>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if(isStepSkipped(index)){
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </Grid>
        </Body>
      </Module>
    </NewCustomerContext.Provider>
  );
}
