import axios from 'axios';
import config from './apiConfig.js';

export default class Orders{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "lhsr2j4lvf";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.orders_api_key;
  }
  async get(){
    return axios.get(`${this.endpoint}/read/all/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(oid){
    return axios.get(`${this.endpoint}/read/${oid}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getGroup(oids){
    this.requestTemplate.body = {"oids": oids};
    return axios.post(`${this.endpoint}/get/group`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getGroupWhere(from, to, col, vals){
    this.requestTemplate.body = {"vals": vals};
    return axios.post(`${this.endpoint}/get/group/between/${from}/${to}/${col}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async getWhere(col, val){
    console.log("col", col);
    console.log("val", val);
    return axios.get(`${this.endpoint}/read/where/${col}/${val}/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async updateWhere(oid, col, val){
    return axios.post(`${this.endpoint}/update/${oid}/${col}/${val}/${this.user}`,
      {},
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }
}

