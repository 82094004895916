import React, {useState, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useAppContext } from '../../libs/contextLib';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import VasaDialog from '../../shared/VasaDialog.js';
import Ticker from 'react-ticker'

import {unFormatInteger} from '../general/format.js';

import { useComsContext } from '../../libs/comsContextLib';

import {
  Grid,
  Typography,
  Avatar,
  Paper,
  LinearProgress
} from "@material-ui/core";

import {
  LineChart, 
  AreaChart,
  Area,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Line,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  Tooltip,
  Brush,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
} from 'recharts';

import { 
  getMonday, 
  getMonthPeriod, 
  currentMonthPeriod, 
  dateFormatZero,
  getMonthIndexFromName,
  getWeekPeriod,
  getWeek,
  getCurrentDay,
  getDaysAhead,
  getDaysAheadFromDate,
  getTimeFromDate,
} from '../../shared/dateHelper.js';

import Users from '../../shared/Users.js';
import Groups from '../../shared/Groups.js';

var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
});

function SalesmanPill(params){
  const theme = useTheme();

  const [salesman, setSalesman] = useState(params.salesman)
  const [profile, setProfile]   = useState(null)
  const [data, setData]         = useState(params.data)
  const [title, setTitle]       = useState(params.title)


  useEffect(() => {
    setSalesman(params.salesman);
    setData(params.data);
    if(params.allUsers !== null){
      const current = params.allUsers.filter(u => u.username == params.salesman)[0]
      if(current !== undefined){
        setProfile(current.profileMedia);
      }
    }

  },[params])
  return(
    <Paper elevation={2} style={{borderRadius:"100px"}}>
      <Grid container>
        <Grid item xs={3}>
        <Avatar 
          style={{width:theme.spacing(10), height:theme.spacing(10),}}
          src={`https://vasa.s3.us-east-2.amazonaws.com/${profile}`} 
        />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {title} 
          </Typography>
          <Typography variant="h6" style={{lineHeight:"25px"}}>
            <b>{salesman}</b> <span style={{fontSize:"18px"}}>{data}</span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

function NewSale(params){
  const [openDialog, setOpenDialog] = useState(params.open);
  const setOpenMain= params.setOpen;
  const users = params.users;
  const event = params.event;

  const [msgs, setMsgs] = useState([
    "vem fan är som du!?", 
    "du är fan snyggast på kontoret!🍆💦", 
    "ett nytt sälj!🤑🤑🤑", 
    "ta det lungt nu du får låta dom andra hinna ikapp också!🤑",
    "oooofff, ännu ett sälj!",
    "har gjort ett sälj!😍😍😍",
  ])

  const vids = [{name: "doggy.webm", time:13500}, {name: "saddam.webm", time: 29500}, {name: "ygyl.webm", time: 35500}];
  const [vid, setVid] = useState("ygyl.webm");
  const [time, setTime] = useState(35500);

  useEffect(() => {
    if(params.open){
      var i = Math.floor(Math.random()*vids.length);
      setOpenDialog(true);
      setVid(vids[i].name);
      setTimeout(() => {setOpenDialog(false)}, vids[i].time);
    }
  },[params.open])
  return(
    <VasaDialog 
      open={openDialog} 
      handleClose={() => setOpenDialog(false)}
    >
      {event !== null ?
        <Grid container>
          <Grid container alignItems="center" justify="center">
            <video width="50%" controls autoPlay muted>
              <source src={`https://vasa.s3.us-east-2.amazonaws.com/dashboard_vids/${vid}`} type="video/webm" />
            </video>
            <Typography variant="h1" style={{color:"#2aeadd"}}><b>{event.creator} har gjort ett sälj!😍😍😍</b></Typography>
          </Grid>
        </Grid>
        :
          null
      }
    </VasaDialog>
  )
}

function UsersTicker(params){

  const [users, setUsers]     = useState([]);
  const [members, setMembers] = useState([]);
  const [width, setWidth]     = useState(7);
  
  useEffect(() => {
    setUsers(params.users);
    setMembers(params.members);
    setWidth(7*params.members.length);
  },[params.users, params.members]);

  return(
    <Ticker>
      {({ index }) => (
        <p style={{width:`${width}vw`}}>
        {members.map(e => (
          <>
            <span style={{paddingRight:"20px"}}>
              {e}: <b>{users.filter(x => x.salesman == e).length>0 ? formatter.format(users.filter(x => x.salesman == e)[0].sold): "0,00 kr"}</b>
            </span>
          </>
        ))}
        </p>
      )}
    </Ticker>
  )
}

function TopList(params){
  const theme = useTheme();

  const { currentUser }         = useAppContext();
  const [soldToday, setSoldToday] = useState(0);
  const [allUsers, setAllUsers] = useState(); 
  const [events, setEvents]     = useState([]);
  const [remove, setRemove]     = useState([]);
  const [list, setList]         = useState([]);

  useEffect(() => {
    setSoldToday(params.soldToday) 
  },[params.soldToday])

  useEffect(() => {
    setAllUsers(params.allUsers);

    var rm = params.allUsers.filter(e => e.authority == "kundsjänstp");
    rm = rm.map(e => {return(e.username)});

    var removedList = params.list.filter(e => !rm.includes(e.salesman));
    setList(removedList);
  },[params.allUsers, params.list])

  return(
    <Grid style={{marginLeft:"50px"}}>
      <div style={{textAlign:"center"}}>
      <Typography variant="h5">
        Idag
      </Typography>
      <Typography style={{fontSize:"90px"}}> 
        <b>{soldToday}</b>
      </Typography> 
      </div>
    {
      list[0] !== undefined ?
        <Grid 
          container 
        >
        {[list[0], list[1], list[2]].map((e,i) => {
          if(e !== undefined){
            const current = allUsers.filter(u => u.username == e.salesman)[0]
            if(i == 0 && current !== undefined){ 
              return(
                <Grid container style={{marginBottom: "20px"}}>
                  <Grid row container alignItems="center" justify="center">
                    <Avatar 
                      style={{width:theme.spacing(24), height:theme.spacing(24),}}
                      src={`https://vasa.s3.us-east-2.amazonaws.com/${current.profileMedia}`} 
                    />
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <Typography variant="h6">
                      1. {current.fullName}
                    </Typography>
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <img 
                      src={`https://vasa.s3.us-east-2.amazonaws.com/dashboard_vids/trophy.gif`}
                      height={50}
                    />
                    <Typography variant="h4">
                      <b>{e.data}</b>
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
            if(i == 1 && current !== undefined){    
              return(
                <Grid row xs={6}>
                  <Grid row container alignItems="center" justify="center">
                    <Avatar 
                      style={{width:theme.spacing(24), height:theme.spacing(24),}}
                      src={`https://vasa.s3.us-east-2.amazonaws.com/${current.profileMedia}`} 
                    />
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <Typography variant="h6">
                      2. {e.salesman}
                    </Typography>
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <Typography variant="h5">
                      <b>{e.data}</b>
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
            if(i == 2 && current !== undefined){    
              return(
                <Grid row xs={6} style={{paddingTop:"50px"}}>
                  <Grid row container alignItems="center" justify="center">
                    <Avatar 
                      style={{width:theme.spacing(24), height:theme.spacing(24)}}
                      src={`https://vasa.s3.us-east-2.amazonaws.com/${current.profileMedia}`} 
                    />
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <Typography variant="h6">
                      3. {e.salesman}
                    </Typography>
                  </Grid>
                  <Grid row container alignItems="center" justify="center">
                    <Typography variant="h6">
                      <b>{e.data}</b>
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
          }
        })}
        <Grid container style={{paddingLeft:"50px", paddingTop:"50px"}}>
          {list.slice(3).map((e,i) => {
            const current = allUsers.filter(u => u.username == e.salesman)[0]
            if(current !== undefined){
              return(
                <>
                <Grid row xs={2}>
                  <Avatar 
                    src={`https://vasa.s3.us-east-2.amazonaws.com/${current.profileMedia}`} 
                  />
                </Grid>
                <Grid row xs={5}>
                  <Typography variant="h6">
                    {i+4}.{e.salesman}
                  </Typography>
                </Grid>
                  <Grid row container xs={5} alignItems="flex-end" justify="flex-end" style={{paddingRight:"30px"}}>
                  <Typography variant="h6">
                    <b>{e.data}</b>
                  </Typography>
                </Grid>
                </>
              )
            }
          })}
        </Grid>
      </Grid>
    :
      null
    }
    </Grid>
  )
}

function GroupsList(params){
  const [groupsApi, setGroupsApi] = useState(null);
  const [groups, setGroups]       = useState([]);
  const [groupsSold, setGroupsSold] = useState({});
  const [groupSorted, setGroupSorted] = useState([]);
  const [userSold, setUserSold]       = useState({});

  useState(() => {
    var gapi = new Groups("admin1");
    gapi.getAll().then(res => {
      setGroups(res.data.groups);
        res.data.groups.map((e,i) => {
          if(e.showGlobal){
            var m = currentMonthPeriod();
            var body = {name: e.id, from:m.start, to: m.end};
            gapi.groupSold(body).then(r=> {
              setUserSold(p => ({...p, [e.id]: r.data.recordset}))
              
              var tot = 0;
              r.data.recordset.map(e => tot += e.sold);
              setGroupsSold(p => ({...p, [e.id]: tot}));
            });
          }
      });
    })
  },[]);

  const userList = (users) => {
    var res = "";
    users.map(e => res += `${e.salesman}: <b>${e.sold}</b>`)
    return res;
  }

  return(
    <Grid container spacing={3}>
      {Object.keys(groupsSold).filter(e => e !== "filter").sort((a,b) => groupsSold[a]- groupsSold[b]).reverse().map(e => {
        var groupData = groups.filter(x => x.id == e)[0];
        var id        = groupData.id;
        var goal      = groupData.goal;
        var users     = groupData.users;
        var color     = groupData.color;
        var p         = Math.floor((groupsSold[id]/goal)*100);
        return(
          <Grid item xs={12} style={{marginTop:"20px", borderLeft: `5px solid ${color}`, borderRadius:"5px"}}>
          <Typography variant="h4">
            {groupData.id} <span style={{color: p >= 99 ? "rgba(38,224,162,0.9)":"rgba(224,38,112,0.9)"}}><b>{Math.floor((groupsSold[id]/goal)*100 > 100 ? 100 : groupsSold[id]/goal*100)}%</b></span>
          </Typography>
          <Grid container>
            <Grid item xs={11}>
              <LinearProgress 
                value={(groupsSold[id]/goal)*100 > 100 ? 100 : groupsSold[id]/goal*100} variant="determinate"/>
            </Grid>
          </Grid>
          <Typography>
            Mål: {formatter.format(goal)} Sålt: {groupsSold[id] !== undefined ? formatter.format(groupsSold[id]) : "0,00 kr"}
          </Typography>
           <Typography variant="overline">
             Medlemmar ({users.length})
           </Typography>
            <UsersTicker members={users} users={userSold[id]}/>
        </Grid>
        )
      })}
    </Grid>
  )
}

export default function DefaultDashboard(params){
  const theme = useTheme();

  const { currentUser } = useAppContext();

  const [topList, setTopList]               = useState([]);
  const [soldToday, setSoldToday]           = useState(0);
  const [soldMonth, setSoldMonth]           = useState([]);
  const [soldTotalMonth, setSoldTotalMonth] = useState([]);
  const [events, setEvents]                 = useState([]);
  const [connected, setConnected]           = useState(false);
  const [dataFromServer, setDataFromServer] = useState(null);
  const [sortedSales, setSortedSales]       = useState([]);
  const [ws, setWs]                         = useState(null);

  const [filter, setFilter]                 = useState([]);
  
  const [allUsers, setAllUsers]             = useState([]);
  
  const [newSale, setNewSale]           = useState(false);
  const [newSaleEvent, setNewSaleEvent] = useState(null);

  const [minMonth, setMinMonth] = useState(0);
  const [maxMonth, setMaxMonth] = useState(0);

  const connect = () => {
    var newWs = new WebSocket('wss://45pfhurbek.execute-api.us-east-2.amazonaws.com/dev');

    newWs.onopen = () => {
      setConnected(true);
    }
    newWs.onmessage = evt => {
      const message = JSON.parse(evt.data);
      setDataFromServer(message);
    }
    newWs.onclose = (e) => {
      console.log("Socket closed. Reconnect will be attempted in 1 second: ", e.reason)
      setTimeout(() => connect(), 1000);
      setConnected(false);
    }

    setWs(newWs);
  }

  const getData = () => {
    setSoldTotalMonth(0);
    var from = getCurrentDay();
    var to = getDaysAhead(1);
    var requestTemplate =  {
      "body": {"from": from, "to": to},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    };

    axios.get("https://rlxysyw958.execute-api.us-east-2.amazonaws.com/dev/officeSold/today", requestTemplate).then(res => {
      setSoldToday(res.data.recordset[0].data);
    }).catch(err => console.log("error", err));

    axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/group/sold/price", requestTemplate).then(res => {
      setTopList(res.data.recordset);
    }).catch(err => console.log("error", err));

    const cMonth = currentMonthPeriod(); 
    from = cMonth.start;
    to = cMonth.end;

    var requestTemplate =  {
      "body": {"from": from, "to": to},
      "requestContext": {
        "identity": {
          "cognitoUserIdentity": "admin1"
        } 
      }
    };

    axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/group/timeseries/day", requestTemplate).then(res => {
      setSoldMonth(res.data.recordset);
      var min = 0;
      var max = 0;
      res.data.recordset.map((e,i) => {
        setSoldTotalMonth(n => Number(n) + Number(e.data));
        if(i == 0){
          min = e.data;
        }
        else if(e.data < min && e.data > 10){
          min = e.data; 
        }
        if(e.data > max){
          max = e.data;
        }
      })
      setMinMonth(min);
      setMaxMonth(max);
    })
  }

  useEffect(() => {
    getData();
    var uApi = new Users(currentUser.username);
    uApi.getAll().then((res) => {
      setAllUsers(res.data.recordset);
    });

    var gapi = new Groups("admin1");
    gapi.getAll().then(res => {
      var f = res.data.groups.filter(e => e.id == "filter")[0];
      setFilter(f);
      const cMonth = currentMonthPeriod(); 
      var from = cMonth.start;
      var to = cMonth.end;

      var requestTemplate =  {
        "body": {"from": from, "to": to},
        "requestContext": {
          "identity": {
            "cognitoUserIdentity": "admin1"
          } 
        }
      };

      axios.post("https://rvxoumkbp1.execute-api.us-east-2.amazonaws.com/dev/chart/salesman/sold/fsg", requestTemplate).then(res => {
        console.log("res", res);
        setSortedSales(res.data.recordset.filter(e => e.salesman !== "julia.sayah"));
      });
    });


  },[])

  useEffect(() => {
    if(ws == null){
      connect();
    }
    else{
      if(dataFromServer.type == "CREATE" && dataFromServer.entity == "orders"){
        setEvents([...events, dataFromServer]);
        getData();
        setNewSale(true);
        setNewSaleEvent(dataFromServer);
      }
    }
  },[dataFromServer])

  return(
    <Grid container>
      <Grid container>
        <Grid item xs={12} container itemsAlign="center" justify="center">
          <img src="https://vasa.s3.us-east-2.amazonaws.com/isgg-logo.png" height={75} width={75}/>
        </Grid>
      </Grid>
      <Grid row xs={3}>
        <TopList allUsers={allUsers} list={topList} soldToday={soldToday}/>
      </Grid>
      <Grid row xs={7} style={{paddingLeft:"100px", paddingRight:"100px"}}>
        <div style={{height:"50vh", padding:"50px", borderRadius:"10px"}}>
          <div style={{height: "90%"}}>
            <Grid container style={{marginBottom:"10px"}}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Dagsförsäljning
                </Typography>
                <Grid container>
                  <Grid style={{padding:"10px"}}>
                    <Typography variant="overline">
                      totalt
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h4">
                      {formatter.format(soldTotalMonth)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6} style={{padding:"5px", background:"rgba(38,224,162,0.1)"}}>
                    <Grid>
                      <Typography variant="overline">
                        Bästa 
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h4">
                        {formatter.format(maxMonth)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{padding:"5px", background:"rgba(224,38,112,0.1)"}}>
                    <Grid>
                      <Typography variant="overline">
                        Sämsta 
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h4">
                        {formatter.format(minMonth)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ResponsiveContainer width="100%">
              <AreaChart
                data={soldMonth.filter(e => e.data > 10)}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area dataKey="data" type="monotone" stackId="a" stroke="#4c8bf5" strokeWidth={5} fill="rgba(75,139,245,0.1)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div style={{height:"30vh", padding:"50px", borderRadius:"10px", marginTop:"20px"}} elevation={2}>
          <div style={{height:"80%"}}>
            <Grid container style={{marginBottom:"10px"}}>
              <Grid item xs={3}>
                <Typography variant="h6">
                  Månadens säljare
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {sortedSales[0] !== undefined ?
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <SalesmanPill allUsers={allUsers} title="Etta" salesman={sortedSales[0].salesman} data={formatter.format(sortedSales[0].data)}/>
                    </Grid>
                    <Grid item xs={4}>
                      <SalesmanPill allUsers={allUsers} title="Tvåa" salesman={sortedSales[1].salesman} data={formatter.format(sortedSales[1].data)}/>
                    </Grid>
                    <Grid item xs={4}>
                      <SalesmanPill allUsers={allUsers} title="Trea" salesman={sortedSales[2].salesman} data={formatter.format(sortedSales[2].data)}/>
                    </Grid>
                  </Grid>
                  :
                  null
                }
              </Grid>
            </Grid>
            <ResponsiveContainer width="100%">
              <ComposedChart
                data={sortedSales}
              >
                <XAxis dataKey="salesman" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="data" stackId="a" fill="#4c8bf5" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Grid>
      <Grid row xs={2}>
         <Typography variant="h6">
           Grupper (siffror visar FSG ex. Mak)
         </Typography>
         <GroupsList />
      </Grid>
      <NewSale open={newSale} setOpen={setNewSale} event={newSaleEvent}/>
    </Grid>
  )
}

