import React, { useState, useEffect, useRef } from 'react';
import { MainGridContext } from '../../libs/mainGridContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';
import { useComsContext } from '../../libs/comsContextLib';
import { useAppContext } from '../../libs/contextLib';

import { v4 as uuidv4 } from 'uuid';

import { makeStyles, useTheme  } from '@material-ui/core/styles';

import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';

import EntityEvents from '../../shared/EntityEvents.js'
import OfficePrefs from '../../shared/OfficePrefs.js'
import Users from '../../shared/Users.js'
import {getTimeFromDate, formatDate} from '../../shared/dateHelper.js';
import SecureFiles from '../../shared/SecureFiles.js';

import _ from 'lodash';
import axios from 'axios';

// Main-ui
import ImportantMessageEvent from './events/important-message';
import LinkShareEvent from './events/link-share';
import CreateEvent from './events/create';
import UserPostEvent from './events/user-post';
import LoginEvent from './events/login';
import EventProducer from './events/event-producer';

// forms
import DefaultMessage from './forms/message.jsx'; 
import ImportantMessage from './forms/important-message.jsx'; 
import LinkShareForm from './forms/link.jsx'; 
import ActivityForm from './forms/activity.jsx'; 

import {
  IconButton, 
  Typography,
  Button,
  Grid,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Tabs,
  Tab,
  Avatar,
  TextField,
  Collapse,
  Paper,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  MenuItem
} from '@material-ui/core';

import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import PublishIcon from '@material-ui/icons/Publish';
import MessageIcon from '@material-ui/icons/Message';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import EventIcon from '@material-ui/icons/Event';
import LinkIcon from '@material-ui/icons/Link';

// Emoji stuff
const i18n = {
  search: 'Sök',
  clear: 'Rensa', // Accessible label on "clear" button
  notfound: 'Ingen emoji hittad',
  skintext: 'Välj automatisk hudton',
  categories: {
    search: 'Sökresultat',
    recent: 'Ofta använda',
    smileys: 'Smileys och känslor',
    people: 'Människor och kroppar',
    nature: 'Djur och natur',
    foods: 'Mat och dryck',
    activity: 'Aktivitet',
    places: 'Semester och platser',
    objects: 'Föremål',
    symbols: 'Symboler',
    flags: 'Falggor',
    custom: 'Eget',
  },
  categorieslabel: 'Emoji kategorier', // Accessible title for the list of categories
  skintones: {
    1: 'Automatisk hudton',
    2: 'Ljus hudton',
    3: 'Medium-Ljus hudton',
    4: 'Medium hudton',
    5: 'Medium-Mörk hudton',
    6: 'Mörk hudton',
  },
}


const useStyles = makeStyles((theme) => ({
  button:{
    background:"transparent", 
    border:"none", 
    color:theme.palette.text.primary, 
    cursor:"pointer"
  },
  link:{
    cursor:"pointer",
    "&:hover":{
      boxShadow: "1px 1px 5px 1px #e685ff",
    }
  },
  publishToolbar: {
    border:`1px solid ${theme.palette.background.paper}`, 
    borderRadius:"5px"
  },
  newsFeedTitle:{
    border:`1px solid ${theme.palette.border.primary}`, 
    borderBottom:"0", 
    padding:"25px", 
    borderTopRightRadius:"10px", 
    borderTopLeftRadius:"10px"
  }
}))

// TODO: mode to another file and import
function textParser(text, v){
  return text.split("::BR::").map(r => (<Typography variant={v}>{r}</Typography>))
}

function GlobalMessages(params){
  const classes = useStyles();
  const theme = useTheme();
  
  const { notify }                = useMainGridContext();
  const { currentUser, session } = useAppContext();
  const {
    dataFromServer, 
  } = useComsContext();

  const [events, setEvents]           = useState([]);
  const [prevEventId, setPrevEventId] = useState(0);
  const [firstLoad, setFirstLoad]     = useState(true);
  const [loading, setLoading]         = useState(true);

  const eventsApi = new EntityEvents(currentUser.username);

  const getLatestEvent = () => {
    setLoading(true)
    eventsApi.getPagePublic(0, 1).then(res => {
      if(res.data.recordset[0].id !== events[0].id){
        setEvents(p => [res.data.recordset[0], ...p].filter((c,index) => {
          return [res.data.recordset[0], ...p].indexOf(c) !== index;
        }));
      }
      setLoading(false);
    }).catch(res => {
      console.log("error", "Kunde inte hämta händelser");
      setLoading(false)
    });
  }

  useEffect(() => {
    setEvents(params.events);
    setFirstLoad(false);
  },[params.events])

  useEffect(() => {
    if(
      String(dataFromServer.created) !== String(prevEventId) && 
      !firstLoad 
    ){
      setPrevEventId(dataFromServer.created);
      getLatestEvent();
    }
  },[dataFromServer]);
  return(
    <>
      {events.map((e,i) => (
        <EventProducer 
          users={params.users} 
          events={events} 
          index={i} 
          event={e}
          key={e.id}
        />
      ))
      }
    </>
  )
}

export default function NewsFeed(params){
  const classes = useStyles();
  const theme = useTheme();

  const { currentUser, session } = useAppContext();
  const { notify } = useMainGridContext();
  const {
    dataFromServer, 
  } = useComsContext();

  const [currentPost, setCurrentPost]         = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [events, setEvents]                   = useState([]);
  const [users, setUsers]                     = useState([]);
  const [firstLoad, setFirstLoad]             = useState(true);
  const [page, setPage]                       = useState(-1);
  const [prevEventId, setPrevEventId]         = useState(0)
  const [loading, setLoading]                 = useState(false);

  const [navVal, setNavVal] = useState(0);
  const [selectedOpt, setSelectedOpt] = useState(0);

  const loader = useRef(null);

  const eventsApi = new EntityEvents(currentUser.username);
  const usersApi  = new Users(currentUser.username);

  useEffect(() => {
    if(!firstLoad){
      getPage();
    }
    setFirstLoad(false);
  },[page])

  // On render do this
  useEffect(() => {
    usersApi.getAll().then(res => {
      setUsers(res.data.recordset);
    });

    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options);
    if(loader.current){
      observer.observe(loader.current)
    }
  },[])

  const handleObserver = (entities) => {
    const target = entities[0];
    if(target.isIntersecting){
      setPage(p => p + 1);
    }
  }

  const getPage = () => {
    setLoading(true);
    eventsApi.getPagePublic(page, 25).then(res => {
      setEvents(p => p.concat(res.data.recordset));
      setLoading(false);
    }).catch(res => {
      console.log("error", "Kunde inte hämta händelser");
      setLoading(false);
    })
  }

  return(
    <Grid 
      container 
      item 
      xs={12} 
      justify="center" 
      alignItems="center" 
      direction="column" 
      style={{marginTop:"200px"}}
    >
        <Grid 
          container 
          className={classes.newsFeedTitle} 
          item 
          xs={6} 
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h3">
              <b>Nyhetsflödet</b>
            </Typography>
            <Typography variant="h6">
              Ta reda på vad som händer i kontoret just nu...
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6} style={{padding:"25px", border:`1px solid ${theme.palette.border.primary}`}}>
          <Grid item xs={12}>
            <Typography variant="h6">
              ...eller skapa egna händelser!
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Text">
              <Button
                className={classes.button}
                onClick={() => setSelectedOpt(0)} 
                style={selectedOpt == 0 ? {color:"#ffa000"} : null}
              >
                <MessageIcon /> 
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Viktigt meddelande">
              <Button
                className={classes.button}
                onClick={() => setSelectedOpt(1)} 
                style={selectedOpt == 1 ? {color:"#ffa000"} : null}
              >
                <AnnouncementIcon /> 
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Aktivitet">
              <Button
                onClick={() => setSelectedOpt(2)} 
                className={classes.button}
                style={selectedOpt == 2 ? {color:"#ffa000"} : null}
              >
                <EventIcon /> 
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Länk">
              <Button
                onClick={() => setSelectedOpt(3)} 
                style={selectedOpt == 3 ? {color:"#ffa000"} : null}
                className={classes.button}
              >
                <LinkIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
          {[
            <DefaultMessage key={1}
              setCurrentPost={(p) => setCurrentPost(p)}
              currentPost={currentPost}
            />,
            <ImportantMessage key={2}/>,
            <ActivityForm key={3}/>, 
            <LinkShareForm key={4}/>,
            ].map((e, i) => {
              if(selectedOpt == i){
                return e;
              }
            })
          }
          </Grid>
        </Grid>
        {loading ?
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
          :
          null
        }
        <Grid 
          container 
          item 
          xs={4} 
          style={{borderLeft:`1px solid ${theme.palette.border.primary}`, borderRight:`1px solid ${theme.palette.border.primary}`}}>
          <GlobalMessages 
            users={users}
            events={events}
          />
        </Grid>
        <Grid item xs={12} style={{padding:"100px"}}>
          <div ref={loader}>
            {loading ?
              <Typography>
                Ladda in mer
              </Typography>
            :
              <CircularProgress />
            }
          </div>
        </Grid>
    </Grid>
  )
}
