import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import Orders from '../../shared/Orders.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDate} from '../../shared/dateHelper.js'

import Customers from '../../shared/Customers.js';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import FaceIcon from '@material-ui/icons/Face';

import _ from "lodash";

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@material-ui/core";

import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Timeline from '@material-ui/lab/Timeline';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {RiMore2Line} from "react-icons/ri";
import ErrorIcon from '@material-ui/icons/Error';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


const useStyles = makeStyles((theme) => ({
  timelineElement:{
    padding:"10px", 
    borderBottom: "1px solid #bdbdbd", 
    borderRadius: "20px"
  },

  eventCreator:{
    padding:"5px"
  },

  eventTopBar: {
    padding: "5px"
  },

  timelineContentElement:{
    background: "#f2f2f2", 
    padding:"10px", 
    borderBottom:"1px solid #bdbdbd"
  },

  backgroundRed:{
    background:"#ffdedb"
  },
  backgroundGreen:{
    background:"#c9ffe2"
  }


}));

function getTimeInfo(time){
  var d = new Date(time);  
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var hours = d.getHours();
  var mins = d.getMinutes(); 
  var secs = d.getSeconds();

  return `${year}-${month}-${date} när klockan var ${hours}:${mins}:${secs}`;
}

function TimelineElement(params){
  const classes = useStyles();
  const e = params.e;

  const getEntity = (entity) => {
    switch(entity){
      case "customers":
        if(e.type.split(":")[0] == "CREATE"){
          return "ny kund";
        }
        return "kund";
      case "orders":
        return "ny order"
      case "services":
        return "ny tjänst"
    }
  }
  return(
   <>
     <Grid container xs={12} justify="center" alignItems="center">
      <RiMore2Line />
     </Grid>
     <Grid item xs={1}>
       <FaceIcon color="inherit"/>
     </Grid>
     <Grid item xs={11}>
       <Grid container className={classes.timelineElement}>
         <Typography variant="subtitle2" className={classes.eventCreator}>
           <b>{e.creator}</b>
         </Typography>
         {e.type.split(":")[0] == "CREATE" ?
           <Chip label="skapade" color="primary" />
           :
           <Chip label="öppnade" />
         }
         <Typography variant="subtitle2" className={classes.eventTopBar}>
           {getEntity(e.entity)} {formatDate(e.created)}
         </Typography>
       </Grid>
     </Grid>
   </>
  )
}

/**
 * Component that shows what column, the old value and the new value in a
 * human readable format.
 * In swedish:
 * Uppdaterade {status} från {old value} till {new value}
**/

function UpdatedValue(params){
  const classes = useStyles();
  const entity  = params.entity;
  const id      = params.id;
  const col     = params.col;
  const oldVal  = params.oldVal;
  const newVal  = params.newVal;

  return (
    <Grid container>
      <Grid container>
        <Grid container xs={4}>
          <Typography variant="overline">
            {entity}
          </Typography>
        </Grid>
        <Grid container xs={4}>
          <Typography variant="overline">
            {id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Typography>
          Uppdaterade <b>{col}</b> från {" "} 
          <span className={classes.backgroundRed}>{oldVal}</span> till {" "} 
          <span className={classes.backgroundGreen}>{newVal}</span>.
        </Typography>
      </Grid>
    </Grid>
  )
}


function TimelineContentElement(params){
  const classes = useStyles();
  const e = params.e;
  const { commentsApi } = useAppContext();
  const { notify } = useMainGridContext();

  const [content, setContent] = useState(<CircularProgress />)
  const [contentNotFound, setContentNotFound] = useState(false);

  // Format top
  const getType = (type, entity) => {
    switch(type.split(":")[0]){
      case "CREATE":
        if(entity == "comments"){
          return( 
            <Chip 
              label="skrev" 
              color="primary" 
              style={{background: "#26a69a"}}
            />);
        }
        else{
          return <Chip label="skapade" color="primary"/>
        }
      case "UPDATE":
        return <Chip label="uppdaterade" color="secondary"/>
      case "DELETE":
        return <Chip label="raderade" color="secondary" />
      default:
        return <Chip label="odefinierat" color="default" />
    }
  }

  // Decide what content to show in event element
  const getContent = (type, entity) => {

    const operation = type.split(":")[0];

    switch(entity){
      case "comments":
        const commentId = String(type.split(":")[1]);
        if(commentsApi == null){
          notify("error", `API fel, kan inte läsa in kommentar ${commentId}`);
          setContentNotFound(true);
          break;
        }
        if(operation == "CREATE" && commentId !== "undefined"){
          showCommentContent(commentId);
        }
        else{
          setContentNotFound(true)
        }
        break;
      default:
        if(operation == "UPDATE"){
          showUpdateContent(type, entity);
        }
        else{
          setContent(entity + " "+ type.split(":")[1]);
        }
        break;
    }
  }

  const showUpdateContent = (type, entity) => {
    const col= type.split(":")[1];
    const oldVal = type.split(":")[2];
    const newVal = type.split(":")[3];
    const id = type.split(":")[4];
    setContent(
      <UpdatedValue 
        entity={entity}
        id={id}
        col={col} 
        oldVal={oldVal} 
        newVal={newVal}
      />
    );
  }

  const showCommentContent = (commentId) => {
    commentsApi.getWhere("id", commentId)
      .then(res => {
        const comment = res.data.recordset[0].content;
        setContent(<Typography>{comment}</Typography>);
      });
  }

  useEffect(() => {
    getContent(e.type, e.entity);
  },[]);

  return(
   <>
     <Grid container xs={12} justify="center" alignItems="center">
       <RiMore2Line />
     </Grid>
     <Grid item xs={1}>
       <FaceIcon color="inherit"/>
     </Grid>
     <Grid item xs={11}>
       <Paper elevation={1}>
         <Grid container className={classes.timelineContentElement}>
           <Typography variant="subtitle2" className={classes.eventCreator}>
             <b>{e.creator}</b>
           </Typography>
           {getType(e.type, e.entity)}
           <Typography variant="subtitle2" className={classes.eventCreator}>
            {formatDate(e.created)}  
           </Typography>
         </Grid>
         <Grid container style={{padding: "10px"}}>
           {contentNotFound ? 
             <Typography variant="overline" color="secondary">
              <ErrorIcon /> Något gick fel vid inhämtning.
             </Typography>
             :
             content
           }
         </Grid>
       </Paper>
     </Grid>
   </>
  )
}

export default function CustomerEventModule(params){
  const classes = useStyles();
  const { onRemoveItem } = useMainGridContext();
  const { entityEventsApi, currentUser } = useAppContext();
  const { dataFromServer  } = useComsContext();

  const cid = params.cid;
  const [meta, setMeta] = useState({salesman:null});

  const [events, setEvents] = useState([]);
  const [total, setTotal] = useState(0);
  const [shown, setShown] = useState(0);
  const [filter, setFilter] = useState({UPDATE: true, COMMENTS: true, CREATE: true, READ: false});
  const [eventCount, setEventCount] = useState({UPDATE: 0, COMMENTS: 0, CREATE: 0, READ: 0});

  useEffect(() => {
    var customersApi = new Customers(currentUser.username);
    customersApi.get(cid)
      .then(res => {
        console.log("ownership res", res.data.recordset[0]);
        setMeta(res.data.recordset[0]);
      })
      .catch(err => console.log(`cant get custoer ${err}`));
  },[])

  useEffect(() => {
    entityEventsApi.getEntityEvents(cid, "customerid")
      .then((res) => {
        setEvents([]);
        const evs = res.data.recordset;
        setEvents(evs.reverse());
        setTotal(evs.length);
        
        var evCount = {};
        evCount["UPDATE"] = countEvents(evs, "UPDATE");
        evCount["COMMENTS"] = countEvents(evs, "COMMENTS");
        evCount["CREATE"] = countEvents(evs, "CREATE");
        evCount["READ"] = countEvents(evs, "READ");
        setEventCount(evCount);
        setShown(evCount["UPDATE"] + evCount["COMMENTS"] + evCount["CREATE"]);
        
      });
  },[dataFromServer])

  const toggleFiltered = (e) => {
    var val = e.target.value;
    setFilter(prevState => ({
        ...prevState, 
        [val]: !prevState[val]
      })
    );
    if(filter[val]){
      setShown(prevState => prevState - eventCount[val]);
    }
    else{
      setShown(prevState => prevState + eventCount[val]);
    }

  }

  const countEvents = (evs, type) => {
    return _.filter(evs, function(o){
      if(o.entity == "comments"){
        if(type == "COMMENTS" && o.type.split(":")[0] == "CREATE"){
          return true
        }
        return false
      }
      else{
        return o.type.split(":")[0] == type;
      }
    }).length;
  }

  const filterEvents = (e) => {
    var eventType = e.type.split(":")[0];
    eventType = e.entity == "comments" && eventType == "CREATE" ?
      "COMMENTS" : eventType
    if(filter[eventType]){
      if(e.type !== "CREATE" || e.entity == "comments"){
        return(
          <TimelineContentElement e={e} />
        )
      }
      else{
        return (<TimelineElement e={e} />);
      }
    }
  }
  const isOwner = async (body) => {
    console.log("ownership body", body);
    console.log("ownership meta", meta);
    var customersApi = new Customers(currentUser.username);
    var meta = await customersApi.get(cid);
    meta = meta.data.recordset[0];
    return meta.salesman.replace("\r", "") == body.username;
  }


  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
      ownership={(x) => isOwner(x)}
    >
      <Header color="#2196f3" remove={() => onRemoveItem(params.id)}>
        {params.id} Händelser {cid}
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"90%", padding: "20px"}}>
          <Grid container direction="column">
            <Grid> 
              <Typography variant="h5">
                <b>Händelser {cid}</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">
                Här visas alla händelser som angår {cid} i kronologisk ordning.
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{marginTop: "20px"}}>
            <Grid container xs={2} direction="column">
              <Grid>
                <Typography variant="overline">
                  Totalt
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle2">
                  {total}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="overline">
                  Visas 
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle2">
                  {shown}
                </Typography>
              </Grid>

            </Grid>
            <Grid container xs={10}>
              <Grid>
                <FormControl>
                  <FormGroup row>
                    <FormControlLabel
                      value="UPDATE"
                      control={
                        <Checkbox 
                          color="primary" 
                          size="small" 
                          onChange={toggleFiltered}
                          checked={filter["UPDATE"]}
                        />}
                      label={<Typography variant="overline">Uppdaterade {eventCount["UPDATE"]}</Typography>}
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="COMMENTS"
                      control={
                        <Checkbox 
                          color="primary" 
                          size="small" 
                          onChange={toggleFiltered}
                          checked={filter["COMMENTS"]}
                        />}
                      label={<Typography variant="overline">Skrev {eventCount["COMMENTS"]}</Typography>}
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="CREATE"
                      control={
                        <Checkbox 
                          color="primary" 
                          size="small" 
                          onChange={toggleFiltered}
                          checked={filter["CREATE"]}
                        />}
                      label={<Typography variant="overline">Skapade {eventCount["CREATE"]}</Typography>}
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="READ"
                      control={
                        <Checkbox 
                          color="primary" 
                          size="small" 
                          onChange={toggleFiltered}
                          checked={filter["READ"]}
                        />}
                      label={<Typography variant="overline">Visat {eventCount["READ"]}</Typography>}
                      labelPlacement="bottom"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

          </Grid>
          <Grid container spacing={2} style={{marginTop: "50px"}}>
            <Grid container xs={12} justify="center" alignItems="center">
              <Typography variant="overline">
                Nyaste
              </Typography>
            </Grid>
            <>
              {events.map(e => {
                var eventType = e.type.split(":")[0];
                eventType = e.entity == "comments" && eventType == "CREATE" ?
                  "COMMENTS" : eventType
                if(filter[eventType]){
                  if(
                    eventType == "UPDATE" || 
                    eventType == "COMMENTS"
                  ){
                    return(
                      <TimelineContentElement e={e} />
                    )
                  }
                  else{
                    return (<TimelineElement e={e} />);
                  }
                }
              })}
            </>
            <Grid container xs={12} justify="center" alignItems="center">
               <RiMore2Line />
            </Grid>
            <Grid container xs={12} justify="center" alignItems="center">
              <Typography variant="overline">
                Äldsta 
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
