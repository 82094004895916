import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAppContext } from '../libs/contextLib';
import { ComsContext, useComsContext } from '../libs/comsContextLib';
import { 
  DataProviderContext, 
  useDataProviderContext 
} from '../libs/dataProviderContextLib';

import axios from 'axios';
import _ from 'lodash';

import MainGrid from './MainGrid';
import MainNavBar from './MainNavBar';
import Users from '../shared/Users.js';
import UserPrefs from '../shared/UserPrefs.js';
import DataStreams from '../shared/DataStreams.js';

import EntityEvents from '../shared/EntityEvents.js';
import Goals from '../shared/Goals.js';

import '../css/Grid.css';
import {useHistory} from 'react-router-dom';
import '../App.css';

import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Grid
} from '@material-ui/core/';

import {
  InboxIcon,
  MailIcon,
} from '@material-ui/icons';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import _config from '../config.js'; 

const drawerWidth = 240; 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height:"100%",
    overflowX: "hidden",
    
  },
  hide:{
    display: "none",
  },
  drawer:{
    width: drawerWidth
  },
  drawerPaper:{
    width: drawerWidth
  },
  drawerHeader:{
    display: 'flex',
    alignItems: theme.spacing(0,1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content:{
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin',  {
      easing: theme.transitions.easing.sharp,
      duration: 1,
    }),
    marginLeft: -drawerWidth
  },
  contentShift:{
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: 1,
    }),
    marginLeft: 0,
  },

}));

function DataProvider(params){

  const { 
    dataFromServer, 
    dataStreams, 
    userDataStreams, 
    setUserDataStreams,
    setUserPrefs,
    handleLogout
  } = useComsContext();

  const [firstLoad, setFirstLoad] = useState(true);
  const [prevEvent, setPrevEvent] = useState(-1);

  useEffect(() => {
    if(params.user.username !== null && params.dataStreams !== null){
      var uPrefs = new UserPrefs(params.user.username);
      setUserDataStreams([]);
      if(
        dataFromServer.type == "FORCE_LOGOUT" && 
        dataFromServer.identifier == params.user.username
      ){
        handleLogout()
      }
      uPrefs.read(params.user.username).then(res => {
        var names = res.data.dataStreams;
        // Set bookmarks
        setUserPrefs(res.data);

        names.map(e => {
          var s = _.filter(params.dataStreams, function(o){ return o.name == e});
          var appropriateEvent = false;
          if(appropriateEvent){
            fetchStream(s[0]);
          }
          else if(firstLoad){
            fetchStream(s[0]);
          }
        });
      });
      if(firstLoad){
        setFirstLoad(false);
      }
    } 
  }, [dataFromServer, params.user, params.dataStreams])

  const fetchStream = (e) => {
    const url = e.url;
    const user = params.user.username;
    axios.get(`${url}/${user}`).then(res => {
      var tmp = userDataStreams;
      if(res.data.recordset[0] !== undefined){
        var data = res.data.recordset[0].data;
        setUserDataStreams(prev => [...prev, {"name": e.name, "data": data}]);
      }
      else{
        setUserDataStreams(prev => [...prev, {"name": e.name, "data": 0}]);
      }
    }).catch(err => {console.log(err)});
  }

  return(
    <div>
      {params.children} 
    </div>
  );
}

function Main() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { 
    userHasAuthenticated, 
    session, 
    setSession,
    userPool, 
    setServerAuthority,
    setServerUsername,
    getCurrentSession
  } = useAppContext();
  const events = new EntityEvents();
  const [ws, setWs] = useState(null);
  const [dataFromServer, setDataFromServer] = useState({});
  const [connected, setConnected] = useState(false);

  const [dataStreams, setDataStreams] = useState(null)
  const [userDataStreams, setUserDataStreams] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabsMain, setTabsMain] = useState([{name:"Start", color:"transparent"}]);
  const [currentTab, setCurrentTab] = useState(tabsMain[0]);
  const [activeGoals, setActiveGoals] = useState([]);

  const [userPrefs, setUserPrefs] = useState({});


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  const verifyUser = async (s) => {
    if(s !== undefined){
      var jwtToken = s.idToken.jwtToken;
      var uname = userPool.getCurrentUser().username;
      var apiId = "bilxr34ks0";
      var endPoint = `https://${apiId}.execute-api.us-east-2.amazonaws.com/dev`;

      var res = await axios.post(`${endPoint}/auth`, {
        "body": {"jwtToken": jwtToken},
        "requestContext": {
          "identity":{ 
          "cognitoUserIdentity": uname
          }
        }
      });

      setServerAuthority(res.data.message.authority);
      setServerUsername(res.data.message.username);
    }
  }


  const {currentUser, setCurrentUser} = useAppContext();   

  function handleLogout(){
    events.create({
      entity: "USER",
      creator: currentUser.username,
      type: "LOGOUT",
      identifier: currentUser.username,
      message: "success"
    });

    var users = new Users(currentUser.username);
    users.updateUsername(currentUser.username, "loggedIn", false);

    Auth.signOut();
    userHasAuthenticated(false)
    history.push("");
  }

  function addTab(){
    setCurrentTabIndex(prev => prev + 1);
    setTabsMain([...tabsMain, {name: tabsMain.length, color:"transparent"}])
  }

  function removeTab(i){
    setTabsMain(tabsMain.filter((e, index) => i !== index));
    // Preserver the open tab while closing others
    if(currentTabIndex >= i){
      openTab(currentTabIndex - 1);
    }
  }

  function openTab(i){
    setCurrentTabIndex(i);
  }
  
  // Runs everytime Main is rendered
  useEffect(() => { 
    (async () => {
      var currentUserInfo = await Auth.currentUserInfo();
      if(currentUserInfo !== null){
        var username = currentUserInfo.username;
        var auth = currentUserInfo.attributes["custom:authority"];
        setCurrentUser({"username":username, auth:auth});

        var dStreams = new DataStreams(username);
        dStreams.getAll().then(res => {
          setDataStreams(res.data.modules);
        })

        var goalsApi = new Goals(username);

        goalsApi.getActive(username).then(res => {
          setActiveGoals(res.data.recordset);
        })
        
      }
      else{
        history.push("/");
      }
    })();

    connect();

    if(session !== undefined){
      verifyUser(session);
    }
    else{
      verifyUser(getCurrentSession());
    }
  }, [session]);

  const connect = () => {
    var newWs = new WebSocket('wss://45pfhurbek.execute-api.us-east-2.amazonaws.com/dev');

    newWs.onopen = () => {
      setConnected(true);
    }
    newWs.onmessage = evt => {
      const message = JSON.parse(evt.data);
      setDataFromServer(message);
    }
    newWs.onclose = (e) => {
      console.log("Socket cloased. Reconnect will be attempted in 1 second: ", e.reason)
      setTimeout(() => connect(), 1000);
      setConnected(false);
    }

    setWs(newWs);

  }

  return (
    <div className={classes.root}>
      <ComsContext.Provider value={{
        dataFromServer, 
        setDataFromServer, 
        connected,
        userDataStreams,
        setUserDataStreams,
        dataStreams,
        userPrefs,
        setUserPrefs,
        handleLogout,
        activeGoals,
      }}>
        <DataProvider user={currentUser} dataStreams={dataStreams}>
          <MainNavBar 
            style={{zIndex: 1}}
            currentUser={currentUser}
            handleLogout={handleLogout}
            addTab={() => addTab()}
            removeTab={(i) => removeTab(i)}
            setCurrentTabIndex={(i) => setCurrentTabIndex(i)}
            currentTabIndex={currentTabIndex}
            tabs={tabsMain}
          />
          {tabsMain.map((e,i) => (
            <MainGrid 
              key={i}
              visible={currentTabIndex==i} 
              style={{zIndex: -1, overflowX:"hidden", overFlowY:"hidden"}}
            />
          ))
          }
        </DataProvider>
      </ComsContext.Provider>
    </div>
  );
}

export default Main;
