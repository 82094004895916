import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {Header, Body, Module} from '../Modules'; 
import Customers from '../../shared/Customers.js';

import { canOpenModule, getModule } from '../../shared/moduleAuthority.js';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  Grid, 
  Typography,
  Avatar,
  Button,
  Fade,
  TextField,
  CircularProgress,
  Select,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}))

export default function Search(params){
  const classes = useStyles();
  const term = params.term;
  const { onRemoveItem, addModule, notify } = useMainGridContext();
  const { currentUser } = useAppContext();

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if(params.term.length > 0){
      const customersApi = new Customers(currentUser.username);
      get();
    }
  },[])

  const get = () => {
    setLoading(true);
    const customersApi = new Customers(currentUser.username);
    customersApi.searchGlobal(params.term).then(res => {
      setLoading(false);
      setResult(res.data.recordset.reverse());
    }).catch(err => {
      notify("error", "Kunde inte hämta data.");
      setError(true);
      setLoading(false);
    })
  }
  
  return(
    <Module
      remove={() => onRemoveItem(params.id)}
      auths={params.auths}
    >
      <Header remove={() => onRemoveItem(params.id)}>
        {params.id} Sök
      </Header>
      <Body>
        <Grid container style={{overflow:"auto", height:"90%", padding: "20px"}}>
          <Typography>
            Söker efter: {params.term}
          </Typography>
          {loading ?
            <Grid container alignItems="center" justify="center">
              <CircularProgress />
            </Grid>
            :
            <Table>
              <TableHead>
                <TableCell>KID</TableCell>
                <TableCell>Namn</TableCell>
                <TableCell>Adress</TableCell>
              </TableHead>
              <TableBody>
                {result.map(e => (
                  <TableRow>
                    <TableCell>
                      <Button 
                        variant="outlined" 
                        onClick={() => addModule(`::om ${e.customerid}`)}
                      >
                        {e.customerid}
                      </Button>
                    </TableCell>
                    <TableCell>{e.name}</TableCell>
                    <TableCell>{e.adress}</TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
            </Table>
          }
          {
            error ? 
              <Grid>
              <Button onClick={() => get()} variant="outlined" color="primary">Försök igen</Button>
              </Grid>
            :
              null
          }
        </Grid>
      </Body>
    </Module>
  )
}

