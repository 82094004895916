import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';
import { Auth } from 'aws-amplify';

import axios from 'axios';
import { EditableText } from '../general/UpdatableValue.js';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  textfield:{
    width: "100%",
  },
  
}));

export default function CreateUserModule(params){
  const classes = useStyles();

  const { onRemoveItem, notify }                = useMainGridContext();
  const { entityEventApi, usersApi, authsApi }  = useAppContext();
  const { dataFromServer }                      = useComsContext();

  const [auths, setAuths]                                     = useState([]);
  const [verifyBySMS, setVerifyBySMS]                         = useState(false);
  const [verifyByMail, setVerifyByMail]                       = useState(false);
  const [chooseVerificationError, setChooseVerificationError] = useState(false);
  const [verificationSent, setVerificationSent]               = useState(false);
  const [formError, setFormError]                             = useState("");
  const [formErrorTitle, setFormErrorTitle]                   = useState("");
  const [submitTries, setSubmitTries]                         = useState(0);

  const [userData, setUserData] = useState({
    fullName: "",
    username: "",
    password: "",
    email: "",
    telephone: "",
    authority: "",
  });

  const changedMailVerification = (e) => {
    setVerifyByMail(prev => !prev);
  }

  const changedSMSVerification = (e) => {
    setVerifyBySMS(prev => !prev);
  }

  // Generates a random 8 character psw
  const generateTemporaryPassword = () => {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789(=?-_+!!!!!########???????#%&",
      retVal = "";
    for(var i = 0, n = charset.length; i < length; ++i){
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal
  }

  const handleSubmit = (e) => {
    var requiredFields = ["fullName", "username", "authority"];
    if(valid(requiredFields, userData)){
      if(userData.email == "" && userData.telephone == ""){
        setChooseVerificationError(true);
        return;
      }

      const tmpPsw = generateTemporaryPassword();
      setUserData(prevState => ({...prevState, ["password"]: tmpPsw}));
      const amplifyUser = {
        username: userData["username"],
        password: tmpPsw,
        attributes: {
          email: userData["email"],
          phone_number: userData["telephone"],
          'custom:authority': userData["authority"],
          'custom:uname_creation': userData["username"],
          'custom:tmp_password': tmpPsw
        }
      }
      signUp(amplifyUser);
    }
    else{
      console.log(`userData ${userData} is not valid`);
      setSubmitTries(prev => prev + 1);
    }
  } 

  const signUp = async (data) => {
    try{
      const { user } = await Auth.signUp(data);
      setVerificationSent(true);
      
      const body = {
        username: userData.username.toLowerCase(),
        fullName: userData.fullName,
        profileMedia: `/users/profiles/${userData.username}`,
        authority: userData.authority,
        vasaId: userData.username.toLowerCase(),
        lastLogin: 0,
        created: new Date(),
        active: 1,
        loggedIn: 0,
        telephone: userData.telephone,
        email: userData.email,
      }

      usersApi.create(body).then(res => {
        console.log("res", res);
        notify("success", "Ny användare skapad och verifikationskod skickad.");
      }).catch(err => {
        notify("error", `Kan inte skapa ny användare. ${err}`)
      })
    }
    catch(error){
      var errorCode = error.code;
      switch(errorCode){
        case("UsernameExistsException"):
          setFormErrorTitle("Användare finns redan")
          setFormError("Det finns redan en någon med detta användarnamnet");
          break;
        case("InvalidPasswordException"):
          setFormErrorTitle("Ogiltigt lösenord");
          setFormError("Lösenord måste innehålla <b>siffror</b> och <b>symboler</b>. Tryck på SKAPA för att generera ett nytt.");
          break;
        case("UnexpectedLambdaException"):
          setFormErrorTitle("Lambda fel");
          setFormError("Något gick fel i lambda exekvering.");
          break;
      }
    }
  }

  const resendSignUp = async () => {
    try{
      await Auth.resendSignUp(userData.username);
    } catch (err){
      console.log("error resending code", err);
    }
  }

  const valid = (fields, userData) => {
    for(var i = 0; i < fields.length; i++){
      if(userData[fields[i]] == ""){
        return false;
      }
    }
    return true;
  }

  const updateUser = (field, e) => {
    const val = e.target.value;
    setVerificationSent(false);
    setUserData(prevState => ({...prevState, [field]: val}));
  }

  useEffect(() => {
    if(authsApi !== null){
      authsApi.getAll().then(res => {
        console.log("auths", res);
        setAuths(res.data.auths);
      })
    }
  },[authsApi])

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.is)}
    >
      <Header color="#00c853" remove={() => onRemoveItem(params.id)}>
        {params.id} Skapa användare
      </Header>
      <Body>
        <Grid container style={{width: "100%"}}>
          <Grid item xs={12}>
            <Typography variant="h4"><b>Ny användare</b></Typography>
          </Grid>
          <form style={{width: "100%"}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="h6">
                Personuppgifter
              </Typography>
                <TextField 
                  label="Fulltsändigt namn"
                  className={classes.textfield}
                  onChange={(e) => updateUser("fullName", e)}
                  error={userData.fullName.length == 0 && submitTries > 0}
                  required
                />
                <TextField 
                  label="Användarnamn"
                  className={classes.textfield}
                  onChange={(e) => updateUser("username", e)}
                  error={userData.username.length == 0 && submitTries > 0}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  VASA specifikationer
                </Typography>
                <TextField 
                  label="Auktoritet"
                  select
                  className={classes.textfield}
                  onChange={(e) => updateUser("authority", e)}
                  value={userData["authority"]}
                  error={userData.authority.length == 0 && submitTries > 0}
                  required
                >
                  {auths.map(e => (
                    <MenuItem value={e.name}>
                      {e.name} - {e.description}
                    </MenuItem>
                  ))
                  }
                </TextField>
                <FormControl 
                  style={{marginTop: "20px"}} 
                  error={chooseVerificationError}
                >
                  <FormLabel>Verifikationsmedel</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      value="SMS"
                      control={<Checkbox color="primary" />}
                      label="SMS"
                      checked={verifyBySMS}
                      onChange={changedSMSVerification}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="E-mail"
                      control={<Checkbox color="primary" />}
                      label="E-mail"
                      onChange={changedMailVerification}
                      checked={verifyByMail}
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormHelperText>
                    Denna metod används även då VASA ska notifiera användaren om saker.
                  </FormHelperText>
                </FormControl>
                {verifyBySMS ? 
                  <TextField 
                    label="Telefon" 
                    className={classes.textfield}
                    onChange={(e) => updateUser("telephone", e)}
                  />
                  :
                  null
                }
                {verifyByMail ? 
                  <TextField 
                    label="E-mail" 
                    className={classes.textfield}
                    onChange={(e) => updateUser("email", e)}
                  />
                  :
                  null
                }

              </Grid>
              <Grid item xs={12}>
                {!verificationSent ?
                  <Button 
                    color="primary" 
                    style={{width: "100%"}} 
                    onClick={handleSubmit}
                  >
                    Skapa
                  </Button>
                  :
                  <Button 
                    color="primary" 
                    style={{width: "100%"}} 
                    onClick={handleSubmit}
                  >
                    Skicka en till verifikationskod 
                  </Button>

                }
              </Grid>
              {userData.password.length > 0 ?
                <Grid container xs={12} alignItems="center" justify="center">
                  <Typography variant="subtitle2">
                    Autogenererat lösenord: <b>{userData.password}</b>
                  </Typography>
                </Grid>
              :
                null
              }
            </Grid>
            {formError.length ?
              <Alert severity="error" style={{marginTop: "50px"}}>
                {formError}
              </Alert>
              :
              null
            }
          </form>
        </Grid>
      </Body>
    </Module>
  )
}
