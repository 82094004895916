import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import _ from 'lodash';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { FixedSizeList } from 'react-window';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  TextField,
  MenuItem,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Fade
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  textfield:{
    width:"100%"
  },
  formElement:{
    marginTop:"50px", 
    padding:"10px", 
    border:"1px solid #9e9e9e", 
    borderRadius:"10px"
  }
}));

function DynamicList(params){
  const classes = useStyles();
  const title = params.title;
  const list = params.list;
  const push = params.push;
  const deleteIndex = params.deleteIndex;
  const setCurrent = params.setCurrent;
  const current = params.current;
  const setAddMode = params.setAddMode;
  const addMode = params.addMode;

  return(
    <Grid container className={classes.formElement} style={{marginTop:"50px"}}>
      <Grid item xs={11}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton size="small">
          <AddIcon onClick={() => setAddMode(!addMode)}/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        {list.map((e,i) => {
          return(
            <Grid container> 
              <Grid item xs={11}>
                <Typography>
                  {e}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton 
                  size="small" 
                >
                  <RemoveIcon onClick={() => deleteIndex(i)}/>
                </IconButton>
              </Grid>
            </Grid>
          )})
        }
      </Grid>
      <Fade in={!addMode}>
        <Grid item xs={12}>
          <TextField 
            label="Läsbehörighet"
            select
            value={current}
            className={classes.textfield}
            onChange={(e) => params.setCurrent(e.target.value)}
            required
          >
            <MenuItem value="*">*</MenuItem>
            <MenuItem value="admin">admin</MenuItem>
          </TextField>
        </Grid>
      </Fade>
      <Fade in={!addMode}>
        <Grid item xs={12}>
          <Button color="primary" onClick={(e) => push(e)}>
            Lägg till
          </Button>
          <Button onClick={() => setAddMode(!addMode)}>
            Avbryt
          </Button>
        </Grid>
      </Fade>
    </Grid>
  );
}

function DynamicDictionary(params){
  const classes = useStyles();
  const [newName, setNewName] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [addNew, setAddNew] = useState(true);

  const title = params.title;
  const removeElement = params.removeElement
  const object = params.object;
  const setObject = params.setObject;

  return(
    <Grid container className={classes.formElement}>
      <Grid item xs={11}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton size="small">
          <AddIcon onClick={() => setAddNew(!addNew)}/>
        </IconButton>
      </Grid>
      <Grid container>
        {Object.keys(object).map((e,i) => {
          return(
            <Grid container style={{borderBottom: "1px solid #757575"}}>
              <Grid item xs={5}>
                <Typography variant="p">
                  {e} 
                </Typography>
              </Grid>
              <Grid item xs={1}>
                - 
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  {object[e]}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton size="small">
                  <RemoveIcon onClick={() => removeElement(e)}/>
                </IconButton>
              </Grid>
            </Grid>
        )})
        }
      </Grid>
      <Fade in={addNew}>
        <Grid container xs={12}>
          <Grid item xs={5}> 
            <TextField 
              label="Namn"
              style={{width:"100%"}}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Grid>
          <Grid container xs={2} alignItems="center" justify="center"> 
            -
          </Grid>
          <Grid item xs={5}> 
            <TextField 
              label="Beskrivning"
              style={{width:"100%"}}
              onChange={(e) => setNewDesc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" onClick={() => {setObject(prev => ({...prev, [newName]: newDesc}));setAddNew(!addNew)}}>Lägg till</Button>
            <Button onClick={() => setAddNew(!addNew)}>Avbryt</Button>
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );

}

export default function NewModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify } = useMainGridContext();
  const { modulesApi, usersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  // Module informationstates
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("admin");
  const [desc, setDesc] = useState("");
  const [modParams, setModParams] = useState({});
  // Read auth states
  const [readAuths, setReadAuths] = useState([]);
  const [addReadAuth, setAddReadAuth] = useState(false);
  const [currentReadAuth, setCurrentReadAuth] = useState("");
  // Update auth states
  const [updAuths, setUpdAuths] = useState([]);
  const [addUpdAuth, setAddUpdAuth] = useState(false);
  const [currentUpdAuth, setCurrentUpdAuth] = useState("");

  const [conditions, setConditions] = useState({});
  const [dbCons, setDbCons] = useState({});

  // Add element to update auths
  const pushUpdAuth = () => {
    setUpdAuths(prev => [...prev, currentUpdAuth]);
    setAddUpdAuth(!addUpdAuth);
  }
  
  // Remove element from upd auths at index
  const removeUpdAuthAtIndex = (index) => {
    setUpdAuths(updAuths.filter(item => item !== updAuths[index]));
  }

  // Add element to read auths
  const pushReadAuth = () => {
    setReadAuths(prev => [...prev, currentReadAuth]);
    setAddReadAuth(!addReadAuth);
  }
  
  // Remove element from read auths at index
  const removeReadAuthAtIndex = (index) => {
    setReadAuths(readAuths.filter(item => item !== readAuths[index]));
  }

  const removeParam = (name) => {
    setModParams(prev => delete prev[name]);
  }  

  const removeCond = (name) => {
    setConditions(prev => delete prev[name]);
  }

  const removeDb = (name) => {
    setDbCons(prev => delete prev[name]);
  }

  const createModule = () => {
    var body = {
      name: name,
      color: domain,
      description: desc,
      auths: readAuths,
      height: String(40),
      width: String(12),
      databaseConnections: dbCons,
      updateAuths: updAuths,
      parameters: params,
      conditions: conditions
    }

    modulesApi.create(body)
      .then(res => {notify("success", "Ny modul har registrerats")})
      .catch(err => {notify("error", "Modul kunde inte skapas")});
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} Ny modul
      </Header>
      <Body>
        <Grid container style={{width:"100%", overflowY:"auto", height:"95%", overflowX:"hidden"}}>
          <Grid item xs={12}>
            <Typography variant="h4"><b>Ny modul</b></Typography>
          </Grid>
          <form style={{width:"100%"}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Modulinformation
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField 
                  label="Modulens namn"
                  style={{width:"100%"}}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField 
                  label="Domän"
                  select
                  value={domain}
                  className={classes.textfield}
                  onChange={(e) => setDomain(e.target.value)}
                  required
                >
                  <MenuItem value="admin">admin</MenuItem>
                  <MenuItem value="customers">kunder</MenuItem>
                  <MenuItem value="products">produkter</MenuItem>
                  <MenuItem value="users">användare</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Funktionsbeskrivning
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Funktionsbeskrivning"
                  multiline
                  value={desc}
                  className={classes.textfield}
                  onChange={(e) => setDesc(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicDictionary 
                  title="Parametrar"
                  removeElement={(val) => removeParam(val)}
                  object={modParams}
                  setObject={(e) => setModParams(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicList
                  title="Läsbehörighet"
                  list={readAuths}
                  push={() => pushReadAuth()}
                  deleteIndex={(i) => removeReadAuthAtIndex(i)}
                  setCurrent={(val) => setCurrentReadAuth(val)}
                  setAddMode={(v) => setAddReadAuth(v)}
                  addMode={addReadAuth}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicList
                  title="Uppdateringsbehörighet"
                  list={updAuths}
                  push={() => pushUpdAuth()}
                  deleteIndex={(i) => removeUpdAuthAtIndex(i)}
                  setCurrent={(val) => setCurrentUpdAuth(val)}
                  setAddMode={(v) => setAddUpdAuth(v)}
                  addMode={addUpdAuth}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicDictionary 
                  title="Villkor"
                  removeElement={(val) => removeCond(val)}
                  object={conditions}
                  setObject={(e) => setConditions(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicDictionary 
                  title="Databas"
                  removeElement={(val) => removeDb(val)}
                  object={dbCons}
                  setObject={(e) => setDbCons(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  style={{width:"100%"}} 
                  color="primary"
                  onClick={() => createModule()}
                >
                  Lägg till
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Body>
    </Module>
  )
}
