import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { useMainGridContext } from '../../../libs/mainGridContextLib.js';
import { useAppContext } from '../../../libs/contextLib';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import VasaEmojis from '../../general/Emojis';
import FileUpload from '../../general/file-upload';

import EntityEvents from '../../../shared/EntityEvents.js'
import SecureFiles from '../../../shared/SecureFiles.js';
import { getMime } from '../../../shared/file-handler/utils.js';

import EventIcon from '@material-ui/icons/Event';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';

import {
  Typography,
  Button,
  Grid,
  Tooltip,
  Collapse,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button:{
    background:"transparent", 
    border:"none", 
    color:theme.palette.text.primary, 
    cursor:"pointer",
    border:`1px solid ${theme.palette.border.primary}`, 
    width:"100%", height:"100%"
  },
  publishToolbar: {
    border:`1px solid ${theme.palette.background.paper}`, 
    borderRadius:"5px"
  },
}))

export default function DefaultMessage(params){

  const classes = useStyles();
  const theme = useTheme();

  const { currentUser, session } = useAppContext();
  const { notify } = useMainGridContext();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [currentPost, setCurrentPost] = useState("");
  const eventsApi = new EntityEvents(currentUser.username);

  const [file, setFile] = useState("");
  const [mime, setMime]   = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const sfs = new SecureFiles();

  const onEmojiClick = (emojiObject, event) => {
    setCurrentPost(prev => prev + emojiObject.native);
  }

  const resetMessage = () => {
    setCurrentPost("");
    setFile("");
    setMime("");
    setFileUpload("");
  }

  const publishPost = () => {
    var b = {
      entity: "public", 
      creator: currentUser.username, 
      type:"NEWSFEED_POST", 
      identifier:uuidv4(),
      message:currentPost.replaceAll("\n", "::BR::"),
      idColumn: "username"
    }
    if(fileUpload !== ""){
      b.fileUpload = fileUpload;
    }

    eventsApi.create(b)
    .then(res => {
      resetMessage();
      notify("success", "Ditt inlägg har delats");
    })
    .catch(err => {
      notify("error", `Kunde inte skapa inlägg. ${err}`)
    });
    if(file.name !== undefined && file.name.length > 0){
      if(session !== undefined){
        sfs.upload(mime, `uploads/${file.name}`, session.idToken.jwtToken).then(res => {
          var url = res.data.curl;
          axios.put(url, file);
        }).catch(err => {notify("error", `Kunde inte ladda upp meddelande. ${err}`)});
      }
    }
  }

  const getFname = (e) => {
    setFile(e.target.files[0]);
    setFileUpload({"filepath": `uploads/${e.target.files[0].name}`})
    var fileType = e.target.files[0].name.split(".")[1];
    setMime(getMime(fileType));
  }

  return(
    <>
    <Grid item xs={12}>
      <Collapse in={showEmojiPicker}>
        <VasaEmojis onEmojiClick={(o, e) => onEmojiClick(o,e)}/>
      </Collapse>
    </Grid>
    <Grid item container xs={12}>
      <Grid 
        item 
        xs={1} 
        container 
        justify="center" 
        alignItems="center" 
      >
        <button 
          className={classes.button} 
          style={{border:`1px solid ${theme.palette.border.primary}`, width:"100%", height:"100%"}}
          variant="outlined" 
          onClick={() => setShowEmojiPicker(p => !p)}
        >
          <EmojiEmotionsIcon />
        </button>
      </Grid>
      <Grid item xs={11}>
        <TextField 
          fullWidth 
          value={currentPost}
          label="Skriv något som du tror andra kommer att uppskatta..."
          multiline
          variant="outlined"
          onChange={(e) => setCurrentPost(e.target.value)}
        />
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography>
        {file.name}
      </Typography>
    </Grid>
    <Grid container className={classes.publishToolbar}>
      <Grid item>
        <FileUpload getFname={(e) => getFname(e)}/>
      </Grid> 
      <Grid container item justify="flex-end" alignItems="flex-end">
        <Button onClick={publishPost}>
          <PublishIcon /> Publicera
        </Button>
      </Grid>
    </Grid>
    </>
  )
}

