import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';

import DataStreams from '../../shared/DataStreams.js';

import {
  Grid,
  Typography,
  Chip,
  Paper,
  TextField,
  Divider,
  Button,
  IconButton,
  Fade,
  Checkbox
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({

  dataList: {
    padding:"10px", 
    borderRadius: "5px",
  },
  textFieldContainer: {
    padding:"10px"
  }

}))

export function DynamicDictionary(params){
  const classes = useStyles();
  const [newName, setNewName] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [addNew, setAddNew] = useState(true);

  const title = params.title;
  const removeElement = params.removeElement
  const object = params.object;
  const setObject = params.setObject;

  return(
    <Grid container className={classes.formElement}>
      <Grid item xs={11}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton size="small">
          <AddIcon onClick={() => setAddNew(!addNew)}/>
        </IconButton>
      </Grid>
      <Grid container>
        {Object.keys(object).map((e,i) => {
          return(
            <Grid container style={{borderBottom: "1px solid #757575"}}>
              <Grid item xs={5}>
                <Typography variant="p">
                  {e} 
                </Typography>
              </Grid>
              <Grid item xs={1}>
                - 
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  {object[e]}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton size="small">
                  <RemoveIcon onClick={() => removeElement(e)}/>
                </IconButton>
              </Grid>
            </Grid>
        )})
        }
      </Grid>
      <Fade in={addNew}>
        <Grid container xs={12}>
          <Grid item xs={5}> 
            <TextField 
              label="Namn"
              style={{width:"100%"}}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Grid>
          <Grid container xs={2} alignItems="center" justify="center"> 
            -
          </Grid>
          <Grid item xs={5}> 
            <TextField 
              label="Beskrivning"
              style={{width:"100%"}}
              onChange={(e) => setNewDesc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" onClick={() => {setObject(prev => ({...prev, [newName]: newDesc}));setAddNew(!addNew)}}>Lägg till</Button>
            <Button onClick={() => setAddNew(!addNew)}>Avbryt</Button>
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );

}

function NewDataStreamForm(params){
  const classes = useStyles();
  
  const { notify } = useMainGridContext();

  const dataStream    = params.dataStream;
  const showAddNew    = params.showAddNew;
  const setShowAddNew = params.setShowAddNew;
  const updateForm    = params.updateForm;

  const [type, setType]         = useState("");
  const [name, setName]         = useState("");
  const [desc, setDesc]         = useState("");
  const [url, setUrl]           = useState("");

  // Event list
  const [addEvent, setAddEvent]         = useState(false);
  const [currentEvent, setCurrentEvent] = useState("");
  const [events, setEvents]             = useState({});

  useEffect(() => {
    setType(params.formValues.type);
    setName(params.formValues.name);
    setDesc(params.formValues.description);
    setUrl(params.formValues.url);

  },[params.updateForm, params.formValues]);

  const createStream = () => {
    if(type == undefined || type.length == 0){
      notify("error", "Strömmen måste ha en typ.") 
      return;
    }
    if(name == undefined || name.length == 0){
      notify("error", "Strömmen måste ha ett namn.") 
      return;
    }
    if(desc == undefined || desc.length == 0){
      notify("error", "Strömmen måste ha en beskrivning.") 
      return;
    }
    if(url == undefined || url.length == 0){
      notify("error", "Strömmen måste kopplas till en URL.") 
      return;
    }

    const body = {
      type: type,
      name: name, 
      desc: desc,
      url: url,
      events: events
    }

    dataStream.create(body).then(res => {
      notify("success", "Ny dataström har skapats");
      setShowAddNew(false);
    });
  }

  const deleteEvent = (key) => {
    const {[key]: tmp, ...rest} = events;
    setEvents(rest);
  }

  return(
    <Fade in={showAddNew}>
      <Grid container xs={12} spacing={2}>
        <Grid xs={2} className={classes.textFieldContainer}>
          <TextField 
            label="typ" 
            style={{width:"100%"}}
            onChange={e => setType(e.target.value)}
            value={type}
          />
        </Grid>
        <Grid xs={10} className={classes.textFieldContainer}>
          <TextField 
            label="namn" 
            style={{width:"100%"}}
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </Grid>
        <Grid xs={12} className={classes.textFieldContainer}>
          <TextField 
            label="beskrivning" 
            style={{width:"100%"}}
            onChange={e => setDesc(e.target.value)}
            value={desc}
          />
        </Grid>
        <Grid xs={12} className={classes.textFieldContainer}>
          <TextField 
            label="url" 
            style={{width:"100%"}}
            onChange={e => setUrl(e.target.value)}
            value={url}
          />
        </Grid>
        <Grid xs={12} className={classes.textFieldContainer}>
          <DynamicDictionary
            title="Händelser"
            object={events}
            removeElement={(e) => deleteEvent(e)}
            setObject={(e) => setEvents(e)}
          />
        </Grid>
        <Grid xs={12}>
          <Button style={{width:"100%"}} onClick={() => createStream()}>
            {updateForm ? "Uppdatera" : "Lägg till"}
          </Button>
        </Grid>
      </Grid>
    </Fade>
  );
}

export default function AllDataStreams(params){
  const classes = useStyles();
  const theme = useTheme();
  const { onRemoveItem, notify, addModule } = useMainGridContext();

  const { 
    entityEventsApi, 
    productsApi, 
    currentUser, 
    userPrefs 
  } = useAppContext();
  const { dataFromServer  } = useComsContext();

  const [dataStream, setDataStream] = useState(null);
  const [streams, setStreams] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [updatable, setUpdatable] = useState(true);

  const [type, setType] = useState()
  const [selected, setSelected] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [showOperations, setShowOperations] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [updateForm, setUpdateForm] = useState(false);

  useEffect(() => {
    if(
      (currentUser.username !== null && currentUser.username !== undefined)
      || dataFromServer.entity == "dataStreams");
    {
      setUpdatable(canUpdate(params.updateAuths));
      const ds = new DataStreams(currentUser.username);
      setDataStream(ds);

      ds.getAll().then(res => {
        setStreams(res.data.modules);

        var tmp = {};
        res.data.modules.map(e => {
          tmp[e.name] = false;
        });
        setSelected(tmp);
      });
    }
    if(userPrefs !== null){
      userPrefs.read(currentUser.username).then(res => {
        if(res.data !== ""){
          console.log(res.data);
        }
      });
    }
  },[currentUser, dataFromServer, userPrefs])

  const handleCheckboxChange = (id) => {
    const val = !selected[id];

    var tmpSel = selected;
    tmpSel[id] = !selected[id];

    if(Object.values(tmpSel).includes(true)){
      setShowOperations(true);
    }
    else{
      setShowOperations(false)
    }
    setSelected({...selected, [id]:val});
  }

  const savePreferences = () => {

    var names = [];
    Object.keys(selected).map(e => {
      if(selected[e]){
        names.push(e);
      }
    });

    console.log("currentUser", currentUser);

    if(names.length > 0){
      userPrefs.update(
        currentUser.username, 
        "dataStreams", 
        names
      ).then(res => {
        console.log(res);
        notify("success", "Datatrömmar uppdaterade");
      }).catch(err => {
        console.log("error", err);
        notify("error", "Kunde inte uppdatera dataströmmar");
      }); 
      setShowOperations(false);
    }
    else{
      notify("info", "Ändra på någonting för att spara");
    }
  }

  const deleteSelected = () => {
    Object.keys(selected).map(e => {
      console.log(selected[e]);
      if(selected[e]){
        dataStream.delete(e).then(res => {
          notify("success", "dataström har blivit borttagen");
        });
      }
    });
    setShowOperations(false);
  }

  const canUpdate = (authList) => {
    if(authList.includes("*")){
      return true;
    }
    for(var i = 0; i < authList.length; i++){
      var main = authList[i].split(":");
      var auth = main[0];
      if(main.length == 1){
        if(currentUser.auth == auth || currentUser.username == auth){
          return true;
        }
        else{
          return false;
        }
      }
    }
    return false;
  }

  const onSelectAll = () => {
    setSelectAll(prev => !prev);
    var tmp = selected;
    Object.keys(tmp).map(e => {
      tmp[e] = !selectAll;
    });
    
    setSelected(tmp);
  }

  const handleUpdate = (e) => {
    setShowAddNew(prev => !prev);
    if(!showAddNew){
      setFormValues(e);
      setUpdateForm(true);
    }
    else{
      setUpdateForm(false);
    }
  }

  return(
    <Module auths={params.auths} remove={() => onRemoveItem(params.id)}>
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} Dataströmmar
      </Header>
      <Body>
        <Grid container style={{overflowY: "auto", height:"99%"}}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Dataströmmar</b> 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
              En dataström är en direkt koppling till en mikroservice som 
              beräknar något väldefinierat värde som ska hjälpa dig får en 
              bättre uppfattning om din försäljning.
              Dataströmmar kan komma i olika format, siffror, grafer och tabeller.
              Siffror kommer att visas i en banderoll, grafer och tabeller kan 
              kommas åt genom speciella moduler.
            </Typography>
          </Grid>
          <Grid 
            container 
            xs={12} 
            className={classes.dataList} 
            style={{marginTop:"50px"}}
          > 
            <Fade in={showOperations || selectAll}>
              <Grid container xs={12} justify="flex-end" alignItems="flex-end">
                {updatable ?
                  <Button onClick={deleteSelected}>
                    Ta bort
                  </Button>
                :
                  null
                }
                <Button onClick={savePreferences}>
                  Spara 
                </Button>
              </Grid>
            </Fade>
            <Grid 
              item 
              container
              xs={12} 
              justify="flex-end" 
              alignItems="flex-end" 
            >
              <Checkbox onChange={onSelectAll}/>
            </Grid>

            {streams.map((e) => {
              return(
                <>
                  <Grid container xs={12} style={{background:theme.palette.background.default, marginBottom:"20px", padding:"20px", borderRadius:"10px"}}>
                    <Grid item xs={10}>
                      <Typography variant="overline">
                        {e.type} 
                      </Typography>
                      <Typography variant="h4">
                        <b>{e.name}</b>
                      </Typography>
                      <Typography variant="p">
                        {e.description}
                      </Typography>
                    </Grid>
                    {updatable ?
                      <Grid 
                        item 
                        container
                        xs={1} 
                        justify="flex-end" 
                        alignItems="flex-end" 
                      >
                        <IconButton onClick={() => handleUpdate(e)}>
                          <UpdateIcon/>
                        </IconButton>
                      </Grid>
                      :
                      null
                    }
                    <Grid 
                      item 
                      container
                      xs={updatable ? 1 : 2} 
                      justify="flex-end" 
                      alignItems="flex-end" 
                    >
                      <Checkbox 
                        checked={selected[e.name] || selectAll} 
                        onChange={() => handleCheckboxChange(e.name)}
                      />
                    </Grid>
                    <Grid container alignItems="flex-end" justify="flex-end">
                      <Grid item xs={2}>
                        <Button 
                          fullWidth 
                          style={{color:"#ed3856", borderRadius:"20px", background:theme.palette.select.primary}}
                          onClick={() => addModule(`::data ${e.name}`)}
                        >
                          <b>Hämta</b>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })
            }
            {updatable ?
                <Grid 
                  container 
                  xs={12} 
                  className={classes.dataList}
                  alignItems="flex-end"
                  justify="flex-end"
                  style={{paddingTop:"50px"}}
                >
                  <IconButton onClick={() => setShowAddNew(prev => !prev)}>
                    <AddIcon /> 
                  </IconButton>
                </Grid>
              :
                null
            }
          </Grid>
          <NewDataStreamForm 
            showAddNew={showAddNew} 
            dataStream={dataStream}
            setShowAddNew={setShowAddNew}
            formValues={formValues}
            updateForm={updateForm}
          />
        </Grid>
      </Body>
    </Module>
  )
}
