import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';
import { EditableText } from '../general/UpdatableValue.js';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import {formatDate} from '../../shared/dateHelper.js';
import {
  formatter, 
  formatNumber, 
  capitalizeFirstLetter
} from '../general/format.js';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Paper,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  FormControlLabel,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  
}));

export default function ProductModule(params){
  const classes = useStyles();
  const { onRemoveItem, notify } = useMainGridContext();
  const { entityEventApi, productsApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const [product, setProduct] = useState(null);
  const [keyFigures, setKeyFigures] = useState({});
  const productName = params.product;

  useEffect(() => {
    if(
      (product == null || dataFromServer.entity == "products") && 
      productsApi !== null
    )
      if(isNaN(productName)){
        productsApi.getWhere("name", productName).then((res) => {
          setProduct(res.data.recordset[0]);
          productsApi.getKeyFigures(res.data.recordset[0].name).then((res) => {
            console.log("key figures", res);
            setKeyFigures(res.data.recordset);
          })
        })
      }
      else{
        productsApi.get(productName).then((res) => {
          setProduct(res.data.recordset[0]);
          productsApi.getKeyFigures(res.data.recordset[0].name).then((res) => {
            console.log("key figures", res);
            setKeyFigures(res.data.recordset);
          });
          console.log(res); 
        })

      }
  },[dataFromServer, productsApi]);

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="#ff9100" remove={() => onRemoveItem(params.id)}>
        {params.id} Produkt {productName}
      </Header>
      <Body>
        {product !== null ?
          <Grid container>
            <Grid container spacing={0}> 
              <Grid item xs={12}>
                <Typography variant="h4">
                  PID: {product.pid}
                </Typography>
                <Typography variant="h2">
                  <b>{product.name}</b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">
                  Skapad {formatDate(product.created)} av {product.creator}
                </Typography>
              </Grid>
              <Grid container xs={4} justify="flex-end" alignItems="flex-end">
                <Typography variant="overline">
                  Kategori: {product.category}
                </Typography>
              </Grid>
              <Grid container>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lager</TableCell>
                      <TableCell>Inköpspris</TableCell>
                      <TableCell>Min.</TableCell>
                      <TableCell>Max.</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <EditableText
                          dbLabel="storage"
                          variant="subtitle"
                          id="0"
                          value={formatNumber.format(product.storage) + " st"}
                          url="https://isgg-intelligens.biz"
                          align="left"
                          onUpdate={(nv) => (productsApi.updateWhere(product.pid, "storage", nv))}
                        />
                      </TableCell>
                      <TableCell>
                        <EditableText
                          dbLabel="storage"
                          variant="subtitle"
                          id="0"
                          value={formatter.format(product.unitPrice)}
                          url="https://isgg-intelligens.biz"
                          align="left"
                          onUpdate={(nv) => (productsApi.updateWhere(product.pid, "unitPrice", nv))}
                        />
                      </TableCell>
                      <TableCell>
                        <EditableText
                          dbLabel="storage"
                          variant="subtitle"
                          id="0"
                          value={formatNumber.format(product.minimum) + " st"}
                          url="https://isgg-intelligens.biz"
                          align="left"
                          onUpdate={(nv) => (productsApi.updateWhere(product.pid, "minimum", nv))}
                        />
                      </TableCell>
                      <TableCell>
                        <EditableText
                          dbLabel="storage"
                          variant="subtitle"
                          id="0"
                          value={formatNumber.format(product.maximum) + " st"}
                          url="https://isgg-intelligens.biz"
                          align="left"
                          onUpdate={(nv) => (productsApi.updateWhere(product.pid, "maximum", nv))}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} style={{marginTop: "40px", marginBottom: "20px"}}>
                <Typography variant="h5">
                  <b>Beskrivning</b>
                </Typography>
                <br />
                <Typography variant="p">
                  {product.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Typography variant="h5">
                <b>Nyckelsiffror</b>
              </Typography>
              <Table> 
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Lagervärde
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      {formatter.format(product.unitPrice * product.storage)}
                    </TableCell>
                  </TableRow>
                  {Object.keys(keyFigures).map(r => {
                    if(keyFigures[r].status == "order" || 
                      keyFigures[r].status == "fakturerad"
                    ){
                      return(
                        <TableRow>
                          <TableCell>
                            {capitalizeFirstLetter(keyFigures[r].status)} 
                          </TableCell>
                          <TableCell>
                            {formatNumber.format(keyFigures[r].statusCount) + " st"} 
                          </TableCell>
                          <TableCell>
                            {formatter.format(keyFigures[r].statusCount * product.unitPrice)} 
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        :
          <CircularProgress />
        }
      </Body>
    </Module>
  )
}
