import React, {useState, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';

import { useMainGridContext } from '../../libs/mainGridContextLib';

import axios from 'axios';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Files from '../../shared/Files.js';
import Report from '../../shared/pdf/daily-sales-report.jsx';
import {formatDateTime} from '../../shared/dateHelper.js';

import Customers from '../../shared/Customers.js';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font
} from '@react-pdf/renderer'

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  Fade,
  Paper,
  IconButton,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TabelContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {

})

Font.register("https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf", {family:"roboto"});

const styles = StyleSheet.create({
  page:{
    flexDirection: "column",
    backgroundColor: 'white',
  },
  section:{
    margin: 20,
    padding: 10,
    flexGrow: 1
  },
  header:{
    borderTop: "10pt solid #34A1EB",
  },
  headerContent:{
    marginTop:20,
    marginLeft:20,
    marginRight:20,
    flexDirection: "column",
  },
  headerRow:{
    padding: 10,
    flexDirection: "row"
  },
  headerRight:{
    flexDirection: "column",
    width:"11cm"
  },
  headerLeft:{
    flexDirection: "column",
    width:"9.5cm",
  },
  image:{
    width: "150px",
    height:"50px"
  },
  p:{
    fontSize:"9pt",
    fontWeight:"medium",
  },
  pBold:{
    fontSize:"9pt",
    fontWeight:"medium",
    fontFamily:"roboto",
    fontWeight:700
  },

  p2:{
    fontSize:"10pt",
    lineHeight:1.2,
  },
  p2Bold:{
    fontFamily:"roboto",
    fontSize:"10pt",
    lineHeight:1.2,
    fontWeight:700
  },
  p3:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454"
  },
  p3Bold:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454",
    fontFamily:"roboto",
    fontWeight:700
  },
  h4:{
    fontSize:"24pt",
    fontWeight:"bold",

  },
  h4Bold:{
    fontSize:"24pt",
    fontFamily:"roboto",
    fontWeight:700
  },
  h5:{
    fontSize:"18pt",
    fontWeight:"bold",
  },
  h5Bold:{
    fontSize:"18pt",
    fontWeight:"bold",
    fontFamily:"roboto",
    fontWeight:700
  },

  textContainer:{
    marginTop:10
  },
  body:{
    margin:50,
    marginTop:10,
    padding:10,
    height: "70%"
  },
  column:{
    flexDirection:"column"
  },
  row:{
    flexDirection:"row"
  },
})
function DocumentListElement(params){
  const title       = params.title;
  const description = params.description;
  const value       = params.value
  return(
    <View style={[styles.textContainer, {borderBottom: "2pt solid #34A1EB", paddingBottom:10}]}>
      <Text style={[styles.p2Bold, {color:"#34A1EB"}]}>{title}</Text>
      <Text style={styles.p}>{description}</Text>
      <Text style={{fontSize:"8pt", color:"#34A1EB", textAlign:"right"}}>{value}</Text>
    </View>

  )
}

function DocumentList(params){
  //const [es, setEs] = useState(params.elements);
  const es = [
    {
      name: "ID-assistans", 
      description: "Spärrar din identitet om någon försöker kapa den.", 
      price:"100"
    },
    {
      name: "Kortspärr", 
      description: "Spärra dina kort och värdehandlingar met ETT telefonnummer", 
      price:"100"
    },
    {
      name: "Säkerhetskortet", 
      description: "Skyddar din plånbok mot skimning.", 
      price:"100"
    },
    {
      name: "Nyckelbricka", 
      description: "Fäst på nyckelbrickan och skyddar nycklarna.", 
      price:"100"
    }
  ]
  return(
    <View style={{padding:0, marginTop:0}}>
      <Text style={{fontSize:"12pt"}}>Dina tjänster</Text>
      {
        es.map((e) => (
          <DocumentListElement 
            title={e.name} 
            description={e.description} 
            value={e.price}
          />
        ))
      }
    </View>
  )
}

function DocumentColumn(params){
  const classes = useStyles();

  const [width, setWidth] = useState(params.width);
  
  return(
    <View style={[styles.textContainer], {width:`${width}cm`}}>
      {params.children}
    </View>
  )
}

export default function PdfReader(params){
  const classes = useStyles();
  const theme = useTheme()
  const { onRemoveItem, notify, addModule } = useMainGridContext();
  const [customer, setCustomer] = useState(null);

  const customersApi = new Customers("admin1");

  useEffect(() => {
    if(customersApi !== null){
      customersApi.get("174399").then((res) => {
        setCustomer(res.data.recordset[0]);
      });
    } 
  },[])

  const RenderPdf = () => {
    return useMemo(
     () => (
       <PDFViewer style={{
         width:"100%", 
         height:"100%", 
         border:"none", 
         backgroundColor:theme.palette.background.paper
         }}>
         <Report />
       </PDFViewer>
     )
    )
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color={theme.palette.domain["admin"].main}
        remove={() => onRemoveItem(params.id)}
      >
        Pdf läsare
      </Header>
      <Body>
        <Grid container xs={12} style={{height:"100%"}}>
          {customer !== null ?
            <RenderPdf />
          :
            null
          }
        </Grid>
      </Body>
    </Module>
  )
}
