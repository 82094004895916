import React, {useState, useEffect} from 'react';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';

import { 
  formatDateTime
} from '../../shared/dateHelper.js';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import {
  Grid,
  Typography,
  Chip,
  Button,
  IconButton,
  Checkbox,
  TextField,
  MenuItem,
  List,
  ListItem,
  FormControlLabel,
  ListItemSecondaryAction,
  ListItemText,
  Fade
} from "@material-ui/core";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  listItem:{
    margin:0, 
    padding:0, 
    borderLeft:"3px solid #757575", 
    paddingLeft:"10px"
  },
  listItemUpdateMode:{
    margin:0, 
    padding:0, 
    borderLeft:"3px solid #2196f3", 
    paddingLeft:"10px"
  },
  mainComponent:{
    background:theme.palette.background.default, 
    marginTop:"50px", 
    padding:"10px"
  }
}));

function UpdatableList(params){
  const classes = useStyles();
  const title = params.title;
  const ps = params.params;
  const column = params.column

  const [updateMode, setUpdateMode] = useState();
  const [addMode, setAddMode] = useState();
  const [newItemName, setNewItemName] = useState("");
  const [newItemDesc, setNewItemDesc] = useState("");
  const [items, setItems] = useState(params.params);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setItems(params.params)

    var sels = {};
    Object.keys(params.params).map((e,i) => (sels[e] = false));
    setSelected(sels);

  },[params.params])

  const removeParam = (key) => {
    var tmp = items;
    delete tmp[key];
    setItems(tmp);
    params.updateModule(column, JSON.stringify(tmp));
  }

  const updateParams = () => {
    setItems(prev => ({...prev, [newItemName]: newItemDesc}));
    var tmp = items;
    items[newItemName] = newItemDesc;
    params.updateModule(column, JSON.stringify(tmp));
    setAddMode(!addMode);
  }

  const removeSelected = () => {
    var tmp = items;
    Object.keys(selected).map(e => {
      if(selected[e]){
        delete tmp[e];
      }
    });
    setItems(tmp);
    params.updateModule(column, JSON.stringify(tmp));
    setUpdateMode(!updateMode);
  }

  return(
    <Grid container>
      <Grid item xs={10}>
        <Typography variant="h6">
          {title} 
        </Typography>
      </Grid>
      <Grid container xs={1} justify="flex-end" alignItems="flex-end">
        <IconButton size="small" onClick={() => setAddMode(!addMode)}>
          <AddIcon/>
        </IconButton>
      </Grid>
      <Grid container xs={1} justify="flex-end" alignItems="flex-end">
        {updateMode ?
          <IconButton size="small" onClick={() => setUpdateMode(!updateMode)}>
            <ExitToAppIcon/>
          </IconButton>
        :
          <IconButton size="small" onClick={() => setUpdateMode(!updateMode)}>
            <UpdateIcon/>
          </IconButton>
        }
      </Grid>

      <List dense={true} style={{width:"100%", background:"transparent"}}>
        {Object.keys(ps).map((p,i) => {
          return(
            <ListItem 
              key={i}
              className={updateMode ? 
                classes.listItemUpdateMode 
                : 
                classes.listItem
              }>
              <ListItemText primary={`${p} - ${ps[p]}`} />
              <ListItemSecondaryAction>
                <Fade in={updateMode}>
                  <Checkbox 
                    checked={selected[p]}
                    onChange={() => setSelected(prev => ({...prev,[p]:!selected[p]}))}
                  />
                </Fade>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      {addMode ?
        <>
          <Grid item xs={5}>
            <TextField 
              label="Namn" 
              size="small"
              style={{width:"100%"}}
              onChange={(e) => setNewItemName(e.target.value)}

            />
          </Grid>
          <Grid container xs={2} justify="center" alignItems="center">
            <Typography variant="body1">
              -
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField 
              label="Beskrivning" 
              size="small"
              style={{width:"100%"}} 
              onChange={(e) => setNewItemDesc(e.target.value)}
            />
          </Grid>
          <Grid container xs={12} alignItems="flex-end" justify="flex-end">
            <Button onClick={() => setUpdateMode(false)}>Avbryt</Button>
            <Button color="primary" onClick={() => updateParams()}>Skapa</Button>
          </Grid>
        </>
        :
        null
      }
      {updateMode ? 
          <Grid containe xs={12}>
            <Button style={{width:"100%"}} onClick={() => removeSelected()}>
              Ta bort
            </Button>
          </Grid>
        :
          null
      }
    </Grid>
  )
}

function AuthDescription(params){
  const classes = useStyles();

  const { authsApi } = useAppContext();

  const [auths, setAuths]                     = useState([]);
  const [authsList, setAuthsList]             = useState([]);
  const [modRead, setModRead]                 = useState(false);
  const [currentReadAuth, setCurrentReadAuth] = useState("Välj");
  const [database, setDatabase]               = useState("");
  const [activeUsers, setActiveUsers]         = useState(params.activeUsers);
  const [selectedUser, setSelectedUser]       = useState("");
  const [chooseUserAuth, setChooseUserAuth]   = useState(false);

  // UpdateMode
  const [updateMode, setUpdateMode] = useState(false);
  const [selected, setSelected]     = useState({});

  const [condIsOwner, setCondIsOwner] = useState(false);

  const pushReadAuths = (e) => {
    var newAuth = currentReadAuth;
    setCurrentReadAuth(newAuth);
    if(condIsOwner){
      newAuth += ":OWNER";
    }
    if(!auths.includes(newAuth)){
      var tmp = auths;
      tmp.push(newAuth);
      setAuths(tmp);
      params.updateModule(params.column, tmp);
      setModRead(!modRead);
    }
  }

  const removeAuth = (i) => {
    var tmp = auths;
    tmp.splice(i, 1);
    setAuths(tmp);
    params.updateModule(params.column, tmp);
  }

  const updateCondOwner = (e) => {
    setCondIsOwner(!condIsOwner);
  }
  
  const updateDatabase = (e) => {
    setDatabase(e.target.value);
  }

  const pushRemoveIndex = (i) => {
    console.log(i);
  }

  const removeSelected = () => {
    var is = Object.keys(selected);
    var tmp = auths;
    console.log("ta bort: ", selected);
    is.map(e => {
      console.log("selected", selected);
      console.log("selected e", e);
      if(selected[e]){
        tmp.splice(e, 1);
        console.log("spliced array", tmp);
      }
    })

    console.log("tmp", tmp);
    params.updateModule(params.column, tmp);
    setUpdateMode(!updateMode);
  }

  const writeConditional = (main) => {
    var name = main[0];
    var cond = main[1];

    switch(cond){
      case "OWNER":
        cond = "ägare";
        break;
      default:
        cond ="VILLKOR_STÖDS_EJ"
        break;
    }

    return(
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{margin:0, padding:0}}>
          <Typography variant="subtitle2" style={{textAlign:"center"}}>
            {cond}
          </Typography>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    if(authsApi !== null){
      authsApi.getAll().then(res => {
        setAuthsList(res.data.auths);
      })
    }
    setAuths(params.auths);
    var sels = {};
    params.auths.map((e,i) => (sels[i] = false));
    setSelected(sels);
    setActiveUsers(params.activeUsers);
  },[params.auths, params.activeUsers, authsApi]);

  return(
    <Grid container>
      <Grid item xs={8}>
        <Typography variant="p">
          {params.title} 
        </Typography>
      </Grid>
      <Grid container xs={2} justify="flex-end" alignItems="flex-end">
        <IconButton size="small" onClick={() => setModRead(!modRead)}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid container xs={2} justify="flex-end" alignItems="flex-end">
        {!updateMode ?
          <IconButton size="small" onClick={() => setUpdateMode(true)}>
            <UpdateIcon />
          </IconButton>
          :
          <IconButton size="small" onClick={() => setUpdateMode(false)}>
            <ExitToAppIcon />
          </IconButton>
        }
      </Grid>
      <Grid item xs={12}>
        <List dense={true}>
        {auths.map((auth,i) => {
          var mainAuth = auth.split(":");
          var name = mainAuth[0]
          return(<ListItem key={i} className={updateMode ? classes.listItemUpdateMode : classes.listItem}>
            <ListItemText primary={mainAuth.length === 1 ? name : writeConditional(mainAuth)} />
            <Fade in={updateMode}>
              <ListItemSecondaryAction>
                  <Checkbox 
                    checked={selected[i]}
                    onChange={() => setSelected(p => ({...p,[i]:!selected[i]}))}
                  />
              </ListItemSecondaryAction>
            </Fade>
          </ListItem>)
        })}
        </List>
      </Grid>
      {modRead ?
        <Grid container>
          <Button onClick={() => setChooseUserAuth(!chooseUserAuth)}>Användare</Button>
          {!chooseUserAuth ?
          <Grid item xs={12}>
            <TextField 
              select 
              label="Auktoritet"
              style={{width:"100%"}} 
              value={currentReadAuth} 
              onChange={e => setCurrentReadAuth(e.target.value)}
            >
              <MenuItem value="*">*</MenuItem>
              {authsList.map((a,i) => (
                <MenuItem key={i} value={a.name}>{a.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          :
          <Grid item xs={12}>
            <TextField 
              select 
              label="Användare"
              style={{width:"100%"}} 
              value={currentReadAuth} 
              onChange={e => setCurrentReadAuth(e.target.value)}
            >
              {activeUsers.map((user, i) => {
                return(<MenuItem key={i} value={user.username}>{user.fullName} - {user.username}</MenuItem>)
              })}
            </TextField>
          </Grid>
          }
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="overline">
                Villkor
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                <Checkbox 
                  checked={condIsOwner}
                  onChange={updateCondOwner}
                />}
                label="Endast som ägare"
              >
              </FormControlLabel>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Button style={{width:"100%"}} onClick={() => setModRead(!modRead)}>
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button 
                style={{width:"100%"}} 
                onClick={pushReadAuths} 
                color="primary"
              >
                Lägg till 
              </Button>
            </Grid>

          </Grid>
        </Grid>
        :
        null
      }
      <Fade in={updateMode}>
        <Grid item xs={12}>
          <Button style={{width:"100%"}} onClick={() => (removeSelected())}>
            Ta bort
          </Button>
        </Grid>
      </Fade>
    </Grid>
  )

}

export default function ModuleModule(params){
  const theme   = useTheme();
  const classes = useStyles();

  const { onRemoveItem, notify }  = useMainGridContext();
  const { modulesApi, usersApi }  = useAppContext();
  const { dataFromServer }        = useComsContext();

  const name = params.name;

  const [firstLoad, setFirstLoad]     = useState(true);
  const [updateDesc, setUpdateDesc]   = useState(false);
  const [addParam, setAddParam]       = useState(false);
  const [updColor, setUpdColor]       = useState(false);

  const [chosenColor, setChoseColor]  = useState("");
  const [category, setCategory]       = useState("");

  const [module, setModule]           = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [readAuth, setReadAuth]       = useState([]);
  const [updAuths, setUpdAuths]       = useState([]);

  const [modDesc, setModDesc]                   = useState(null);
  const [modRead, setModRead]                   = useState(false);
  const [modUpdate, setModUpdate]               = useState([]);
  const [modNewParamName, setNewParamName]      = useState("");
  const [modNewParamDesc, setNewParamDesc]      = useState("");
  const [modConditionDesc, setModConditionDesc] = useState({});
  const [modParams, setModParams]               = useState({});
  const [modDatabase, setModDatabase]           = useState({});


  const [currentReadAuth, setCurrentReadAuth] = useState("Välj");

  const catColors = {
    admin: "#757575", 
    customers: "#2196f3", 
    products: "#ff9100",
    users: "#00c853"
  }

  
  const handleDescUpdate = (e) => {
    if(e.keyCode == 13){
    }
  }

  const updateModule = (col, val) => {
    var body = {"col": col, "val": val, "name": name}
    modulesApi.update(body).then(res => {
      notify("success", "Modul har uppdaterats");
    })
  }

  const pushReadAuths = (e) => {
    const newAuth = e.target.value;
    setCurrentReadAuth(newAuth);
    if(!readAuth.includes(newAuth)){
      var tmp = readAuth;
      tmp.push(newAuth);
      setReadAuth(tmp);
      updateModule("auths", tmp);
    }
  }

  useEffect(() => {
    if(modulesApi !== null && firstLoad)
    {
      getData();
      setFirstLoad(false);
    }
    if(dataFromServer.entity == "modules" && dataFromServer.type !== "READ"){
      getData();
    }
  },[modulesApi, dataFromServer]);

  const getData = () => {
    modulesApi.getName(name).then(res => {
      setModule(res.data);
      setModDesc(res.data.description);
      setReadAuth(res.data.auths);
      if(res.data.parameters !== undefined){
        setModParams(JSON.parse(res.data.parameters));
      }
      if(res.data.databaseConnections !== undefined){
        setModDatabase(JSON.parse(res.data.databaseConnections));
      }
      if(res.data.updateAuths !== undefined){
        setUpdAuths(res.data.updateAuths);
      }
      if(res.data.conditions !== undefined){
        setModConditionDesc(JSON.parse(res.data.conditions));
      }
      if(res.data.category !== undefined){
        setCategory(res.data.category);
      }
    })
  }

  return(
    <Module
      auths={params.auths}
      remove={() => onRemoveItem(params.id)}
    >
      <Header
        color="white"
        remove={() => onRemoveItem(params.id)}
      >
        {params.id} Modul {name}
      </Header>
      <Body>
        <Grid container style={{overflowY: "auto", height:"95%", overflowX:"hidden"}}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>Modul {name}</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="sutitle2">
              Skapad: {formatDateTime(new Date(module.created))} 
            </Typography>
          </Grid>
          <Grid container xs={4} alignItems="flex-end" justify="flex-end">
            <Chip 
              label={module.color} 
              style={{width:"100%", background: catColors[module.color]}} 
              color="primary"
              onClick={() => setUpdColor(!updColor)}
            />
            {
              updColor ? 
                <>
                <TextField 
                  label="Domän"
                  value={chosenColor} 
                  onChange={(e) => {
                    updateModule("color", e.target.value);
                    setUpdColor(!updColor)}
                  }
                  select 
                  style={{width:"100%"}}
                >
                  <MenuItem value="admin">admin</MenuItem>
                  <MenuItem value="products">produkter</MenuItem>
                  <MenuItem value="users">användare</MenuItem>
                  <MenuItem value="customers">kunder</MenuItem>
                </TextField> 
                </>
                : 
                null
            }
            <TextField 
              label="Kategori"
              value={category} 
              onChange={(e) => {
                updateModule("category", e.target.value);
              }}
              select 
              style={{width:"100%"}}
            >
              <MenuItem value="Skapa">Skapa</MenuItem>
              <MenuItem value="Updatera">Uppdatera/Ta bort</MenuItem>
              <MenuItem value="Sammanfattning">Sammanfattning</MenuItem>
              <MenuItem value="Händelser">Händelser</MenuItem>
              <MenuItem value="Moduler">Moduler</MenuItem>
              <MenuItem value="Auktoriteter">Auktoriteter</MenuItem>
              <MenuItem value="Data">Data</MenuItem>
              <MenuItem value="Filer">Filer</MenuItem>
            </TextField> 
          </Grid>
          <Grid item xs={12} className={classes.mainComponent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Funktionsbeskrivning
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={10}>
                  {updateDesc ?
                    <>
                      <TextField 
                        style={{width:"100%"}}
                        multiline 
                        defaultValue={module.description}
                        onChange={(e) => setModDesc(e.target.value)}
                      />
                      <Grid container alignItems="flex-end" justify="flex-end">
                        <Button onClick={() => setUpdateDesc(false)}>Avbryt</Button>
                        <Button color="primary" onClick={() => {
                          updateModule("description", modDesc);
                          setUpdateDesc(false)}}
                        >
                          Uppdatera
                        </Button>
                      </Grid>
                    </>
                  :
                    <Typography variant="body1" onClick={() => setUpdateDesc(true)}>
                      {module.description}
                    </Typography>
                  }
                </Grid>
                <Grid container xs={2} justify="flex-end" alignItems="flex-end">
                  <IconButton size="small" onClick={() => setUpdateDesc(!updateDesc)}>
                    <UpdateIcon />
                  </IconButton>
                </Grid>
                <Grid container style={{paddingTop:"25px"}}>
                  <Grid container style={{padding: 0, margin:0}}>
                    <UpdatableList 
                      title="Parametrar"
                      column="parameters"
                      params={modParams} 
                      updateModule={(x,y) => updateModule(x,y)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mainComponent}> 
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Behörigheter
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <AuthDescription 
                  auths={readAuth} 
                  updateModule={(x,y) => updateModule(x,y)}
                  activeUsers={activeUsers}
                  title="Läsa"
                  column="auths"
                />
              </Grid>
              <Grid item xs={6}>
                <AuthDescription 
                  auths={updAuths} 
                  updateModule={(x,y) => updateModule(x,y)}
                  activeUsers={activeUsers}
                  title="Uppdatera"
                  column="updateAuths"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.mainComponent}>
            <UpdatableList 
              title="Villkor"
              column="conditions"
              params={modConditionDesc}
              updateModule={(x,y) => updateModule(x,y)}
            />
          </Grid>
          <Grid container className={classes.mainComponent}>
            <UpdatableList 
              title="Databas"
              column="databaseConnections"
              params={modDatabase} 
              updateModule={(x,y) => updateModule(x,y)}
            />
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
