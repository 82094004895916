import axios from 'axios';
import config from './apiConfig.js';

export default class Customers{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "yf53stsjpd";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.customers_api_key;
  }

  async get(){
    return axios.get(`${this.endpoint}/read/all/${this.user}`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(cid){
    return axios.get(`${this.endpoint}/read/${cid}/${this.user}`,{
      headers: {
        'x-api-key': this.apiKey
      }
    });
  }
  async getGroup(cids){
    this.requestTemplate.body = {"cids": cids}; 
    return axios.post(`${this.endpoint}/customer/read/group`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey 
      }}
  );
  }

  async getGroupWhere(col, vals){
    this.requestTemplate.body = {"vals": vals}; 
    return axios.post(`${this.endpoint}/customer/read/group/where/${col}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey 
      }}
  );
  }

  async getWhere(col, val){
    return axios
      .get(`${this.endpoint}/read/where/${col}/${val}/${this.user}`,
        {headers: {
          'x-api-key': this.apiKey 
        }}
      );
  }

  async updateWhere(cid, col, val){
    return axios
      .post(`${this.endpoint}/update/where/${cid}/${col}/${val}/${this.user}`,
        {},
        {headers: {
          'x-api-key': this.apiKey 
        }}
      );
  }

  async createOld(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/old/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }

  async create(body){
    this.requestTemplate.body = body;
    return axios.post(`${this.endpoint}/create/${this.user}`, 
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }
  async searchGlobal(s){
    return axios.get(`${this.endpoint}/search/${s}`, 
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }
  async getOwner(cid){
    return axios.get(`${this.endpoint}/get/owner/customer/${cid}`, 
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }
  async getCustomerFull(cid){
    return axios.get(`${this.endpoint}/get/customer/full/${cid}`, 
      {headers: {
        'x-api-key': this.apiKey 
      }}
    );
  }
}

