import React, { useState, useEffect } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Fade from "@material-ui/core/Fade"
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { MainGridContext } from '../../libs/mainGridContextLib.js';
import { useMainGridContext } from '../../libs/mainGridContextLib.js';

import NewCustomerDocs from './NewCustomerDocs';
import AboutDocs from './AboutDocs';

const docsObj = {
  "::nykund": <NewCustomerDocs />,
  "::nyorder": <AboutDocs />,
  "::om": <AboutDocs />,
  "kundnummer": "Visar information om en specifik kund",
  "::tjänster": "Generell informatiom om tjänsterna som en specifik kund har",
  "::inbet": "Visa en specifik kunds inbetalNewCustomerDocs />"
}

const useStyles = makeStyles((theme) => ({
  root:{
    height: "100%",
    flexGrow: 1,
    maxWidth: 400,
  },
  mainGrid:{
    height: "90%",
  },
  leftGrid:{
    borderRight: "1px dotted lightGray"
  },
  rightGrid:{
    padding: theme.spacing(4)
  }
}));

export default function DocsModule(params){

  const classes = useStyles();
  const theme = useTheme();

  const [show, setShow] = useState(false);

  const [def, setDef] = useState(null);
  const [showDef, setShowDef] = useState(false);
  const [currentlyShowing, setCurrentlyShowing] = useState(null);

  const {onRemoveItem} = useMainGridContext();

  const handleClick = (label) => {
    setShowDef(false);
    setTimeout(() => {
      setCurrentlyShowing(label);
      setDef(docsObj[label]);
      setShowDef(true);
    }, 50);
  }


  return(
    <Module 
      className={classes.mainGrid} 
      auths={params.auths} 
      index={params.id} 
      remove={() => onRemoveItem(params.id)}
    >
      <Header color="#4db6ac" remove={() => onRemoveItem(params.id)}>
        {params.id} Hjälp 
      </Header>
      <Body> 
        <Grid container spacing={2} className={classes.mainGrid}>
          <Grid item xs={3} className={classes.leftGrid}>
            <Typography variant="h6">
              VASA Dokumentation
            </Typography>
            <Typography variant="overline">
              ISGG v4.1.0 
            </Typography>
            <br />
            <br />
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem nodeId="1" label="Kunder">
                <TreeItem 
                  onClick={() => handleClick("kundnummer")}
                  nodeId="2" 
                  label="kundnummer" 
                />

                <TreeItem 
                  onClick={() => handleClick("::nykund")}
                  nodeId="3" 
                  label="::nykund" 
                />
                 <TreeItem 
                  onClick={() => handleClick("::nyorder")}
                  nodeId="4" 
                  label="::nytjänst" 
                />
                <TreeItem 
                  onClick={() => handleClick("::om")}
                  nodeId="5" 
                  label="::om" 
                />
                <TreeItem 
                  onClick={() => handleClick("::tjänster")}
                  nodeId="6" 
                  label="::tjänster" 
                />
                <TreeItem 
                  onClick={() => handleClick("::inbet")}
                  nodeId="7" 
                  label="::inbet" 
                />

              </TreeItem>
              <TreeItem nodeId="100" label="Anställda">
              </TreeItem>
              <TreeItem nodeId="101" label="Produkter/Tjänster">
              </TreeItem>
              <TreeItem nodeId="102" label="Administrering">
              </TreeItem>
            </TreeView>
          </Grid>
          <Grid item xs={9} className={classes.rightGrid}>
            <Fade in={showDef}> 
              <div>
                {def} 
              </div>
            </Fade>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )

}
