export function getMime(fileType){
    switch(fileType){
      case "webm":
        return ("video/webm")
        break;
      case "mp4":
        return("video/mp4")
        break;
      case "mpeg":
        return("video/mpeg")
        break;
      case "gif":
        return("image/gif")
        break;
      case "jpeg":
      case "jpg":
        return("image/jpeg")
        break;
      case "png":
        return("image/png")
        break;
      default:
        return("error", "Fileformatet stöds inte, för videor stöds .webm,mp4, mpeg. För bilder: .gif,jpeg,jpg,png");
    }
  }

