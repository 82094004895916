import axios from 'axios';
import config from './apiConfig.js';

export default class Bugs{
  constructor(user){
    this.requestTemplate = config.requestTemplate;
    this.user = user;
    this.apiId = "cnbtey4rcc";
    this.endpoint = `https://${this.apiId}.execute-api.us-east-2.amazonaws.com/dev`;
    this.apiKey = config.api_keys.bugs_api_key;
  }

  async getAll(){
    return axios.get(`${this.endpoint}/read/all`,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async get(){
  }

  async create(body){
    this.requestTemplate.body = body; 
    return axios.post(`${this.endpoint}/create/bug`,
      this.requestTemplate,
      {headers: {
        'x-api-key': this.apiKey
      }}
    );
  }

  async update(id, col, val){
    return axios
      .post(`${this.endpoint}/update/${id}/${col}/${val}/${this.user}`,
        {},
        {headers: {
          'x-api-key': this.apiKey 
        }}
      );

  }

  async delete(id){
    return axios.delete(`${this.endpoint}/delete/${id}/${this.user}`,{
      headers: {
        'x-api-key': this.apiKey
      }
    });
  }
}
