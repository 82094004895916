import React, {useState, useEffect} from 'react';
import MUIDataTable from 'mui-datatables';

import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { 
  getMonthPeriod, 
  formatDateNormal, 
  currentMonthPeriod,
  formatDateTime
} from '../../shared/dateHelper.js';
import { formatter } from '../general/format.js';

import {makeStyles} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
  Box,
  Collapse,
  Table,
  TableHead,
  TableBody
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({

}));

const CustomTableToolbar = (props) => {
  const { addModule } = useMainGridContext();
  const handleClick = () => {
    addModule("::nykund");
  }

  return (
    <React.Fragment>
      <Tooltip title={"custom icon"}>
        <IconButton onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

function OrderSpecifications(params){
  const classes = useStyles();

  const [services, setServices] = useState([]);
  const oid = params.oid;
  const { servicesApi } = useAppContext(); 
  const [open, setOpen] = useState(false);

  useEffect(() => {
    servicesApi.getWhere("orderNumber", oid).then(res => {
      var ss = res.data.recordset;
      console.log(ss);
      setServices(ss);
      setOpen(true);
    }); 
  },[oid, servicesApi])

  return(
    <TableRow>
      <TableCell colSpan={7}>
        <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Tjänst</b></TableCell>
                <TableCell><b>Antal</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map(e => (
                <TableRow>
                  <TableCell>{e.productCode}</TableCell>
                  <TableCell>{e.amount}</TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
          </Collapse>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default function UserOrders(params){
  const classes = useStyles();
  const { onRemoveItem, addModule } = useMainGridContext();
  const { usersApi } = useAppContext();
  const { dataFromServer } = useComsContext();

  const tmpPeriod = currentMonthPeriod();
  const [loadTime, setLoadTime] = useState([]);
  const [fromDate, setFromDate] = useState(tmpPeriod.start);
  const [toDate, setToDate] = useState(tmpPeriod.end);
  const [orders, setOrders] = useState([]); 
  const [specificDate, setSpecificDate] = useState(true); 

  const uname = params.uname;

  useEffect(() => {
    var start = new Date();
    if(
      orders.length === 0 && usersApi !== null ||
      (dataFromServer.entity === "users" || 
        dataFromServer.creator === uname &&
        usersApi !== null)
    ){
      var period = {
        start: formatDateNormal(new Date(fromDate)), 
        end: formatDateNormal(new Date(toDate))
      };
      usersApi.readOrders(period, uname).then(res => {
        setOrders(res.data.recordset);
        var end = new Date();
        setLoadTime((end - start)/1000)
      })
    }
  },[dataFromServer, fromDate, orders.length, toDate, uname, usersApi]);

  const getData = () => {
    var start = new Date();
    const period = {start: formatDateNormal(new Date(fromDate)), end: formatDateNormal(new Date(toDate))};
    usersApi.readOrders(period, uname).then(res => {
      setOrders(prev => res.data.recordset);
      var end = new Date();
      setLoadTime((end - start)/1000)
    })
  }

  const handleYearDateChange = (e) => {
    const start = new Date();
    const p  = getMonthPeriod(e.getFullYear(), e.getMonth()+1); 
    setFromDate(p.start);
    setToDate(p.end);
    usersApi.readOrders(p, uname).then(res => {
      setOrders(prev => res.data.recordset);
      var end = new Date();
      setLoadTime((end - start)/1000)
    });
  }

  const handleFromDateChange = (e) => {
    setFromDate(e);
  }

  const handleToDateChange = (e) => {
    setToDate(e);
  }

  const columns = [
    {
      name: "customerid",
      label: "KID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<Button onClick={() => addModule(`::order ${value}`)}>{value}</Button>)
        }
      }
    },
    {
      name: "orderId",
      label: "OID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return(<Typography variant="p">{value}</Typography>)
        }
      }
    },
    {
      name: "services",
      label: "#Tjänster",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "price",
      label: "Pris",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<Typography variant="subtitle2">{formatter.format(value)}</Typography>)
        }
      }
    },
    {
      name: "paid",
      label: "Betalt",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<Typography variant="subtitle2">{formatter.format(value)}</Typography>)
        }
      }
    },
    {
      name: "fsg",
      label: "FSG",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<Typography variant="subtitle2">{(value)}</Typography>)
        }
      }
    },
    {
      name: "orderDate",
      label: "Regdatum",
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<Typography variant="subtitle2">{formatDateTime(new Date(value))}</Typography>)
        }
      }
    }
  ]

  const options = {
    filterType: 'checkbox',
    responsive: "standard",
    tableBodyHeight: "100%",
    elevation: "0",
    download: false,
    print: false,
    expandableRows: true,
    expandableRowOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const oid = rowData[1].props.children;
      return(
        <OrderSpecifications oid={oid}/>
      )
    },
    setTableProps: () => ({size:"small"}),
    customToolbar: () => {
      return (<CustomTableToolbar />)
    },
    textLabels: {
      body: {
        noMatch: "Det hittades tyvär inga matchande rader",
        toolTip: "Sortera",
        columnHeaderTooltip: column => `Sortera för ${column.label}`
      },
      pagination: {
        next: "Nästa sida",
        previous: "Föregående sida",
        rowsPerPage: "Rader per sida",
        displayRows: "av"
      },
      toolbar:{
        search: "Sök",
        downloadCsv: "Ladda ner CSV",
        print: "Skriv ut",
        viewColumns: "Visa kolumner",
        filterTable: "Filtrera tabell"
      },
      filter:{
        all: "Alla",
        title: "FILTER",
        reset: "Återställ",
      },
      viewColumns:{
        title: "Visa kommentarer",
        titleAria: "Visa/Göm Tabell Kolumner",
      },
      selectedRows:{
        text: "Rad(er) visade",
        delete: "Ta bort",
        deleteAria: "Ta Bort Valda Rader"
      }
    }
  }

  // Owner is the username it is regarding
  const isOwner = async (body) => {
    return body.username === params.uname;
  }

  return(
    <Module 
      auths={params.auths} 
      remove={() => onRemoveItem(params.id)}
      ownership={(body) => isOwner(body)}
    >
      <Header color="#00c853" remove={() => onRemoveItem(params.id)}>
        {params.id} Order {params.uname}
      </Header>
      <Body>
        <Grid container style={{overflowY: "auto", height:"95%", overflowX:"hidden"}}>
          <Grid container>
            <Typography variant="h4"><b>Order</b></Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Typography variant="overline">
                Inladdningstid
              </Typography>
              <Typography variant="subtitle2">
                {loadTime + "s"} 
              </Typography>
            </Grid>
            <Grid container xs={1}>
              {specificDate ? 
              <Button onClick={() => setSpecificDate(prev => !prev)}>År/Månad</Button>
              :
              <Button onClick={() => setSpecificDate(prev => !prev)}>Datum</Button>
              }
            </Grid>
            {specificDate ?
              <Grid container xs={1} justify="center" alignItems="center">
                <Typography variant="p">Mellan</Typography>
              </Grid>
              :
              null
            }
            <Grid container xs={3} direction="row">
              {specificDate ?
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      KeyboardButtonProps={{'aria-label': 'ändra datum'}}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                :
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <DatePicker
                      views={["year", "month"]}
                      label="År och Månad"
                      minDate={new Date("2017-01-01")}
                      maxDate={new Date()}
                      value={fromDate}
                      onChange={handleYearDateChange}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              }
            </Grid>
            {specificDate ?
              <>
              <Grid container xs={1} justify="center" alignItems="center">
                <Typography variant="p">och</Typography>
              </Grid>
              <Grid container xs={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={toDate}
                        onChange={handleToDateChange}
                        KeyboardButtonProps={{'aria-label': 'ändra datum'}}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => getData()}>
                  <UpdateIcon />
                </IconButton>
              </Grid>
              </>
              :
              null
            }
          </Grid>
          <Grid container>
            <div style={{width: "100%"}}>
              <MUIDataTable
                size="small"
                title={"Order"}
                data={orders}
                columns={columns}
                options={options}
                style={{width: "100%"}}
              />
            </div>
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
