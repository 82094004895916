export default {
  url: "https://isgg-intelligens.biz",
  requestTemplate: {
    "body": null,
    "requestContext": {
      "identity": {
        "cognitoUserIdentity": "admin1"
      } 
    }
  },
  api_keys:{
    customers_api_key:"FvY3fDJUNs9magGEWpHQk52fyOcnS2DnyDsdLqci",
    events_api_key:"ZUhgp52pml9Rf634sLlHq77TdroqITZF8qPKVOEb",
    goals_api_key: "nfX4p00kwtaxdKz6qN4VfaCIQivRDDLLaAarFy5O",
    modules_api_key: "3hHZTsXRIz8RGK6FrJlka1cXqJb6yd982dPZxdod",
    auths_api_key: "fSIiE4GNjT6Kq9Q2JXB6i3r0hogD0Jgh58crCrkx",
    comments_api_key: "PmzoWQp33k5m04oSMOD2H4T1Ra8ipDRW26zfeC72",
    files_api_key: "JmueblkVjraO1eqEdnlIw4Vcqtk9XU8I10oXc4Xt",
    orders_api_key: "Pl0yRC0qjO1Mh7mKIRqrJ6cMJuYnHeFT7oaVfYm4",
    payments_api_key: "Ut68B7HsAt2ONppybAzYaHhroMvuBKHaK4prIGda",
    products_api_key: "UHCdSez4pN7r8F6iredjp5dwC9RgJciV7wJNclmf",
    users_api_key: "cSOS50zZFj4VjjLMFEnwh41PTPdspKfl1VfK6uBY",
    userAdmin_api_key: "9V3MGwWnNK5wyqTlClTfTanPNylTQ4cZ62PKGb26",
    userPrefs_api_key: "v0V11sfhIuaDzSoYPnTva5CVcFbBkNAD3OhojtnV",
    bugs_api_key: "51PxZKkKwv527CsWA9AAI8Ch00IF90M71jWL6w7f",
    officePrefs_api_key: "rz4f4Tr9Xu9NYANJwg8Th6YaohJxpccT5XURAVrJ",
    reactions_api_key: "UflLAOSwne5hl7Clvg50C7eGlVBYaEPv2ewwdQlw",
    docs_api_key: "OFHlUFG19kTZx0mN7MJ16Uwct3x2Gre6WtY7vHJ6",
    challenges_api_key: "e9PTrZK9DY2GMdcbtB1W44wpuWyDff7EPuw7fi1a",
    groups_api_key: "09uen0wun843lsZiO2Jmj6m4VHwtW31C9cuU7qgG"
  }
}
