import React, { useState, useEffect, useRef } from 'react';

import { useAppContext } from '../libs/contextLib';
import { useMainGridContext } from '../libs/mainGridContextLib.js';

import {makeStyles, useTheme} from "@material-ui/core/styles";
import { GithubPicker } from 'react-color';

import BugReportDialog from './BugReportDialog.js';

import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';

import Icon from "@material-ui/core/Icon";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton'
import Zoom from '@material-ui/core/Zoom'

import {sizing} from '@material-ui/system'

import forbiddenGif from "../assets/forbidden.gif";

import VasaDialog from '../shared/VasaDialog.js';
import Customers from '../shared/Customers.js';
import Orders from '../shared/Orders.js';
import Services from '../shared/Services.js';
import Payments from '../shared/Payments.js';
import Comments from '../shared/Comments.js';
import Products from '../shared/Products.js';
import Users from '../shared/Users.js';
import Modules from '../shared/Modules.js';
import Auths from '../shared/Auths.js';
import UserPrefs from '../shared/UserPrefs.js';
import DataStreams from '../shared/DataStreams.js';

import BookmarkIcon from '@material-ui/icons/Bookmark';
import ColorizeIcon from '@material-ui/icons/Colorize';
import BugReportIcon from '@material-ui/icons/BugReport';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative", 
    border:theme.palette.type == "light" ? "1px solid #ebebeb" : "1px solid #0f0f0f",
  },
  header: {
    background: "transparent", 
  },
  toolbar: {
    minHeight: 40,
    maxHeight: 45
  },

  bodyNoPadding:{
    height:"100%"
  },

  body: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(7),
    height:"100%",
  },
  moduleTitle: {
    marginRight: 20,
    marginLeft: 0,
    padding:"0px"
  },
  closeIcon: {
    margin:"auto",
    marginRight: 0,
    color: "white",
  }
}))

function BookmarkDialog(params){
  // From previous creation 
  //onClick={() => createBookmark(String(params.children).split(",")[0])}>
  const theme = useTheme();
  const { createBookmark } = useMainGridContext();

  const [headerArray, setHeaderArray] = useState(params.headerArray);
  const [desc, setDesc] = useState("");
  const [color, setColor] = useState(theme.palette.background.default);
  const [showColorPicker, setShowColorPicker] = useState("");

  const update = (c, e) => {
    setColor(c.hex);
  }

  const create = (e) => {
    createBookmark(headerArray[0], desc, color);
    params.handleClose();
  }

  return(
    <VasaDialog
      open={params.open}
      handleClose={params.handleClose}
    >
      <div style={{background: color, padding:"50px"}}>
        <Typography variant="h2">
          Bokmärke <b>{String(headerArray.splice(1))}</b>
        </Typography>
        <TextField 
          fullWidth 
          label="beskrivning" 
          onChange={(e) => setDesc(e.target.value)}
        />
        <Button onClick={() => setShowColorPicker(prev => !prev)}>
          <ColorizeIcon /> Välj en Färg
        </Button>
        <Collapse in={showColorPicker}>
          <GithubPicker onChange={update}/>
        </Collapse>
        <Button fullWidth onClick={create}>
          Klar
        </Button>
      </div>
    </VasaDialog>
  )
}

export function Header(params){
    const classes = useStyles();
    const theme = useTheme();
    
    const { createBookmark, items, setItems } = useMainGridContext();

    const title = params.title;
    const color = params.color !== undefined ? params.color : 'white';
    
    const [openBookmarkDialog, setOpenBookmarkDialog] = useState(false);
    const [openBugDialog, setOpenBugDialog]           = useState(false);

    const [show, setShow] = useState(false);

    return (
        <AppBar 
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          elevation={0} 
          className={classes.header}
          style={{
            background: theme.palette.type == "dark" ? "#0f0f0f" : "white", 
            borderBottom: `1px solid ${params.color}`,
            borderTop: `5px solid ${params.color}`,
            cursor: "grab",
            color:theme.palette.text.primary
          }}
        >
          <Fade in={show}>
            <div>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {params.children} 
                </Typography>
              </Grid>
              <Grid 
                item 
                xs={6} 
                container 
                justify="flex-end" 
                alignItems="flex-end"
              >
                <Grid item>
                  <Tooltip title="Rapportera bug">
                    <Button 
                      style={{padding:"0",margin:"0"}} 
                      onClick={() => setOpenBugDialog(true)}>
                      <BugReportIcon fontSize="small"/>
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Skapa bokmärke">
                    <Button 
                      style={{padding:"0",margin:"0"}} 
                      onClick={() => setOpenBookmarkDialog(true)}>
                      <BookmarkIcon fontSize="small"/>
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Stäng modul">
                    <Button 
                        style={{padding:"0",margin:"0"}} 
                        onClick={params.remove}>
                      <CloseIcon fontSize="small"/>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          <BookmarkDialog 
            open={openBookmarkDialog} 
            handleClose={() => setOpenBookmarkDialog(false)}
            headerArray={(String(params.children).split(","))}
          />
          <BugReportDialog
            open={openBugDialog} 
            handleClose={() => setOpenBugDialog(false)}
            title={String(params.children).split(",")[0]}
          />
          </div>
          </Fade>
        </AppBar>
    )
}

export function Body(params){
  const classes = useStyles();
  const theme = useTheme();
  const bodyClass = Object.keys(params).includes("body") ? classes[params.body] : classes.body
  return (
    <div className={bodyClass}>
      <span className="not-draggable">
        {params.children}
      </span>
    </div>
  )
}

export function Module(params){
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const auths = params.auths;

  const { refs, setRefs, currentOwner }= useMainGridContext();

  const [isAuthorised, setIsAuthorised] = useState(null);
  const [isOwned, setIsOwned] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const { session, 
          currentUser, 
          setCustomersApi, 
          setOrdersApi,    
          setServicesApi,  
          setPaymentsApi,  
          setCommentsApi,  
          setProductsApi,  
          setUsersApi,  
          setModulesApi,   
          serverAuthority, 
          serverUsername,  
          setAuthsApi,     
          setUserPrefs}    = useAppContext();

  const needsOwner = (auths) => {
    var ownerAuths = auths.filter(auth => auth.split(":").includes("OWNER"));
    return ownerAuths; 
  }

  const allowAccess = (uname) => {
    setIsAuthorised(true);
    setCustomersApi(new Customers(uname));
    setOrdersApi(new Orders(uname));
    setServicesApi(new Services(uname));
    setPaymentsApi(new Payments(uname));
    setCommentsApi(new Comments(uname));
    setProductsApi(new Products(uname));
    setUsersApi(new Users(uname));
    setModulesApi(new Modules(uname));
    setAuthsApi(new Auths(uname));
    setUserPrefs(new UserPrefs(uname));
  }

  const verifyAuthorities = (userAuth, username) => {
    if(
      !auths.includes(userAuth) && 
      !auths.includes("*") &&
      !auths.includes(username)
    ){
      setIsAuthorised(false);
    }
    else{
      allowAccess(username);
    }
  }

  const verifyAuthority = (auth, userAuth, username) => {
    if(
      auth !== userAuth &&
      auth !== "*"
    ){
      return false;
    }
    else{
      return true;
    }
  }

  const authorised = async () =>{

    if(serverUsername !== null){
      const userAuth = serverAuthority;
      const username = serverUsername;
      const ownerAuths = needsOwner(auths);

      if(auths.includes("*")){
        allowAccess(username);
      }
      if(ownerAuths.length == 0){
        verifyAuthorities(userAuth, username);
      }
      else{
        var authorised = false
        for(var i = 0; i < auths.length; i++){
          var main = auths[i].split(":");
          if(main.length == 1){
            if(verifyAuthority(main[0], userAuth, username)){
              allowAccess(username);
              authorised = true;
              break;
            }
          }
          else{
            var auth = main[0];
            var ownership = main[1];
            if(userAuth == auth || auth == username){
              // Check ownership
              var body = {"username": username, "auths": auths}
              if(Object.keys(params).includes("ownership")){
                setLoadingMessage("Kontrollerar ägarskap");
                var isOwner = await params.ownership(body);
                if(isOwner == true){
                  allowAccess(username);
                  authorised = true;
                  break;
                }
              }
            }
          }
        }
        if(!authorised){
          setIsAuthorised(false);
        }
      }
    }
    else{
      setIsAuthorised(false);
    }
  }

  useEffect(() => {
    setShow(true);
    if(currentUser.username !== null){
      authorised();
    }
  },[currentUser, session]);

  if(isAuthorised == null || currentUser.username == null){
    return(
      <Zoom in={show}>
        <Paper className={classes.root} style={{position:"relative"}}>
        </Paper>
      </Zoom>
    )
  }

  if(!isAuthorised){
    return(
      <Zoom in={show}>
        <Paper className={classes.root}>
          <Header remove={params.remove} setShow={setShow}>
            {params.index} 
          </Header>
          <Body> 
            <Grid container alignItems="center" justify="center" direction="column"> 
              <img src={forbiddenGif} style={{width:"200px"}}/>
              <Typography variant="h4">
                Attans!
              </Typography> 
              <Typography variant="h6" align="center">
                Det verkar som att du saknar behörighet för att se denna modulen.
              </Typography> 
            </Grid>
          </Body>
        </Paper>
      </Zoom>
    )
  }

  return(
    <Fade in={show}>
      <Paper className={classes.root} ref={(ref) => {
        if(ref !== null && !refs.includes(ref)){
          var rs = refs;
          rs.push(ref);
          setRefs(rs);
        }
      }}>
        {params.children}
      </Paper>
    </Fade>
  ) 
}
