import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import MUIDataTable, { TableToolbar} from 'mui-datatables';

import axios from 'axios';

import {makeStyles, useTheme, fade} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 

import { useComsContext } from '../../libs/comsContextLib';
import { useMainGridContext } from '../../libs/mainGridContextLib';
import { useAppContext } from '../../libs/contextLib';

import DataStreams from '../../shared/DataStreams.js';
import UserPrefs from '../../shared/UserPrefs.js';
import {
  Grid,
  Typography,
  Chip,
  Paper,
  TextField,
  Divider,
  Button,
  IconButton,
  Fade,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({

  dataList: {
    padding:"10px", 
    borderRadius: "5px",
  },
  textFieldContainer: {
    padding:"10px"
  }

}))

export default function PersonalStreams(params){
  const classes = useStyles();
  const theme   = useTheme();
  const { onRemoveItem, notify } = useMainGridContext();

  const { 
    entityEventsApi, 
    productsApi, 
    currentUser, 
  } = useAppContext();
  const { dataFromServer  } = useComsContext();

  const [dataStream, setDataStream]   = useState(null);
  const [streams, setStreams]         = useState([]);
  const [showAddNew, setShowAddNew]   = useState(false);
  const [userStreams, setUserStreams] = useState([]);

  const [type, setType]                     = useState()
  const [selected, setSelected]             = useState({});
  const [selectAll, setSelectAll]           = useState(false);
  const [showOperations, setShowOperations] = useState(false);
  const [formValues, setFormValues]         = useState({});
  const [updateForm, setUpdateForm]         = useState(false);

  const userPrefs = new UserPrefs(currentUser.username);

  useEffect(() => {
    if(
      (currentUser.username !== null && currentUser.username !== undefined)
      || dataFromServer.entity == "dataStreams");
    {
      const ds = new DataStreams(currentUser.username);
      setDataStream(ds);

      ds.getAll().then(res => {
        setStreams(res.data.modules);

        var tmp = {};
        res.data.modules.map(e => {
          tmp[e.name] = false;
        });

        userPrefs.read(currentUser.username).then(res => {
          if(res.data !== ""){
            setUserStreams(res.data.dataStreams);
            res.data.dataStreams.map(e => {tmp[e] = true;})
          }
          setSelected(tmp);
        });
      });
    }
  },[currentUser, dataFromServer])

  const handleCheckboxChange = (id) => {
    const val = !selected[id];

    var tmpSel = selected;
    tmpSel[id] = !selected[id];

    if(Object.values(tmpSel).includes(true)){
      setShowOperations(true);
    }
    else{
      setShowOperations(false)
    }
    setSelected({...selected, [id]:val});
    savePreferences({...selected, [id]:val})
  }

  const savePreferences = (sd) => {

    var names = [];
    Object.keys(sd).map(e => {
      if(sd[e]){
        names.push(e);
      }
    });

    if(names.length >= 0){
      userPrefs.update(
        currentUser.username, 
        "dataStreams", 
        names
      ).then(res => {
        notify("success", "Datatrömmar uppdaterade");
      }).catch(err => {
        console.log("error", err);
        notify("error", "Kunde inte uppdatera dataströmmar");
      }); 
      setShowOperations(false);
    }
    else{
      notify("info", "Ändra på någonting för att spara");
    }
  }

  const deleteSelected = () => {
    Object.keys(selected).map(e => {
      console.log(selected[e]);
      if(selected[e]){
        dataStream.delete(e).then(res => {
          notify("success", "dataström har blivit borttagen");
        });
      }
    });
    setShowOperations(false);
  }

  const onSelectAll = () => {
    setSelectAll(prev => !prev);
    var tmp = selected;
    Object.keys(tmp).map(e => {
      tmp[e] = !selectAll;
    });
    
    setSelected(tmp);
  }

  const handleUpdate = (e) => {
    setShowAddNew(prev => !prev);
    if(!showAddNew){
      setFormValues(e);
      setUpdateForm(true);
    }
    else{
      setUpdateForm(false);
    }
  }

  return(
    <Module auths={params.auths} remove={() => onRemoveItem(params.id)}>
      <Header color="white" remove={() => onRemoveItem(params.id)}>
        {params.id} Dataströmmar
      </Header>
      <Body>
        <Grid container style={{overflowY: "auto", height:"99%"}}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{paddingLeft:"10px"}}>
              <b>Mina siffror</b> 
            </Typography>
          </Grid>
          <Grid 
            container 
            style={{background:theme.palette.background.default, borderRadius:"10px", padding:"10px"}}
          >
            {streams.map(e => (
              <Grid style={{paddingRight:"10px"}}>
                <Typography>{e.name}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} style={{marginTop:"50px"}}>
            <Typography variant="h4" style={{paddingLeft:"10px"}}>
              <b>Tillgängliga siffror</b> 
            </Typography>
          </Grid>
          <Grid 
            container 
            xs={12} 
            className={classes.dataList} 
          > 

            {streams.filter(e => {return(e.type === "siffra")}).map((e) => {
              return(
                <>
                  <Grid container xs={12} style={{background:theme.palette.background.default, marginBottom:"20px", padding:"20px", borderRadius:"10px"}}>
                    <Grid item xs={10}>
                      <Typography variant="overline">
                        {e.type} 
                      </Typography>
                      <Typography variant="h4">
                        <b>{e.name}</b>
                      </Typography>
                      <Typography variant="p">
                        {e.description}
                      </Typography>
                    </Grid>
                    <Grid 
                      item 
                      container
                      xs={2} 
                      justify="flex-end" 
                      alignItems="flex-end" 
                    >
                      <FormControlLabel
                        control={<Checkbox 
                          checked={selected[e.name] || selectAll} 
                          onChange={() => handleCheckboxChange(e.name)}
                        />}
                        label={selected[e.name] ? "Ta bort" : "Lägg till"}
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </>
              );
            })
            }
          </Grid>
        </Grid>
      </Body>
    </Module>
  )
}
