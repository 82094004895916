import React, {useEffect, useState} from 'react';
import { NewCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useCustomerContext } from '../../libs/newCustomerContextLib.js';
import { useAppContext } from '../../libs/contextLib';
import { useComsContext } from '../../libs/comsContextLib';

import {useTheme} from '@material-ui/core/styles';

import {
  formatter, 
  formatNumber, 
  capitalizeFirstLetter
} from '../general/format.js';

import _ from 'lodash';

import { makeStyles, usetheme } from '@material-ui/core/styles';
import {
  Grid,
  Avatar,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Fade,
  TextField,
  Button,
  Divider
} from '@material-ui/core';
import {ImUser} from "react-icons/im";
import {BiUserPin} from "react-icons/bi";
import {BsHouseDoor} from "react-icons/bs";
import {CgPassword} from "react-icons/cg";
import {GoLocation} from "react-icons/go";
import {AiOutlinePhone, AiOutlineMail} from "react-icons/ai";


import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: "gray",
  },
  large:{
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  table:{
   width: "100%", 
   paddingTop: "40px",
  }

}));

function FormattedRow(params){
  const classes = useStyles();

  const labels = Object.keys(params.data);
  const values = Object.values(params.data);
  return(
    <Grid container style={{paddingTop: "20px"}}>
      <Grid item xs={4} alignItems="center" justify="center">
        {values[0].icon !== undefined ? values[0].icon: null}
        <Typography variant="caption" className={classes.caption}>
          {labels[0]}
        </Typography>
        <br />
        <Typography variant="p">
          {values[0].value} 
        </Typography>
      </Grid>
      <Grid 
        container 
        xs={4} 
        alignItems="center" 
        justify="center" 
        direction="column"
      >
        <Grid item alignItems="center" justify="center" direction="column">
          {values[1].icon !== undefined ? values[1].icon: null}
          <Typography variant="caption" className={classes.caption}>
            {labels[1]}
          </Typography>
        </Grid>
        <Grid item alignItems="center" justify="center" direction="column">
          <Typography variant="p">
            {values[1].value}
          </Typography>
        </Grid>
      </Grid>
      <Grid 
        container 
        xs={4} 
        alignItems="flex-end" 
        justify="flex-end" 
        direction="column"
      >
        <Grid 
          item 
          alignItems="flex-end" 
          justify="flex-end" 
          direction="column"
        >
          {values[2].icon !== undefined ? values[2].icon: null}
          <Typography variant="caption" className={classes.caption}>
            {labels[2]}
          </Typography>
        </Grid>
        <Grid 
          item 
          alignItems="flex-end" 
          justify="flex-end" 
          direction="column"
        >
          <Typography variant="p">
            {values[2].value} 
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

function ServicesTable(){

  const classes = useStyles();

  const { services } = useCustomerContext();
  const { fsg, setFsg } = useCustomerContext();
  const { setLoadingFSG } = useCustomerContext();
  const { entityEventsApi, productsApi } = useAppContext();
  const { dataFromServer } = useComsContext();
  const [avServices, setAvServices] = useState([]);
  const [serviceCosts, setServiceCosts] = useState({});

  const [totalPrice, setTotalPrice] = useState(_.sumBy(services, function(s){
    return parseFloat(s.price);
  }));
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if(
      productsApi !== null)
    {
      setLoadingFSG(true);
      productsApi.getAll().then(res => {
        var costs = {};
        res.data.recordset.map(s => {
          costs[s.name] = s.unitPrice;
        });
        var serviceCosts = 0;
        services.map(r => {
          serviceCosts += costs[r.service]*r.amount;
          setTotalCost(prev => prev + costs[r.service]*r.amount);
        })
        setFsg(totalPrice*0.8 - serviceCosts);
        setLoadingFSG(false);
      }); 
    }
    else{
      console.log("productsApi", productsApi);
      setLoadingFSG(false);
    }
 
  }, [productsApi, dataFromServer, services])

  return(
    <TableContainer className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Tjänst</b> 
            </TableCell>
            <TableCell align="right">
              <b>Antal</b>
            </TableCell>
            <TableCell align="right">
              <b>Pris</b> 
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {services.map(r => {
              return (
              <TableRow key={r.service}>
                <TableCell>{r.service}</TableCell>
                <TableCell align="right">{r.amount}</TableCell>
                <TableCell align="right">{r.price}</TableCell>
              </TableRow>
            )})}
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1} ><b>FSG</b></TableCell>
              <TableCell align="right">{formatter.format(fsg)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1} ><b>Totalt</b></TableCell>
              <TableCell align="right">{formatter.format(totalPrice)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function BillingDetails(){

  const classes = useStyles();

  const {billingLen, billing} = useCustomerContext();

  return(
    <Grid container style={{paddingTop: "40px", paddingBottom: "40px"}}>
      <Grid 
        container 
        xs={4} 
        alignItems="center" 
        justify="center" 
        direction="column"
      >
        <Grid item alignItems="center" justify="center" direction="column">
          <Typography variant="caption" className={classes.caption}>
            Avtalslängd 
          </Typography>
        </Grid>
        <Grid item alignItems="center" justify="center" direction="column">
          <Typography variant="p">
            {billingLen} 
          </Typography>
        </Grid>
      </Grid>
      <Grid 
        container 
        xs={4} 
        alignItems="center" 
        justify="center" 
        direction="column"
      >
      </Grid>
      <Grid 
        container 
        xs={4} 
        alignItems="center" 
        justify="center" 
        direction="column"
      >
        <Grid item alignItems="center" justify="center" direction="column">
          <Typography variant="caption" className={classes.caption}>
           Betalning 
          </Typography>
        </Grid>
        <Grid item alignItems="center" justify="center" direction="column">
          <Typography variant="p">
            {billing} 
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function CustomerSummary(){
  const theme = useTheme();
  const classes = useStyles();

  const {customerInfo}              = useCustomerContext();
  const {services}                  = useCustomerContext();
  const {billingLen}                = useCustomerContext();
  const {billing}                   = useCustomerContext();
  const {comments, setComments}     = useCustomerContext();
  const {loadingFSG, setLoadingFSG} = useCustomerContext();

  const [showCommentButtons, setShowCommentButtons] = useState(false);
  const [comment, setComment] = useState("");

  const writeComment = (e) => {
    setComments([...comments, comment]);
    setComment("");
    setShowCommentButtons(false);
  }

  return(
    <Grid container>
        <Grid 
          container 
          alignItems="center" 
          justify="center" 
          direction="column"
        >
          <Avatar className={classes.large}>
            <AccountCircleIcon className={classes.large}/>
          </Avatar>
          <Typography variant="h5">
            {customerInfo.name} 
          </Typography>
        </Grid>
      
      <FormattedRow data={{
        "Personnummer": {"value": customerInfo.socialSecurityNumber, "icon": <BiUserPin/>},
        "Telefon": {"value": customerInfo.telephone, "icon": <AiOutlinePhone />},
        "E-mail": {"value": customerInfo.email, "icon": <AiOutlineMail />}
        }}
      />

      <FormattedRow data={{
        "Adress": {"value": customerInfo.adress, "icon": <BsHouseDoor />},
        "Postnummer": {"value": customerInfo.zipCode, "icon": <CgPassword />},
        "Ort": {"value": customerInfo.municipality, "icon": <GoLocation />}
        }}
      />

      <ServicesTable /> 
      <BillingDetails />
      <Grid 
        container 
        style={{border: `1px solid ${theme.palette.border.primary}`, padding:"5px"}}
      >
        <Grid container>
          {comments.map(e => (
            <Grid item xs={12}>
              <Typography>{e}<br/></Typography>
              <Divider />
            </Grid>
          ))}
        </Grid>
        <TextField
          label="Skriv en kommentar"
          multiline
          rowMax={10}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{
            width:"100%", 
            background:theme.palette.background.paper, 
            borderRadius:"5px"
          }}
          onFocus={() => setShowCommentButtons(true)}
        />
        <Fade in={showCommentButtons}>
          <Grid container>
            <Grid item xs={6}>
              <Button 
                fullWidth
                color="secondary" 
                onClick={() => {setShowCommentButtons(false); setComment("")}}
              >
                Avbryt 
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth color="primary" onClick={writeComment}>
                Kommentera 
              </Button>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
}
