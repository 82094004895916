import React, {useState, useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';

import { useMainGridContext } from '../../libs/mainGridContextLib.js';

export default function NewCustomerDocs(params){

  const { addModule } = useMainGridContext();

  const [module, setModule] = useState({});

  useEffect(() => {
    axios.get("https://isgg-intelligens.biz/modules/read/nykund").then((res) => {
      console.log(res);
      var d = new Date(res.data.created);
      res.data.created = d.getFullYear() + '-' + d.getMonth()+ '-' + d.getDate();
      console.log(d);
      setModule(res.data);
    });
  },[]);

  return(
    <Grid continer>
      <Grid item style={{padding: "5px", borderRadius:"5px"}}>
        <Typography variant="h4">
          ::nykund 
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="overline">
              Alias: nk,nyk
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="overline">
              Skapad: {module.created} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="primary" onClick={() => addModule("::nykund")}>
              Öppna
            </Button>
          </Grid>
        </Grid>
        <Typography variant="subtitle2">
          Använd ::nykund för att lägga till en ny kund och order. Innehåller ett 
          formulär i tre steg.
        </Typography>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5">
            Beskrivning
          </Typography>
          <Typography>
            En ny kund skapas i tre steg.
          </Typography>
          <div style={{padding: "10px"}}>
            <Typography variant="subtitle2">
              1. <b>Skriv</b> in kundens personuppgifter och tjänsterna
              som ordern består utav.
            </Typography>
            <Typography variant="subtitle2">
              2. <b>Inspektera</b> uppgifterna och se till att värdena är rätt
              här kan du också en kommentar på kunden.
            </Typography>
            <Typography variant="subtitle2">
              3. <b>Skapa</b> kunden genom att låta VASA utföra tre 
              webbförfrågningar för att skapa kund, order och tjänster.
            </Typography>
          </div>
          <Typography variant="h5">
            Auktoriteter
          </Typography>
          <Typography>
            De nedan listade auktoriteterna visar vem som har tillgång till
            modulen
          </Typography>
          <div style={{padding:"10px"}}>
            {Object.keys(module).length > 0 ? module.auths.map(a => <Typography>{a}</Typography>): null}
          </div>
        </Grid>

      </Grid>
    </Grid>
  )

}
