import React, {useState, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';

import { useMainGridContext } from '../../libs/mainGridContextLib';
import {
  getPaymentAmount, 
  paymentPlan,
  priceConstructor,
  textualSubscriptionLength,
  daysToPeriod
} from '../../components/utils.js';


import axios from 'axios';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Header, Body, Module} from '../Modules'; 
import Files from '../../shared/Files.js';
import {formatDateTime} from '../../shared/dateHelper.js';

import Customers from '../../shared/Customers.js';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font
} from '@react-pdf/renderer'

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  TextField,
  Fade,
  Paper,
  IconButton,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TabelContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

var formatter = new Intl.NumberFormat("sv-SV", {
  style: 'currency',
  currency: 'SEK'
});

const useStyles = makeStyles((theme) => {

})

Font.register("https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf", {family:"roboto"});

const styles = StyleSheet.create({
  page:{
    marginTop:"10pt",
    flexDirection: "column",
    backgroundColor: 'white',
  },
  section:{
    margin: 20,
    padding: 10,
    flexGrow: 1
  },
  headerContent:{
    marginTop:20,
    marginLeft:20,
    marginRight:20,
    flexDirection: "column",
  },
  headerRow:{
    padding: 10,
    flexDirection: "row"
  },
  headerRight:{
    paddingLeft:"10pt",
    flexDirection: "column",
    width:"11cm"
  },
  headerLeft:{
    flexDirection: "column",
    paddingLeft:"2cm",
    width:"9.5cm",
  },
  image:{
    width: "150px",
    height:"50px"
  },
  p:{
    fontSize:"9pt",
    fontWeight:"medium",
  },
  pBold:{
    fontSize:"9pt",
    fontWeight:"medium",
    fontFamily:"roboto",
    fontWeight:700
  },

  p2:{
    fontSize:"10pt",
    lineHeight:1.2,
  },
  p2Bold:{
    fontFamily:"roboto",
    fontSize:"10pt",
    lineHeight:1.2,
    fontWeight:700
  },
  p3:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454"
  },
  p3Bold:{
    fontSize:"8pt",
    fontWeight:"normal",
    lineHeight:1.2,
    color: "#545454",
    fontFamily:"roboto",
    fontWeight:700
  },
  h4:{
    fontSize:"24pt",
    fontWeight:"bold",
  },
  h4Bold:{
    fontSize:"24pt",
    fontFamily:"roboto",
    fontWeight:700
  },
  h5:{
    fontSize:"18pt",
    fontWeight:"bold",
  },
  h5Bold:{
    fontSize:"18pt",
    fontWeight:"bold",
    fontFamily:"roboto",
    fontWeight:700
  },

  textContainer:{
    marginTop:10
  },
  body:{
    margin:50,
    marginTop:10,
    padding:10,
    height: "70%"
  },
  column:{
    flexDirection:"column"
  },
  row:{
    flexDirection:"row"
  },
})

function DocumentListElement(params){
  const title       = params.title;
  const description = params.description;
  const value       = params.value
  const meta        = params.meta

  return(
    <View style={[styles.textContainer, {borderBottom: `2pt solid ${meta.color}`, paddingBottom:10}]}>
      <Text style={[styles.p2Bold, {color:`${meta.color}`}]}>{title}</Text>
      <Text style={styles.p}>{description}</Text>
      <Text style={{fontSize:"8pt", color:`${meta.color}`, textAlign:"right", marginTop:"5pt"}}>{value}</Text>
    </View>

  )
}

function DocumentList(params){
  //const [es, setEs] = useState(params.elements);
  const meta = params.meta
  const listItems = params.listItems
  const es = [
    {
      name: "ID-assistans", 
      description: "Spärrar din identitet om någon försöker kapa den.", 
      price:"100"
    },
    {
      name: "Kortspärr", 
      description: "Spärra dina kort och värdehandlingar met ETT telefonnummer", 
      price:"100"
    },
    {
      name: "Säkerhetskortet", 
      description: "Skyddar din plånbok mot skimning.", 
      price:"100"
    },
    {
      name: "Nyckelbricka", 
      description: "Fäst på nyckelbrickan och skyddar nycklarna.", 
      price:"100"
    }
  ]
  return(
    <View style={{padding:0, marginTop:0}}>
      <Text style={{fontSize:"12pt"}}>Dina tjänster</Text>
      {
        listItems.map((e) => (
          <DocumentListElement 
            title={e.productCode} 
            description={e.description} 
            value={e.price}
            meta={meta}
          />
        ))
      }
    </View>
  )
}

function DocumentColumn(params){
  const classes = useStyles();

  const [width, setWidth] = useState(params.width);
  
  return(
    <View style={[styles.textContainer], {width:`${width}cm`}}>
      {params.children}
    </View>
  )
}

function PdfText(params){
  const [text, setText]   = useState("")
  const [style, setStyle] = useState({}) 

  useEffect(() => {
    setText(params.text);
    setStyle(params.style);
  },[])

  return(
    <>
      {text.split("::BR::").map(e => (
        <>
        <Text 
          style={{
            background:style.background,
            color: style.color,
            fontFamily:"roboto",
            fontSize: style.fontSize,
            fontWeight: style.fontWeight,
          }}>
          {e}
        </Text>
        <Text style={styles.p2}>
          {" "}
        </Text>
        </>
      ))}
    </>
  )
}

export default function PdfDocument(params){
  const classes = useStyles();
  const theme = useTheme()

  const customer = params.customer;
  const order    = params.order;
  const services = params.services;
  const meta = params.meta;

  return(
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{borderTop: `10pt solid ${meta.color}`}}>
            <View style={styles.headerContent}>
              <Image
                  src={meta.logo.url}
                  style={{width: meta.logo.width, height:meta.logo.height}}
              />
            </View>
            <View style={styles.headerRow}>
              <View style={styles.headerRight}>
                <Text style={styles.p}>Ordernummer: {customer.customerid}</Text>
              </View>
              <View style={styles.headerLeft}>
                <Text style={styles.p}>{customer.name}</Text>
                <Text style={styles.p}>{customer.adress}</Text>
                <Text style={styles.p}>{customer.zipCode} {customer.municipality}</Text>
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.column}>
              <View style={styles.row}>

                <DocumentColumn width={6}>
                  <DocumentList meta={meta} listItems={services}/>
                  <View style={styles.row}>
                    <DocumentColumn width={3} style={{marginTop:"10pt"}}>
                      <Text 
                        style={{fontSize:"8pt", marginTop:"10pt"}}>
                        Totalt 
                      </Text>
                      <Text style={{fontSize:"8pt", marginTop:"2pt"}}>
                        Betalning
                      </Text>
                      <Text style={{fontSize:"8pt", marginTop:"2pt"}}>
                        Bindningstid 
                      </Text>
                      {priceConstructor(
                        order.subscriptionLength, 
                        order.billingInterval,
                        order.price
                      ) !== "N/A" ?
                        <Text style={{fontSize:"8pt"}}>
                          Periodbetalning
                        </Text>
                        :
                          null
                      }

                    </DocumentColumn>
                    <DocumentColumn width={3} style={{marginTop:"10pt"}}>
                      <Text 
                        style={{textAlign:"right", fontSize:"8pt", marginTop:"10pt"}}>
                        {formatter.format(order.price)} 
                      </Text>
                      <Text 
                        style={{textAlign:"right", fontSize:"8pt", marginTop:"2pt"}}>
                        {order.billingInterval}
                      </Text>
                      <Text 
                        style={{textAlign:"right", fontSize:"8pt", marginTop:"2pt"}}>
                        {order.subscriptionLength}
                      </Text>
                      {priceConstructor(
                        order.subscriptionLength, 
                        order.billingInterval,
                        order.price
                      ) !== "N/A" ?
                        <Text 
                          style={{textAlign:"right", fontSize:"8pt", marginTop:"2pt"}}>
                          {
                          priceConstructor(
                            order.subscriptionLength, 
                            order.billingInterval,
                            order.price
                          )
                          }
                        </Text>
                        :
                          null
                      }

                    </DocumentColumn>
                  </View>
                </DocumentColumn>
                <View style={{width:"10cm", marginLeft:30, height:"7cm"}}>
                  <View style={styles.textContainer}>
                    <Text style={styles.p}>2021-02-11</Text>
                    <Text 
                      style={{
                        background:meta.data.title.style.background,
                        color: meta.data.title.style.color,
                        fontFamily:"roboto",
                        fontSize: meta.data.title.style.fontSize,
                        fontWeight: meta.data.title.style.fontWeight,
                      }}
                    >
                      {meta.data.title.text}
                    </Text>
                  </View>
                  <View style={{marginTop: 10}}>
                      <>
                        {meta.data.intro.text.split("::BR::").map(e => (
                          <>
                          <Text 
                            style={{
                              background:meta.data.intro.style.background,
                              color: meta.data.intro.style.color,
                              fontSize: meta.data.intro.style.fontSize,
                            }}>
                            {e}
                          </Text>
                          <Text style={styles.p2}>
                            {" "}
                          </Text>
                          </>
                        ))}
                      </>
                  </View>  
                  <View style={{marginTop: 20, backgroundColor: "#FAFAFA", padding: 10}}>
                    {meta.data.p1 !== undefined ?
                      <>
                        {meta.data.p1.text.split("::BR::").map(e => (
                          <>
                          <Text 
                            style={{
                              background:meta.data.p1.style.background,
                              color: meta.data.p1.style.color,
                              fontSize: meta.data.p1.style.fontSize,
                              fontWeight: meta.data.p1.style.fontWeight,
                            }}>
                            {e}
                          </Text>
                          <Text style={styles.p2}>
                            {" "}
                          </Text>
                          </>
                        ))}
                      </>
                      :
                      null
                    }
                  </View>  
                  <View>
                    <Text 
                      style={{
                        fontFamily:"roboto",
                        background:meta.data.title2.style.background,
                        color: meta.data.title2.style.color,
                        fontSize: meta.data.title2.style.fontSize,
                        fontWeight: meta.data.title2.style.fontWeight,
                      }}>
                      {meta.data.title2.text}
                    </Text>
                    {meta.data.subtitle2 !== undefined ? 
                      <>
                        {meta.data.subtitle2.text == "Det är så enkelt som" ? 
                          <>
                            <Text style={styles.p}>Det är så enkelt som</Text>
                            <View style={styles.row}>
                              <Text 
                                style={{
                                  fontSize:"14pt", 
                                  border: `1pt solid ${meta.color}`,
                                  borderRadius:"20pt",
                                  padding:"4pt",
                                  margin:"10pt",
                                  color: `${meta.color}`
                              }}>1
                              </Text>
                              <Text 
                                style={{
                                  fontSize:"14pt", 
                                  border: `1pt solid ${meta.color}`,
                                  borderRadius:"20pt",
                                  padding:"4pt",
                                  margin:"10pt",
                                  color: `${meta.color}`
                              }}>2
                              </Text>
                              <Text 
                                style={{
                                  fontSize:"14pt", 
                                  border: `1pt solid ${meta.color}`,
                                  borderRadius:"20pt",
                                  padding:"4pt",
                                  margin:"10pt",
                                  color: `${meta.color}`
                              }}>3
                              </Text>
                            </View>
                          </>
                          :
                          <>
                            <Text 
                              style={{
                                background:meta.data.subtitle2.style.background,
                                color: meta.data.subtitle2.style.color,
                                fontSize: meta.data.subtitle2.style.fontSize,
                                fontWeight: meta.data.subtitle2.style.fontWeight,
                              }}>
                              {meta.data.subtitle2.text}
                            </Text>
                          </>
                        }
                      </>
                      :
                      null
                    }
                    </View>
                    <View>
                      {meta.data.list2 !== undefined ? 
                        <View style={{flexDirection:"column", width:"10cm", padding:5}}>
                          {meta.data.list2.text.map((e,i) => (
                            <>
                              <Text style={styles.p2}>
                                {i+1}. {meta.data.list2.text[i]}
                              </Text>
                              <Text>
                                {" "}
                              </Text>
                            </>
                          ))}
                        </View>
                        :
                        null
                      }
                      {meta.data.p2 !== undefined ? 
                        <View style={{marginTop:"8pt"}}>
                          {meta.data.p2.text.split("::BR::").map(e => (
                            <>
                            <Text style={styles.p2}>
                              {e}
                            </Text>
                            <Text style={styles.p2}>
                              {" "}
                            </Text>
                            </>
                          ))}
                        </View>
                        :
                        null
                      }
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{
            borderBottom: `10pt solid ${meta.color}`, 
            borderTop: `2pt solid ${meta.color}`, 
            paddingTop: 10, 
            alignItems:"bottom"
            }}>
            <View style={{
              flexDirection:"row", 
              paddingBottom:20, 
              paddingLeft:20, 
              paddingRight: 20
              }}>
              <View style={{width:"7cm"}}>
                <Text style={styles.p3Bold}>
                  Telefon
                </Text>
                <Text style={styles.p3}>
                  {meta.data.footer.phone}
                </Text>
                <Text style={styles.p3}>
                  (öppet vardagar 9-16, lunchstängt 13-14)
                </Text>
              </View>
              <View style={{width:"4cm", alignItems:"center"}}>
                <Text style={styles.p3Bold}>
                  Internet 
                </Text>
                <Text style={styles.p3}>
                  {meta.data.footer.website}
                </Text>
                <Text style={styles.p3}>
                  {meta.data.footer.email}
                </Text>
              </View>
              <View style={{width:"8cm", alignItems:"right"}}>
                <Text style={styles.p3Bold}>
                  Adress 
                </Text>
                <Text style={styles.p3}>
                  Incremenet Security Group Gothia AB
                </Text>
                <Text style={styles.p3}>
                  {meta.data.footer.adress}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
}
